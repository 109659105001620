import useQueryWithRefresh from 'hooks/helpers/useQueryWithRefresh/useQueryWithRefresh';
import {
    listAuthorization,
    listAuthorizationWithoutOrder,
} from 'services/ether/case-manager/authorizations';
import { ListAuthorizationEP } from 'services/ether/case-manager/authorizations/types';

const useListAuthorization = (
    params: ListAuthorizationEP.Params.Many,
    queryOptions?: {
        enabled?: boolean;
    }
) => {
    const queryKey = ['authorization', 'list', params];

    const query = useQueryWithRefresh<Ether.CaseManager.Authorization[], Error>(
        {
            queryKey,
            queryFn: ({ signal }) =>
                params.options?.withoutBlockOrder
                    ? listAuthorizationWithoutOrder({
                          ...params,
                          signal,
                      })
                    : listAuthorization({
                          ...params,
                          signal,
                      }),
            ...queryOptions,
        }
    );

    return query;
};

export default useListAuthorization;
