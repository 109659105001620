import { useLocalization } from 'hooks/context/useLocalization';
import { Badge, BadgeProps } from 'primereact/badge';

const BlockOrderStatusBadge: React.FC<{
    blockOrder: Ether.CaseManager.BlockOrder;
    badgeProps?: BadgeProps;
}> = ({ blockOrder, badgeProps }) => {
    const status = blockOrder.status;
    const [localization] = useLocalization();
    if (!status) return '-';
    badgeProps = {
        ...badgeProps,
        severity: 'info',
        value: status.toUpperCase(),
    };
    switch (status) {
        case 'draft':
            badgeProps.severity = undefined;
            badgeProps.value =
                localization.components.models.blockOrder.badge.status.draft.toLocaleUpperCase();
            break;
        case 'done':
            badgeProps.severity = 'success';
            badgeProps.value =
                localization.components.models.blockOrder.badge.status.done.toLocaleUpperCase();
            break;
    }
    return <Badge {...badgeProps} />;
};

export default BlockOrderStatusBadge;
