import { Dropdown } from 'components/ethercity-primereact';
import { useLocalization } from 'hooks/context/useLocalization';
import { DropdownProps } from 'primereact/dropdown';

export type OrderDocTypeOptions = 'administrative' | 'judicial' | null;

const OrderDocTypeDropdown: React.FC<DropdownProps> = (props) => {
    const [localization] = useLocalization();
    return (
        <Dropdown
            className='w-full'
            required
            label={localization.common.type}
            options={[
                {
                    label: localization.components.models.blockOrder.badge.type
                        .administrative,
                    value: 'administrative',
                },
                {
                    label: localization.components.models.blockOrder.badge.type
                        .judicial,
                    value: 'judicial',
                },
            ]}
            {...props}
        />
    );
};

export default OrderDocTypeDropdown;
