import { Dropdown } from 'components/ethercity-primereact';
import { useLocalization } from 'hooks/context/useLocalization';
import { DropdownProps } from 'primereact/dropdown';

const UserRoleDropdown: React.FC<
    Omit<DropdownProps, 'options'> & { label: string; required?: boolean }
> = (props) => {
    const [localization] = useLocalization();

    return (
        <Dropdown
            {...props}
            options={[
                {
                    label: localization.components.models.user.badge.role.admin,
                    value: 'admin',
                },
                {
                    label: localization.components.models.user.badge.role.operator,
                    value: 'operator',
                },
                {
                    label: localization.components.models.user.badge.role.analyst,
                    value: 'analyst',
                },
                {
                    label: localization.components.models.user.badge.role.authorizerUser,
                    value: 'authorizer-user',
                },
                {
                    label: localization.components.models.user.badge.role
                        .authorizerStrict,
                    value: 'authorizer-strict',
                },
            ]}
        />
    );
};

export default UserRoleDropdown;
