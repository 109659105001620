import { useLocalization } from '../../context/useLocalization';
import { useMemo, useState } from 'react';
import { SendToAuthoritySignEP } from 'services/ether/case-manager/authorizations/types';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import useSendAuthorizationToAuthority from 'hooks/mutations/authorization/useSendAuthorizationToAuthority';

type PartialData = { authorization: Ether.CaseManager.Authorization };

const SendToAuthorityDialog: React.FC<{
    data: PartialData;
    isLoading?: boolean;
    onRespond: (data: SendToAuthoritySignEP.Data) => void;
    onHide: () => void;
}> = ({ data, onRespond, isLoading, onHide }) => {
    const [localization] = useLocalization();

    const componentLocale =
        localization.components.models.authorization.views.sendToAuthoritySign;

    return (
        <Dialog
            visible={true}
            onHide={onHide}
            header={componentLocale.header}
            footer={
                <>
                    <Button
                        label={localization.components.common.button.cancel}
                        severity='danger'
                        onClick={onHide}
                        disabled={isLoading}
                    />
                    <Button
                        label={localization.components.common.button.send}
                        severity='success'
                        onClick={() =>
                            onRespond({
                                authorization_id: data.authorization._id,
                                status: 'pending',
                            })
                        }
                        disabled={isLoading}
                        loading={isLoading}
                    />
                </>
            }
        >
            <div className='flex flex-col gap-2 items-center'>
                <span>
                    {componentLocale.description.replace(
                        '{name}',
                        data.authorization.name
                    )}
                </span>
                <strong>{localization.common.cannotBeUndone}</strong>
            </div>
        </Dialog>
    );
};

const useSendAuthorizationToAuthorityDialog = (options?: {
    onSuccess?: () => void;
}) => {
    const { onSuccess: onSuccessParam } = options ?? {};

    const [visible, setVisible] = useState<null | PartialData>(null);
    const show = (data: PartialData) => setVisible(data);
    const hide = () => setVisible(null);

    const onSuccess = () => {
        if (onSuccessParam) onSuccessParam();
        hide();
    };

    const { sendToAuthority, isLoading, error } =
        useSendAuthorizationToAuthority({
            onSuccess,
        });

    const Dialog = useMemo(
        () =>
            visible && (
                <SendToAuthorityDialog
                    data={visible}
                    onRespond={(data) => sendToAuthority(data)}
                    isLoading={isLoading}
                    onHide={hide}
                />
            ),
        [sendToAuthority, isLoading, visible]
    );

    return {
        dialog: Dialog,
        show: (data: { authorization: Ether.CaseManager.Authorization }) =>
            show(data),
        isLoading: isLoading,
        error: error,
    };
};

export default useSendAuthorizationToAuthorityDialog;
