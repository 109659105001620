import React from 'react';

import { Button } from 'primereact/button';

import './style.css';

const formatDate = (date: Date) => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    const hours = date.getHours().toString().padStart(2, '0');
    const mins = date.getMinutes().toString().padStart(2, '0');
    const secs = date.getSeconds().toString().padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${mins}:${secs}`;
};

interface IDateBadge {
    value: Date | null;
    tooltipText?: string;
    hideTooltip?: boolean;
}

export const DateBadge: React.FC<IDateBadge> = ({
    value,
    tooltipText,
    hideTooltip,
}) => {
    const finalText = tooltipText ?? (value ? formatDate(value) : undefined);
    hideTooltip = hideTooltip || !finalText;

    return (
        <Button
            tooltipOptions={{ autoHide: false, disabled: hideTooltip }}
            rounded
            severity='info'
            className='date-badge'
            label={value ? formatDate(value).slice(0, 10) : '-'}
            tooltip={finalText}
        />
    );
};
