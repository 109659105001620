const DisplayCountBox: React.FC<{
    label: string;
    count: number | string;
    dark?: boolean;
}> = ({ label, count, dark }) => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                gap: '8px',
                backgroundColor: !dark
                    ? 'var(--white)'
                    : 'var(--very-darkish-blue-menu)',
                border: dark ? '1px solid var(--white)' : undefined,
                alignItems: 'center',
                padding: '16px',
                width: '120px',
                borderRadius: '8px',
            }}
        >
            <span
                style={{
                    color: dark ? 'var(--white)' : 'var(--very-darkish-blue)',
                    fontSize: '12px',
                    textAlign: 'center',
                    alignItems: 'center',
                    minHeight: '42px',
                }}
            >
                {label.toUpperCase()}
            </span>
            <span
                style={{
                    color: dark ? 'var(--white)' : 'var(--very-darkish-blue)',
                    fontWeight: 'bold',
                    fontSize: '32px',
                }}
            >
                {count}
            </span>
        </div>
    );
};

export default DisplayCountBox;
