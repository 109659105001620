import useDetailBlockOrder from './useDetailBlockOrder';
import { SortOrder } from 'primereact/api';

const useDetailRecentBlockOrder = (
    projectId: string,
    status: 'done' | 'draft',
    enabled?: boolean
) => {
    const query = useDetailBlockOrder(
        {
            project_id: projectId,
            options: {
                devFilters: {
                    status,
                },
                limit: 5,
                sort: {
                    field: 'created_at',
                    order: SortOrder.DESC,
                },
            },
        },
        'list',
        {
            enabled,
        }
    );

    return query;
};

export default useDetailRecentBlockOrder;
