import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { Button } from 'primereact/button';
import { FilterMatchMode } from 'primereact/api';
import { Dialog, DialogProps } from 'primereact/dialog';

import { useAuth } from 'hooks/context/useAuth';
import { useToast } from 'hooks/context/useToast';

import {
    Dropdown,
    InputText,
    InputTextArea,
    MultiSelect,
} from 'components/ethercity-primereact';

import { getGroup, listGroups } from 'services/ether/base-api';
import {
    listProjects,
    upsertProject,
} from 'services/ether/case-manager/projects';

import { getFilterData } from 'utils/datatable';
import { useLocalization } from 'hooks/context/useLocalization';
import CMBreadCrumb from 'components/page/CMBreadCrumb';
import ProjectsDataTable from 'components/models/Projects/ProjectsDataTable';
import CMPaginator from 'components/datatable/CMPaginator';
import useListUsers from 'hooks/queries/user/useListUsers';
import useInitDataTableState from 'hooks/helpers/useInitDataTableState';

const UpsertProjectModal: React.FC<
    {
        initData: Ether.CaseManager.Project | null;
        onUpsert: (data: {
            _id?: string;
            name: string;
            group_id: string;
            authorizers: string[];
            operators: string[];
            config?: { [key: string]: any };
            block_checker_id?: string;
        }) => void;
        isLoading: boolean;
    } & DialogProps
> = ({ initData, onUpsert, isLoading, ...props }) => {
    const { user, permissions } = useAuth();
    const [localization] = useLocalization();

    const [projectData, setProjectData] = useState<{
        _id?: string;
        name: string;
        group_id: string | null;
        authorizers: string[];
        operators: string[];
        config: string;
        block_checker_id: string;
    }>({
        _id: initData?._id,
        name: initData?.name ?? '',
        group_id: initData?.group_id ?? user.data?.group_id ?? '',
        authorizers: initData?.authorizers ?? [],
        operators: initData?.operators ?? [],
        config: initData?.config ? JSON.stringify(initData.config) : '',
        block_checker_id: initData?.block_checker_id ?? '',
    });

    const [formFields, setFormFields] = useState({
        name: projectData.name !== '',
        group_id: !!user.data?.group_id,
        config: true,
    });
    const isFormValid = Object.values(formFields).every(
        (value) => value === true
    );

    const groupsQuery = useQuery({
        queryKey: ['list-groups-all'],
        queryFn: ({ signal }) => listGroups({ signal, limit: 9999 }),
    });

    const usersQuery = useListUsers(
        {
            options: {
                devFilters: {
                    group_id: projectData.group_id,
                },
                limit: 9999,
            },
        },
        { enabled: !!projectData.group_id && permissions.listUsersByProject }
    );

    const handleUpsert = () => {
        const {
            _id,
            name,
            group_id,
            authorizers,
            operators,
            config,
            block_checker_id,
        } = projectData;
        if (group_id == null) return;
        onUpsert({
            _id,
            name,
            group_id,
            authorizers,
            operators,
            config: config === '' ? undefined : JSON.parse(config),
            block_checker_id:
                block_checker_id === '' ? undefined : block_checker_id,
        });
    };

    return (
        <Dialog
            {...props}
            header={
                projectData._id
                    ? localization.components.models.project.button.edit
                    : localization.components.models.project.button.new
            }
            footer={
                <>
                    <Button
                        label={
                            projectData._id
                                ? localization.components.models.project.button
                                      .edit
                                : localization.components.models.project.button
                                      .new
                        }
                        onClick={handleUpsert}
                        disabled={!isFormValid || isLoading}
                    />
                    <Button
                        label={localization.components.common.button.cancel}
                        severity='danger'
                        onClick={props.onHide}
                    />
                </>
            }
        >
            <div
                style={{
                    display: 'grid',
                    gap: '8px',
                    gridTemplateColumns: 'repeat(3, 1fr)',
                    maxWidth: '50em',
                }}
            >
                <InputText
                    required
                    label={localization.fields.project.name}
                    value={projectData.name}
                    onChange={(e) =>
                        setProjectData((old) => ({
                            ...old,
                            name: e.target.value,
                        }))
                    }
                    validationCallback={(isValid) => {
                        setFormFields((old) => ({
                            ...old,
                            name: isValid,
                        }));
                    }}
                    validations={[
                        {
                            validationError: 'Field is required',
                            validate: (value) => {
                                if (!value || value === '') return false;
                                return true;
                            },
                        },
                    ]}
                    disabled={isLoading}
                />
                <Dropdown
                    required
                    label={localization.fields.project.group}
                    style={{ width: '100%' }}
                    optionLabel='name'
                    optionValue='_id'
                    options={groupsQuery.data}
                    value={projectData.group_id}
                    onChange={(e) => {
                        setProjectData((old) => ({
                            ...old,
                            authorizers: [],
                            receivers: [],
                        }));
                        setFormFields((old) => ({
                            ...old,
                            group_id: !!e.target.value,
                        }));
                        setProjectData((old) => ({
                            ...old,
                            group_id: e.target.value,
                        }));
                    }}
                    disabled={
                        !permissions.createProjectWithDifferentGroup ||
                        isLoading
                    }
                />
                <InputText
                    required={false}
                    label={localization.fields.project.blockCheckerId}
                    value={projectData.block_checker_id}
                    onChange={(e) =>
                        setProjectData((old) => ({
                            ...old,
                            block_checker_id: e.target.value,
                        }))
                    }
                />
                {permissions.listUsersByProject && (
                    <MultiSelect
                        label={localization.fields.project.authorizers}
                        loading={usersQuery.isLoading}
                        placeholder={
                            projectData.group_id
                                ? localization.components.models.project.form
                                      .selectAuthorizersPlaceholder
                                : undefined
                        }
                        value={projectData.authorizers}
                        onChange={(e) => {
                            setProjectData((old) => ({
                                ...old,
                                authorizers: e.target.value,
                            }));
                        }}
                        options={usersQuery.data?.filter(
                            (user) =>
                                user.data?.auth?.level.startsWith(
                                    'authorizer'
                                ) && user.group_id === projectData.group_id
                        )}
                        optionValue='_id'
                        optionLabel='username'
                        style={{ width: '100%' }}
                        wrapperStyle={{
                            gridColumn: 'span 3',
                        }}
                        display='chip'
                    />
                )}
                {permissions.listUsersByProject && (
                    <MultiSelect
                        label={localization.fields.project.operators}
                        loading={usersQuery.isLoading}
                        placeholder={
                            projectData.group_id
                                ? localization.components.models.project.form
                                      .selectOperatorsPlaceholder
                                : undefined
                        }
                        value={projectData.operators}
                        onChange={(e) => {
                            setProjectData((old) => ({
                                ...old,
                                operators: e.target.value,
                            }));
                        }}
                        options={usersQuery.data?.filter(
                            (user) =>
                                user.data?.auth?.level === 'operator' &&
                                user.group_id === projectData.group_id
                        )}
                        optionValue='_id'
                        optionLabel='username'
                        style={{ width: '100%' }}
                        wrapperStyle={{
                            gridColumn: 'span 3',
                        }}
                        display='chip'
                    />
                )}
                <InputTextArea
                    wrapperStyle={{
                        gridColumn: 'span 3',
                    }}
                    label={localization.fields.project.config}
                    value={projectData.config}
                    onChange={(e) =>
                        setProjectData((old) => ({
                            ...old,
                            config: e.target.value,
                        }))
                    }
                    validationCallback={(isValid) => {
                        setFormFields((old) => ({
                            ...old,
                            config: isValid,
                        }));
                    }}
                    validations={[
                        {
                            validationError: 'Must be a valid JSON',
                            validate: (value) => {
                                if (!value || value === '') return true;
                                if (!value.trim().startsWith('{')) return false;
                                try {
                                    JSON.parse(value);
                                    return true;
                                } catch {
                                    return false;
                                }
                            },
                        },
                    ]}
                />
            </div>
        </Dialog>
    );
};

const ListProjects = () => {
    const toast = useToast();
    const { permissions } = useAuth();
    const queryClient = useQueryClient();
    const [localization] = useLocalization();

    const [upsertDialogOptions, setUpsertDialogOptions] = useState<{
        visible: boolean;
        data: Ether.CaseManager.Project | null;
    }>({
        visible: false,
        data: null,
    });

    const { filters, setFilters, pageOptions, setPageOptions } =
        useInitDataTableState({
            filters: {
                _cm_name_id: getFilterData(FilterMatchMode.CONTAINS),
                group_id: getFilterData(FilterMatchMode.EQUALS),
            },
        });

    const projectsQuery = useQuery<CaseManagerApp.ExtendedProject[]>({
        queryKey: ['list-project', pageOptions, filters],
        queryFn: async ({ signal }) => {
            const projects = await listProjects({
                options: {
                    rawFilters: filters,
                    limit: pageOptions.rows,
                    offset: (pageOptions.page - 1) * pageOptions.rows,
                },
                signal,
            });

            const projectsPromises = projects.map((p) => {
                return new Promise<CaseManagerApp.ExtendedProject>(
                    (resolve) => {
                        if (!p.group_id)
                            resolve({
                                ...p,
                                group_name: null,
                            });
                        else {
                            queryClient
                                .fetchQuery({
                                    queryKey: ['get-group', p.group_id],
                                    queryFn: () => getGroup(p.group_id),
                                })
                                .then((group) =>
                                    resolve({
                                        ...p,
                                        group_name: group?.name ?? null,
                                    })
                                );
                        }
                    }
                );
            });

            return Promise.all(projectsPromises);
        },
    });

    const projectUpsertMutation = useMutation<
        { _id: string; status: 'created' | 'updated' },
        Error,
        {
            _id?: string;
            name: string;
            group_id: string;
            authorizers: string[];
            operators: string[];
            config?: { [key: string]: any };
            block_checker_id?: string;
        }
    >({
        mutationFn: (data) => upsertProject(data),
        onSuccess: (data, { name }) => {
            toast.show({
                summary:
                    data.status === 'created'
                        ? 'Created project'
                        : 'Updated project',
                detail: `Project ${name} (${data._id}) ${data.status}`,
                severity: 'success',
                life: 5000,
            });
            projectsQuery.refetch();
            onProjectUpsertModalHide();
        },
        onError: (err) => {
            toast.show({
                summary: 'Unhandled error',
                detail: err.message,
                severity: 'error',
                life: 5000,
            });
        },
    });

    const onProjectUpsertModalShow = (data?: Ether.CaseManager.Project) => {
        setUpsertDialogOptions({
            visible: true,
            data: data ?? null,
        });
    };

    const onProjectUpsertModalHide = () => {
        setUpsertDialogOptions(() => ({
            data: null,
            visible: false,
        }));
    };

    const handleOnUpsert = (data: {
        _id?: string;
        name: string;
        group_id: string;
        authorizers: string[];
        operators: string[];
        config?: { [key: string]: any };
        block_checker_id?: string;
    }) => {
        projectUpsertMutation.mutate(data);
    };

    return (
        <section>
            <UpsertProjectModal
                key={upsertDialogOptions.data?._id}
                initData={upsertDialogOptions.data}
                onUpsert={handleOnUpsert}
                visible={upsertDialogOptions.visible}
                onHide={onProjectUpsertModalHide}
                isLoading={projectUpsertMutation.isPending}
            />
            <CMBreadCrumb />
            <h2>{localization.models.project.plural}</h2>
            {permissions.createProjects && (
                <Button
                    label={localization.components.models.project.button.new}
                    icon='pi pi-plus'
                    onClick={() => onProjectUpsertModalShow()}
                />
            )}
            <CMPaginator
                page={pageOptions.page}
                rows={pageOptions.rows}
                onPageChange={(options) => setPageOptions(options)}
                disableNext={
                    (projectsQuery.data?.length ?? 0) < pageOptions.rows
                }
                onRefresh={() => projectsQuery.refetch()}
            />
            <ProjectsDataTable
                loading={projectsQuery.isLoading}
                value={projectsQuery.data}
                filters={filters}
                onFilter={(e) => setFilters(e.filters)}
                onEdit={(p) => onProjectUpsertModalShow(p)}
            />
        </section>
    );
};

export default ListProjects;
