import { InputText } from 'components/ethercity-primereact';
import { useLocalization } from 'hooks/context/useLocalization';
import { InputTextProps } from 'primereact/inputtext';
import isValidEmail from 'utils/isValidEmail';

const InputEmail: React.FC<InputTextProps & { label: string }> = ({
    label,
    ...rest
}) => {
    const [localization] = useLocalization();

    return (
        <InputText
            label={label}
            {...rest}
            validations={[
                {
                    validate: (s) => isValidEmail(s),
                    validationError: localization.validations.generic.invalidEmail,
                },
            ]}
        />
    );
};

export default InputEmail;
