import NameIdAggregator from 'components/datatable/NameIdAggregator';
import {
    Checkbox,
    DateBadge,
    ObjectDisplayModal,
} from 'components/ethercity-primereact';
import { useAuth } from 'hooks/context/useAuth';
import { useLocalization } from 'hooks/context/useLocalization';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import { Fragment, useRef, useState } from 'react';
import TargetTypeBadge from 'components/models/Targets/TargetTypeBadge';
import { CMDataTableBaseProps } from 'components/datatable/CMDataTable/types';
import CMDataTable from 'components/datatable/CMDataTable';
import TargetEnrichmentField from '../TargetEnrichmentField';
import DetailTargetModal from '../DetailTargetModal';
import {
    getESTICurrentStatus,
    translateSafeIpReasons,
    truncateTargetName,
} from 'utils/target';
import { getFilterData } from 'utils/datatable';
import useValidateTarget from 'hooks/mutations/target/useValidateTarget';
import { Sidebar } from 'primereact/sidebar';
import { confirmDialog } from 'primereact/confirmdialog';

import './styles.css';
import { Badge } from 'primereact/badge';
import SafeIPRules from 'components/views/SafeIPRules';

import {
    getDefaultTypeOptions,
    getPreRejectedReasons,
    getSafeIpOptions,
    getTargetAnswer,
    getTargetMeta,
    getYesNoOptions,
    verifyIfTargetCanBeValidated,
} from './utils';
import {
    DataTableExpandedRows,
    DataTableValueArray,
} from 'primereact/datatable';
import OperatorsTargetSummary from 'components/models/Operators/OperatorsTargetSummary';
import ReservedIpBadge from '../ReservedIpBadge';
import YesNoBadge from 'components/display/YesNoBadge';
import { FilterMatchMode } from 'primereact/api';
import useCopyToClipboard from 'hooks/helpers/useCopyToClipboard';
import useRemoveTargetValidation from 'hooks/mutations/target/useRemoveTargetValidation';
import AdaptableTooltip from 'components/misc/AdaptableTooltip';
import useDownloadEvidencesFromTarget from 'hooks/queries/evidence/useDownloadEvidencesFromTarget';
import { ProgressBar } from 'primereact/progressbar';
import useUpdateTargetOrderStatusDialog from 'hooks/dialogs/target/useUpdateTargetOrderStatusDialog';

const TargetMenu: React.FC<{
    target: Ether.CaseManager.Target.Detailed;
    onShowDetail: (target: Ether.CaseManager.Target.Detailed) => void;
}> = ({ target, onShowDetail }) => {
    const { permissions } = useAuth();
    const [localization] = useLocalization();

    const menuRef = useRef<Menu>(null);

    const menuItems: MenuItem[] = [
        ...(permissions.debugAuthorizations
            ? [
                  {
                      id: 'debug',
                      label: localization.components.common.menu.detailData,
                      command: () => onShowDetail(target),
                  },
              ]
            : []),
    ];

    if (menuItems.length <= 0) return null;
    return (
        <>
            <Menu popup ref={menuRef} model={menuItems} />
            <Button
                icon='pi pi-ellipsis-h'
                onClick={(e) => menuRef?.current?.toggle(e)}
            />
        </>
    );
};

type KeyInColumn = keyof CaseManagerApp.ModelColumns['targets'];

const columnFields: { [key in KeyInColumn]: key } = {
    type: 'type',
    tags: 'tags',
    enrichment: 'enrichment',
    created_at: 'created_at',

    'list_data.accessible_ports': 'list_data.accessible_ports',
    'list_data.asn': 'list_data.asn',
    'list_data.br_domains_count': 'list_data.br_domains_count',
    'list_data.country': 'list_data.country',
    'list_data.highest_grading': 'list_data.highest_grading',
    'list_data.hosted_domains_count': 'list_data.hosted_domains_count',
    'list_data.hosted_domains_list': 'list_data.hosted_domains_list',
    'list_data.official_domain': 'list_data.official_domain',
    'list_data.reserved_ip': 'list_data.reserved_ip',
    'list_data.safe_ip': 'list_data.safe_ip',

    'meta.pirate_brand': 'meta.pirate_brand',
    'meta.porta': 'meta.porta',
    'meta.protocolo': 'meta.protocolo',
    'meta.pre-validado': 'meta.pre-validado',
    'meta.modelo': 'meta.modelo',
    'meta.app': 'meta.app',
    'meta.app_versao': 'meta.app_versao',
    'meta.request_type': 'meta.request_type',
    'meta.rule': 'meta.rule',

    answer: 'answer',
    answered_at: 'answered_at',
    blocked_at: 'blocked_at',
    notified: 'notified',
};

export const targetsFilters = {
    _cm_name_id: getFilterData(),
    [columnFields.type]: getFilterData(FilterMatchMode.EQUALS),
    [columnFields['list_data.highest_grading']]: getFilterData(
        FilterMatchMode.EQUALS
    ),
    [columnFields['list_data.safe_ip']]: getFilterData(FilterMatchMode.EQUALS),
    [columnFields['list_data.reserved_ip']]: getFilterData(
        FilterMatchMode.EQUALS
    ),
    [columnFields['list_data.official_domain']]: getFilterData(
        FilterMatchMode.EQUALS
    ),
    [columnFields.notified]: getFilterData(FilterMatchMode.EQUALS),
    [columnFields['list_data.country']]: getFilterData(FilterMatchMode.EQUALS),
};

const TargetsDataTable: React.FC<
    CMDataTableBaseProps & {
        showTags: boolean;
        projectId: string;
        refAuthorization?: Ether.CaseManager.Authorization.Detailed;
        refBlockOrder?: Ether.CaseManager.BlockOrder.Detailed;
        refUnblockOrder?: Ether.CaseManager.UnblockOrder.Detailed;
        showOperatorAnswer?: boolean;
        onValidation?: () => void;
        tokenData?: CaseManagerApp.TokenInfo;
    }
> = ({
    showTags,
    projectId,
    refAuthorization,
    refBlockOrder,
    refUnblockOrder,
    onValidation,
    showOperatorAnswer,
    tokenData,
    ...props
}) => {
    const [localization] = useLocalization();
    const { user, permissions } = useAuth();

    const copyToClipboard = useCopyToClipboard();

    const [expandedRows, setExpandedRows] = useState<
        DataTableExpandedRows | DataTableValueArray | undefined
    >(undefined);

    const currentUserRole = user.role ?? tokenData?.userRole ?? null;
    const currentUserId =
        (currentUserRole === 'operator'
            ? user.data?.operator_data?._id ?? null
            : user.data?._id ?? null) ??
        tokenData?.userId ??
        null;
    const currentUserCompany = user.data?.data?.company_id ?? null;

    const datatableLocale = localization.components.models.target.datatable;
    const targetValidationLocale =
        localization.components.models.target.views.targetValidation;
    const approvalBadge = localization.components.models.target.badge.approval;

    const [viewTargetDialogOptions, setViewTargetDialogOptions] = useState<{
        mode: 'debug' | 'detail';
        data: Ether.CaseManager.Target.Detailed | null;
    }>({
        mode: 'detail',
        data: null,
    });

    const [targetsSelect, setTargetSelect] = useState<{
        targets: {
            _id: string;
            value: string;
        }[];
        mode: 'revert' | 'validate';
        revertMode: 'accepted' | 'rejected' | null;
    } | null>(null);

    const { dialog: updateTargetOrderDialog, updateTargetOrderStatus } =
        useUpdateTargetOrderStatusDialog();
    const {
        validateTargets: respondTarget,
        dialogElement: validateTargetDialog,
        isLoading: validateTargetLoading,
    } = useValidateTarget({
        onRespond: () => {
            setTargetSelect(null);
            if (props.paginatorProps?.onRefresh)
                props.paginatorProps.onRefresh();
            if (onValidation) onValidation();
        },
        token: tokenData?.value,
        defaultBlockDate: refBlockOrder?.start_date,
    });
    const {
        revertTargets,
        isLoading: revertTargetsLoading,
        dialogs: revertTargetDialogs,
    } = useRemoveTargetValidation({
        onRevert: () => setTargetSelect(null),
    });
    const {
        downloadFile: downloadTargetEvidences,
        isDownloading: isDownloadTargetEvidences,
        progress: downloadTargetEvidencesProgress,
        params: downloadTargetEvidencesParams,
    } = useDownloadEvidencesFromTarget();

    const targetValidationPositiveLabelSingular = refAuthorization
        ? localization.components.common.button.approve
        : localization.components.common.button.notBlock;
    const targetValidationPositiveLabelPlural = refAuthorization
        ? targetValidationLocale.approveTargets
        : targetValidationLocale.notBlockTargets;
    const targetValidationPositiveIcon = refAuthorization
        ? 'pi pi-check'
        : 'pi pi-circle';
    const targetValidationRevert = targetValidationLocale.revertTargets;

    const targetValidationNegativeLabelSingular = refAuthorization
        ? localization.components.common.button.reject
        : localization.components.common.button.block;
    const targetValidationNegativeLabelPlural = refAuthorization
        ? targetValidationLocale.rejectTargets
        : targetValidationLocale.blockTargets;
    const targetValidationNegativeIcon = refAuthorization
        ? 'pi pi-times'
        : 'pi pi-ban';

    const targetAnswerApproved = refAuthorization
        ? approvalBadge.approved
        : approvalBadge.blocked;
    const targetAnswerApprovedSeverity = refAuthorization
        ? 'success'
        : 'danger';
    const targetAnswerRejected = refAuthorization
        ? approvalBadge.rejected
        : approvalBadge.notBlocked;
    const targetAnswerRejectedSeverity = refAuthorization
        ? 'danger'
        : 'success';

    const targetSelectLocale =
        localization.components.models.target.views.allTargetDialogConfirmation
            .toSelect;

    type ValidationModes =
        | { mode: 'positive' | 'negative' }
        | { mode: 'revert'; revertMode: 'accepted' | 'rejected' };

    const validateTargets = (
        options: ValidationModes & {
            targets: {
                _id: string;
                value: string;
            }[];
            checkIfAll?: boolean;
        }
    ) => {
        const isPositive = options.mode === 'positive';
        if (refAuthorization) {
            if (options.mode === 'revert') {
                revertTargets({
                    targets: options.targets,
                    authorization_id: refAuthorization._id,
                    validation_type: options.revertMode,
                    checkIfAll: options.checkIfAll,
                    hideConfirm : true,
                });
                return;
            } else {
                respondTarget({
                    accept: isPositive,
                    authorization: refAuthorization,
                    ...options,
                });
            }
        } else if (refBlockOrder)
            respondTarget({
                blocked: !isPositive,
                blockOrder: refBlockOrder,
                ...options,
            });
        else if (refUnblockOrder)
            respondTarget({
                blocked: !isPositive,
                unblockOrder: refUnblockOrder,
                ...options,
            });
    };

    const onTargetViewModalShow = (
        data: Ether.CaseManager.Target.Detailed,
        mode: 'debug' | 'detail'
    ) =>
        setViewTargetDialogOptions({
            mode: mode,
            data: data,
        });

    const onTargetViewModalHide = () =>
        setViewTargetDialogOptions((old) => ({
            ...old,
            data: null,
        }));

    const defaultTypeOptions = getDefaultTypeOptions(localization);
    const safeIpOptions = getSafeIpOptions(localization);
    const yesNoOptions = getYesNoOptions(localization);

    const sortable = 'sortField' in props;
    const expandable =
        permissions.viewOperators && (refBlockOrder || refUnblockOrder);
    const isViewValidatable =
        (refAuthorization &&
            (refAuthorization?.status === 'pending' ||
                refAuthorization?.status === 'draft')) ||
        (refAuthorization?.status === 'approved' &&
            user.role === 'authorizer-strict') ||
        (refBlockOrder &&
            refBlockOrder.status === 'done' &&
            currentUserRole === 'operator') ||
        (refUnblockOrder &&
            refUnblockOrder.status === 'done' &&
            currentUserRole === 'operator');

    const targetsInScreen = props.value as
        | Ether.CaseManager.Target.Detailed[]
        | undefined;

    const canBeApproved = (target: Ether.CaseManager.Target.Detailed) => {
        const baseData = {
            userId: currentUserId,
            userCompanyId: currentUserCompany,
            userRole: currentUserRole,
            target,
        };
        if (refAuthorization)
            return verifyIfTargetCanBeValidated({
                ...baseData,
                authorization: refAuthorization,
            });
        return !!target._user_fields?.can_respond;
    };

    const allowedToRevert =
        permissions.removeTargetValidation && refAuthorization;

    const validatableTargets =
        targetsInScreen?.filter((t) => canBeApproved(t)) ?? [];
    const revertableTargets = allowedToRevert
        ? targetsInScreen?.filter(
              (t) => t._user_fields?.can_revert_validation
          ) ?? []
        : [];

    const handleTargetConfirmation = ({
        targets,
        ...rest
    }: ValidationModes & {
        targets: {
            _id: string;
            value: string;
        }[];
    }) => {
        if (!isViewValidatable || !props.value) return;

        const selectedTargetsOnThisPage = targets.filter(
            ({ _id: id }) => !!targetsInScreen?.find((t) => t._id === id)
        ).length;
        const availabeForValidate = validatableTargets.length;
        const availableForRevert = revertableTargets.length;

        const isRevert = rest.mode === 'revert';

        const matchesPageSize =
            (isRevert ? availableForRevert : availabeForValidate) ===
            selectedTargetsOnThisPage;

        validateTargets({
            targets,
            checkIfAll: matchesPageSize,
            ...rest,
        });
    };

    const getAnswer = (target: Ether.CaseManager.Target.Detailed) => {
        const answer = getTargetAnswer({
            target,
            showOperatorAnswer,
            authorization: refAuthorization,
        });
        return answer;
    };

    const selectAllTargetsHeader = () => {
        if (!revertableTargets.length && !validatableTargets.length)
            return null;
        const allSelected =
            !!targetsSelect && targetsSelect?.mode === 'revert'
                ? revertableTargets.length === targetsSelect.targets.length
                : validatableTargets.length === targetsSelect?.targets.length;
        const populateWithValidatable = () => {
            setTargetSelect({
                targets: validatableTargets,
                mode: 'validate',
                revertMode: null,
            });
        };
        const populateWithRevertable = () => {
            setTargetSelect({
                targets: revertableTargets,
                mode: 'revert',
                revertMode: revertableTargets.find((t) =>
                    t.removed_authorizations?.find(
                        (a) => a?._id === refAuthorization?._id
                    )
                )
                    ? 'rejected'
                    : 'accepted',
            });
        };
        return (
            <Checkbox
                checked={allSelected}
                onClick={() => {
                    if (allSelected) {
                        setTargetSelect(null);
                        return;
                    }
                    if (
                        !revertableTargets.length ||
                        targetsSelect?.mode === 'validate'
                    ) {
                        populateWithValidatable();
                    } else if (
                        !validatableTargets.length ||
                        targetsSelect?.mode === 'revert'
                    ) {
                        populateWithRevertable();
                    } else {
                        confirmDialog({
                            header: targetSelectLocale.title,
                            message: <p>{targetSelectLocale.description}</p>,
                            footer: (props) => (
                                <div className='flex flex-col gap-2'>
                                    <Button
                                        label={targetSelectLocale.option1}
                                        onClick={() => {
                                            populateWithValidatable();
                                            props.reject();
                                        }}
                                    />
                                    <Button
                                        label={targetSelectLocale.option2}
                                        onClick={() => {
                                            populateWithRevertable();
                                            props.reject();
                                        }}
                                    />
                                    <Button
                                        label={
                                            localization.components.common
                                                .button.cancel
                                        }
                                        onClick={() => props.reject()}
                                    />
                                </div>
                            ),
                        });
                    }
                }}
            />
        );
    };

    return (
        <>
            {updateTargetOrderDialog}
            {validateTargetDialog}
            {revertTargetDialogs}
            <ObjectDisplayModal
                header={viewTargetDialogOptions.data?.value}
                visible={
                    !!viewTargetDialogOptions.data &&
                    viewTargetDialogOptions.mode === 'debug'
                }
                displayData={viewTargetDialogOptions.data}
                onHide={onTargetViewModalHide}
                sortKeys={false}
            />
            {viewTargetDialogOptions.data && (
                <DetailTargetModal
                    style={{ width: '50%' }}
                    visible={
                        !!viewTargetDialogOptions.data &&
                        viewTargetDialogOptions.mode === 'detail'
                    }
                    onHide={onTargetViewModalHide}
                    target={viewTargetDialogOptions.data}
                />
            )}
            {isViewValidatable && targetsSelect && (
                <Sidebar
                    position='bottom'
                    visible={!!targetsSelect}
                    onHide={() => {}}
                    showCloseIcon={false}
                    modal={false}
                    header={
                        <div className='flex flex-row justify-between items-center w-screen'>
                            <span>
                                {targetsSelect
                                    ? targetValidationLocale.selectedTargetsCountPlural.replace(
                                          '{count}',
                                          targetsSelect.targets.length.toString()
                                      )
                                    : targetValidationLocale.selectedTargetsCountSingular}
                            </span>
                            <div className='flex flex-row gap-2'>
                                {targetsSelect.mode === 'validate' && (
                                    <>
                                        <Button
                                            label={
                                                targetValidationPositiveLabelPlural
                                            }
                                            severity='success'
                                            icon={targetValidationPositiveIcon}
                                            onClick={() =>
                                                handleTargetConfirmation({
                                                    mode: 'positive',
                                                    targets:
                                                        targetsSelect.targets,
                                                })
                                            }
                                            disabled={
                                                validateTargetLoading ||
                                                props.loading
                                            }
                                        />
                                        <Button
                                            label={
                                                targetValidationNegativeLabelPlural
                                            }
                                            severity='danger'
                                            icon={targetValidationNegativeIcon}
                                            onClick={() =>
                                                handleTargetConfirmation({
                                                    mode: 'negative',
                                                    targets:
                                                        targetsSelect.targets,
                                                })
                                            }
                                            disabled={
                                                validateTargetLoading ||
                                                props.loading
                                            }
                                        />
                                    </>
                                )}
                                {targetsSelect.mode === 'revert' && (
                                    <Button
                                        label={targetValidationRevert}
                                        severity='warning'
                                        icon='pi pi-undo'
                                        onClick={() =>
                                            handleTargetConfirmation({
                                                mode: 'revert',
                                                revertMode:
                                                    targetsSelect.revertMode ===
                                                    'accepted'
                                                        ? 'accepted'
                                                        : 'rejected',
                                                targets: targetsSelect.targets,
                                            })
                                        }
                                        disabled={
                                            revertTargetsLoading ||
                                            props.loading
                                        }
                                    />
                                )}
                            </div>
                        </div>
                    }
                    style={{ height: '60px' }}
                />
            )}
            <SafeIPRules />
            <CMDataTable
                dataKey='_id'
                columnConfigName='targets'
                lazy
                emptyMessage={datatableLocale.empty}
                rowClassName={(data: Ether.CaseManager.Target.Detailed) => {
                    if (data.list_data?.safe_ip === false)
                        return ['cm-isreview-row', `grade-${data.list_data.highest_grading}`].join(' ');
                }}
                filterOptions={{
                    _cm_name_id: {
                        placeholder: datatableLocale.selectValue,
                        type: 'string',
                    },
                    [columnFields.type]: {
                        label: localization.fields.target.type,
                        placeholder: datatableLocale.selectType,
                        type: 'dropdown',
                        dropdownOptions: defaultTypeOptions,
                    },
                    [columnFields['list_data.highest_grading']]: {
                        label: localization.fields.target.grading,
                        placeholder: datatableLocale.inputGrading,
                        type: 'number',
                    },
                    [columnFields['list_data.safe_ip']]: {
                        label: localization.fields.target.safeIp,
                        type: 'dropdown',
                        dropdownOptions: safeIpOptions,
                    },
                    [columnFields['list_data.reserved_ip']]: {
                        label: localization.fields.target.reservedIp,
                        type: 'dropdown',
                        dropdownOptions: yesNoOptions,
                    },
                    [columnFields['list_data.official_domain']]: {
                        label: localization.fields.target.officialDomain,
                        type: 'dropdown',
                        dropdownOptions: yesNoOptions,
                    },
                    [columnFields.notified]: {
                        label: localization.fields.target.notified,
                        type: 'dropdown',
                        dropdownOptions: yesNoOptions,
                    },
                    [columnFields['list_data.country']]: {
                        label: localization.fields.target.country,
                        type: 'string',
                        hideMatchMode: true,
                    },
                }}
                expandedRows={expandedRows}
                onRowToggle={(e) => setExpandedRows(e.data)}
                rowExpansionTemplate={(data: Ether.CaseManager.Target) => {
                    const modelId = refBlockOrder
                        ? refBlockOrder._id
                        : refUnblockOrder?._id ?? '';
                    return (
                        <OperatorsTargetSummary
                            model={
                                refBlockOrder ? 'block_order' : 'unblock_order'
                            }
                            modelId={modelId}
                            targetValue={data.value}
                        />
                    );
                }}
                {...props}
            >
                {expandable && <Column expander={true} />}
                {isViewValidatable && (
                    <Column
                        headerStyle={{ width: '3rem' }}
                        header={selectAllTargetsHeader}
                        body={(data: Ether.CaseManager.Target.Detailed) => {
                            const canBeValidated = canBeApproved(data);
                            const canBeReverted =
                                allowedToRevert &&
                                data._user_fields?.can_revert_validation;
                            if (!canBeValidated && !canBeReverted) return null;
                            const mode = data._user_fields
                                ?.can_revert_validation
                                ? 'revert'
                                : 'validate';
                            const isRejected =
                                !!data.removed_authorizations?.find(
                                    (a) => a?._id === refAuthorization?._id
                                );
                            const index = (
                                targetsSelect?.targets ?? []
                            ).findIndex((t) => data._id === t._id);
                            const isChecked = index !== -1;
                            if (
                                !isChecked &&
                                targetsSelect &&
                                targetsSelect.mode !== mode
                            )
                                return null;

                            return (
                                <Checkbox
                                    checked={isChecked}
                                    onClick={() => {
                                        setTargetSelect((old) => {
                                            let newSelect = old;
                                            if (!old) {
                                                newSelect = {
                                                    targets: [],
                                                    mode: mode,
                                                    revertMode:
                                                        mode === 'revert'
                                                            ? isRejected
                                                                ? 'rejected'
                                                                : 'accepted'
                                                            : null,
                                                };
                                            } else {
                                                newSelect = {
                                                    ...old,
                                                    targets: [...old.targets],
                                                };
                                            }
                                            if (isChecked)
                                                newSelect.targets.splice(
                                                    index,
                                                    1
                                                );
                                            else newSelect.targets.push(data);
                                            if (newSelect.targets.length <= 0)
                                                return null;
                                            return newSelect;
                                        });
                                    }}
                                />
                            );
                        }}
                    />
                )}
                <Column
                    field='_cm_name_id'
                    body={(data: Ether.CaseManager.Target.Detailed) => {
                        const prerejectedReasons = getPreRejectedReasons({
                            authorizationId: refAuthorization?._id,
                            target: data,
                            localization: localization,
                        });

                        const name = truncateTargetName(data.value);

                        const safeIpReasonsTooltipClass = `span-safeip-target-${data._id}`;
                        const alreadyBlockedTooltipClass = `span-alreadyblocked-target-${data._id}`;
                        const showReasons =
                            (prerejectedReasons?.length ?? 0) > 0;
                        const showAlreadyBlocked =
                            data?.block_orders && data.block_orders.length > 0;

                        const canBeRemoved =
                            permissions.updateTargetOrderStatus &&
                            refBlockOrder?.status === 'draft' &&
                            data.list_data?.safe_ip === false;

                        return (
                            <div className='flex flex-col gap-2'>
                                <div className='flex flex-row justify-start items-center gap-2'>
                                    {showReasons && (
                                        <AdaptableTooltip
                                            target={
                                                '.' + safeIpReasonsTooltipClass
                                            }
                                        >
                                            <div className='flex flex-col gap-1'>
                                                {prerejectedReasons.map((r) => (
                                                    <span key={r}>{r}</span>
                                                ))}
                                            </div>
                                        </AdaptableTooltip>
                                    )}
                                    {showAlreadyBlocked && (
                                        <AdaptableTooltip
                                            target={
                                                '.' + alreadyBlockedTooltipClass
                                            }
                                        >
                                            <span>
                                                {
                                                    localization.components
                                                        .models.target.text
                                                        .alreadyBlocked
                                                }
                                            </span>
                                        </AdaptableTooltip>
                                    )}
                                    {canBeRemoved && (
                                        <Button
                                            icon='pi pi-trash'
                                            severity='danger'
                                            onClick={() =>
                                                updateTargetOrderStatus({
                                                    order_id: refBlockOrder._id,
                                                    order_type: 'block_order',
                                                    status: 'remove',
                                                    target_id: data._id,
                                                    target_value: data.value,
                                                })
                                            }
                                            tooltip={
                                                localization.components.common
                                                    .button.remove
                                            }
                                            tooltipOptions={{
                                                position: 'left',
                                            }}
                                        />
                                    )}
                                    {getESTICurrentStatus({
                                        target: data,
                                    }) !== 'error' && (
                                        <Button
                                            icon='pi pi-info-circle'
                                            size='small'
                                            tooltip={
                                                localization.fields.target
                                                    .enrichment
                                            }
                                            onClick={() =>
                                                onTargetViewModalShow(
                                                    data,
                                                    'detail'
                                                )
                                            }
                                        />
                                    )}
                                    <a
                                        href={'//' + data.value}
                                        rel='noreferrer noopener'
                                        target='_blank'
                                    >
                                        <Button
                                            icon='pi pi-external-link'
                                            size='small'
                                        />
                                    </a>
                                    {refAuthorization &&
                                        permissions.downloadEvidencesAuthorizationTarget && (
                                            <Button
                                                icon='pi pi-download'
                                                // TODO: LOCALIZE
                                                tooltip='Baixar evidências'
                                                size='small'
                                                onClick={() =>
                                                    downloadTargetEvidences({
                                                        authorization_id:
                                                            refAuthorization._id,
                                                        target_id: data._id,
                                                    })
                                                }
                                                disabled={
                                                    isDownloadTargetEvidences ||
                                                    !data._user_fields
                                                        ?.has_evidence
                                                }
                                                loading={
                                                    downloadTargetEvidencesParams?.target_id ===
                                                    data._id
                                                }
                                            />
                                        )}
                                    <div
                                        className={
                                            'flex flex-row gap-2 items-center'
                                        }
                                    >
                                        <span title={data.value}>
                                            <NameIdAggregator
                                                name={name}
                                                id={data._id}
                                                onClick={() =>
                                                    copyToClipboard(data.value)
                                                }
                                            />
                                        </span>
                                        {showReasons && (
                                            <i
                                                className={
                                                    safeIpReasonsTooltipClass +
                                                    ' pi pi-question-circle text-yellow-500'
                                                }
                                            />
                                        )}
                                        {showAlreadyBlocked && (
                                            <i
                                                className={
                                                    alreadyBlockedTooltipClass +
                                                    ' pi pi-exclamation-triangle text-red-500'
                                                }
                                            />
                                        )}
                                    </div>
                                </div>
                                {downloadTargetEvidencesParams?.target_id ===
                                    data._id && (
                                    <ProgressBar
                                        value={
                                            downloadTargetEvidencesProgress *
                                            100
                                        }
                                    />
                                )}
                            </div>
                        );
                    }}
                    sortable={sortable}
                />
                <Column
                    field={columnFields.type}
                    header={localization.fields.target.type}
                    body={(rowData: Ether.CaseManager.Target) => (
                        <TargetTypeBadge target={rowData} />
                    )}
                />
                {showTags && (
                    <Column
                        field={columnFields.tags}
                        header={localization.models.tag.plural}
                        body={(rowData: Ether.CaseManager.Target.Detailed) => {
                            const tags = rowData?.authorizations
                                ?.filter(
                                    (a) =>
                                        !refAuthorization ||
                                        a?._id === refAuthorization._id
                                )
                                .reduce(
                                    (old, a) => [
                                        ...old,
                                        ...(a?.tags_data ?? []),
                                    ],
                                    [] as Ether.CaseManager.Tag[]
                                )

                                .map((t) => `${t.category}/${t.name}`);
                            if (!tags || tags.length <= 0) return '-';
                            const dedupped = tags.filter(
                                (t, i) => tags.indexOf(t) === i
                            );
                            return dedupped.join(', ');
                        }}
                    />
                )}
                <Column
                    field={columnFields.enrichment}
                    header={localization.fields.target.enrichment}
                    body={(rowData: Ether.CaseManager.Target.Detailed) => (
                        <TargetEnrichmentField target={rowData} />
                    )}
                />
                <Column
                    field={columnFields['list_data.highest_grading']}
                    header={localization.fields.target.grading}
                    body={(data: Ether.CaseManager.Target.Detailed) =>
                        data.list_data?.highest_grading ?? '-'
                    }
                    sortable={sortable}
                />
                <Column
                    field={columnFields['list_data.hosted_domains_list']}
                    header={localization.fields.target.hostingHistoryDns}
                    body={(data: Ether.CaseManager.Target.Detailed) => {
                        const domains = data.list_data?.hosted_domains_list;
                        if (!domains || domains.length <= 0) return '-';
                        return (
                            <div className='flex flex-col gap-1 items-start'>
                                {domains.map((dns, index) => (
                                    <span key={dns + '@' + index}>{dns}</span>
                                ))}
                            </div>
                        );
                    }}
                />
                <Column
                    field={columnFields['list_data.hosted_domains_count']}
                    header={localization.fields.target.domainsCount}
                    body={(data: Ether.CaseManager.Target.Detailed) =>
                        data.list_data?.hosted_domains_count ?? '-'
                    }
                    sortable={sortable}
                />
                <Column
                    field={columnFields['list_data.br_domains_count']}
                    header={localization.fields.target.domainsBrCount}
                    body={(data: Ether.CaseManager.Target.Detailed) =>
                        data.list_data?.br_domains_count ?? '-'
                    }
                    sortable={sortable}
                />
                <Column
                    field={columnFields['list_data.country']}
                    header={localization.fields.target.country}
                    body={(data: Ether.CaseManager.Target.Detailed) => {
                        if (data.list_data?.reserved_ip)
                            return <ReservedIpBadge />;
                        return data.list_data?.country ?? '-';
                    }}
                />
                <Column
                    field={columnFields['list_data.asn']}
                    header={localization.fields.target.asn}
                    body={(data: Ether.CaseManager.Target.Detailed) => {
                        if (data.list_data?.reserved_ip)
                            return <ReservedIpBadge />;
                        return data.list_data?.asn ?? '-';
                    }}
                />
                <Column
                    field={columnFields['list_data.accessible_ports']}
                    header={localization.fields.target.accessiblePorts}
                    body={(data: Ether.CaseManager.Target.Detailed) => {
                        const ports = data.list_data?.accessible_ports;
                        if (!ports || ports.length <= 0) return '-';
                        if (ports.length <= 5) return ports.join(', ');
                        return ports.slice(0, 5).join(', ') + ' (...)';
                    }}
                />
                <Column
                    field={columnFields['list_data.safe_ip']}
                    header={localization.fields.target.safeIp}
                    body={(data: Ether.CaseManager.Target.Detailed) => {
                        const isSafe: boolean | null | undefined =
                            data.list_data?.safe_ip;
                        const safeIpReasons = translateSafeIpReasons(
                            data,
                            localization
                        );
                        const classN = `span-safeip-target-${data._id}`;

                        return (
                            <>
                                <div style={{ minWidth: '40px' }}>
                                    {isSafe != null ? (
                                        isSafe ? (
                                            <Badge
                                                severity={'success'}
                                                value='Go'
                                            />
                                        ) : (
                                            <>
                                                <AdaptableTooltip
                                                    target={'.' + classN}
                                                >
                                                    <div className='flex flex-col gap-1'>
                                                        {safeIpReasons.map(
                                                            (r) => (
                                                                <span key={r}>
                                                                    {r}
                                                                </span>
                                                            )
                                                        )}
                                                    </div>
                                                </AdaptableTooltip>
                                                <Badge
                                                    className={classN}
                                                    severity={'warning'}
                                                    value='Review'
                                                />
                                            </>
                                        )
                                    ) : (
                                        '-'
                                    )}
                                </div>
                            </>
                        );
                    }}
                />
                <Column
                    field={columnFields['list_data.reserved_ip']}
                    header={localization.fields.target.reservedIp}
                    body={(data: Ether.CaseManager.Target.Detailed) => (
                        <YesNoBadge value={data.list_data?.reserved_ip} />
                    )}
                />
                <Column
                    field={columnFields['list_data.official_domain']}
                    header={localization.fields.target.officialDomain}
                    body={(data: Ether.CaseManager.Target.Detailed) => (
                        <YesNoBadge value={data.list_data?.official_domain} />
                    )}
                />
                {refAuthorization && (
                    <Column
                        field={columnFields['meta.pirate_brand']}
                        header={localization.fields.target.pirateBrands}
                        body={(data: Ether.CaseManager.Target.Detailed) => {
                            const meta = getTargetMeta({
                                target: data,
                                field: columnFields['meta.pirate_brand'],
                                refAuthorization,
                            });
                            if (meta) return meta;
                            let targetAuth:
                                | Ether.CaseManager.Target.Authorization
                                | null
                                | undefined = data.authorizations?.find(
                                (a) => a?._id === refAuthorization._id
                            );
                            if (targetAuth)
                                return targetAuth.tags_data
                                    .map((t) => t.name)
                                    .join(',');
                            targetAuth = data.removed_authorizations?.find(
                                (a) => a?._id === refAuthorization._id
                            );
                            if (targetAuth)
                                return targetAuth.tags_data
                                    .map((t) => t.name)
                                    .join(',');
                            return '-';
                        }}
                    />
                )}
                {refAuthorization && (
                    <Column
                        field={columnFields['meta.porta']}
                        header={localization.fields.target.port}
                        body={(data: Ether.CaseManager.Target.Detailed) =>
                            getTargetMeta({
                                target: data,
                                field: columnFields['meta.porta'],
                                refAuthorization,
                            }) ?? '-'
                        }
                    />
                )}
                {refAuthorization && (
                    <Column
                        field={columnFields['meta.protocolo']}
                        header={localization.fields.target.protocol}
                        body={(data: Ether.CaseManager.Target.Detailed) =>
                            getTargetMeta({
                                target: data,
                                field: columnFields['meta.protocolo'],
                                refAuthorization,
                            }) ?? '-'
                        }
                    />
                )}
                {refAuthorization && (
                    <Column
                        field={columnFields['meta.pre-validado']}
                        header={localization.fields.target.preValidated}
                        body={(data: Ether.CaseManager.Target.Detailed) =>
                            getTargetMeta({
                                target: data,
                                field: columnFields['meta.pre-validado'],
                                refAuthorization,
                            }) ?? '-'
                        }
                    />
                )}
                {refAuthorization && (
                    <Column
                        field={columnFields['meta.modelo']}
                        header={localization.fields.target.model}
                        body={(data: Ether.CaseManager.Target.Detailed) =>
                            getTargetMeta({
                                target: data,
                                field: columnFields['meta.modelo'],
                                refAuthorization,
                            }) ?? '-'
                        }
                    />
                )}
                {refAuthorization && (
                    <Column
                        field={columnFields['meta.app']}
                        header={localization.fields.target.app}
                        body={(data: Ether.CaseManager.Target.Detailed) =>
                            getTargetMeta({
                                target: data,
                                field: columnFields['meta.app'],
                                refAuthorization,
                            }) ?? '-'
                        }
                    />
                )}
                {refAuthorization && (
                    <Column
                        field={columnFields['meta.app_versao']}
                        header={localization.fields.target.appVersion}
                        body={(data: Ether.CaseManager.Target.Detailed) =>
                            getTargetMeta({
                                target: data,
                                field: columnFields['meta.app_versao'],
                                refAuthorization,
                            }) ?? '-'
                        }
                    />
                )}
                {refAuthorization && (
                    <Column
                        field={columnFields['meta.request_type']}
                        header={localization.fields.target.requestType}
                        body={(data: Ether.CaseManager.Target.Detailed) =>
                            getTargetMeta({
                                target: data,
                                field: columnFields['meta.request_type'],
                                refAuthorization,
                            }) ?? '-'
                        }
                    />
                )}
                {refAuthorization && (
                    <Column
                        field={columnFields['meta.rule']}
                        header={localization.fields.target.rule}
                        body={(data: Ether.CaseManager.Target.Detailed) =>
                            getTargetMeta({
                                target: data,
                                field: columnFields['meta.rule'],
                                refAuthorization,
                            }) ?? '-'
                        }
                    />
                )}
                <Column
                    field={columnFields['notified']}
                    header={localization.fields.target.notified}
                    body={(data: Ether.CaseManager.Target.Detailed) => (
                        <YesNoBadge value={data.notified} />
                    )}
                />
                <Column
                    field={columnFields['created_at']}
                    header={localization.fields.target.created}
                    body={(rowData: Ether.CaseManager.Target.Detailed) => (
                        <DateBadge value={rowData.created_at} />
                    )}
                />
                {(showOperatorAnswer || isViewValidatable) && (
                    <Column
                        field={columnFields['answered_at']}
                        header={localization.fields.target.answeredAt}
                        body={(data: Ether.CaseManager.Target.Detailed) => {
                            if (!currentUserId) return '-';
                            const answer = getAnswer(data);
                            if (answer?.date == null) return '-';
                            return <DateBadge value={answer.date} />;
                        }}
                    />
                )}
                {(showOperatorAnswer || isViewValidatable) && (
                    <Column
                        field={columnFields['answer']}
                        header={localization.fields.target.approval}
                        body={(data: Ether.CaseManager.Target.Detailed) => {
                            const elements: JSX.Element[] = [];
                            const canBeReverted =
                                allowedToRevert &&
                                data._user_fields?.can_revert_validation;
                            const canBeValidated = canBeApproved(data);

                            if (canBeValidated)
                                return (
                                    <div className='flex flex-row gap-2'>
                                        <Button
                                            tooltip={
                                                targetValidationPositiveLabelSingular
                                            }
                                            icon={targetValidationPositiveIcon}
                                            tooltipOptions={{
                                                position: 'left',
                                            }}
                                            severity='success'
                                            onClick={() =>
                                                validateTargets({
                                                    targets: [data],
                                                    mode: 'positive',
                                                })
                                            }
                                            disabled={validateTargetLoading}
                                        />
                                        <Button
                                            tooltip={
                                                targetValidationNegativeLabelSingular
                                            }
                                            icon={targetValidationNegativeIcon}
                                            tooltipOptions={{
                                                position: 'left',
                                            }}
                                            severity='danger'
                                            onClick={() =>
                                                validateTargets({
                                                    targets: [data],
                                                    mode: 'negative',
                                                })
                                            }
                                            disabled={validateTargetLoading}
                                        />
                                    </div>
                                );

                            const answer = getAnswer(data);
                            if (answer?.approved != null)
                                elements.push(
                                    answer.approved ? (
                                        <Badge
                                            value={targetAnswerApproved}
                                            severity={
                                                targetAnswerApprovedSeverity
                                            }
                                            style={{
                                                whiteSpace: 'nowrap',
                                            }}
                                        />
                                    ) : (
                                        <Badge
                                            value={targetAnswerRejected}
                                            severity={
                                                targetAnswerRejectedSeverity
                                            }
                                            style={{
                                                whiteSpace: 'nowrap',
                                            }}
                                        />
                                    )
                                );
                            if (canBeReverted) {
                                const isRejected =
                                    !!data.removed_authorizations?.find(
                                        (a) => a?._id === refAuthorization._id
                                    );
                                elements.push(
                                    <Button
                                        icon='pi pi-undo'
                                        severity='warning'
                                        onClick={() =>
                                            validateTargets({
                                                targets: [data],
                                                mode: 'revert',
                                                revertMode: isRejected
                                                    ? 'rejected'
                                                    : 'accepted',
                                            })
                                        }
                                        tooltip={
                                            localization.components.common
                                                .button.revert
                                        }
                                        tooltipOptions={{
                                            position: 'left',
                                        }}
                                        disabled={revertTargetsLoading}
                                    />
                                );
                            }
                            if (elements.length <= 0) return '-';
                            return (
                                <div className='flex flex-col gap-1 justify-center items-center'>
                                    {elements.map((t, i) => (
                                        <Fragment key={i}>{t}</Fragment>
                                    ))}
                                </div>
                            );
                        }}
                    />
                )}
                <Column
                    field='actions'
                    body={(rowData: Ether.CaseManager.Target.Detailed) => (
                        <TargetMenu
                            target={rowData}
                            onShowDetail={(t) =>
                                onTargetViewModalShow(t, 'debug')
                            }
                        />
                    )}
                />
            </CMDataTable>
            {targetsSelect && <div style={{ height: '120px' }} />}
        </>
    );
};

export default TargetsDataTable;
