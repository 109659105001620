import { DataTable } from 'primereact/datatable';
import { CMDataTableProps } from './types';
import { Children, useEffect, useRef, useState } from 'react';
import CacheControl from 'controller/cache/cacheController';
import { useLocalization } from 'hooks/context/useLocalization';
import CMPaginator from '../CMPaginator';
import { MultiSelect } from 'primereact/multiselect';
import _ from 'lodash';
import FilterBox from '../FilterBox';

type ColumnKeys = CaseManagerApp.ModelColumns;

type AllKeysPresent = {
    [K in keyof ColumnKeys]: {
        [SK in keyof ColumnKeys[K]]: string;
    };
};

const CMDataTable: React.FC<CMDataTableProps> = ({
    children,
    columnConfigName,
    paginatorProps,
    filterOptions,
    setFilters,
    ...rest
}) => {
    const [localization] = useLocalization();
    const wrapperRef = useRef<HTMLDivElement>(null);
    const dataTableRef = useRef<DataTable<any[]>>(null);
    const { fields, models } = localization;
    const columnNames: AllKeysPresent = {
        company: {
            fantasy_name: fields.company.fantasyName,
            type: fields.company.type,
            uf: fields.company.uf,
            cnpj: fields.company.cnpj,
            users_count: fields.company.users,
        },
        authorization: {
            approved_at: fields.authorization.approval,
            approved_by: fields.authorization.approvedBy,
            created_at: fields.authorization.created,
            created_by: fields.authorization.createdBy,
            evidences_count: models.evidence.plural,
            status: fields.authorization.status,
            targets_count: models.target.plural,
            registered_at: fields.authorization.registeredAt,
            authorization_config_name: models.authorizationConfig.singular,
        },
        authorizationConfig: {
            authorizers_count: fields.authorizationConfig.authorizers,
            pirate_brands_count: fields.authorizationConfig.pirateBrands,
            documents_count: models.document.plural,
            targets_count: models.target.plural,
            created_at: fields.authorizationConfig.created,
        },
        blockOrder: {
            status: fields.blockOrder.status,
            validation_status: fields.blockOrder.validationStatus,
            'block_checker.synced_at': fields.blockOrder.doneAt,
            authorizations_count: models.authorization.plural,
            created_at: fields.blockOrder.created,
            created_by: fields.blockOrder.createdBy,
            targets_count: models.target.plural,
            registered_at: fields.blockOrder.registeredAt,
        },
        unblockOrder: {
            targets_count: models.target.plural,
            created_at: fields.unblockOrder.created,
            created_by: fields.unblockOrder.createdBy,
            status: fields.unblockOrder.status,
            validation_status: fields.unblockOrder.validationStatus,
        },
        evidences: {
            type: fields.evidence.type,
            parsed_data_status: fields.evidence.parsedStatus,
        },
        targets: {
            type: fields.target.type,
            tags: models.tag.plural,
            enrichment: fields.target.enrichment,
            created_at: fields.target.created,

            'list_data.accessible_ports': fields.target.accessiblePorts,
            'list_data.asn': fields.target.asn,
            'list_data.br_domains_count': fields.target.domainsBrCount,
            'list_data.country': fields.target.country,
            'list_data.highest_grading': fields.target.grading,
            'list_data.hosted_domains_count': fields.target.domainsCount,
            'list_data.hosted_domains_list': fields.target.hostingHistoryDns,
            'list_data.official_domain': fields.target.officialDomain,
            'list_data.reserved_ip': fields.target.reservedIp,
            'list_data.safe_ip': fields.target.safeIp,

            'meta.pirate_brand': fields.target.pirateBrands,
            'meta.porta': fields.target.port,
            'meta.protocolo': fields.target.protocol,
            'meta.pre-validado': fields.target.preValidated,
            'meta.modelo': fields.target.model,
            'meta.app': fields.target.app,
            'meta.app_versao': fields.target.appVersion,
            'meta.request_type': fields.target.requestType,
            'meta.rule': fields.target.rule,

            answered_at: fields.target.answeredAt,
            answer: fields.target.approval,
            blocked_at: fields.target.blockedAt,
            notified: fields.target.notified,
        },
        operation: {
            created_at: fields.operation.created,
        },
        operators: {
            tags: fields.operator.operatorTags,
            'users_data.0.infos.email': fields.operator.email,
            'last_notification_data.last_sent_notification':
                fields.operator.lastNotificationAt,
        },
        operatorsOrder: {
            'operators_data.0.contact.email': fields.operatorsBlockOrder.email,
            'operators_data.0.tags': fields.operator.operatorTags,
            'operators_data.last_notification_data.last_sent_notification':
                fields.operator.lastNotificationAt,
        },
        document: {
            type: localization.fields.document.type,
            'process_document.document_date':
                localization.fields.document.documentDate,
            created_at: localization.fields.document.createdAt,
        },
        monitoring: {
            name: localization.fields.monitoring.name,
            'data.network_url': localization.fields.monitoring.networkUrl,
            'data.video_url': localization.fields.monitoring.videoUrl,
            created_at: localization.fields.monitoring.created,
            status: localization.fields.monitoring.status,
        },
        tag: {
            official_documents: localization.models.oficio.plural,
            targets_count: localization.models.target.plural,
        },
        user: {
            company: localization.fields.user.company,
            'infos.email': localization.fields.user.email,
            'data.auth.level': localization.fields.user.role,
            'data.status': localization.fields.user.status,
        },
        groupedUserProfile: {
            permissions_count:
                localization.fields.groupedUserProfile.profileCount,
        },
    };

    const [visibleColumns, setVisibileColumns] = useState(() => {
        const defaultConfig = columnNames[columnConfigName];
        const config = CacheControl.UserConfig.get();
        const configColumn = config.columnsVisibility[columnConfigName];
        Object.keys(defaultConfig).forEach((k) => {
            if (!(k in configColumn) || (configColumn as any)[k] == null)
                (configColumn as any)[k] = true;
        });
        return configColumn;
    });
    const [paginatorRows, setPaginatorRows] = useState(() => {
        const config = CacheControl.UserConfig.get();
        const count = config.paginatorRows[columnConfigName];
        return count ?? 50;
    });

    const [scrollWidth, setScrollWidth] = useState(0);

    if (!Array.isArray(children)) children = [children];

    let index = 0;
    let keyOrders: { [key: string]: number } = {};

    const validOptions = [] as { value: string; label: string }[];
    const thisColumnNames = columnNames[columnConfigName] as {
        [key: string]: string;
    };

    const filteredChildren = Children.toArray(children).filter((child) => {
        if (typeof child !== 'object') return true;
        if (!('type' in child)) return true;
        const { field } = child.props;
        // this exists, react is just too shy to tell
        const displayName = (child.type as any)?.displayName as string;
        if (displayName !== 'Column') return true;
        if (!Object.keys(thisColumnNames).find((k) => k === field)) return true;
        validOptions.push({
            value: field,
            label: thisColumnNames[field] ?? '',
        });
        keyOrders[field] = index;
        index += 1;
        if (!(field in visibleColumns)) return true;
        return (
            field in visibleColumns &&
            visibleColumns[field as keyof typeof visibleColumns]
        );
    });

    const scrollWrapperClassname = 'cm-datatable-scroll-wrapper';

    useEffect(() => {
        CacheControl.UserConfig.saveVisibleColumns(
            columnConfigName,
            visibleColumns
        );
    }, [columnConfigName, visibleColumns]);

    useEffect(() => {
        const updateScroll = () => {
            const scrollElement = wrapperRef.current?.querySelector(
                '.cm-datatable-scroll'
            );
            const dataTableElement =
                wrapperRef.current?.querySelector('.p-datatable-table');
            if (!scrollElement || !dataTableElement) return;
            const { scrollWidth } = dataTableElement;
            setScrollWidth(scrollWidth);
        };

        const scrollFn = (scroll: any) => {
            const scrollElement = wrapperRef.current?.querySelector(
                `.${scrollWrapperClassname}`
            );
            if (!scrollElement) return;
            scrollElement.scrollLeft = scroll.target?.scrollLeft;
        };

        const dataTableElement = wrapperRef.current?.querySelector(
            '.p-datatable-wrapper'
        );

        dataTableElement?.addEventListener('scroll', scrollFn);
        updateScroll();
        return () => {
            dataTableElement?.removeEventListener('scroll', scrollFn);
        };
    }, [rest.value]);

    useEffect(() => {
        if (paginatorProps?.onPageChange)
            paginatorProps.onPageChange({
                page: 1,
                rows: paginatorRows,
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const showFilterBox =
        rest.filters &&
        filterOptions &&
        setFilters &&
        Object.keys(rest.filters).length > 0;

    return (
        <section className='mt-4 cm-datatable-wrapper' ref={wrapperRef}>
            {showFilterBox && rest.filters && (
                <FilterBox
                    filterOptions={filterOptions}
                    filters={rest.filters}
                    setFilters={(e) => setFilters(e)}
                />
            )}
            {!!rest.value && (
                <i className='font-light'>
                    {localization.components.common.datatable.showingAmount.replace(
                        '{count}',
                        rest.value.length.toString()
                    )}
                </i>
            )}
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {paginatorProps && (
                    <CMPaginator
                        {...paginatorProps}
                        onPageChange={(e) => {
                            CacheControl.UserConfig.saveRows(
                                columnConfigName,
                                e.rows
                            );
                            setPaginatorRows(e.rows);
                            paginatorProps.onPageChange(e);
                        }}
                        disableNext={
                            !rest?.value?.length ||
                            rest.value.length < paginatorRows
                        }
                    />
                )}
                <MultiSelect
                    style={{ marginLeft: '16px' }}
                    // selectAllLabel={
                    //     localization.components.common.datatable.columnsSelectAll
                    // }
                    placeholder={
                        localization.components.common.datatable.columnsSelect
                    }
                    showClear={false}
                    dropdownIcon='pi pi-eye-slash'
                    fixedPlaceholder
                    options={validOptions.sort((a, b) => {
                        const order1 = keyOrders[a.value] ?? 9999;
                        const order2 = keyOrders[b.value] ?? 9999;
                        if (order1 > order2) return 1;
                        else if (order1 < order2) return -1;
                        return 0;
                    })}
                    value={Object.entries(visibleColumns)
                        .filter(([_, val]) => val)
                        .map(([key]) => key)}
                    onChange={(e) => {
                        const values =
                            e.value as (keyof typeof visibleColumns)[];
                        const newVisible: { [key: string]: boolean } = _.merge(
                            {},
                            visibleColumns
                        );
                        Object.keys(newVisible).forEach(
                            (k) => (newVisible[k] = false)
                        );
                        values.forEach((v) => (newVisible[v] = true));
                        setVisibileColumns(newVisible as typeof visibleColumns);
                    }}
                />
            </div>
            <div
                className={`w-full overflow-x-auto overflow-y-hidden ${scrollWrapperClassname}`}
                onScroll={(a) => {
                    const scrollElement = a.currentTarget;
                    const dataTableElement = wrapperRef.current?.querySelector(
                        '.p-datatable-wrapper'
                    );
                    if (!scrollElement || !dataTableElement) return;
                    dataTableElement.scrollLeft = scrollElement.scrollLeft;
                }}
            >
                <div
                    className='cm-datatable-scroll'
                    style={{
                        width: `${scrollWidth}px`,
                        display: 'block',
                    }}
                >
                    <br />
                </div>
            </div>
            <DataTable removableSort ref={dataTableRef} {...rest}>
                {filteredChildren}
            </DataTable>
        </section>
    );
};

export default CMDataTable;
