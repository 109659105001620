import { exportBlockOrderFiles } from 'services/ether/case-manager/block-orders';
import useBaseFileExport from '../useBaseFileExport';

const useExportBlockOrderFiles = (blockOrderId: string) => {
    const queryKey = blockOrderId;

    const { exportFile, isExporting } = useBaseFileExport(
        'export-block-order-files',
        queryKey,
        ({ signal }) => exportBlockOrderFiles(blockOrderId, signal)
    );

    return { exportFile: () => exportFile({ params: null }), isExporting };
};

export default useExportBlockOrderFiles;
