import NameIdAggregator from 'components/datatable/NameIdAggregator';
import { DateBadge, ObjectDisplayModal } from 'components/ethercity-primereact';
import { useAuth } from 'hooks/context/useAuth';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTableProps } from 'primereact/datatable';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import { useRef, useState } from 'react';
import { useLocalization } from 'hooks/context/useLocalization';
import { CMDataTableProps } from 'components/datatable/CMDataTable/types';
import CMDataTable from 'components/datatable/CMDataTable';
import { useNavigate } from 'react-router-dom';
import MonitoringStatusBadge from '../MonitorStatusBadge';

const MonitoringMenu: React.FC<{
    monitoring: Ether.CaseManager.Monitoring;
    onShowDetail: (monitoring: Ether.CaseManager.Monitoring) => void;
}> = ({ monitoring, onShowDetail }) => {
    const menuRef = useRef<Menu>(null);
    const { permissions } = useAuth();
    const [localization] = useLocalization();
    const navigate = useNavigate();

    const menuItems: MenuItem[] = [
        {
            id: 'watch',
            icon: 'pi pi-server',
            label: localization.components.models.monitoring.button.view,
            command: () => navigate(monitoring._id),
        },
        ...(permissions.debugMonitors
            ? [
                  {
                      id: 'debug',
                      label: localization.components.common.menu.detailData,
                      command: () => onShowDetail(monitoring),
                  },
              ]
            : []),
    ];

    if (menuItems.length <= 0) return null;
    return (
        <>
            <Menu popup ref={menuRef} model={menuItems} />
            <Button
                icon='pi pi-ellipsis-h'
                onClick={(e) => menuRef?.current?.toggle(e)}
            />
        </>
    );
};

const MonitoringDataTable: React.FC<
    DataTableProps<Ether.CaseManager.Monitoring[]> &
        Omit<CMDataTableProps, 'columnConfigName'> & { token?: string }
> = ({ token, ...props }) => {
    const [localization] = useLocalization();

    const [viewMonitoringDialogOptions, setViewMonitoringDialogOptions] =
        useState<{
            visible: boolean;
            data: Ether.CaseManager.Monitoring | null;
        }>({
            visible: false,
            data: null,
        });

    const onMonitoringViewModalShow = (data: Ether.CaseManager.Monitoring) => {
        setViewMonitoringDialogOptions({
            visible: true,
            data: data,
        });
    };

    const onMonitoringViewModalHide = () => {
        setViewMonitoringDialogOptions((old) => ({
            ...old,
            visible: false,
        }));
    };

    const baseFilterOptions = {
        showAddButton: false,
        showFilterOperator: false,
    };

    const datatableLocale = localization.components.models.monitoring.datatable;

    return (
        <>
            <ObjectDisplayModal
                header={viewMonitoringDialogOptions.data?._id}
                visible={viewMonitoringDialogOptions.visible}
                displayData={viewMonitoringDialogOptions.data}
                onHide={onMonitoringViewModalHide}
                sortKeys={false}
            />
            <CMDataTable
                columnConfigName='monitoring'
                lazy
                emptyMessage={datatableLocale.empty}
                {...props}
            >
                <Column
                    field='_cm_name_id'
                    body={(rowData: Ether.CaseManager.Monitoring) => (
                        <NameIdAggregator
                            name={rowData.name}
                            id={rowData._id}
                        />
                    )}
                    filter={!!props?.filters?._cm_name_id}
                    filterPlaceholder={datatableLocale.selectName}
                    {...baseFilterOptions}
                />
                <Column
                    field='data.network_url'
                    header={localization.fields.monitoring.networkUrl}
                    filter={!!props?.filters?.['data.network_url']}
                    filterPlaceholder={
                        localization.fields.monitoring.networkUrl
                    }
                    {...baseFilterOptions}
                />
                <Column
                    field='data.video_url'
                    header={localization.fields.monitoring.videoUrl}
                    filter={!!props?.filters?.['data.video_url']}
                    filterPlaceholder={localization.fields.monitoring.videoUrl}
                    {...baseFilterOptions}
                />
                <Column
                    field='created_at'
                    header={localization.fields.monitoring.created}
                    body={(rowData: Ether.CaseManager.Monitoring) => (
                        <DateBadge value={rowData.created_at} />
                    )}
                />
                <Column
                    field='status'
                    header={localization.fields.monitoring.status}
                    body={(rowData: Ether.CaseManager.Monitoring) => (
                        <MonitoringStatusBadge monitor={rowData} />
                    )}
                />
                <Column
                    field='actions'
                    body={(rowData: Ether.CaseManager.Monitoring) => (
                        <MonitoringMenu
                            monitoring={rowData}
                            onShowDetail={(monitoring) =>
                                onMonitoringViewModalShow(monitoring)
                            }
                        />
                    )}
                />
            </CMDataTable>
        </>
    );
};

export default MonitoringDataTable;
