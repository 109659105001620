import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useLocalization } from 'hooks/context/useLocalization';
import { useToast } from 'hooks/context/useToast';
import { useEffect, useState } from 'react';
import { getErrorToast } from 'utils/errorHandler';

function useBaseFileExport<T>(
    identifier: string,
    queryKey: string | Object,
    call: (options: { params: T; signal?: AbortSignal }) => Promise<boolean>,
    onDownload?: () => void
) {
    const toast = useToast();
    const [localization] = useLocalization();
    const [params, setParams] = useState<T | null | undefined>(undefined);
    const queryClient = useQueryClient();

    const query = useQuery<boolean, Error, T>({
        queryKey: [identifier, queryKey, params],
        queryFn: ({ signal }) =>
            call({
                params: params as T,
                signal: signal,
            }),
        enabled: params === null || !!params,
        retry: 0,
    });

    useEffect(() => {
        if (query.isSuccess) {
            queryClient.removeQueries({ queryKey: [identifier] });
            setParams(undefined);
            if (onDownload) onDownload();
        } else if (query.error) {
            toast.show(getErrorToast(query.error.message, localization));
            queryClient.removeQueries({ queryKey: [identifier] });
            setParams(undefined);
        }
    }, [
        identifier,
        queryClient,
        localization,
        localization.validations.generic.unhandled,
        query,
        toast,
        onDownload,
    ]);

    const exportFile = (options: { params: T }) => {
        setParams(options.params ?? null);
    };
    const isExporting = query.isLoading;

    return {
        exportFile,
        isExporting,
    };
}

export default useBaseFileExport;
