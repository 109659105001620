import { useLocalization } from 'hooks/context/useLocalization';
import { Badge, BadgeProps } from 'primereact/badge';

const TargetTypeBadge: React.FC<{
    target: Ether.CaseManager.Target;
    badgeProps?: BadgeProps;
}> = ({ target, badgeProps }) => {
    const type = target.type;
    const [localization] = useLocalization();
    badgeProps = {
        ...badgeProps,
        severity: 'info',
        value: type ? type.toUpperCase() : 'null',
    };
    switch (type) {
        case 'ipv4':
            badgeProps.value =
                localization.components.models.target.badge.type.ipv4.toLocaleUpperCase();
            break;
        case 'ipv6':
            badgeProps.value =
                localization.components.models.target.badge.type.ipv6.toLocaleUpperCase();
            break;
        case 'domain':
            badgeProps.value =
                localization.components.models.target.badge.type.domain.toLocaleUpperCase();
            break;
        case 'other':
            badgeProps.value =
                localization.components.models.target.badge.type.other.toLocaleUpperCase();
            break;
    }
    return <Badge {...badgeProps} />;
};

export default TargetTypeBadge;
