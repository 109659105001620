type BaseBlockOrderCheck = {
    blockOrder: Ether.CaseManager.BlockOrder.Detailed | undefined;
    userId: string | undefined | null;
};

const baseBlockOrderCheck = ({ blockOrder, userId }: BaseBlockOrderCheck) => {
    if (!blockOrder || !userId) return false;

    const { operators_order_data } = blockOrder;

    const canRespond = !!operators_order_data?.find(
        (oo) =>
            oo.response == null &&
            (oo.operator_id === userId ||
                !!oo.operators_data?.find((o) =>
                    o.users_data?.find((u) => u._id === userId)
                ))
    );

    return canRespond;
};

export const isBlockOrderRespondable = ({
    permissions,
    blockOrder,
    userId,
}: {
    permissions: CaseManagerApp.Permissions | undefined;
} & BaseBlockOrderCheck) => {
    if (!permissions || !permissions.respondBlockOrder) return false;
    return baseBlockOrderCheck({
        blockOrder,
        userId,
    });
};

export const isTokenBlockOrderRespondable = ({
    blockOrder,
    userId,
}: BaseBlockOrderCheck) => {
    return baseBlockOrderCheck({
        blockOrder,
        userId,
    });
};
