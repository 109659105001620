import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { routes } from './routes';

const router = createBrowserRouter(routes, { basename: window.ROUTE_BASENAME });

const CaseManagerRouteProvider = () => {
    return <RouterProvider router={router} />;
};

export default CaseManagerRouteProvider;
