import useQueryWithRefresh from 'hooks/helpers/useQueryWithRefresh/useQueryWithRefresh';
import { detailOneEvidence } from 'services/ether/case-manager/evidences';

const useDetailOneEvidence = (
    evidenceId: string,
    projectId: string,
    queryOptions?: {
        enabled?: boolean;
    }
) => {
    const queryKey = ['evidence', 'detail', projectId, evidenceId];

    const query = useQueryWithRefresh<
        Ether.CaseManager.Evidence.Detailed | null,
        Error
    >({
        queryKey,
        queryFn: async ({ signal }) =>
            detailOneEvidence({
                project_id: projectId,
                _id: evidenceId,
                signal,
            }),
        ...queryOptions,
    });

    return query;
};

export default useDetailOneEvidence;
