import GoBackButton from 'components/misc/GoBackButton';
import LoadingMessage from 'components/misc/LoadingMessage';
import UpsertCompanyForm from 'components/models/Company/UpsertCompanyForm';
import { useProject } from 'hooks/context/project/useProject';
import { useAuth } from 'hooks/context/useAuth';
import { useLocalization } from 'hooks/context/useLocalization';
import useListOneCompany from 'hooks/queries/company/useListOneCompany';
import { Navigate, useParams } from 'react-router-dom';

const EditCompanyPage = () => {
    const { permissions } = useAuth();
    const [localization] = useLocalization();
    const project = useProject();

    const { companyId } = useParams<{ companyId: string }>();

    const companyQuery = useListOneCompany(
        {
            _id: companyId ?? '',
            project_id: project._id,
        },
        {
            enabled: !!companyId,
        }
    );

    if (companyQuery.isFetching)
        return (
            <LoadingMessage>
                {localization.common.loading}
            </LoadingMessage>
        );

    if (!permissions.upsertCompanies || !companyId)
        return <Navigate to='/admin' />;

    return (
        <>
            <GoBackButton />
            <UpsertCompanyForm companyToEdit={companyQuery.data} />
        </>
    );
};

export default EditCompanyPage;
