import FileViewer from 'components/models/Evidences/FileViewer';
import NameIdAggregator from 'components/datatable/NameIdAggregator';
import { ObjectDisplayModal } from 'components/ethercity-primereact';
import { useAuth } from 'hooks/context/useAuth';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTableProps } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import { useRef, useState } from 'react';
import { downloadEvidenceFile } from 'services/ether/case-manager/evidences';
import { useLocalization } from 'hooks/context/useLocalization';
import { CMDataTableProps } from 'components/datatable/CMDataTable/types';
import CMDataTable from 'components/datatable/CMDataTable';
import FileViewerWrapper from '../FileViewerWrapper';
import EvidenceParsedDataStatusBadge from '../EvidenceParsedDataStatusBadge';
import { getFilterData } from 'utils/datatable';
import { getFileModelName, handleFileModelDownload } from 'utils/file';

const EvidenceMenu: React.FC<{
    evidence: Ether.CaseManager.Evidence.Detailed;
    onShow: (evidence: Ether.CaseManager.Evidence.Detailed) => void;
    onShowDetail: (evidence: Ether.CaseManager.Evidence.Detailed) => void;
}> = ({ evidence, onShow, onShowDetail }) => {
    const menuRef = useRef<Menu>(null);
    const { permissions } = useAuth();
    const [localization] = useLocalization();

    const onDownload = () => {
        const file = evidence.file;
        if (!file) return;
        downloadEvidenceFile(evidence._id, file.id).then((data) =>
            handleFileModelDownload({
                file: data.file,
                item: evidence,
                extension: data.extension,
            })
        );
    };

    const menuItems: MenuItem[] = [
        {
            id: 'view',
            // icon: 'pi pi-sign-out',
            label: localization.components.common.menu.view,
            command: () => onShow(evidence),
        },
        {
            id: 'download',
            icon: 'pi pi-download',
            label: localization.components.common.button.download,
            command: () => onDownload(),
        },
        ...(permissions.debugAuthorizations
            ? [
                  {
                      id: 'debug',
                      label: localization.components.common.menu.detailData,
                      command: () => onShowDetail(evidence),
                  },
              ]
            : []),
    ];
    return (
        <>
            <Menu popup ref={menuRef} model={menuItems} />
            <Button
                icon='pi pi-ellipsis-h'
                onClick={(e) => menuRef?.current?.toggle(e)}
            />
        </>
    );
};

export const evidenceFilters = {
    _cm_name_id: getFilterData(),
    type: getFilterData(),
};

const EvidencesDatatable: React.FC<
    DataTableProps<Ether.CaseManager.Evidence.Detailed[]> &
        Omit<CMDataTableProps, 'columnConfigName'> & { token?: string }
> = ({ token, ...props }) => {
    const [localization] = useLocalization();
    const [selectedEvidence, setSelectedEvidence] =
        useState<Ether.CaseManager.Evidence.Detailed | null>(null);

    const [viewEvidenceDialogOptions, setViewEvidenceDialogOptions] = useState<{
        visible: boolean;
        data: Ether.CaseManager.Evidence | null;
    }>({
        visible: false,
        data: null,
    });

    const onEvidenceViewModalShow = (data: Ether.CaseManager.Evidence) => {
        setViewEvidenceDialogOptions({
            visible: true,
            data: data,
        });
    };

    const onEvidenceViewModalHide = () => {
        setViewEvidenceDialogOptions((old) => ({
            ...old,
            visible: false,
        }));
    };

    const datatableLocale = localization.components.models.evidence.datatable;

    const evidenceFile = selectedEvidence?.file;

    const evidenceViewer = selectedEvidence ? (
        token ? (
            <FileViewer
                item={selectedEvidence}
                mode='evidence'
                onClose={() => setSelectedEvidence(null)}
                showCloseButton
                token={token}
            />
        ) : (
            <FileViewerWrapper
                mode='evidence'
                modelId={selectedEvidence._id}
                showCloseButton
                onClose={() => setSelectedEvidence(null)}
            />
        )
    ) : null;

    const filename = getFileModelName(selectedEvidence);

    return (
        <>
            <ObjectDisplayModal
                header={filename}
                visible={viewEvidenceDialogOptions.visible}
                displayData={viewEvidenceDialogOptions.data}
                onHide={onEvidenceViewModalHide}
                sortKeys={false}
            />
            {evidenceFile && (
                <Dialog
                    visible
                    onHide={() => setSelectedEvidence(null)}
                    header={filename ?? ''}
                >
                    {evidenceViewer}
                </Dialog>
            )}
            <CMDataTable
                columnConfigName='evidences'
                lazy
                emptyMessage={datatableLocale.empty}
                filterOptions={{
                    _cm_name_id: {
                        type: 'string',
                        placeholder: datatableLocale.selectFilename,
                    },
                    type: {
                        label: localization.fields.evidence.type,
                        placeholder:
                            localization.components.models.evidence.datatable
                                .selectType,
                        type: 'string',
                    },
                }}
                {...props}
            >
                <Column
                    field='_cm_name_id'
                    body={(rowData: Ether.CaseManager.Evidence.Detailed) => {
                        const target = rowData.targets_data?.[0];
                        const auth = target?.authorizations_data?.[0];
                        return (
                            <NameIdAggregator
                                name={getFileModelName(rowData) ?? '-'}
                                id={rowData._id}
                                association={auth?.name}
                                onClick={() => setSelectedEvidence(rowData)}
                            />
                        );
                    }}
                />
                <Column
                    field='type'
                    header={localization.fields.evidence.type}
                    body={(rowData: Ether.CaseManager.Evidence) => {
                        return rowData.type ? (
                            <Badge
                                value={rowData.type.toLocaleUpperCase()}
                                severity='info'
                            />
                        ) : (
                            '-'
                        );
                    }}
                />
                <Column
                    field='parsed_data_status'
                    header={localization.fields.evidence.parsedStatus}
                    body={(rowData: Ether.CaseManager.Evidence) => (
                        <EvidenceParsedDataStatusBadge evidence={rowData} />
                    )}
                />
                <Column
                    field='actions'
                    body={(rowData: Ether.CaseManager.Evidence.Detailed) => (
                        <EvidenceMenu
                            evidence={rowData}
                            onShow={(evidence) => setSelectedEvidence(evidence)}
                            onShowDetail={(evidence) =>
                                onEvidenceViewModalShow(evidence)
                            }
                        />
                    )}
                />
            </CMDataTable>
        </>
    );
};

export default EvidencesDatatable;
