import { useAuth } from 'hooks/context/useAuth';
import { useProject } from 'hooks/context/project/useProject';
import { Navigate, useParams } from 'react-router-dom';
import CMBreadCrumb from 'components/page/CMBreadCrumb';
import useDetailOneAuthorizationConfig from 'hooks/queries/authorization-config/useDetailOneAuthorizationConfig';
import DetailedAuthorizationConfigView from 'components/models/AuthorizationConfig/DetailedAuthorizationConfigView';

const DetailedAuthorizationConfig = () => {
    const { permissions } = useAuth();
    const params = useParams<{ authConfigId: string }>();
    const project = useProject();

    const authConfigId = params.authConfigId as string;

    const query = useDetailOneAuthorizationConfig(
        {
            _id: authConfigId,
            project_id: project._id,
        },
        {
            enabled: permissions.viewAuthorizationConfigs,
        }
    );

    const refresh = () => query.refetch();

    if (!permissions.detailAuthorizations) return <Navigate to='/' />;
    return (
        <>
            <CMBreadCrumb
                projectLabel={project.name}
                authorizationConfigLabel={query.data?.name}
            />
            <DetailedAuthorizationConfigView
                authorizationConfig={query.data}
                isLoading={query.isLoading}
                error={query.error}
                onRefresh={refresh}
            />
        </>
    );
};

export default DetailedAuthorizationConfig;
