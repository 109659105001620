import React from 'react';

import { Button } from 'primereact/button';

import { Dropdown } from '../../input/Dropdown';
import { InputNumber } from '../../input/InputNumber';
import { InputText } from '../../input/InputText';

import './style.css';

export interface IPaginator {
    page: number;
    rows: number;
    hideRowsPerPage?: boolean;
    rowsPerPageOptions?: number[];
    onPageChange(event: { page: number; rows: number }): void;
    disableNext?: boolean;
    style?: React.CSSProperties;
    maxPages?: number;
    showRefresh?: boolean;
    onRefresh?(): void;
    isRefreshing?: boolean;
    labelOptions?: {
        next?: string;
        previous?: string;
        refresh?: string;
        maxPageCount?: string;
    };
}

export const Paginator: React.FC<IPaginator> = ({
    page,
    rows,
    onPageChange,
    hideRowsPerPage,
    rowsPerPageOptions = [10, 20, 50],
    disableNext,
    style,
    maxPages,
    showRefresh,
    onRefresh,
    isRefreshing,
    labelOptions,
}) => {
    const nextLabel = labelOptions?.next ?? 'Next';
    const previousLabel = labelOptions?.previous ?? 'Prev.';
    const refreshLabel = labelOptions?.refresh ?? 'Refresh';
    const maxPageCountLabel = labelOptions?.maxPageCount ?? 'of {maxPages}';

    if (maxPages != null && maxPages <= 0) maxPages = 1;

    return (
        <div className='no-total-paginator-wrap' style={style}>
            <div className='page-buttons'>
                <span className='p-buttonset'>
                    <Button
                        icon='pi pi-angle-double-left'
                        disabled={page === 1}
                        onClick={() => onPageChange({ page: 1, rows })}
                    />
                    <Button
                        label={previousLabel}
                        icon='pi pi-angle-left'
                        disabled={page === 1}
                        onClick={() => onPageChange({ page: page - 1, rows })}
                    />
                    <InputNumber
                        format={false}
                        value={page}
                        inputStyle={{ borderRadius: 0, margin: '0 1px 0 1px' }}
                        onValueChange={(e) =>
                            onPageChange({
                                page:
                                    e.target.value == null ? 1 : e.target.value,
                                rows,
                            })
                        }
                        size={1}
                        min={1}
                    />
                    {!!maxPages && (
                        <InputText
                            value={maxPageCountLabel
                                .replace('{maxPages}', maxPages.toString())
                                .toLowerCase()}
                            style={{
                                borderRadius: 0,
                                margin: '0 0 0 -1px',
                            }}
                            size={5}
                            disabled
                        />
                    )}
                    <Button
                        label={nextLabel}
                        icon='pi pi-angle-right'
                        iconPos='right'
                        disabled={
                            disableNext || (!!maxPages && page >= maxPages)
                        }
                        onClick={() => onPageChange({ page: page + 1, rows })}
                        style={{ marginRight: !maxPages ? '8px' : '0' }}
                    />
                    {!!maxPages && (
                        <Button
                            icon='pi pi-angle-double-right'
                            disabled={page >= maxPages}
                            onClick={() =>
                                onPageChange({ page: maxPages ?? 1, rows })
                            }
                            style={{ marginRight: '8px' }}
                        />
                    )}
                </span>
            </div>
            {!hideRowsPerPage && (
                <Dropdown
                    value={rows}
                    options={rowsPerPageOptions}
                    onChange={(e) => onPageChange({ page, rows: e.value })}
                />
            )}
            {showRefresh && (
                <Button
                    icon='pi pi-refresh'
                    label={refreshLabel}
                    loading={isRefreshing}
                    onClick={onRefresh}
                    style={{ marginLeft: '8px' }}
                />
            )}
        </div>
    );
};
