import { Datepicker, InputText } from 'components/ethercity-primereact';
import { useLocalization } from 'hooks/context/useLocalization';
import useExportAuthorizationFiles from 'hooks/queries/authorization/useExportAuthorizationFiles';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useState } from 'react';

const useTemplateDownloadDialog = () => {
    const [localization] = useLocalization();

    const [data, setData] = useState<Data | null>(null);

    const show = (data: Data) => setData(data);
    const hide = () => setData(null);

    const { exportFile: exportAuthFiles, isExporting: isExportingAuthFiles } =
        useExportAuthorizationFiles({ onDownload: hide });

    type Data = {
        filename: string;
        authorizationId: string;
        strategy: string;
    };

    const [date, setDate] = useState<Date | null>(null);
    const [process, setProcess] = useState<string>('');

    const templateDialog = !!data && (
        <Dialog
            visible={true}
            onHide={hide}
            header={`${localization.components.models.authorization.views.downloadTemplateForSignature.title} ${data.filename}`}
            footer={
                <>
                    <Button
                        label={localization.components.common.button.cancel}
                        severity='danger'
                        onClick={hide}
                        disabled={isExportingAuthFiles}
                    />
                    <Button
                        label={localization.components.common.button.download}
                        severity='success'
                        onClick={() => {
                            exportAuthFiles({
                                filename: data.filename,
                                authorization_id: data.authorizationId,
                                strategy: data.strategy,
                                doc_date: date ?? undefined,
                                process_number:
                                    process === '' ? undefined : process,
                            });
                        }}
                        disabled={isExportingAuthFiles}
                        loading={isExportingAuthFiles}
                    />
                </>
            }
        >
            <Datepicker
                style={{ width: '100%' }}
                label={
                    localization.components.models.authorization.views.downloadTemplateForSignature.fields.date
                }
                value={date}
                onChange={setDate}
                required={false}
            />
            <InputText
                wrapperStyle={{ width: '100%' }}
                label={
                    localization.components.models.authorization.views.downloadTemplateForSignature.fields.code
                }
                value={process}
                onChange={(e) => setProcess(e.target.value)}
                required={false}
            />
        </Dialog>
    );

    return {
        templateDialog,
        show,
    };
};

export default useTemplateDownloadDialog;
