const downloadFromUrl = (url: string, filename?: string) => {
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = filename ? filename : 'unknown';
    document.body.appendChild(a);
    a.click();
};

export default downloadFromUrl;
