import { useLocalization } from 'hooks/context/useLocalization';
import useOneDetailDocument from 'hooks/queries/document/useDetailOneDocument';
import { useProject } from 'hooks/context/project/useProject';
import useDetailOneEvidence from 'hooks/queries/evidence/useDetailOneEvidence';
import LoadingMessage from 'components/misc/LoadingMessage';
import FileViewer from '../FileViewer';

const FileViewerWrapper: React.FC<{
    mode: 'document' | 'evidence';
    modelId: string;
    token?: string;
    showCloseButton?: boolean;
    onClose?: () => void;
}> = ({ mode, modelId, token, showCloseButton, onClose }) => {
    const [localization] = useLocalization();
    const project = useProject();

    const documentQuery = useOneDetailDocument(modelId, project._id, {
        enabled: mode === 'document',
    });
    const evidenceQuery = useDetailOneEvidence(modelId, project._id, {
        enabled: mode === 'evidence',
    });

    const itemQuery = mode === 'document' ? documentQuery : evidenceQuery;
    const itemData = itemQuery.data;

    if (itemQuery.isLoading)
        return (
            <LoadingMessage>
                {localization.common.loading}
            </LoadingMessage>
        );

    if (!itemData) return <h2>{localization.validations.generic.notFound}</h2>;

    return (
        <FileViewer
            item={itemData}
            mode={mode}
            onClose={onClose}
            showCloseButton={showCloseButton}
            token={token}
        />
    );
};

export default FileViewerWrapper;
