import CompanyDataTable, {
    companyFilters,
} from 'components/models/Company/CompanyDataTable';
import CacheControl from 'controller/cache/cacheController';
import useListCompany from 'hooks/queries/company/useListCompany';
import { useAuth } from 'hooks/context/useAuth';
import useInitDataTableState from 'hooks/helpers/useInitDataTableState';
import { useLocalization } from 'hooks/context/useLocalization';
import { useProject } from 'hooks/context/project/useProject';
import { Button } from 'primereact/button';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useDetailManyUsers from 'hooks/queries/user/useDetailManyUsers';
import GroupedUserProfileDataTable from 'components/models/User/GroupedUserProfileDataTable';

const CompanyView = () => {
    const [localization] = useLocalization();
    const { permissions } = useAuth();
    const project = useProject();
    const navigate = useNavigate();

    const { pageOptions, setPageOptions, filters, setFilters } =
        useInitDataTableState({
            filters: companyFilters,
            pageOptions: {
                startingRow:
                    CacheControl.UserConfig.get()['paginatorRows']?.company,
            },
        });

    const companyQuery = useListCompany({
        project_id: project._id,
        options: {
            rawFilters: filters,
            limit: pageOptions.rows,
            offset: (pageOptions.page - 1) * pageOptions.rows,
        },
    });

    return (
        <section>
            <div className='flex flex-row justify-between'>
                <strong className='text-3xl'>
                    {localization.components.views.adminPanel.tabCompanies}
                </strong>
                <div>
                    {permissions.upsertCompanies && (
                        <Link to='create-company'>
                            <Button
                                icon='pi pi-plus'
                                label={
                                    localization.components.models.company
                                        .button.new
                                }
                            />
                        </Link>
                    )}
                </div>
            </div>
            <CompanyDataTable
                filters={filters}
                setFilters={(e) => setFilters(e)}
                paginatorProps={{
                    ...pageOptions,
                    onPageChange: (e) => setPageOptions(e),
                    disableNext:
                        (companyQuery.data?.length ?? 9999) < pageOptions.rows,
                    onRefresh: companyQuery.refresh,
                }}
                loading={companyQuery.isFetching}
                value={companyQuery.data}
                onEditCompany={(company) =>
                    navigate('edit-company/' + company._id)
                }
            />
        </section>
    );
};

const UserView = () => {
    const [localization] = useLocalization();
    const { permissions } = useAuth();

    const { pageOptions, setPageOptions, filters, setFilters } =
        useInitDataTableState({
            pageOptions: {
                startingRow:
                    CacheControl.UserConfig.get()['paginatorRows']?.groupedUserProfile,
            },
        });

    const usersQuery = useDetailManyUsers({
        options: {
            rawFilters: filters,
            limit: pageOptions.rows,
            offset: (pageOptions.page - 1) * pageOptions.rows,
        },
    });

    return (
        <section>
            <div className='flex flex-row justify-between'>
                <strong className='text-3xl'>
                    {localization.components.views.adminPanel.tabUsers}
                </strong>
                <div>
                    {permissions.upsertUsers && (
                        <Link to='create-user'>
                            <Button
                                icon='pi pi-plus'
                                label={
                                    localization.components.models.user.button
                                        .new
                                }
                            />
                        </Link>
                    )}
                </div>
            </div>
            <GroupedUserProfileDataTable
                filters={filters}
                setFilters={(e) => setFilters(e)}
                paginatorProps={{
                    ...pageOptions,
                    onPageChange: (e) => setPageOptions(e),
                    disableNext:
                        (usersQuery.data?.length ?? 9999) < pageOptions.rows,
                    onRefresh: usersQuery.refresh,
                }}
                loading={usersQuery.isFetching}
                value={usersQuery.data}
            />
        </section>
    );
};

const MenuViews = {
    USERS: 'users',
    COMPANIES: 'companies',
};

type MenuKey = keyof typeof MenuViews;

const MenuItem: React.FC<{
    label: string;
    icon?: string;
    active?: boolean;
    onClick?: () => void;
}> = ({ label, active, icon, onClick }) => {
    const textColor = active ? 'text-blue-950' : 'text-white';
    const bgColor = active ? 'bg-white' : 'bg-blue-950';

    return (
        <div
            className={[
                'flex',
                'flex-row',
                'gap-4',
                bgColor,
                'border-solid border-b border-white border-x-0 border-t-0',
                'hover:cursor-pointer',
                'p-6',
            ].join(' ')}
            onClick={onClick}
        >
            {icon ? <i className={[icon, textColor].join(' ')} /> : null}
            <span className={textColor}>{label}</span>
            <i className={['pi pi-chevron-right', textColor].join(' ')} />
        </div>
    );
};

const AdminManagementLanding = () => {
    const [localization] = useLocalization();
    const { permissions } = useAuth();

    const views = {
        // [MenuViews.USERS]: permissions.upsertUsers
        //     ? {
        //           label: localization.components.views.adminPanel.tabUsers,
        //           icon: 'pi pi-user',
        //       }
        //     : null,
        [MenuViews.COMPANIES]: permissions.upsertCompanies
            ? {
                  label: localization.components.views.adminPanel.tabCompanies,
                  icon: 'pi pi-building',
              }
            : null,
    };
    const validViews = Object.entries(views)
        .filter(([_, value]) => !!value)
        .map(([key, value]) => ({
            ...value,
            id: key as MenuKey,
        }));
    const [selectedMenu, setSelectedMenu] = useState(
        Object.keys(views)[0] as keyof typeof MenuViews
    );

    return (
        <section>
            <h1>{localization.components.views.adminPanel.title}</h1>
            <section className='grid grid-cols-10 gap-4'>
                <div className='col-span-2'>
                    {validViews.map((t) =>
                        t.label ? (
                            <MenuItem
                                label={t.label}
                                active={t.id === selectedMenu}
                                onClick={() => setSelectedMenu(t.id)}
                                icon={t.icon}
                                key={t.id}
                            />
                        ) : null
                    )}
                </div>
                <div className='col-span-8'>
                    {selectedMenu === MenuViews.USERS && <UserView />}
                    {selectedMenu === MenuViews.COMPANIES && <CompanyView />}
                </div>
            </section>
        </section>
    );
};

export default AdminManagementLanding;
