import { useMutation } from '@tanstack/react-query';
import useQueryRefresh from 'hooks/queries/useQueryRefresh';
import { useAuth } from 'hooks/context/useAuth';
import { useLocalization } from 'hooks/context/useLocalization';
import { useToast } from 'hooks/context/useToast';
import { signProjectDocument } from 'services/ether/case-manager/projects';
import { SignProjectDocumentEP } from 'services/ether/case-manager/projects/types';
import { getErrorToast } from 'utils/errorHandler';

const useSignProjectDocument = (params?: { onSign?: () => void }) => {
    const { hardRefresh } = useQueryRefresh();
    const { refreshUser } = useAuth();
    const toast = useToast();
    const [localization] = useLocalization();

    const handleSuccess = () => {
        toast.show({
            summary:
                localization.endpoints.project.sign_project_document.success,
            severity: 'success',
            life: 5000,
        });
        if (params?.onSign) params.onSign();
        hardRefresh(['project']);
        refreshUser('soft');
    };

    const signProjectDocumentMutation = useMutation<
        SignProjectDocumentEP.Result,
        Error,
        SignProjectDocumentEP.Data
    >({
        mutationFn: (data) => signProjectDocument(data),
        onSuccess: handleSuccess,
        onError: (e) => toast.show(getErrorToast(e.message, localization)),
    });

    return {
        signDocument: (data: SignProjectDocumentEP.Data) =>
            signProjectDocumentMutation.mutate(data),
        signStatus: signProjectDocumentMutation.status,
    };
};

export default useSignProjectDocument;
