import { useAuth } from '../context/useAuth';

const useGoToSupport = () => {
    const { user } = useAuth();
    const src =
        user.role === 'admin'
            ? 'https://forms.clickup.com/3055569/f/2x7yh-9473/6FE2T71XHXUW3SJ4EX'
            : user.role === 'analyst'
            ? 'https://forms.clickup.com/3055569/f/2x7yh-9493/86P0ANIR6OV1VXN1JZ'
            : user.role?.startsWith('authorizer')
            ? 'https://forms.clickup.com/3055569/f/2x7yh-9513/Q3ORWG9Q4ZJZQKHNUG'
            : 'https://forms.clickup.com/3055569/f/2x7yh-9533/806CG8NYE9HQN4CQTK';

    const goToSupport = () => window.open(src, '_blank', 'noreferrer');

    return goToSupport;
};

export default useGoToSupport;
