import { SortOrder } from 'primereact/api';
import useDetailAuthorization from './useDetailAuthorization';

const useDetailRecentAuthorization = (
    projectId: string,
    status: 'approved' | 'pending' | 'draft',
    queryOptions?: {
        enabled?: boolean;
    }
) => {
    const query = useDetailAuthorization(
        {
            project_id: projectId,
            options: {
                devFilters: {
                    status: status,
                },
                limit: 5,
                sort: {
                    field: 'created_at',
                    order: SortOrder.DESC,
                },
            },
        },
        queryOptions
    );

    return query;
};

export default useDetailRecentAuthorization;
