import { useQueryClient } from '@tanstack/react-query';
import { useAuth } from 'hooks/context/useAuth';

export type ValidCancelKeys =
    | 'target'
    | 'authorization'
    | 'authorization-config'
    | 'document'
    | 'evidence'
    | 'block-order'
    | 'unblock-order'
    | 'metrics'
    | 'operator'
    | 'operator-order'
    | 'tag'
    | 'monitor'
    | 'company'
    | 'user'
    | 'project'
    | 'me'
    | 'operation'
    | 'grouped-user';

const useQueryRefresh = () => {
    const { refreshUser } = useAuth();
    const query = useQueryClient();

    const hardRefresh = (queries: ValidCancelKeys[]) => {
        if (queries.length <= 0) return;
        queries.forEach((q) => {
            if (q === 'me') refreshUser('hard');
            else
                query.resetQueries({
                    queryKey: [q],
                });
        });
    };

    const softRefresh = (queries: ValidCancelKeys[]) => {
        if (queries.length <= 0) return;
        queries.forEach((q) => {
            if (q === 'me') refreshUser('soft');
            else
                query.refetchQueries({
                    queryKey: [q],
                });
        });
    };

    return { hardRefresh, softRefresh };
};

export default useQueryRefresh;
