import { useState } from 'react';
import { ViewTablesWithTabsProps } from './types';
import useInitDataTableState from 'hooks/helpers/useInitDataTableState';
import { DataTableOperatorFilterMetaData } from 'primereact/datatable';
import { authorizationFilters } from 'components/models/Authorization/AuthorizationDataTable';
import CacheControl from 'controller/cache/cacheController';
import { authorizationConfigFilters } from 'components/models/AuthorizationConfig/AuthorizationConfigDataTable';
import { blockOrderFilters } from 'components/models/BlockOrder/BlockOrderDataTable';
import { companyFilters } from 'components/models/Company/CompanyDataTable';
import { documentFilters } from 'components/models/Document/DocumentDataTable';
import { evidenceFilters } from 'components/models/Evidences/EvidencesDataTable';
import { operatorFilters } from 'components/models/Operators/OperatorsDataTable';
import { targetsFilters } from 'components/models/Targets/TargetsDataTable';
import { tagFilters } from 'components/models/Tag/TagDataTable';
import { unblockOrderFilters } from 'components/models/UnblockOrder/UnblockOrderDataTable';
import useQueryRefresh, {
    ValidCancelKeys,
} from 'hooks/queries/useQueryRefresh';
import useQueryWithRefresh from 'hooks/helpers/useQueryWithRefresh/useQueryWithRefresh';

type ColumnKeys = keyof CaseManagerApp.ModelColumns;

const defaultModelItems: Record<
    ColumnKeys,
    {
        filters: {
            [field: string]: DataTableOperatorFilterMetaData;
        };
    }
> = {
    authorization: {
        filters: authorizationFilters,
    },
    authorizationConfig: {
        filters: authorizationConfigFilters,
    },
    blockOrder: {
        filters: blockOrderFilters,
    },
    company: {
        filters: companyFilters,
    },
    document: {
        filters: documentFilters,
    },
    evidences: {
        filters: evidenceFilters,
    },
    monitoring: {
        filters: {},
    },
    operation: {
        filters: {},
    },
    operators: {
        filters: operatorFilters,
    },
    operatorsOrder: {
        filters: {},
    },
    tag: {
        filters: tagFilters,
    },
    targets: {
        filters: targetsFilters,
    },
    unblockOrder: {
        filters: unblockOrderFilters,
    },
    user: {
        filters: {},
    },
    groupedUserProfile: {
        filters: {},
    },
};

const TabBox: React.FC<{
    label: string;
    count?: number | string;
    active?: boolean;
    onClick?: () => void;
}> = ({ label, count, active, onClick }) => {
    const color = !active ? 'blue-950' : 'white';
    const oppositeColor = active ? 'blue-950' : 'white';

    const wrapperDivClasses = [
        'flex',
        'flex-col',
        'justify-center',
        'gap-4',
        `bg-${color}`,
        'items-center',
        'p-4',
        'w-[140px]',
        'rounded-t-lg',
        'hover:cursor-pointer',
    ];

    return (
        <div
            className={wrapperDivClasses.join(' ')}
            style={{
                border: !active ? '1px solid var(--white)' : undefined,
            }}
            onClick={onClick}
        >
            <span
                className={[
                    `text-${oppositeColor}`,
                    'text-xs',
                    'text-center',
                    'items-center',
                    ...(count != null ? ['min-h-[42px]'] : []),
                ].join(' ')}
            >
                {label.toUpperCase()}
            </span>
            {count != null && (
                <span
                    className={[
                        `text-${oppositeColor}`,
                        'text-3xl',
                        'font-bold',
                    ].join(' ')}
                >
                    {count}
                </span>
            )}
        </div>
    );
};

const cancelKeyDict: {
    [key in ColumnKeys]: ValidCancelKeys;
} = {
    authorization: 'authorization',
    authorizationConfig: 'authorization-config',
    blockOrder: 'block-order',
    company: 'company',
    document: 'document',
    evidences: 'evidence',
    monitoring: 'monitor',
    operation: 'operation',
    operators: 'operator',
    operatorsOrder: 'operator-order',
    tag: 'tag',
    targets: 'target',
    unblockOrder: 'unblock-order',
    user: 'user',
    groupedUserProfile: 'grouped-user',
};

const ViewTablesWithTabs: React.FC<ViewTablesWithTabsProps> = ({
    mainModel,
    uniqueQueryKey,
    views,
    onTabChange,
}) => {
    const [selectedIndex, setSelectedIndex] = useState(0);

    const currentView = views[selectedIndex];
    if (!currentView) throw new Error('expected view to exist');

    const model = 'model' in currentView ? currentView.model : null;

    const baseFilters =
        'overrideFilters' in currentView && currentView.overrideFilters
            ? currentView.overrideFilters
            : model
            ? defaultModelItems[model].filters
            : {};
    const baseRows = model
        ? CacheControl.UserConfig.get()['paginatorRows'][model]
        : undefined;
    const fetchMethod =
        'fetchData' in currentView
            ? currentView.fetchData
            : () => new Promise<boolean[]>((resolve) => resolve([true]));

    const { filters, setFilters, sort, setSort, pageOptions, setPageOptions } =
        useInitDataTableState({
            filters: baseFilters,
            pageOptions: {
                startingRow: baseRows,
            },
        });

    const onIndexChange = (index: number) => {
        setSelectedIndex(index);
        const newView = views[index];
        if (!newView || !('model' in newView)) return;
        const model = newView.model;
        const baseFilters =
            'overrideFilters' in newView && newView.overrideFilters
                ? newView.overrideFilters
                : defaultModelItems[model].filters;
        const rows = CacheControl.UserConfig.get()['paginatorRows'][model];
        setPageOptions({
            page: 1,
            rows: rows ?? 50,
        });
        setFilters(baseFilters);
        if (onTabChange) onTabChange(index);
    };

    const dataQuery = useQueryWithRefresh<any[]>({
        queryKey: [
            model ? cancelKeyDict[model] : model,
            'list-view-tables',
            uniqueQueryKey,
            selectedIndex,
            filters,
            sort,
            pageOptions,
        ],
        queryFn: ({ signal }) => {
            return fetchMethod({
                options: {
                    rawFilters: filters,
                    offset: (pageOptions.page - 1) * pageOptions.rows,
                    limit: pageOptions.rows,
                    sort: sort,
                },
                signal: signal,
            });
        },
    });

    const { softRefresh } = useQueryRefresh();

    const elementToDisplay =
        'tableElement' in currentView ? (
            <currentView.tableElement
                lazy
                paginatorProps={{
                    ...pageOptions,
                    onPageChange: (e) => setPageOptions(e),
                    disableNext:
                        !dataQuery.data?.length ||
                        dataQuery.data.length < pageOptions.rows,
                    onRefresh: () => {
                        if (mainModel) softRefresh([mainModel]);
                        dataQuery.refresh();
                    },
                }}
                filters={filters}
                setFilters={(e) => setFilters(e)}
                sortOrder={sort?.order}
                sortField={sort?.field}
                onSort={(e) =>
                    setSort({
                        field: e.sortField,
                        order: e.sortOrder,
                    })
                }
                value={dataQuery.data}
                loading={dataQuery.isFetching}
            />
        ) : (
            currentView.customElement
        );

    return (
        <section>
            <div className='flex flex-row gap-4 border-0 border-b-white border-b-2 border-solid'>
                {views.map((v, index) => (
                    <TabBox
                        key={index}
                        count={v.totalCount ?? undefined}
                        label={v.label}
                        active={selectedIndex === index}
                        onClick={() => onIndexChange(index)}
                    />
                ))}
            </div>
            {elementToDisplay}
        </section>
    );
};

export default ViewTablesWithTabs;
