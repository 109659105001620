import { ObjectId } from 'bson';
import storageJSONSafeParse from 'utils/storageJSONSafeParse';
import _ from 'lodash';
import { CMLocalization } from 'static/language';

export const MAX_CACHE_TIME = 30 * 60 * 1000; // 30 minutes

const prefix = 'case-manager-app';

enum STORAGE_KEYS {
    AUTH = `${prefix}:auth`,
    SELECTED_PROJECT = `${prefix}:selected-project`,
    SAVED_LANGUAGE = `${prefix}:saved-language`,
    USER_CONFIG = `${prefix}:user-config`,
}

abstract class BaseCache<T> {
    key: string;
    constructor(key: string) {
        this.key = key;
    }

    abstract validate(): void;
    protected abstract getAndHandleStorage(...args: any[]): T | null;

    get(...args: any[]): T | null {
        this.validate();
        return this.getAndHandleStorage(...args);
    }
    abstract save(...args: any[]): void;
    delete(): void {
        localStorage.removeItem(this.key);
    }
}

namespace CacheManager {
    export class Auth extends BaseCache<CacheSavedTypes.Auth> {
        constructor() {
            super(STORAGE_KEYS.AUTH);
        }
        validate() {
            const auth = storageJSONSafeParse<CacheSavedTypes.Auth>(this.key);
            if (!auth) return;
            if (Object.prototype.toString.call(auth) !== '[object Object]') {
                this.delete();
                return;
            }
        }
        getAndHandleStorage() {
            return storageJSONSafeParse<CacheSavedTypes.Auth>(this.key);
        }
        save(value: CacheSavedTypes.Auth) {
            localStorage.setItem(this.key, JSON.stringify(value));
        }
    }

    export class SelectedProject extends BaseCache<string> {
        constructor() {
            super(STORAGE_KEYS.SELECTED_PROJECT);
        }
        validate() {
            const projId = localStorage.getItem(this.key);
            if (!projId) return;
            if (!ObjectId.isValid(projId)) {
                this.delete();
                return;
            }
        }
        getAndHandleStorage() {
            return localStorage.getItem(this.key);
        }
        save(value: string) {
            localStorage.setItem(this.key, value);
        }
    }

    export class SavedLanguage extends BaseCache<CMLocalization.ValidLanguages> {
        constructor() {
            super(STORAGE_KEYS.SAVED_LANGUAGE);
        }
        validate() {
            const language = localStorage.getItem(this.key);
            if (!language) return;
        }
        getAndHandleStorage() {
            return localStorage.getItem(
                this.key
            ) as CMLocalization.ValidLanguages;
        }
        save(value: CMLocalization.ValidLanguages) {
            localStorage.setItem(this.key, value);
        }
    }

    export class UserConfiguration extends BaseCache<CacheSavedTypes.UserConfig> {
        defaultConfiguration: CacheSavedTypes.UserConfig = {
            columnsVisibility: {
                authorization: {},
                authorizationConfig: {},
                blockOrder: {},
                company: {},
                document: {},
                evidences: {},
                monitoring: {},
                operators: {},
                operation: {},
                operatorsOrder: {},
                tag: {},
                targets: {},
                unblockOrder: {},
                user: {},
                groupedUserProfile: {},
            },
            paginatorRows: {},
        };

        resetConfig() {
            const config = _.merge({}, this.defaultConfiguration);
            this.save(config);
            return config;
        }

        validateAndFixConfig<T extends { [key: string]: any }>(
            config: T,
            defConfig: T
        ): T {
            const defaultKeys = Object.keys(defConfig) as (keyof T)[];
            defaultKeys.forEach((key) => {
                if (!(key in config) || config[key] == null)
                    config[key] = _.merge({}, defConfig[key]);
                else if (typeof defConfig[key] !== typeof config[key])
                    config[key] = _.merge({}, defConfig[key]);
                else if (typeof config[key] === 'object')
                    this.validateAndFixConfig(config[key], defConfig[key]);
            });
            return config;
        }

        constructor() {
            super(STORAGE_KEYS.USER_CONFIG);
        }
        validate() {
            let config = storageJSONSafeParse<CacheSavedTypes.UserConfig>(
                this.key
            );
            if (!config) return this.resetConfig();
            if (!(typeof config === 'object')) return this.resetConfig();
            config = this.validateAndFixConfig(
                config,
                this.defaultConfiguration
            );
            this.save(config);
        }
        getAndHandleStorage(): CacheSavedTypes.UserConfig {
            return storageJSONSafeParse<CacheSavedTypes.UserConfig>(
                this.key
            ) as CacheSavedTypes.UserConfig;
        }
        get(): CacheSavedTypes.UserConfig {
            this.validate();
            return this.getAndHandleStorage();
        }
        save(value: CacheSavedTypes.UserConfig) {
            localStorage.setItem(this.key, JSON.stringify(value));
        }
        saveVisibleColumns<Key extends keyof CaseManagerApp.ModelColumns>(
            key: Key,
            value: Partial<CaseManagerApp.ModelColumns[Key]>
        ) {
            const config = this.get();
            config.columnsVisibility[key] = value;
            this.save(config);
        }

        saveRows<Key extends keyof CaseManagerApp.ModelColumns>(
            key: Key,
            value: number
        ) {
            const config = this.get();
            config.paginatorRows[key] = value;
            this.save(config);
        }
    }
}

class CacheControl {
    static Auth = new CacheManager.Auth();
    static SelectedProject = new CacheManager.SelectedProject();
    static SavedLanguage = new CacheManager.SavedLanguage();
    static UserConfig = new CacheManager.UserConfiguration();
}

export default CacheControl;
