import { useAuth } from 'hooks/context/useAuth';
import { Navigate, Outlet } from 'react-router-dom';

const Projects = () => {
    const { user } = useAuth();
    if (!user.isAdmin) return <Navigate to='/' />;

    return (
        <section>
            <Outlet />
        </section>
    );
};

export default Projects;
