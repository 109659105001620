const useMatomo = () => {
    // const sendEvent = () => {
    //     var _mtm = (window._mtm = window._mtm || []);
    //     _mtm.push({
    //         'mtm.startTime': new Date().getTime(),
    //         event: 'mtm.Start',
    //     });
    // }

    const initialize = (user: Ether.CaseManager.User) => {
        var _mtm = (window._mtm = window._mtm || []);
        _mtm.push({
            'mtm.startTime': new Date().getTime(),
            event: 'mtm.Start',
            user: user,
        });
        var d = document,
            g = d.createElement('script'),
            s = d.getElementsByTagName('script')[0];
        if (!s?.parentNode) {
            console.error('s.parentNode not found');
            return;
        }
        g.async = true;
        g.src = window.MATOMO_URL;
        s.parentNode.insertBefore(g, s);
    };

    return { initialize };
};

export default useMatomo;
