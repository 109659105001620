import { ProgressSpinner } from 'primereact/progressspinner';

const LoadingMessage: React.FC<React.PropsWithChildren> = ({ children }) => {
    return (
        <div className='px-4 flex flex-row gap-2 justify-start items-center'>
            <span>
                <ProgressSpinner className='w-6 h-6' strokeWidth='8' />
            </span>
            <h3>{children}</h3>
        </div>
    );
};

export default LoadingMessage;
