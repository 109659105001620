import InsertUserForm from 'components/models/User/InsertUserForm';
import { useAuth } from 'hooks/context/useAuth';
import { Navigate } from 'react-router-dom';

const InsertUserPage = () => {
    const { permissions } = useAuth();

    if (!permissions.upsertUsers) return <Navigate to='/admin' />;

    return <InsertUserForm />;
};

export default InsertUserPage;
