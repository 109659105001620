import NameIdAggregator from 'components/datatable/NameIdAggregator';
import { Column } from 'primereact/column';
import { DataTableProps } from 'primereact/datatable';
import { Menu } from 'primereact/menu';
import { useRef } from 'react';
import { useLocalization } from 'hooks/context/useLocalization';
import { CMDataTableProps } from 'components/datatable/CMDataTable/types';
import CMDataTable from 'components/datatable/CMDataTable';
import { MenuItem } from 'primereact/menuitem';
import { Button } from 'primereact/button';
import { getFilterData } from 'utils/datatable';
import { DateBadge } from 'components/ethercity-primereact';
import { useAuth } from 'hooks/context/useAuth';
import useShowOperationStats from 'hooks/dialogs/operation/useShowOperationStats';

type DetailedModel = Ether.CaseManager.Operation.Detailed;

const RowMenu: React.FC<{
    item: DetailedModel;
    onShowStats?: (item : { id: string, name: string }) => void;
}> = ({ item, onShowStats }) => {
    const { permissions } = useAuth();
    const [localization] = useLocalization();
    const menuRef = useRef<Menu>(null);

    const menuItems: MenuItem[] = [
        ...(permissions.getOperationStats && onShowStats
            ? [
                  {
                      icon: 'pi pi-chart-bar',
                      label: localization.components.common.stats.header,
                      command: () =>
                          onShowStats({
                              id: item._id,
                              name: item.name,
                          }),
                  },
              ]
            : []),
    ];
    if (menuItems.length <= 0) return null;
    return (
        <>
            <Menu popup ref={menuRef} model={menuItems} />
            <Button
                icon='pi pi-ellipsis-h'
                onClick={(e) => menuRef?.current?.toggle(e)}
            />
        </>
    );
};

type KeyInColumn =
    | keyof CaseManagerApp.ModelColumns['operation']
    | CaseManagerApp.DefaultNameColumn;

const columnFields: { [key in KeyInColumn]: key } = {
    _cm_name_id: '_cm_name_id',
    created_at: 'created_at',
};

export const operationFilters = {
    [columnFields['_cm_name_id']]: getFilterData(),
};

const OperationDataTable: React.FC<
    DataTableProps<DetailedModel[]> & Omit<CMDataTableProps, 'columnConfigName'>
> = ({ ...props }) => {
    const [localization] = useLocalization();

    const datatableLocalization =
        localization.components.models.operation.datatable;
    const fieldsLocalization = localization.fields.operation;

    const { dialog: statsDialog, show: showStats } = useShowOperationStats();

    return (
        <>
            {statsDialog}
            <CMDataTable
                columnConfigName='operation'
                lazy
                emptyMessage={datatableLocalization.empty}
                filterOptions={{
                    [columnFields['_cm_name_id']]: {
                        placeholder: datatableLocalization.selectName,
                        type: 'string',
                    },
                }}
                {...props}
            >
                <Column
                    field={columnFields['_cm_name_id']}
                    body={(data: DetailedModel) => (
                        <NameIdAggregator name={data.name} id={data._id} />
                    )}
                />
                <Column
                    field={columnFields['created_at']}
                    header={fieldsLocalization.created}
                    body={(data: DetailedModel) => (
                        <DateBadge value={data.created_at} />
                    )}
                />
                <Column
                    field='actions'
                    body={(data: DetailedModel) => (
                        <RowMenu item={data} onShowStats={showStats} />
                    )}
                />
            </CMDataTable>
        </>
    );
};

export default OperationDataTable;
