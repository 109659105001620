import React, { useCallback, useRef, useState } from 'react';
import LoadingMessage from 'components/misc/LoadingMessage';
import InsertBlockOrderModal from 'components/models/BlockOrder/InsertBlockOrderModal';
import { useProject } from 'hooks/context/project/useProject';
import { useLocalization } from 'hooks/context/useLocalization';
import { useToast } from 'hooks/context/useToast';
import { detailOneBlockOrder } from 'services/ether/case-manager/block-orders';
import { Dialog } from 'primereact/dialog';
import { getErrorToast } from 'utils/errorHandler';

const useUpsertBlockOrderDialog = (options?: { onUpsert?: () => void }) => {
    const project = useProject();
    const toast = useToast();
    const [mode, setMode] = useState<'edit' | 'create' | null>(null);
    const [editItem, setEditItem] =
        useState<Ether.CaseManager.BlockOrder.Detailed | null>(null);
    const [localization] = useLocalization();

    const signalRef = useRef<AbortController>();

    const hideDialog = () => {
        setEditItem(null);
        setMode(null);
        if (signalRef.current && !signalRef.current.signal.aborted)
            signalRef.current.abort();
    };
    const showCreateDialog = () => {
        setMode('create');
        setEditItem(null);
    };
    const showEditDialog = (itemId: string) => {
        signalRef.current = new AbortController();
        setMode('edit');
        setEditItem(null);
        detailOneBlockOrder({
            _id: itemId,
            project_id: project._id,
            signal: signalRef.current.signal,
        })
            .then((data) => {
                if (!data) {
                    toast.show({
                        severity: 'error',
                        summary: localization.validations.generic.notFound,
                        life: 5000,
                    });
                    setMode(null);
                    return;
                }
                setEditItem(data);
            })
            .catch((err) => {
                if ('message' in err && err.message === 'canceled') return;
                toast.show(getErrorToast(err, localization));
            });
    };

    const UpsertDialog = useCallback(() => {
        if (mode === 'edit' && !editItem) {
            return (
                <Dialog visible={true} onHide={hideDialog}>
                    <LoadingMessage>
                        {localization.common.loading}
                    </LoadingMessage>
                </Dialog>
            );
        }
        return (
            mode !== null && (
                <InsertBlockOrderModal
                    visible={true}
                    onHide={hideDialog}
                    header={localization.components.models.operation.button.new}
                    onInsert={options?.onUpsert}
                    blockOrderToEdit={editItem}
                />
            )
        );
    }, [editItem, mode, localization, options?.onUpsert]);

    return {
        UpsertDialog,
        showCreate: showCreateDialog,
        showEdit: showEditDialog,
        hide: hideDialog,
    };
};

export default useUpsertBlockOrderDialog;
