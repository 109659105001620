import useGetBlockOrderHistory from 'hooks/queries/block-order/useGetBlockOrderHistory';
import {
    AvailableColors,
    BlockOrderHistoryViewerProps,
    DisplayModelProps,
    DisplaySingleModelProps,
    ExpandedItemProps,
} from './types';
import { useLocalization } from 'hooks/context/useLocalization';
import LoadingMessage from 'components/misc/LoadingMessage';
import React, { useEffect, useRef, useState } from 'react';

import './style.css';
import { Link } from 'react-router-dom';
import { format as formatDate } from 'date-fns';

const presentDate = (date: Date) => formatDate(date, 'dd/MM/yyyy - HH:mm:ss');

const getClassColorName = (color?: AvailableColors) => {
    return color === 'orange'
        ? 'orange-500'
        : color === 'green'
        ? 'green-500'
        : 'blue-500';
};

const ExpandedItem: React.FC<ExpandedItemProps> = ({
    color,
    model,
    operations,
}) => {
    const classColor = getClassColorName(color);
    const [localization] = useLocalization();

    const getLocalizedOperation = (operation: string) => {
        const opNames =
            localization.components.models.blockOrder.views.history[model];
        switch (operation) {
            case 'created_at':
                return opNames.created;
            case 'updated_at':
                return opNames.updated;
            case 'approved_at':
                if ('approved' in opNames) return opNames.approved;
                break;
            case 'rejected_at':
                if ('rejected' in opNames) return opNames.rejected;
        }
        return operation;
    };

    const link =
        model === 'blockOrder'
            ? 'block-orders'
            : model === 'unblockOrder'
            ? 'unblock-orders'
            : 'authorizations';

    return (
        <>
            <div
                className={`border-${classColor} border-solid border-l-[2px] border-0 arrow-item`}
                style={{
                    position: 'relative',
                    width: 0,
                    height: '2.5rem',
                    margin: 'auto',
                }}
            />
            {operations.map((op, index) => {
                const isLast = index === operations.length - 1;

                return (
                    <React.Fragment key={`${op._id}_${index}`}>
                        <section
                            className={`text-center text-${classColor} flex flex-col py-3 w-[70%]`}
                        >
                            <span>{presentDate(op.date)}</span>
                            <Link
                                to={`/${link}/${op._id}`}
                                className={`border-${classColor} border-dotted border-[2px] rounded-md py-2`}
                            >
                                {getLocalizedOperation(op.operation)}
                            </Link>
                        </section>
                        {!isLast && (
                            <div
                                className={`border-${classColor} border-dotted border-l-[2px] border-0 arrow-item`}
                                style={{
                                    position: 'relative',
                                    width: 0,
                                    height: '2rem',
                                    margin: 'auto',
                                }}
                            >
                                <div
                                    className={`border-${classColor}`}
                                    style={{
                                        position: 'absolute',
                                        width: 0,
                                        height: 0,
                                        borderStyle: 'solid',
                                        borderWidth: '10px 5px 0 5px',
                                        borderLeftColor: 'transparent',
                                        borderRightColor: 'transparent',
                                        right: '-4px',
                                        bottom: '-6px',
                                    }}
                                />
                            </div>
                        )}
                    </React.Fragment>
                );
            })}
        </>
    );
};

const DisplayModelSingle: React.FC<DisplaySingleModelProps> = ({
    model,
    item,
    color,
}) => {
    const classColor = getClassColorName(color);
    const [isExpanded, setIsExpanded] = useState(false);

    const classNames = [
        'border-solid',
        `border-${classColor}`,
        'border-2',
        'font-bold',
        'py-2',
        'text-center',
        'w-[80%]',
        'relative',
        'rounded-md',
        'hover:cursor-pointer',
        isExpanded ? `text-blue-900` : `text-${classColor}`,
    ];

    if (isExpanded) {
        classNames.push(`bg-${classColor}`);
    }

    return (
        <>
            <span className={`mt-6 text-${classColor}`}>
                {presentDate(item.data.created_at)}
            </span>
            <section
                className={classNames.join(' ')}
                onClick={() => setIsExpanded((old) => !old)}
            >
                {item.data.name}
                <div className='z-10 absolute right-[50%] bottom-[-10px]'>
                    <span
                        className={`absolute left-[-9px] top-[-16px] bg-${classColor} rounded-full p-1 pi pi-${
                            isExpanded ? 'minus' : 'plus'
                        }`}
                        style={{
                            fontSize: '10px',
                            color: 'var(--blue-900)',
                            fontWeight: 'bold',
                        }}
                    />
                </div>
            </section>
            {isExpanded && (
                <ExpandedItem
                    model={model}
                    color={color}
                    operations={item.operations}
                />
            )}
        </>
    );
};

const DisplayModel: React.FC<DisplayModelProps> = ({
    model,
    hideArrow,
    color,
    items,
}) => {
    const [localization] = useLocalization();

    const [isExpanded, setIsExpanded] = useState(false);

    const classColor = getClassColorName(color);

    const arrowRef = useRef<HTMLDivElement>(null);
    const [arrowPos, setArrowPos] = useState<DOMRect>({
        bottom: 0,
        height: 0,
        left: 0,
        right: 0,
        top: 0,
        width: 0,
        x: 0,
        y: 0,
        toJSON: () => {},
    });

    useEffect(() => {
        const handleResize = () => {
            const arrowElem = arrowRef.current;
            if (!arrowElem) return;
            const boundary = arrowElem.getBoundingClientRect();
            setArrowPos(boundary);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [arrowRef]);

    if (items.length <= 0) return null;

    const date = items
        .flatMap((item) => item.operations)
        .sort((a, b) => (a.date > b.date ? -1 : b.date > a.date ? 1 : 0))[0]
        ?.date as Date;

    const targetModel = localization.models[model];

    const upperLabel =
        items.length > 1 ? targetModel.plural : targetModel.singular;
    const bottomLabel =
        items.length > 1
            ? `(${items.length} ${targetModel.plural})`
            : (items[0]?.data.name as string);

    const classNames = [
        'flex',
        'flex-col',
        'rounded-md',
        `border-${classColor}`,
        'border-2',
        'border-solid',
        'arrow-wrapper',
        'hover:cursor-pointer',
        'relative',
        'w-full',
    ].join(' ');

    return (
        <section className='flex flex-col items-center'>
            <span className={`text-center text-${classColor}`}>
                {presentDate(date)}
            </span>
            <div
                className={classNames}
                ref={arrowRef}
                onClick={() => setIsExpanded((old) => !old)}
            >
                <div
                    className={`bg-${classColor} text-center py-2 text-blue-900 font-bold`}
                >
                    {upperLabel.toLocaleUpperCase()}
                </div>
                <div className='bg-transparent text-center py-2'>
                    {bottomLabel}
                </div>
                <div className='z-10 absolute right-[50%] bottom-[-10px]'>
                    <span
                        className={`absolute left-[-9px] top-[-16px] bg-${classColor} rounded-full p-1 pi pi-${
                            isExpanded ? 'minus' : 'plus'
                        }`}
                        style={{
                            fontSize: '10px',
                            color: 'var(--blue-900)',
                            fontWeight: 'bold',
                        }}
                    />
                </div>
                {!hideArrow && (
                    <div
                        className={`border-${classColor} border-dotted border-t-[2px] border-0 arrow-item`}
                        style={{
                            position: 'absolute',
                            width: '11rem',
                            left: arrowPos.width + 8,
                            bottom: arrowPos.height / 2,
                        }}
                    >
                        <div
                            className={`border-${classColor}`}
                            style={{
                                position: 'absolute',
                                width: 0,
                                height: 0,
                                borderStyle: 'solid',
                                borderWidth: '5px 0 5px 10px',
                                borderTopColor: 'transparent',
                                borderBottomColor: 'transparent',
                                right: '-3px',
                                top: '-6px',
                            }}
                        >
                            {' '}
                        </div>{' '}
                    </div>
                )}
            </div>
            {isExpanded && items.length === 1 && items[0]?.operations && (
                <ExpandedItem
                    model={model}
                    color={color}
                    operations={items[0].operations}
                />
            )}
            {isExpanded &&
                items.length > 1 &&
                items.map((item) => {
                    return (
                        <DisplayModelSingle
                            key={item.data._id}
                            model={model}
                            color={color}
                            item={item}
                        />
                    );
                })}
        </section>
    );
};

const ArrowColumn: React.FC<React.PropsWithChildren> = ({ children }) => {
    return (
        <div className='relative'>
            <span className='absolute font-bold text-blue-900 z-10 w-full text-center top-2'>
                {children}
            </span>
            <div
                className={`skew-x-[24deg] bg-white text-center py-2 mb-[-1px]`}
            />
            <div className={`skew-x-[-24deg] bg-white text-center py-2`} />
        </div>
    );
};

const BlockOrderHistoryViewer: React.FC<BlockOrderHistoryViewerProps> = ({
    blockOrderId,
}) => {
    const blockOrderHistory = useGetBlockOrderHistory(blockOrderId);
    const [localization] = useLocalization();

    if (blockOrderHistory.isLoading)
        return (
            <LoadingMessage>
                {localization.common.loading}
            </LoadingMessage>
        );

    if (blockOrderHistory.isError)
        return <span>{localization.validations.generic.unhandled}</span>;

    const data = blockOrderHistory.data;

    if (!data) return <span>{localization.validations.generic.notFound}</span>;

    const organizedHistory: {
        authorizations: {
            [id: string]: {
                operations: Ether.CaseManager.BlockOrderHistory['operations_history'];
                data: Ether.CaseManager.Authorization.Detailed;
            };
        };
        blockOrder: {
            operations: Ether.CaseManager.BlockOrderHistory['operations_history'];
            data: Ether.CaseManager.BlockOrder.Detailed;
        };
        unblockOrders: {
            [id: string]: {
                operations: Ether.CaseManager.BlockOrderHistory['operations_history'];
                data: Ether.CaseManager.UnblockOrder.Detailed;
            };
        };
    } = {
        authorizations: {},
        unblockOrders: {},
        blockOrder: {
            operations: [],
            data: data.data.block_order,
        },
    };

    data.operations_history.forEach((operation) => {
        if (operation.model === 'authorization') {
            if (!(operation._id in organizedHistory.authorizations)) {
                const authorization = data.data.authorizations.find(
                    (a) => a._id === operation._id
                );
                if (!authorization) return;
                organizedHistory.authorizations[operation._id] = {
                    data: authorization,
                    operations: [],
                };
            }
            organizedHistory.authorizations[operation._id]?.operations.push(
                operation
            );
        } else if (operation.model === 'unblock_order') {
            if (!(operation._id in organizedHistory.unblockOrders)) {
                const unblockOrder = data.data.unblock_orders.find(
                    (a) => a._id === operation._id
                );
                if (!unblockOrder) return;
                organizedHistory.unblockOrders[operation._id] = {
                    data: unblockOrder,
                    operations: [],
                };
            }
            organizedHistory.unblockOrders[operation._id]?.operations.push(
                operation
            );
        } else if (operation.model === 'block_order') {
            organizedHistory.blockOrder.operations.push(operation);
        }
    });

    return (
        <section className='grid grid-cols-3 gap-x-48 gap-y-4 block-order-history-viewer mt-4'>
            <ArrowColumn>
                {localization.models.authorization.singular.toLocaleUpperCase()}
            </ArrowColumn>
            <ArrowColumn>
                {localization.models.blockOrder.singular.toLocaleUpperCase()}
            </ArrowColumn>
            <ArrowColumn>
                {localization.models.unblockOrder.singular.toLocaleUpperCase()}
            </ArrowColumn>
            <div>
                <DisplayModel
                    model='authorization'
                    color='orange'
                    items={Object.values(organizedHistory.authorizations)}
                />
            </div>
            <div>
                <DisplayModel
                    model='blockOrder'
                    color='blue'
                    items={[organizedHistory.blockOrder]}
                    hideArrow={
                        Object.values(organizedHistory.unblockOrders).length <=
                        0
                    }
                />
            </div>
            <div>
                <DisplayModel
                    model='unblockOrder'
                    color='green'
                    items={Object.values(organizedHistory.unblockOrders)}
                    hideArrow
                />
            </div>
        </section>
    );
};

export default BlockOrderHistoryViewer;
