import useQueryWithRefresh from 'hooks/helpers/useQueryWithRefresh/useQueryWithRefresh';
import { listOneUser } from 'services/ether/case-manager/users';
import { ListUserEP } from 'services/ether/case-manager/users/types';

const useListOneUser = (
    params: ListUserEP.Params.One,
    queryOptions?: {
        enabled?: boolean;
    }
) => {
    const queryKey = ['user', 'list', params];

    const query = useQueryWithRefresh<Ether.CaseManager.User | null, Error>({
        queryKey,
        queryFn: ({ signal }) =>
            listOneUser({
                ...params,
                signal,
            }),
        ...queryOptions,
    });

    return query;
};

export default useListOneUser;
