import { useLocalization } from 'hooks/context/useLocalization';
import { Badge } from 'primereact/badge';

const YesNoBadge: React.FC<{ value?: boolean | null }> = ({ value }) => {
    const [localization] = useLocalization();
    if (value == null) return '-';
    return value ? (
        <Badge severity={'success'} value={localization.common.yes} />
    ) : (
        <Badge severity={'danger'} value={localization.common.no} />
    );
};
export default YesNoBadge;
