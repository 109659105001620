import FileViewer from 'components/models/Evidences/FileViewer';
import { Dialog } from 'primereact/dialog';
import { useCallback, useMemo, useState } from 'react';

const useFileViewerDialog = () => {
    const [data, setData] = useState<
        | ((
              | {
                    mode: 'document';
                    data: Ether.CaseManager.Document.Detailed;
                }
              | { mode: 'evidence'; data: Ether.CaseManager.Evidence.Detailed }
          ) & {
              token?: string;
          })
        | null
    >(null);

    const hide = useCallback(() => setData(null), []);
    const showDocument = useCallback(
        (doc: Ether.CaseManager.Document.Detailed, token?: string) =>
            setData({
                data: doc,
                mode: 'document',
                token: token,
            }),
        []
    );
    const showEvidence = useCallback(
        (evidence: Ether.CaseManager.Evidence.Detailed, token?: string) =>
            setData({
                data: evidence,
                mode: 'evidence',
                token: token,
            }),
        []
    );

    const fileViewer = useMemo(
        () =>
            data && (
                <Dialog
                    header={data.data.file.name}
                    visible={true}
                    onHide={hide}
                >
                    <FileViewer
                        item={data.data}
                        mode={data.mode}
                        onClose={hide}
                        showCloseButton
                        token={data.token}
                    />
                </Dialog>
            ),
        [data, hide]
    );

    return {
        fileViewer,
        hide,
        showDocument,
        showEvidence,
    };
};

export default useFileViewerDialog;
