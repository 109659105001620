import { filesize } from 'filesize';
import { ToastMessage } from 'primereact/toast';
import { CMLocalization } from 'static/language';

export const localizeError = (
    message: string,
    localization: CMLocalization.Localization
): {
    summary: string;
    details?: string;
} => {
    if (message.includes('timeout ') && message.includes(' exceeded')) {
        return {
            summary: 'Timeout',
        };
    }

    if (message.includes('auth_error: forbidden'))
        return {
            summary: 'Forbidden',
        };

    if (
        message.includes('name already exists') ||
        message.includes('duplicated register')
    ) {
        return {
            summary: localization.validations.generic.nameExists,
        };
    }

    if (message.includes('File exceeds ')) {
        const limit = message.split('limit of ')[1] ?? 'unknown';
        return {
            summary:
                localization.endpoints.document.upsert_document.insertFailedDetailsTooBig.replace(
                    '{size}',
                    limit
                ),
        };
    }

    if (message.includes('company already exists')) {
        return {
            summary:
                localization.endpoints.company.upsert_company
                    .createAlreadyExists,
        };
    }

    if (
        message.includes('all targets must be checked') ||
        message.includes('all targets must be validated')
    ) {
        return {
            summary: localization.validations.target.haveAllTargetsValidated,
        };
    }

    if (message.includes('no_value'))
        return {
            summary:
                localization.endpoints.target.parse_target_file.error.noValue,
        };

    if (message.includes('invalid target')) {
        const value = message.replace('invalid target ', '');
        return {
            summary: `${localization.endpoints.target.parse_target_file.error.invalidTarget}: ${value}`,
        };
    }

    if (message.includes('malformed_target')) {
        return {
            summary:
                localization.endpoints.target.parse_target_file.error
                    .malformedTarget,
        };
    }

    if (message.includes('invalid_category_value')) {
        return {
            summary:
                localization.endpoints.target.parse_target_file.error
                    .invalidCategory,
        };
    }

    if (message.includes('authority validation initiated')) {
        return {
            summary:
                localization.endpoints.authorization.change_authorization_status
                    .authorityStartedValidationError,
        };
    }

    return {
        summary: localization.validations.generic.unhandled,
    };
};

export const getErrorToast = (
    message: string,
    localization: CMLocalization.Localization
): ToastMessage => {
    const error = localizeError(message, localization);

    const baseToast: ToastMessage = {
        summary: error.summary,
        detail: error.details,
        severity: 'error',
        life: 10000,
    };

    return baseToast;
};

export const getFileErrors = (
    messages: [number, string][],
    localization: CMLocalization.Localization
) => {
    const maxErrorLen = 7;
    if (messages.length === 1) {
        const [, error] = messages[0] as [number, string];
        const reason = localizeError(error, localization).summary;
        return <span>{reason}</span>;
    }
    return (
        <div className='flex flex-col gap-1 text-xs'>
            <b>{localization.endpoints.target.upsert_target.insertFailed}</b>
            {messages.slice(0, maxErrorLen).map(([index, error]) => {
                const fileIndex = `${localization.models.file.singular} ${
                    index + 1
                }`;
                const reason = localizeError(error, localization).summary;
                return (
                    <span key={fileIndex}>
                        {fileIndex}: {reason}
                    </span>
                );
            })}
            {messages.length > maxErrorLen &&
                localization.validations.generic.lineMoreErrors.replace(
                    '{count}',
                    (messages.length - maxErrorLen).toString()
                )}
        </div>
    );
};

export const getCsvLineErrors = (
    messages: [number, string][],
    localization: CMLocalization.Localization,
    key?: string
) => {
    const maxErrorLen = 7;
    return (
        <div className='flex flex-col gap-1 text-xs' key={key}>
            {messages.slice(0, maxErrorLen).map(([index, error]) => {
                const value = localization.validations.generic.lineError
                    .replace('{index}', index.toString())
                    .replace(
                        '{error}',
                        localizeError(error, localization).summary
                    );
                return <span key={value}>{value}</span>;
            })}
            {messages.length > maxErrorLen &&
                localization.validations.generic.lineMoreErrors.replace(
                    '{count}',
                    (messages.length - maxErrorLen).toString()
                )}
        </div>
    );
};

export const checkFileSizeForEndpoint = ({
    endpoint,
    size,
}: {
    endpoint:
        | 'parse-target-file'
        | 'insert-evidence'
        | 'upsert-document'
        | 'parse-zip-file';
    size: number;
}) => {
    const kb = 1000;
    const mb = kb * 1000;
    const maxSize = 70 * mb;
    if (size > maxSize)
        throw new Error('File exceeds limit of ' + filesize(maxSize));
};
