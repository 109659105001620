import { downloadTemplateTargets } from 'services/ether/case-manager/projects';
import useBaseFileExport from '../useBaseFileExport';

const useDownloadTemplateTargets = ({
    authorizationConfigId,
}: {
    authorizationConfigId: string;
}) => {
    const queryKey = authorizationConfigId;

    const { exportFile, isExporting: isDownloadingTemplate } =
        useBaseFileExport('download-template-targets', queryKey, ({ signal }) =>
            downloadTemplateTargets({
                authorization_config_id: authorizationConfigId,
                signal,
            })
        );

    const downloadTemplate = () => exportFile({ params: null });

    return { downloadTemplate, isDownloadingTemplate };
};

export default useDownloadTemplateTargets;
