import { DownloadEvidencesFromTargetParams } from 'services/ether/case-manager/evidences/types';
import { downloadEvidencesFromTarget } from 'services/ether/case-manager/evidences';
import { useState } from 'react';

const useDownloadEvidencesFromTarget = () => {
    const [isDownloading, setIsDownloading] = useState(false);
    const [error, setError] = useState<null | Error>(null);
    const [params, setParams] = useState<
        null | DownloadEvidencesFromTargetParams['params']
    >(null);
    const [progress, setProgress] = useState(0);

    const download = (params: DownloadEvidencesFromTargetParams['params']) => {
        setIsDownloading(true);
        setError(null);
        setParams(params);
        setProgress(0);
        downloadEvidencesFromTarget({
            params,
            options: {
                onProgressUpdate: ({ progress }) => setProgress(progress),
            },
        })
            .then(() => {
                setIsDownloading(false);
                setError(null);
                setParams(null);
            })
            .catch((err) => setError(err));
    };

    return {
        downloadFile: download,
        params,
        progress,
        isDownloading: isDownloading,
        error,
    };
};

export default useDownloadEvidencesFromTarget;
