import useQueryWithRefresh from 'hooks/helpers/useQueryWithRefresh/useQueryWithRefresh';
import { groupManyUserProfiles } from 'services/ether/case-manager/users';
import { GroupUserProfilesEP } from 'services/ether/case-manager/users/types';

const useDetailManyUsers = (
    params: GroupUserProfilesEP.Params.Many,
    queryOptions?: {
        enabled?: boolean;
    }
) => {
    const queryKey = ['grouped-user', 'list', params];

    const query = useQueryWithRefresh<Ether.CaseManager.User.GroupedProfile[], Error>({
        queryKey,
        queryFn: ({ signal }) =>
            groupManyUserProfiles({
                ...params,
                signal,
            }),
        ...queryOptions,
    });

    return query;
};

export default useDetailManyUsers;
