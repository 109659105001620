import { Dropdown } from 'components/ethercity-primereact';
import { useLocalization } from 'hooks/context/useLocalization';
import { DropdownProps } from 'primereact/dropdown';

const ActiveStatusDropdown: React.FC<
    Omit<DropdownProps, 'options'> & { label: string; required?: boolean }
> = (props) => {
    const [localization] = useLocalization();

    return (
        <Dropdown
            {...props}
            options={[
                { label: localization.common.active, value: 'active' },
                { label: localization.common.inactive, value: 'inactive' },
            ]}
        />
    );
};

export default ActiveStatusDropdown;
