import { useProject } from 'hooks/context/project/useProject';
import { TargetsCompanySummaryProps } from './types';
import useInitDataTableState from 'hooks/helpers/useInitDataTableState';
import useDetailTarget from 'hooks/queries/target/useDetailTarget';
import TargetsDataTable, {
    targetsFilters,
} from 'components/models/Targets/TargetsDataTable';

const TargetsCompanySummary: React.FC<TargetsCompanySummaryProps> = ({
    companyId,
    authorizationConfigId,
    blockOrder,
    unblockOrder,
}) => {
    const project = useProject();

    const { filters, setFilters, pageOptions, setPageOptions } =
        useInitDataTableState({
            filters: targetsFilters,
            pageOptions: {
                startingRow: 5,
            },
        });

    const targetsQuery = useDetailTarget({
        project_id: project._id,
        options: {
            rawFilters: filters,
            limit: pageOptions.rows,
            offset: (pageOptions.page - 1) * pageOptions.rows,
            devFilters: {
                company_id: companyId,
                authorization_config_id: authorizationConfigId,
                block_order_id: blockOrder?._id,
                unblock_order_id: unblockOrder?._id,
            },
        },
    });

    return (
        <TargetsDataTable
            showTags={false}
            showOperatorAnswer
            projectId={project._id}
            refBlockOrder={blockOrder}
            refUnblockOrder={unblockOrder}
            value={targetsQuery.data}
            loading={targetsQuery.isFetching}
            filters={filters}
            setFilters={setFilters}
            paginatorProps={{
                hideRowsPerPage: true,
                onPageChange: (e) => setPageOptions(e),
                page: pageOptions.page,
                rows: pageOptions.rows,
                onRefresh: targetsQuery.refresh,
                disableNext:
                    !targetsQuery.data?.length ||
                    targetsQuery.data.length < pageOptions.rows,
            }}
        />
    );
};

export default TargetsCompanySummary;
