import { useLocalization } from 'hooks/context/useLocalization';
import { Tooltip } from 'primereact/tooltip';

const ReservedIpBadge = () => {
    const [localization] = useLocalization();
    return (
        <>
            <Tooltip target='.reserved-ip-badge' />
            <i
                className='reserved-ip-badge pi pi-exclamation-circle text-yellow-500'
                data-pr-tooltip={localization.fields.target.reservedIp}
                data-pr-position='right'
                data-pr-my='left center-2'
                style={{ fontSize: '1.2rem' }}
            />
        </>
    );
};

export default ReservedIpBadge;
