import { FilterOperator } from 'primereact/api';
import {
    DataTableFilterMetaData,
    DataTableOperatorFilterMetaData,
    SortOrder,
} from 'primereact/datatable';

export const getFilterData = (
    initialMatchMode?: DataTableFilterMetaData['matchMode'],
    initialValue?: string | number | boolean | null
): DataTableOperatorFilterMetaData => {
    return {
        operator: FilterOperator.AND,
        constraints: [
            {
                matchMode: initialMatchMode ?? 'contains',
                value: initialValue ?? null,
            },
        ],
    };
};

export const handleSort = (
    sort:
        | {
              order: SortOrder | null;
              field: string;
          }
        | undefined
        | null,
    overrideField?: Record<string, string>
) => {
    if (!sort) return undefined;
    let { order, field } = sort;
    let finalOrder: string | undefined = undefined;
    if (overrideField && field in overrideField)
        field = overrideField[field] as string;
    if (order === 1) finalOrder = field;
    else if (order === -1) finalOrder = '-' + field;
    return finalOrder;
};
