import { InputText } from 'components/ethercity-primereact';
import { useLocalization } from 'hooks/context/useLocalization';

const MonitorForm: React.FC<MonitorFormProps> = ({
    name,
    setName,
    networkURL,
    videoURL,
    setNetworkURL,
    setVideoURL,
    disableAll,
}) => {
    const [localization] = useLocalization();

    return (
        <div className='flex flex-col gap-2'>
            <InputText
                required
                label={localization.fields.monitoring.name}
                value={name}
                onChange={(e) => setName(e.target.value)}
                disabled={disableAll}
            />
            <InputText
                required
                label={localization.fields.monitoring.networkUrl}
                value={networkURL}
                onChange={(e) => setNetworkURL(e.target.value)}
                disabled={disableAll}
            />
            <InputText
                required
                label={localization.fields.monitoring.videoUrl}
                value={videoURL}
                onChange={(e) => setVideoURL(e.target.value)}
                disabled={disableAll}
            />
        </div>
    );
};

export default MonitorForm;
