import { useMutation } from '@tanstack/react-query';
import useQueryRefresh from 'hooks/queries/useQueryRefresh';
import { useLocalization } from 'hooks/context/useLocalization';
import { useToast } from 'hooks/context/useToast';
import { ToastMessage } from 'primereact/toast';
import { importOperator } from 'services/ether/case-manager/operator';
import { ImportOperatorEP } from 'services/ether/case-manager/operator/types';
import { getErrorToast } from 'utils/errorHandler';

const useImportOperator = ({ onInsert }: { onInsert?: () => void }) => {
    const toast = useToast();
    const [localization] = useLocalization();
    const { hardRefresh, softRefresh } = useQueryRefresh();

    const handleUpdate = ({
        data,
        params,
    }: {
        data: ImportOperatorEP.Result;
        params: ImportOperatorEP.Data;
    }) => {
        const { created, errors, updated } = data;

        const stats = {
            success: created.length,
            updated: updated.length,
            errors: errors.length,
        };

        const toastMessage: ToastMessage = {
            summary:
                localization.endpoints.operator.import_operator.insertSuccess,
        };
        if (stats.errors && (stats.success || stats.updated)) {
            toastMessage.summary =
                localization.endpoints.operator.import_operator.insertPartial;
            toastMessage.detail = (
                <div className='flex flex-col gap-2'>
                    <span>
                        {
                            localization.endpoints.operator.import_operator
                                .insertFailedDetails
                        }
                    </span>
                    {errors.map(([index, error]) => {
                        const value = localization.validations.generic.lineError
                            .replace('{index}', index.toString())
                            .replace('{error}', error);
                        return <span key={value}>{value}</span>;
                    })}
                </div>
            );
            toastMessage.severity = 'warn';
            toastMessage.life = 60000;
        } else if (stats.errors) {
            toastMessage.summary =
                localization.endpoints.operator.import_operator.insertFailed;
            toastMessage.detail = (
                <div className='flex flex-col gap-2'>
                    <span>
                        {
                            localization.endpoints.operator.import_operator
                                .insertFailedDetails
                        }
                    </span>
                    {errors.map(([index, error]) => {
                        const value = localization.validations.generic.lineError
                            .replace('{index}', index.toString())
                            .replace('{error}', error);
                        return <span key={value}>{value}</span>;
                    })}
                </div>
            );
            toastMessage.severity = 'error';
            toastMessage.life = 60000;
        } else if (!stats.updated) {
            toastMessage.summary = (
                <b>
                    {
                        localization.endpoints.operator.import_operator
                            .insertSuccess
                    }
                </b>
            );
            toastMessage.severity = 'success';
            toastMessage.life = 5000;
        } else {
            toastMessage.summary = (
                <b>
                    {
                        localization.endpoints.operator.import_operator
                            .updateSuccess
                    }
                </b>
            );
            toastMessage.severity = 'success';
            toastMessage.life = 5000;
        }

        toast.show(toastMessage);
    };

    const importOperatorMutation = useMutation<
        ImportOperatorEP.Result,
        Error,
        ImportOperatorEP.Data
    >({
        mutationFn: (data) => importOperator(data),
        onSuccess: (data, params) => {
            handleUpdate({ data, params });
            hardRefresh(['operator']);
            softRefresh(['company']);
            if (onInsert) onInsert();
        },
        onError: (err) => toast.show(getErrorToast(err.message, localization)),
    });

    return importOperatorMutation;
};

export default useImportOperator;
