import { useLocalization } from 'hooks/context/useLocalization';
import { Badge, BadgeProps } from 'primereact/badge';

const EvidenceParsedDataStatusBadge: React.FC<{
    evidence: Ether.CaseManager.Evidence;
    badgeProps?: BadgeProps;
}> = ({ evidence, badgeProps }) => {
    const [localization] = useLocalization();
    const evidenceParsedDataStatusBadge =
        localization.components.models.evidence.badge.parseStatus;
    const status = evidence.parse_status;
    if (!status) return '-';
    badgeProps = {
        ...badgeProps,
        severity: 'info',
        value: status?.toLocaleUpperCase(),
    };
    switch (status) {
        case 'done':
            badgeProps.severity = 'success';
            badgeProps.value =
                evidenceParsedDataStatusBadge.done.toLocaleUpperCase();
            break;
        case 'pending':
            badgeProps.severity = 'warning';
            badgeProps.value =
                evidenceParsedDataStatusBadge.processing.toLocaleUpperCase();
            break;
        case 'error':
            badgeProps.severity = 'danger';
            badgeProps.value =
                evidenceParsedDataStatusBadge.error.toLocaleUpperCase();
            break;
    }
    return <Badge {...badgeProps} />;
};

export default EvidenceParsedDataStatusBadge;
