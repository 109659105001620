import { ErrorBoundary } from 'react-error-boundary';

const ErrorBoundaryWrapper: React.FC<React.PropsWithChildren> = ({
    children,
}) => {
    return (
        <ErrorBoundary
            FallbackComponent={({ error }) => {
                return (
                    <div>
                        <h1>Unexpected error on this page</h1>
                        <p>
                            Don't worry! This error is not your fault, and
                            nothing serious should have happened, but please do
                            report this problem to a developer.
                        </p>
                        <p>{error.message}</p>
                    </div>
                );
            }}
        >
            {children}
        </ErrorBoundary>
    );
};

export default ErrorBoundaryWrapper