import useQueryWithRefresh from 'hooks/helpers/useQueryWithRefresh/useQueryWithRefresh';
import { detailOneAuthorization } from 'services/ether/case-manager/authorizations';
import { DetailAuthorizationEP } from 'services/ether/case-manager/authorizations/types';

const useDetailOneAuthorization = (
    params: DetailAuthorizationEP.Params.One,
    queryOptions?: {
        enabled?: boolean;
    }
) => {
    const queryKey = ['authorization', 'detail', params];

    const query = useQueryWithRefresh<
        Ether.CaseManager.Authorization.Detailed | null,
        Error
    >({
        queryKey,
        queryFn: ({ signal }) =>
            detailOneAuthorization({
                ...params,
                signal,
            }),
        ...queryOptions,
    });

    return query;
};

export default useDetailOneAuthorization;
