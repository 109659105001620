import useQueryWithRefresh from 'hooks/helpers/useQueryWithRefresh/useQueryWithRefresh';
import { detailOperatorCompany } from 'services/ether/case-manager/company';
import { DetailOperatorCompanyEP } from 'services/ether/case-manager/company/types';

const useDetailOperatorCompany = (
    params: DetailOperatorCompanyEP.Params.Many,
    queryOptions?: {
        enabled?: boolean;
    }
) => {
    const queryKey = ['company', 'detail', 'operator', params];

    const query = useQueryWithRefresh<
        Ether.CaseManager.Company.Operator[],
        Error
    >({
        queryKey: ['company', 'detail', 'operator', queryKey],
        queryFn: ({ signal }) =>
            detailOperatorCompany({
                ...params,
                signal,
            }),
        ...queryOptions,
    });

    return query;
};

export default useDetailOperatorCompany;
