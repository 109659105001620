import { useNavigate } from 'react-router-dom';

const useGoBack = () => {
    const navigate = useNavigate();

    const goBack = () => {
        const hasPrev = window.history.state.idx !== 0;
        if (hasPrev) navigate(-1);
        else {
            const currentPath = window.location.pathname;
            const pathSegments = currentPath.split('/');
            const newPath = pathSegments.slice(0, -1).join('/');
            navigate(newPath === '' ? '/' : newPath, { replace: true });
        }
    };

    return goBack;
};

export default useGoBack;
