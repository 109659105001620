import { Outlet } from 'react-router-dom';

import PageWrap from 'components/page/PageWrap';
import { ProjectProvider, useProject } from 'hooks/context/project/useProject';
import PageWithNavbar from 'components/page/PageWithNavbar';
import ErrorBoundaryWrapper from 'components/error/ErrorBoundaryWrapper';
import { useAuth } from 'hooks/context/useAuth';
import LocalizationSync from 'static/LocalizationSync';
import { ProjectSelectProvider } from 'hooks/context/project/useProjectSelect';
import ProjectTerms from 'components/models/Projects/ProjectTerms';

const DefaultMain = () => {
    const project = useProject();
    const { user } = useAuth();

    return (
        <ErrorBoundaryWrapper>
            <LocalizationSync project={project} user={user.data}>
                <PageWrap>
                    <PageWithNavbar>
                        <Outlet />
                    </PageWithNavbar>
                </PageWrap>
            </LocalizationSync>
        </ErrorBoundaryWrapper>
    );
};

const Main = () => {
    return (
        <ProjectSelectProvider>
            <ProjectProvider>
                <ProjectTerms>
                    <DefaultMain />
                </ProjectTerms>
            </ProjectProvider>
        </ProjectSelectProvider>
    );

    // return <DefaultMain />;
};

export default Main;
