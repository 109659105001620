import CellGroup from 'components/display/CellGroup';
import { DateBadge } from 'components/ethercity-primereact';
import LoadingMessage from 'components/misc/LoadingMessage';
import { useProjectMaybe } from 'hooks/context/project/useProject';
import { useAuth } from 'hooks/context/useAuth';
import { useLocalization } from 'hooks/context/useLocalization';
import useFileViewerDialog from 'hooks/models/document/useFileViewerDialog';
import useTemplateDownloadDialog from 'hooks/models/document/useTemplateDownloadDialog';
import useDetailDocument from 'hooks/queries/document/useDetailDocument';
import { getUserDisplayName } from 'utils/user';
import { format as formatDate } from 'date-fns';

const ShowDocumentsToSign: React.FC<{
    authorization: Ether.CaseManager.Authorization.Detailed;
    tokenData?: CaseManagerApp.TokenInfo;
}> = ({ authorization, tokenData }) => {
    const { user } = useAuth();
    const projectContext = useProjectMaybe();
    const project = tokenData?.project ?? projectContext;
    const [localization] = useLocalization();
    const { templateDialog, show: showTemplateDownload } =
        useTemplateDownloadDialog();
    const { fileViewer, showDocument } = useFileViewerDialog();

    if (!project) throw new Error('missing project');

    const today = formatDate(Date.now(), 'yyyy_MM_dd_');
    const userRole = tokenData?.userRole ?? user.role;

    const canViewFilesToDownload = userRole === 'authorizer-strict';

    const signedDocsCount =
        authorization.documents_data?.filter(
            (d) => d.category === 'official_document'
        ).length ?? 0;

    const signedDocsQuery = useDetailDocument(
        {
            project_id: project._id,
            options: {
                limit: 999,
                devFilters: {
                    authorization_id: authorization._id,
                    category: 'official_document',
                },
            },
        },
        {
            enabled: signedDocsCount > 0,
        }
    );

    if (signedDocsCount > 0) {
        if (signedDocsQuery.isLoading)
            return (
                <LoadingMessage>{localization.common.loading}</LoadingMessage>
            );
        if (!signedDocsQuery.data) return null;
        return (
            <>
                {fileViewer}
                <h2>
                    {
                        localization.components.models.authorization.views
                            .detail.signedDocs
                    }
                </h2>
                <div className='inline-flex flex-col items-start gap-2 mb-4'>
                    {signedDocsQuery.data.map((signedDoc) => (
                        <div
                            key={signedDoc._id}
                            className='inline-flex flex-nowrap flex-row items-center gap-2 text-xl hover:cursor-pointer'
                            onClick={() =>
                                showDocument(signedDoc, tokenData?.value)
                            }
                        >
                            <i className='pi pi-file' />
                            <span className='underline'>
                                {signedDoc.file.name}
                            </span>
                            <i className='pi pi-download' />
                        </div>
                    ))}
                </div>
                <div className='flex flex-row gap-2 text-[14px]'>
                    <CellGroup
                        element1={
                            <b className='whitespace-nowrap'>
                                {localization.fields.document.signedAt}:
                            </b>
                        }
                        element2={
                            <DateBadge
                                value={
                                    signedDocsQuery.data?.[0]?.created_at ??
                                    null
                                }
                            />
                        }
                    />
                    <CellGroup
                        element1={
                            <b className='whitespace-nowrap'>
                                {localization.fields.document.signedBy}:
                            </b>
                        }
                        element2={
                            <span className='whitespace-nowrap'>
                                {getUserDisplayName(
                                    signedDocsQuery.data?.[0]
                                        ?.created_by_data?.[0]
                                ) ?? '-'}
                            </span>
                        }
                    />
                </div>
            </>
        );
    }
    if (!canViewFilesToDownload) return null;
    return (
        <>
            {templateDialog}
            <h2>
                {
                    localization.components.models.authorization.views.detail
                        .docsToSign
                }
            </h2>
            <div className='inline-flex flex-col items-start gap-2'>
                {authorization.authorization_configs_data?.[0]?.templates?.map(
                    (f) => (
                        <div
                            key={f.strategy}
                            className='inline-flex flex-nowrap flex-row items-center gap-2 text-xl hover:cursor-pointer'
                            onClick={() =>
                                showTemplateDownload({
                                    filename: f.name,
                                    authorizationId: authorization._id,
                                    strategy: f.strategy,
                                })
                            }
                        >
                            <i className='pi pi-file' />
                            <span className='underline'>{today + f.name}</span>
                            <i className='pi pi-download' />
                        </div>
                    )
                )}
            </div>
        </>
    );
};

export default ShowDocumentsToSign;
