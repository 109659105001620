import { confirmDialog } from 'primereact/confirmdialog';
import { useMutation } from '@tanstack/react-query';
import { useToast } from '../../context/useToast';
import { respondUnblockOrder } from 'services/ether/case-manager/unblock-orders';
import { getErrorToast } from 'utils/errorHandler';
import { useLocalization } from 'hooks/context/useLocalization';

const useUnblockOrderRespond = (options: {
    onRespond?: () => void;
    token?: string;
}): [
    (blockOrder: Ether.CaseManager.UnblockOrder, accept: boolean) => void,
    {
        isLoading: boolean;
        error: Error | null;
    }
] => {
    const { token, onRespond } = options ?? {};

    const [localization] = useLocalization();
    const toast = useToast();
    const viewLocale =
        localization.components.models.unblockOrder.views.respond;
    const endpointLocale = localization.endpoints.order.accept_order;

    const unblockOrderRespondMutation = useMutation<
        Ether.CaseManager.UnblockOrder,
        Error,
        { unblockOrderId: string; accepted: boolean }
    >({
        mutationFn: (data) => {
            if (token)
                return respondUnblockOrder({
                    accepted: data.accepted,
                    token: token,
                });
            if (data.unblockOrderId)
                return respondUnblockOrder({
                    accepted: data.accepted,
                    _id: data.unblockOrderId,
                });
            throw new Error('Must supply token or unblockOrderId');
        },
        onSuccess: (_, params) => {
            toast.show({
                summary: params.accepted
                    ? endpointLocale.approvedUnblockOrder
                    : endpointLocale.rejectedUnblockOrder,
                severity: 'success',
                life: 5000,
            });
            if (onRespond) onRespond();
        },
        onError: (err) => toast.show(getErrorToast(err.message, localization)),
    });

    const onRespondUnblockOrder = (
        unblockOrder: Ether.CaseManager.UnblockOrder,
        accept: boolean
    ) => {
        const message = (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <span>
                    {viewLocale.summary.replace(
                        '{unblockOrderName}',
                        unblockOrder.name
                    )}
                </span>
                <span>{viewLocale.detail}</span>
            </div>
        );

        const answer = accept
            ? localization.components.common.button.confirm
            : localization.components.common.button.reject;

        confirmDialog({
            header: viewLocale.header.replace('{answer}', answer),
            message: message,
            accept: () =>
                unblockOrderRespondMutation.mutate({
                    unblockOrderId: unblockOrder._id,
                    accepted: accept,
                }),
            acceptLabel: answer,
            rejectLabel: localization.components.common.button.cancel,
        });
    };
    return [
        onRespondUnblockOrder,
        {
            isLoading: unblockOrderRespondMutation.isPending,
            error: unblockOrderRespondMutation.error,
        },
    ];
};

export { useUnblockOrderRespond };
