import { UseMutationResult, useMutation } from '@tanstack/react-query';
import useQueryRefresh from 'hooks/queries/useQueryRefresh';
import { useLocalization } from 'hooks/context/useLocalization';
import { useToast } from 'hooks/context/useToast';
import { getErrorToast } from 'utils/errorHandler';
import { UpsertUnblockOrderEP } from 'services/ether/case-manager/unblock-orders/types';
import { upsertUnblockOrder } from 'services/ether/case-manager/unblock-orders';

const useUpsertUnblockOrder = (options?: {
    onInsert?: () => void;
}): {
    insertUnblockOrder: (data: UpsertUnblockOrderEP.InsertData) => void;
    updateUnblockOrder: (data: UpsertUnblockOrderEP.UpdateData) => void;
    status: UseMutationResult['status'];
} => {
    const [localization] = useLocalization();
    const toast = useToast();

    const { hardRefresh, softRefresh } = useQueryRefresh();

    const upsertUnblockOrderMutation = useMutation<
        UpsertUnblockOrderEP.Result,
        Error,
        UpsertUnblockOrderEP.UpsertData
    >({
        mutationFn: (data) => upsertUnblockOrder(data),
        onSuccess: (res, data) => {
            hardRefresh(['unblock-order']);
            softRefresh(['metrics', 'me']);
            const isEdit = '_id' in data;
            toast.show({
                summary: isEdit
                    ? localization.endpoints.unblockOrder.upsert_unblock_order
                          .updateSuccess
                    : localization.endpoints.unblockOrder.upsert_unblock_order
                          .createSuccess,
                detail:
                    res.targets.length > 0
                        ? localization.endpoints.unblockOrder.upsert_unblock_order.upsertSuccessDetails
                              .replace(
                                  '{length}',
                                  res.targets.length.toString()
                              )
                              .replace('{name}', data.name)
                        : null,
                life: 10000,
                severity: 'success',
            });
            if (options?.onInsert) options.onInsert();
        },
        onError: (err) => toast.show(getErrorToast(err.message, localization)),
    });

    return {
        insertUnblockOrder: (data) => upsertUnblockOrderMutation.mutate(data),
        updateUnblockOrder: (data) => upsertUnblockOrderMutation.mutate(data),
        status: upsertUnblockOrderMutation.status,
    };
};

export default useUpsertUnblockOrder;
