import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { Checkbox } from 'components/ethercity-primereact';
import LoadingMessage from 'components/misc/LoadingMessage';
import { useProject } from 'hooks/context/project/useProject';
import { useProjectSelect } from 'hooks/context/project/useProjectSelect';
import useSignProjectDocument from 'hooks/mutations/project/useSignProjectDocument';
import { useAuth } from 'hooks/context/useAuth';
import { useLocalization } from 'hooks/context/useLocalization';
import { Button } from 'primereact/button';
import { TabPanel, TabView } from 'primereact/tabview';
import { Suspense, useState } from 'react';
import { downloadProjectDocument } from 'services/ether/case-manager/projects';
import { CMLocalization } from 'static/language';
import downloadFromUrl from 'utils/downloadFromUrl';

const AcceptTermsGroup: React.FC<{
    filename: string;
    projectId: string;
}> = ({ filename, projectId }) => {
    const [isChecked, setIsChecked] = useState(false);
    const [localization] = useLocalization();

    const { signDocument, signStatus } = useSignProjectDocument();

    return (
        <div className='w-full flex flex-col items-center mt-4'>
            <div
                className='flex flex-row gap-2 justify-center hover:cursor-pointer'
                onClick={() => setIsChecked((old) => !old)}
            >
                <Checkbox checked={isChecked} />
                <span>
                    {
                        localization.components.models.project.views.useTerms
                            .acceptDocument
                    }
                </span>
            </div>
            <Button
                className='mt-4'
                label={localization.components.common.button.confirm}
                disabled={!isChecked || signStatus === 'pending'}
                loading={signStatus === 'pending'}
                onClick={() =>
                    signDocument({
                        document_key: filename,
                        project_id: projectId,
                    })
                }
            />
        </div>
    );
};

const LocaleDocumentsToSignMap: Record<
    CMLocalization.ValidLanguages,
    Ether.CaseManager.ValidDocumentsToSignLanguage
> = {
    'en-US': 'en-us',
    'pt-BR': 'pt-br',
};

const ButtonGroups = () => {
    const { signOut } = useAuth();
    const projectSelect = useProjectSelect();
    const [localization, { switchLanguage, currentLanguage }] =
        useLocalization();
    return (
        <div className='flex flex-row gap-2 absolute top-4 right-4'>
            <Button
                label={
                    currentLanguage === 'en-US'
                        ? 'Alterar para pt-BR'
                        : 'Switch to en-US'
                }
                onClick={() =>
                    switchLanguage(
                        currentLanguage === 'en-US' ? 'pt-BR' : 'en-US'
                    )
                }
                icon='pi pi-globe'
            />
            <Button
                label={localization.components.navbar.changeProject}
                onClick={() => projectSelect?.enableChangeProject()}
                icon='pi pi-list'
            />
            <Button
                label={localization.components.common.button.signOut}
                onClick={signOut}
                icon='pi pi-sign-out'
            />
        </div>
    );
};

const DisplayFile: React.FC<{
    filename: string;
    fileKey: string;
    language: Ether.CaseManager.ValidDocumentsToSignLanguage;
    projectId: string;
    contentType: string;
}> = ({ filename, fileKey, language, projectId, contentType }) => {
    const [localization] = useLocalization();

    const isPdf = contentType === 'application/pdf';
    const isImg = contentType.startsWith('image/');
    const isViewable = isPdf || isImg;

    const docQuery = useSuspenseQuery({
        queryKey: ['project', { fileKey, language, projectId }],
        queryFn: () =>
            isViewable
                ? downloadProjectDocument({
                      key: fileKey,
                      lang: language,
                      project_id: projectId,
                  })
                : new Promise<null>((resolve) => resolve(null)),
    });

    const docQueryWithUrl = useQuery({
        queryKey: ['project', { fileKey, language, projectId, url: true }],
        queryFn: () =>
            new Promise((resolve) =>
                downloadProjectDocument({
                    key: fileKey,
                    lang: language,
                    project_id: projectId,
                }).then((d) => {
                    downloadFromUrl(
                        d.internalBlobUrl,
                        filename + '.' + d.extension
                    );
                    resolve(true);
                })
            ),
        enabled: false,
    });

    const { data } = docQuery;

    let item: JSX.Element | null = null;

    if (!data) return null;

    if (isPdf) {
        item = (
            <embed
                type='application/pdf'
                src={data.internalBlobUrl}
                aria-label='Arquivo PDF'
                className='w-full h-full'
            />
        );
    }
    if (isImg) {
        item = (
            <img
                className='h-full'
                src={data.internalBlobUrl}
                aria-label='Preview image'
            />
        );
    }

    return item ? (
        <div className='h-[80%] flex flex-row justify-center'>{item}</div>
    ) : (
        <div className='flex flex-col justify-center items-center mb-8'>
            <h3>
                {
                    localization.components.models.project.views.useTerms
                        .fileTypes.unknown
                }
            </h3>
            <Button
                label={localization.components.common.button.download}
                icon='pi pi-download'
                onClick={() => docQueryWithUrl.refetch()}
                disabled={docQueryWithUrl.isFetching}
                loading={docQueryWithUrl.isFetching}
            />
        </div>
    );
};

const ViewProjectTerms: React.FC<{
    docsToSign: [string, Ether.CaseManager.ProjectDocumentData][];
}> = ({ docsToSign }) => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const project = useProject();
    const [localization, { currentLanguage }] = useLocalization();
    const { user } = useAuth();

    const docLanguage = LocaleDocumentsToSignMap[currentLanguage];

    const userDocs = user.data?.data?.signed_documents ?? {};

    return (
        <section className='w-full h-full flex flex-col justify-center items-center'>
            <div className='border border-gray-500 border-solid rounded-xl w-[90%] h-[80%] p-4'>
                <TabView
                    activeIndex={selectedIndex}
                    onTabChange={(e) => setSelectedIndex(e.index)}
                    className='h-full'
                    panelContainerStyle={{
                        height: '90%',
                    }}
                >
                    {docsToSign.map(([name, item], index) => {
                        const tabName =
                            item.name[docLanguage] ?? 'File ' + index;

                        const isSigned = (userDocs?.[project._id] ?? []).find(
                            (doc) => doc.key === name
                        );

                        return (
                            <TabPanel
                                className='h-full'
                                key={name}
                                header={tabName}
                            >
                                <Suspense
                                    fallback={
                                        <LoadingMessage>
                                            {localization.common.loading}
                                        </LoadingMessage>
                                    }
                                >
                                    <DisplayFile
                                        filename={tabName}
                                        fileKey={name}
                                        language={docLanguage}
                                        projectId={project._id}
                                        contentType={item.content_type}
                                    />
                                    {isSigned ? (
                                        <h3 className='w-full text-center'>
                                            {
                                                localization.components.models
                                                    .project.views.useTerms
                                                    .documentAlreadyAccepted
                                            }
                                        </h3>
                                    ) : (
                                        <AcceptTermsGroup
                                            filename={name}
                                            projectId={project._id}
                                        />
                                    )}
                                </Suspense>
                            </TabPanel>
                        );
                    })}
                </TabView>
            </div>
            <ButtonGroups />
            <h2 className='absolute left-4 top-0'>
                {localization.components.models.project.views.useTerms.title}
            </h2>
        </section>
    );
};

const ProjectTerms: React.FC<{
    children?: React.ReactNode;
}> = ({ children }) => {
    const project = useProject();
    const { user } = useAuth();

    if (!project.documents_to_sign) return children;

    const docsToSign = Object.entries(project.documents_to_sign);

    if (docsToSign.length <= 0) return children;

    const signedDocs = user.data?.data?.signed_documents?.[project._id] ?? [];

    if (signedDocs.length < docsToSign.length)
        return <ViewProjectTerms docsToSign={docsToSign} />;

    return children;
};

export default ProjectTerms;
