import useQueryWithRefresh from 'hooks/helpers/useQueryWithRefresh/useQueryWithRefresh';
import { listAuthorizationConfig } from 'services/ether/case-manager/authorization-config';
import { ListAuthorizationConfigEP } from 'services/ether/case-manager/authorization-config/types';

const useListAuthorizationConfig = (
    params: ListAuthorizationConfigEP.Params.Many,
    queryOptions?: {
        enabled?: boolean;
    }
) => {
    const queryKey = ['authorization-config', 'list', params];

    const query = useQueryWithRefresh<
        Ether.CaseManager.AuthorizationConfig[],
        Error
    >({
        queryKey,
        queryFn: ({ signal }) =>
            listAuthorizationConfig({
                signal,
                ...params,
            }),
        ...queryOptions,
    });

    return query;
};

export default useListAuthorizationConfig;
