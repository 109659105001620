import NameIdAggregator from 'components/datatable/NameIdAggregator';
import { Column } from 'primereact/column';
import { DataTableProps } from 'primereact/datatable';
import { DateBadge, ObjectDisplayModal } from 'components/ethercity-primereact';
import { useAuth } from 'hooks/context/useAuth';
import { MenuItem } from 'primereact/menuitem';
import { Menu } from 'primereact/menu';
import { useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { useLocalization } from 'hooks/context/useLocalization';
import { CMDataTableProps } from 'components/datatable/CMDataTable/types';
import CMDataTable from 'components/datatable/CMDataTable';
import { downloadDocumentFile } from 'services/ether/case-manager/documents';
import { Badge } from 'primereact/badge';
import { Dialog } from 'primereact/dialog';
import FileViewer from 'components/models/Evidences/FileViewer';
import FileViewerWrapper from 'components/models/Evidences/FileViewerWrapper';
import { getFilterData } from 'utils/datatable';
import { getFileModelName, handleFileModelDownload } from 'utils/file';

const DocumentMenu: React.FC<{
    document: Ether.CaseManager.Document.Detailed;
    onShow: (document: Ether.CaseManager.Document.Detailed) => void;
    onShowDetail: (document: Ether.CaseManager.Document.Detailed) => void;
    onStartEdit?: (document: Ether.CaseManager.Document.Detailed) => void;
}> = ({ document, onShow, onShowDetail, onStartEdit }) => {
    const { permissions } = useAuth();
    const [localization] = useLocalization();

    const onDownload = () => {
        downloadDocumentFile(document._id).then((data) =>
            handleFileModelDownload({
                file: data.file,
                item: document,
                extension: data.extension,
            })
        );
    };

    const menuRef = useRef<Menu>(null);

    const menuItems: MenuItem[] = [
        ...(permissions.downloadDocuments
            ? [
                  {
                      id: 'view',
                      label: localization.components.common.menu.view,
                      command: () => onShow(document),
                  },
              ]
            : []),
        ...(permissions.downloadDocuments
            ? [
                  {
                      id: 'download',
                      icon: 'pi pi-download',
                      label: localization.components.common.button.download,
                      command: () => onDownload(),
                  },
              ]
            : []),
        ...(permissions.insertDocuments && onStartEdit
            ? [
                  {
                      id: 'edit',
                      label: localization.components.common.button.edit,
                      command: () => onStartEdit(document),
                  },
              ]
            : []),
        ...(permissions.debugDocuments
            ? [
                  {
                      id: 'debug',
                      label: localization.components.common.datatable
                          .menuDetailItem,
                      command: () => onShowDetail(document),
                  },
              ]
            : []),
    ];
    if (menuItems.length <= 0) return null;
    return (
        <>
            <Menu popup ref={menuRef} model={menuItems} />
            <Button
                icon='pi pi-ellipsis-h'
                onClick={(e) => menuRef?.current?.toggle(e)}
            />
        </>
    );
};

type KeyInColumn =
    | keyof CaseManagerApp.ModelColumns['document']
    | CaseManagerApp.DefaultNameColumn;

const columnFields: { [key in KeyInColumn]: key } = {
    _cm_name_id: '_cm_name_id',
    type: 'type',
    created_at: 'created_at',
    'process_document.document_date': 'process_document.document_date',
};

export const documentFilters = {
    [columnFields._cm_name_id]: getFilterData(),
    [columnFields.type]: getFilterData(),
    [columnFields['created_at']]: getFilterData('dateIs'),
    [columnFields['process_document.document_date']]: getFilterData('dateIs'),
};

const DocumentDataTable: React.FC<
    DataTableProps<Ether.CaseManager.Document[]> &
        Omit<CMDataTableProps, 'columnConfigName'> & {
            token?: string;
            onStartEdit?: (doc: Ether.CaseManager.Document.Detailed) => void;
        }
> = ({ token, onStartEdit, ...props }) => {
    const [localization] = useLocalization();

    const [viewDocumentDialogOptions, setViewDocumentDialogOptions] = useState<{
        mode: 'debug' | 'view';
        data: Ether.CaseManager.Document.Detailed | null;
    }>({
        mode: 'view',
        data: null,
    });

    const onDocumentViewModalShow = (
        data: Ether.CaseManager.Document.Detailed,
        mode: 'debug' | 'view'
    ) =>
        setViewDocumentDialogOptions({
            mode: mode,
            data: data,
        });

    const onDocumentViewModalHide = () =>
        setViewDocumentDialogOptions((old) => ({
            ...old,
            data: null,
        }));

    const documentViewer =
        viewDocumentDialogOptions.mode === 'view' &&
        viewDocumentDialogOptions.data ? (
            token ? (
                <FileViewer
                    item={viewDocumentDialogOptions.data}
                    mode='document'
                    onClose={onDocumentViewModalHide}
                    showCloseButton
                    token={token}
                />
            ) : (
                <FileViewerWrapper
                    mode='document'
                    modelId={viewDocumentDialogOptions.data._id}
                    showCloseButton
                    onClose={onDocumentViewModalHide}
                />
            )
        ) : null;

    const filename = getFileModelName(viewDocumentDialogOptions.data);
    const sortable = 'sortField' in props;

    return (
        <>
            <Dialog
                header={filename ?? ''}
                visible={
                    !!viewDocumentDialogOptions.data &&
                    viewDocumentDialogOptions.mode === 'view'
                }
                onHide={() => onDocumentViewModalHide()}
            >
                {documentViewer}
            </Dialog>
            <ObjectDisplayModal
                header={filename ?? ''}
                visible={
                    !!viewDocumentDialogOptions.data &&
                    viewDocumentDialogOptions.mode === 'debug'
                }
                displayData={viewDocumentDialogOptions.data}
                onHide={onDocumentViewModalHide}
                sortKeys={false}
            />
            <CMDataTable
                columnConfigName='document'
                lazy
                emptyMessage={
                    localization.components.models.document.datatable.empty
                }
                filterOptions={{
                    [columnFields._cm_name_id]: {
                        placeholder:
                            localization.components.models.document.datatable
                                .selectName,
                        type: 'string',
                    },
                    [columnFields.type]: {
                        label: localization.fields.document.type,
                        placeholder:
                            localization.components.models.document.datatable
                                .selectType,
                        type: 'string',
                    },
                    [columnFields['process_document.document_date']]: {
                        label: localization.fields.document.documentDate,
                        type: 'date',
                    },
                }}
                {...props}
            >
                <Column
                    field={columnFields['_cm_name_id']}
                    body={(rowData: Ether.CaseManager.Document.Detailed) => {
                        let name: string | undefined;
                        if ('authorizations_data' in rowData)
                            name = rowData.authorizations_data?.[0]?.name;
                        return (
                            <NameIdAggregator
                                name={getFileModelName(rowData) ?? ''}
                                id={rowData._id}
                                association={name}
                                onClick={() =>
                                    onDocumentViewModalShow(rowData, 'view')
                                }
                            />
                        );
                    }}
                />
                <Column
                    field={columnFields['type']}
                    header={localization.fields.document.type}
                    body={(rowData: Ether.CaseManager.Document) => {
                        return (
                            <Badge
                                value={rowData.type?.toLocaleUpperCase() ?? '-'}
                                severity='info'
                            />
                        );
                    }}
                />
                <Column
                    field={columnFields['process_document.document_date']}
                    sortable={sortable}
                    header={localization.fields.document.documentDate}
                    body={(data: Ether.CaseManager.Document) =>
                        data.process_document?.document_date ? (
                            <DateBadge
                                value={data.process_document.document_date}
                            />
                        ) : (
                            '-'
                        )
                    }
                />
                <Column
                    field={columnFields['created_at']}
                    sortable={sortable}
                    header={localization.fields.document.createdAt}
                    body={(data: Ether.CaseManager.Document) => (
                        <DateBadge value={data.created_at} />
                    )}
                />
                <Column
                    field='actions'
                    body={(rowData: Ether.CaseManager.Document.Detailed) => (
                        <DocumentMenu
                            document={rowData}
                            onShow={(doc) =>
                                onDocumentViewModalShow(doc, 'view')
                            }
                            onShowDetail={(doc) =>
                                onDocumentViewModalShow(doc, 'debug')
                            }
                            onStartEdit={onStartEdit}
                        />
                    )}
                />
            </CMDataTable>
        </>
    );
};

export default DocumentDataTable;
