import useQueryWithRefresh from 'hooks/helpers/useQueryWithRefresh/useQueryWithRefresh';
import { listAuthorizationFlow } from 'services/ether/case-manager/authorization-flow';
import { ListAuthorizationFlowEP } from 'services/ether/case-manager/authorization-flow/types';

const useListAuthorizationFlow = (
    params: ListAuthorizationFlowEP.Params.Many,
    queryOptions?: {
        enabled?: boolean;
    }
) => {
    const queryKey = ['authorization-flow', 'detail', params];

    const query = useQueryWithRefresh<
        Ether.CaseManager.AuthorizationFlow[],
        Error
    >({
        queryKey,
        queryFn: ({ signal }) =>
            listAuthorizationFlow({
                signal,
                ...params,
            }),
        ...queryOptions,
    });

    return query;
};

export default useListAuthorizationFlow;
