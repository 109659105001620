import { useMutation } from '@tanstack/react-query';
import { useLocalization } from 'hooks/context/useLocalization';
import { useToast } from 'hooks/context/useToast';
import { useState } from 'react';
import { InsertEvidenceEP } from 'services/ether/case-manager/evidences/types';
import { insertEvidence } from 'services/ether/case-manager/evidences';
import { getFileErrors } from 'utils/errorHandler';

const useInsertEvidence = (params?: {
    onUpload?: (data: {
        okEvidences: InsertEvidenceEP.Result[];
        errorEvidences: InsertEvidenceEP.Error[];
    }) => void;
    onSettled?: () => void;
}) => {
    const toast = useToast();
    const [localization] = useLocalization();
    const [abortController, setAbortController] = useState(
        new AbortController()
    );

    const evidencesFilesMutation = useMutation<
        InsertEvidenceEP.ApiResponse,
        Error,
        InsertEvidenceEP.Data
    >({
        mutationFn: (data) =>
            insertEvidence({
                ...data,
                signal: abortController.signal,
            }),
        onSuccess: (res) => {
            const okEvidences = res.filter(
                (r) => r.status === 'created' || r.status === 'exists'
            ) as InsertEvidenceEP.Result[];
            const errorEvidences = res.filter(
                (r) => r.status === 'error'
            ) as InsertEvidenceEP.Error[];
            if (errorEvidences.length > 0) {
                toast.show({
                    severity: okEvidences.length === 0 ? 'error' : 'warn',
                    summary:
                        okEvidences.length === 0
                            ? localization.endpoints.document.upsert_document
                                  .insertFailed
                            : localization.endpoints.document.upsert_document
                                  .insertPartial,
                    detail: getFileErrors(
                        errorEvidences.map(({ error }, index) => [
                            index + 1,
                            error,
                        ]),
                        localization
                    ),
                });
            }

            if (params?.onUpload) {
                params.onUpload({
                    okEvidences,
                    errorEvidences,
                });
            }
        },
        onError: (e) => {
            if (e.message === 'canceled') return;
            toast.show({
                severity: 'error',
                summary: localization.validations.generic.fileUpload,
                detail: e.message,
            });
        },
        onSettled: params?.onSettled,
    });

    return {
        uploadEvidences: (data: InsertEvidenceEP.Data) =>
            evidencesFilesMutation.mutate(data),
        uploadStatus: evidencesFilesMutation.status,
        cancelUpload: () => {
            abortController.abort();
            setAbortController(new AbortController());
            evidencesFilesMutation.reset();
        },
    };
};

export default useInsertEvidence;
