import { useAuth } from 'hooks/context/useAuth';
import { Navigate, Outlet } from 'react-router-dom';

const AdminManagement = () => {
    const { permissions } = useAuth();

    if (!permissions.accessAdminPanel) return <Navigate to='/' />;

    return <Outlet />;
};

export default AdminManagement;
