import { useCallback, useMemo, useState } from 'react';
import TargetCheckWarningDialog from 'components/models/Targets/TargetCheckWarningDialog';
import { CheckActionTargetWarningEP } from 'services/ether/case-manager/targets/types';

type ViewData = {
    targets: CheckActionTargetWarningEP.Result;
    action: 'revertTargets' | 'finishAuthorization' | 'finishBlockOrder';
    onContinue: () => void;
};

const useShowTargetCheckWarning = () => {
    const [viewData, setViewData] = useState<ViewData | null>(null);

    const show = useCallback((data: ViewData) => setViewData(data), []);
    const hide = () => setViewData(null);

    const dialog = useMemo(
        () =>
            viewData && (
                <TargetCheckWarningDialog
                    action={viewData.action}
                    onContinue={viewData.onContinue}
                    targets={viewData.targets}
                    onHide={hide}
                />
            ),
        [viewData]
    );

    return {
        dialog: dialog,
        show: show,
    };
};

export default useShowTargetCheckWarning;
