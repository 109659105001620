import downloadFromUrl from './downloadFromUrl';

export const getFileModelName = (
    item?: Ether.CaseManager.Document | Ether.CaseManager.Evidence | null
) => {
    if (!item) return null;
    let name: string;
    if (
        'meta' in item &&
        'document_name' in (item?.meta ?? {}) &&
        !!item?.meta?.document_name
    )
        name = item.meta.document_name;
    else {
        name = item.file.name;
        if (name.includes('.')) {
            const split = name.split('.');
            split.pop();
            name = split.join('.');
        }
    }
    return name;
};

export const handleFileModelDownload = ({
    file,
    extension,
    item,
}: {
    file: Blob;
    item: Ether.CaseManager.Document | Ether.CaseManager.Evidence;
    extension?: string | null;
}) => {
    const itemName = getFileModelName(item);
    const fileExtension =
        extension ?? item.extension ?? item.file.name.split('.')[1] ?? '';
    const filename = fileExtension.includes('.')
        ? itemName + fileExtension
        : `${itemName}.${fileExtension}`;
    const url = URL.createObjectURL(file);
    downloadFromUrl(url, filename);
};
