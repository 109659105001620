import { useQuery } from '@tanstack/react-query';
import NameIdAggregator from 'components/datatable/NameIdAggregator';
import { Dropdown, ObjectDisplayModal } from 'components/ethercity-primereact';
import MenuTemplate from 'components/misc/MenuTemplate';
import { useAuth } from 'hooks/context/useAuth';
import { useLocalization } from 'hooks/context/useLocalization';
import { Button } from 'primereact/button';
import { Column, ColumnFilterElementTemplateOptions } from 'primereact/column';
import { DataTable, DataTableProps } from 'primereact/datatable';
import { Menu } from 'primereact/menu';
import { MenuItem, MenuItemOptions } from 'primereact/menuitem';
import { useRef, useState } from 'react';
import { listGroups } from 'services/ether/base-api';

const ProjectMenu: React.FC<{
    project: Ether.CaseManager.Project;
    onShowDetail: (project: Ether.CaseManager.Project) => void;
    onEdit: (project: Ether.CaseManager.Project) => void;
    onDelete?: (project: Ether.CaseManager.Project) => void;
}> = ({ project, onShowDetail, onEdit, onDelete }) => {
    const { permissions } = useAuth();
    const [, { getLocalizationFromProject }] = useLocalization();
    const localization = getLocalizationFromProject(project);

    const menuRef = useRef<Menu>(null);

    const menuItems: MenuItem[] = [
        ...(permissions.viewTargets
            ? [
                  {
                      id: 'targets',
                      label: localization.models.target.plural,
                      icon: 'pi pi-map-marker',
                      url: `${project._id}/targets`,
                      template: (item: MenuItem, options: MenuItemOptions) => (
                          <MenuTemplate item={item} options={options} />
                      ),
                  },
              ]
            : []),
        ...(permissions.viewAuthorizations
            ? [
                  {
                      id: 'authorizations',
                      label: localization.models.authorization.plural,
                      icon: 'pi pi-folder-open',
                      url: `${project._id}/authorizations`,
                      template: (item: MenuItem, options: MenuItemOptions) => (
                          <MenuTemplate item={item} options={options} />
                      ),
                  },
              ]
            : []),
        ...(permissions.viewDocuments
            ? [
                  {
                      id: 'documents',
                      label: localization.models.document.plural,
                      icon: 'pi pi-file',
                      url: `${project._id}/documents`,
                      template: (item: MenuItem, options: MenuItemOptions) => (
                          <MenuTemplate item={item} options={options} />
                      ),
                  },
              ]
            : []),
        ...(permissions.viewEvidences
            ? [
                  {
                      id: 'evidences',
                      label: localization.models.evidence.plural,
                      icon: 'pi pi-box',
                      url: `${project._id}/evidences`,
                      template: (item: MenuItem, options: MenuItemOptions) => (
                          <MenuTemplate item={item} options={options} />
                      ),
                  },
              ]
            : []),
        ...(permissions.viewBlockOrders
            ? [
                  {
                      id: 'block-orders',
                      label: localization.models.blockOrder.plural,
                      icon: 'pi pi-ban',
                      url: `${project._id}/block-orders`,
                      template: (item: MenuItem, options: MenuItemOptions) => (
                          <MenuTemplate item={item} options={options} />
                      ),
                  },
              ]
            : []),
        ...(permissions.viewUnblockOrders
            ? [
                  {
                      id: 'unblock-orders',
                      label: localization.models.unblockOrder.plural,
                      icon: 'pi pi-check',
                      url: `${project._id}/unblock-orders`,
                      template: (item: MenuItem, options: MenuItemOptions) => (
                          <MenuTemplate item={item} options={options} />
                      ),
                  },
              ]
            : []),
        ...(permissions.debugProjects
            ? [
                  {
                      id: 'debug',
                      icon: 'pi pi-search',
                      label: localization.components.common.menu.detailData,
                      command: () => onShowDetail(project),
                  },
              ]
            : []),
        ...(permissions.editProjects
            ? [
                  {
                      id: 'edit',
                      icon: 'pi pi-pencil',
                      label: localization.components.models.project.button.edit,
                      command: () => onEdit(project),
                  },
              ]
            : []),
        ...(permissions.deleteProjects
            ? [
                  {
                      id: 'delete',
                      icon: 'pi pi-times',
                      label: localization.components.models.project.button
                          .delete,
                      command: () => (onDelete ? onDelete(project) : null),
                  },
              ]
            : []),
    ];
    return (
        <>
            <Menu popup ref={menuRef} model={menuItems} />
            <Button
                icon='pi pi-ellipsis-h'
                onClick={(e) => menuRef?.current?.toggle(e)}
            />
        </>
    );
};

const ProjectsDataTable: React.FC<
    DataTableProps<Ether.CaseManager.Project[]> & {
        onEdit: (project: Ether.CaseManager.Project) => void;
        onDelete?: (project: Ether.CaseManager.Project) => void;
    }
> = ({ onEdit, onDelete, ...props }) => {
    const { permissions } = useAuth();
    const [localization] = useLocalization();

    const groupsQuery = useQuery({
        queryKey: ['list-groups-all'],
        queryFn: () => listGroups({ limit: 9999 }),
    });

    const datatableLocale = localization.components.models.project.datatable;

    const [viewProjectDialogOptions, setViewProjectDialogOptions] = useState<{
        visible: boolean;
        data: Ether.CaseManager.Project | null;
    }>({
        visible: false,
        data: null,
    });

    const onProjectViewModalShow = (data: Ether.CaseManager.Project) =>
        setViewProjectDialogOptions({
            visible: true,
            data: data,
        });

    const onProjectViewModalHide = () =>
        setViewProjectDialogOptions((old) => ({
            ...old,
            visible: false,
        }));

    const GroupFilterTemplate: React.FC<ColumnFilterElementTemplateOptions> = (
        options: ColumnFilterElementTemplateOptions
    ) => {
        return (
            <Dropdown
                style={{ width: '100%' }}
                value={options.value}
                optionLabel='name'
                optionValue='_id'
                options={groupsQuery.data}
                onChange={(e) => options.filterCallback(e.value, options.index)}
                placeholder={datatableLocale.selectGroup}
                className='p-column-filter'
            />
        );
    };

    const baseFilterOptions = {
        showAddButton: false,
        showFilterOperator: false,
    };

    return (
        <>
            <ObjectDisplayModal
                header={viewProjectDialogOptions.data?.name}
                visible={viewProjectDialogOptions.visible}
                displayData={viewProjectDialogOptions.data}
                onHide={onProjectViewModalHide}
                sortKeys={false}
            />
            <DataTable lazy emptyMessage={datatableLocale.empty} {...props}>
                <Column
                    field='_cm_name_id'
                    body={(rowData: Ether.CaseManager.Project) => (
                        <NameIdAggregator
                            name={rowData.name}
                            id={rowData._id}
                        />
                    )}
                    filter={!!props?.filters?._cm_name_id}
                    filterPlaceholder={datatableLocale.selectName}
                    {...baseFilterOptions}
                />
                {permissions.createProjectWithDifferentGroup && (
                    <Column
                        field='group_id'
                        header={localization.fields.project.group}
                        filter
                        showAddButton={false}
                        showFilterOperator={false}
                        showFilterMatchModes={false}
                        filterElement={GroupFilterTemplate}
                        body={(rowData: CaseManagerApp.ExtendedProject) =>
                            rowData.group_name
                        }
                    />
                )}
                <Column
                    field='actions'
                    body={(rowData: Ether.CaseManager.Project) => (
                        <ProjectMenu
                            project={rowData}
                            onShowDetail={onProjectViewModalShow}
                            onEdit={onEdit}
                        />
                    )}
                />
            </DataTable>
        </>
    );
};

export default ProjectsDataTable;
