import { Datepicker, InputText } from 'components/ethercity-primereact';
import { useLocalization } from 'hooks/context/useLocalization';
import { Divider } from 'primereact/divider';
import { InsertUnblockOrderFormProps } from './types';
import SelectManyOperatorTags from 'components/models/Operators/SelectManyOperatorTags';
import SelectManyBlockOrders from 'components/models/BlockOrder/SelectManyBlockOrders';
import SelectOneAuthorizationFlow from 'components/models/AuthorizationFlow/SelectOneAuthorizationFlow';
import SelectManyAuthorizationConfigs from 'components/models/AuthorizationConfig/SelectManyAuthorizationConfigs';
import { useEffect, useState } from 'react';
import OrderDocTypeDropdown from 'components/form/OrderDocTypeDropdown';
import SelectOrCreateOperation from 'components/models/Operation/SelectOrCreateOperation';

const InsertUnblockOrderForm: React.FC<InsertUnblockOrderFormProps> = ({
    name,
    authorizationFlowId,
    authorizationConfigIds,
    blockOrders,
    operatorTags,
    startDate,
    type,
    operationId,
    isDefaultOperation,
    disabled,
}) => {
    const [localization] = useLocalization();

    const updateConfigs = (
        configs: Ether.CaseManager.AuthorizationConfig[]
    ) => {
        authorizationConfigIds.set(configs.map((c) => c._id));
    };

    const [endDate, setEndDate] = useState<Date | null>(
        startDate.value ?? null
    );
    const [endHour, setEndHour] = useState<Date | null>(
        startDate.value ?? null
    );

    const { set: setStartDate } = startDate;
    useEffect(() => {
        if (!endDate || !endHour) {
            setStartDate(null);
            return;
        }
        const newStartDate = new Date(endDate);
        newStartDate.setHours(endHour.getHours(), endHour.getMinutes(), 0, 0);
        setStartDate(newStartDate);
    }, [endDate, endHour, setStartDate]);

    return (
        <div className='flex flex-col gap-1'>
            <h3>{localization.models.unblockOrder.singular}</h3>
            <InputText
                label={localization.fields.unblockOrder.name}
                required
                value={name.value}
                onChange={(e) => name.set(e.target.value)}
                disabled={disabled}
            />
            <Divider />
            <h3>{localization.common.type}</h3>
            <OrderDocTypeDropdown
                placeholder={
                    localization.components.models.unblockOrder.views.insert
                        .selectTypePlaceholder
                }
                value={type.value}
                onChange={(e) => type.set(e.target.value)}
                disabled={disabled}
            />
            <Divider />
            <h3>{localization.fields.unblockOrder.operationDate}</h3>
            <span>
                {
                    localization.components.models.unblockOrder.views.insert
                        .selectDates
                }
            </span>
            <div className='flex flex-row gap-2'>
                <Datepicker
                    label={localization.fields.unblockOrder.operationDay}
                    value={endDate}
                    onChange={(value) => setEndDate(value)}
                    type='date'
                    required
                />
                <Datepicker
                    label={localization.fields.unblockOrder.operationEndHour}
                    value={endHour}
                    onChange={(value) => setEndHour(value)}
                    type='time'
                    required
                />
            </div>
            <Divider />
            <SelectOrCreateOperation
                operationId={operationId.value}
                setOperationId={(id) => operationId.set(id)}
                isDefaultOperation={isDefaultOperation.value}
                setIsDefaultOperation={(def) => isDefaultOperation.set(def)}
            />
            <Divider />
            <SelectOneAuthorizationFlow
                value={authorizationFlowId.value}
                onChange={(e) => {
                    authorizationFlowId.set(e?._id ?? null);
                    updateConfigs([]);
                }}
                disabled={disabled}
                required
            />
            {authorizationFlowId.value && (
                <>
                    <Divider />
                    <SelectManyAuthorizationConfigs
                        value={authorizationConfigIds.value}
                        onChange={(newConfigs) => {
                            updateConfigs(newConfigs);
                            blockOrders.set((old) =>
                                old.filter(
                                    (bo) =>
                                        !!bo.authorization_config_ids?.find(
                                            (configId) =>
                                                newConfigs.find(
                                                    (newConfig) =>
                                                        configId ===
                                                        newConfig._id
                                                )
                                        )
                                )
                            );
                        }}
                        onLoad={(configs) => {
                            const validConfigs = configs.filter((c) =>
                                authorizationConfigIds.value.find(
                                    (c2) => c._id === c2
                                )
                            );
                            updateConfigs(validConfigs);
                        }}
                        queryOptions={{
                            devFilters: {
                                authorization_flow_ids:
                                    authorizationFlowId.value,
                            },
                        }}
                        disabled={disabled}
                        required
                    />
                </>
            )}
            {authorizationConfigIds.value.length > 0 && (
                <>
                    <Divider />
                    <SelectManyBlockOrders
                        value={blockOrders.value.map((bo) => bo._id)}
                        onChange={(orders) => blockOrders.set(orders)}
                        queryOptions={{
                            devFilters: {
                                status: 'done',
                                authorization_flow_id:
                                    authorizationFlowId.value,
                                authorization_config_ids:
                                    authorizationConfigIds.value,
                            },
                        }}
                        disabled={disabled}
                        required
                    />
                    <Divider />
                    <SelectManyOperatorTags
                        value={operatorTags.value}
                        onChange={(data) =>
                            operatorTags.set(data.map((t) => t.value))
                        }
                        disabled={disabled}
                    />
                </>
            )}
        </div>
    );
};

export default InsertUnblockOrderForm;
