import { useLocalization } from 'hooks/context/useLocalization';
import { BaseStep, SharedDocumentFormData } from '../types';
import {
    Checkbox,
    Datepicker,
    InputText,
    InputTextArea,
} from 'components/ethercity-primereact';
import { format as formatDate } from 'date-fns';
import { FileItem } from 'components/form/CMFileUpload';
import { UpsertDocumentEP } from 'services/ether/case-manager/documents/types';
import { ButtonPair } from '..';

export type UpsertOfficialDocumentFormData = SharedDocumentFormData & {
    officialDocName: string;
    officialDocCode: string;
    officialDocDescription: string;
    officialDocProtocolDate: Date | null;
    officialDocValidityStart: Date | null;
    officialDocValidityEnd: Date | null;
    eventName: string;
    eventDescription: string;
    eventDate: Date | null;
    isDuty: boolean;
    dutyDate: Date | null;
};

type DocumentBaseStep = BaseStep<UpsertOfficialDocumentFormData>;

export const validateOfficialDocumentForm = ({
    form,
    project_id,
}: {
    project_id: string;
    form: UpsertOfficialDocumentFormData;
}) => {
    if (
        !form.file ||
        !form.authorizationConfig ||
        form.officialDocName === '' ||
        form.officialDocCode === '' ||
        form.officialDocDescription === '' ||
        form.officialDocProtocolDate == null ||
        form.officialDocValidityStart == null ||
        (form.isDuty && !form.dutyDate)
    )
        return;
    return {
        project_id: project_id,
        authorization_config_id: form.authorizationConfig._id,
        category: 'official_document',
        status: 'using',
        meta: {
            document_name: form.officialDocName,
            document_code: form.officialDocCode,
            document_description: form.officialDocDescription,
            document_protocol_date: form.officialDocProtocolDate.toISOString(),
            document_validity_start:
                form.officialDocValidityStart.toISOString(),
            document_validity_end: form.officialDocValidityEnd
                ? form.officialDocValidityEnd.toISOString()
                : null,
            document_is_duty: form.isDuty,
            document_duty_date: form.dutyDate
                ? form.dutyDate.toISOString()
                : null,
            event_name: form.eventName,
            event_description: form.eventDescription,
            event_date: form.eventDate
                ? form.eventDate.toISOString()
                : undefined,
        },
        files:
            form.file instanceof File
                ? [
                      {
                          file: form.file,
                          custom_identifier: '',
                      },
                  ]
                : [form.file],
        authorization_id: form.authorization?._id,
        block_order_id: form.blockOrder?._id,
    } as UpsertDocumentEP.UpsertData;
};

export const MetadataStep: React.FC<DocumentBaseStep> = ({
    formData,
    setFormData,
    goNext,
    goBack,
}) => {
    const [localization] = useLocalization();

    const { metadataStep, validityStep, dutyStep, eventStep } =
        localization.components.models.oficio.views.insert;

    const disableNext =
        formData.officialDocName === '' ||
        formData.officialDocCode === '' ||
        formData.officialDocDescription === '' ||
        formData.officialDocProtocolDate == null ||
        formData.officialDocValidityStart == null ||
        (formData.isDuty && formData.dutyDate == null);

    return (
        <section>
            <h2>{metadataStep.title}</h2>
            <section className='flex flex-col gap-4'>
                <InputText
                    required
                    label={metadataStep.officialDocName}
                    placeholder={metadataStep.officialDocNamePlaceholder}
                    value={formData.officialDocName}
                    onChange={(e) =>
                        setFormData((old) => ({
                            ...old,
                            officialDocName: e.target.value,
                        }))
                    }
                />
                <InputText
                    required
                    label={metadataStep.officialDocCode}
                    placeholder={metadataStep.officialDocCodePlaceholder}
                    value={formData.officialDocCode}
                    onChange={(e) =>
                        setFormData((old) => ({
                            ...old,
                            officialDocCode: e.target.value,
                        }))
                    }
                />
                <InputTextArea
                    required
                    label={metadataStep.officialDocDescription}
                    placeholder={metadataStep.officialDocDescriptionPlaceholder}
                    value={formData.officialDocDescription}
                    onChange={(e) =>
                        setFormData((old) => ({
                            ...old,
                            officialDocDescription: e.target.value,
                        }))
                    }
                />
                <Datepicker
                    label={metadataStep.officialDocProtocolDate}
                    value={formData.officialDocProtocolDate}
                    required
                    onChange={(date) =>
                        setFormData((old) => ({
                            ...old,
                            officialDocProtocolDate: date,
                        }))
                    }
                />
            </section>
            <section>
                <h2>{validityStep.title}</h2>
                <div className='grid grid-cols-2'>
                    <Datepicker
                        label={validityStep.start}
                        value={formData.officialDocValidityStart}
                        required
                        onChange={(date) =>
                            setFormData((old) => ({
                                ...old,
                                officialDocValidityStart: date,
                            }))
                        }
                    />
                    <Datepicker
                        label={validityStep.end}
                        value={formData.officialDocValidityEnd}
                        onChange={(date) =>
                            setFormData((old) => ({
                                ...old,
                                officialDocValidityEnd: date,
                            }))
                        }
                    />
                </div>
            </section>
            <section>
                <h2>{dutyStep.title}</h2>
                <div className='grid grid-cols-2'>
                    <div className='flex flex-row gap-2'>
                        <Checkbox
                            checked={formData.isDuty}
                            onClick={() =>
                                setFormData((old) => ({
                                    ...old,
                                    isDuty: !old.isDuty,
                                }))
                            }
                        />
                        {localization.common.yes}
                    </div>
                    {formData.isDuty && (
                        <Datepicker
                            required
                            label={dutyStep.dutyDate}
                            value={formData.dutyDate}
                            onChange={(date) =>
                                setFormData((old) => ({
                                    ...old,
                                    dutyDate: date,
                                }))
                            }
                        />
                    )}
                </div>
            </section>
            <h2>{eventStep.title}</h2>
            <section className='flex flex-col gap-4'>
                <InputText
                    label={eventStep.eventName}
                    placeholder={eventStep.eventNamePlaceholder}
                    value={formData.eventName}
                    onChange={(e) =>
                        setFormData((old) => ({
                            ...old,
                            eventName: e.target.value,
                        }))
                    }
                />
                <InputTextArea
                    label={eventStep.eventDescription}
                    placeholder={eventStep.eventDescriptionPlaceholder}
                    value={formData.eventDescription}
                    onChange={(e) =>
                        setFormData((old) => ({
                            ...old,
                            eventDescription: e.target.value,
                        }))
                    }
                />
                <Datepicker
                    label={eventStep.eventDate}
                    value={formData.eventDate}
                    onChange={(date) =>
                        setFormData((old) => ({
                            ...old,
                            eventDate: date,
                        }))
                    }
                />
            </section>
            <ButtonPair
                onBack={goBack}
                onNext={goNext}
                disableNext={disableNext}
            />
        </section>
    );
};

export const ReviewStep: React.FC<DocumentBaseStep> = ({
    formData,
    goNext,
    goBack,
    disabled,
}) => {
    const [localization] = useLocalization();
    const officialDocView = localization.components.models.oficio.views.insert;
    const {
        reviewStep,
        validityStep,
        dutyStep,
        configStep,
        eventStep,
        fileStep,
        metadataStep,
    } = officialDocView;
    return (
        <section>
            <div className='flex flex-col w-full justify-center items-center'>
                <h1>{reviewStep.title}</h1>
                <p>{reviewStep.description}</p>
            </div>
            <h2>{configStep.title}</h2>
            <p>{formData.authorizationConfig?.name}</p>
            {formData.authorization && (
                <>
                    <h3>{localization.models.authorization.singular}</h3>
                    <p>{formData.authorization.name}</p>
                </>
            )}
            {formData.blockOrder && (
                <>
                    <h3>{localization.models.blockOrder.singular}</h3>
                    <p>{formData.blockOrder.name}</p>
                </>
            )}
            <h2>{metadataStep.title}</h2>
            <h3>{metadataStep.officialDocName}</h3>
            <p>{formData.officialDocName}</p>
            <h3>{metadataStep.officialDocCode}</h3>
            <p>{formData.officialDocCode}</p>
            <h3>{metadataStep.officialDocDescription}</h3>
            <p>{formData.officialDocDescription}</p>
            <h3>{metadataStep.officialDocProtocolDate}</h3>
            <p>
                {!formData.officialDocProtocolDate
                    ? '-'
                    : formatDate(
                          formData.officialDocProtocolDate,
                          'dd/MM/yyyy'
                      )}
            </p>

            <h2>{validityStep.title}</h2>
            <section className='grid grid-cols-2'>
                <div>
                    <h3>{validityStep.start}</h3>
                    <p>
                        {!formData.officialDocValidityStart
                            ? '-'
                            : formatDate(
                                  formData.officialDocValidityStart,
                                  'dd/MM/yyyy'
                              )}
                    </p>
                </div>
                <div>
                    <h3>{validityStep.end}</h3>
                    <p>
                        {!formData.officialDocValidityEnd
                            ? '-'
                            : formatDate(
                                  formData.officialDocValidityEnd,
                                  'dd/MM/yyyy'
                              )}
                    </p>
                </div>
            </section>

            <h2>{dutyStep.title}</h2>
            <section className='grid grid-cols-2'>
                <div>
                    <h3>
                        {formData.isDuty
                            ? localization.common.yes
                            : localization.common.no}
                    </h3>
                </div>
                {formData.isDuty && (
                    <div>
                        <h3>{dutyStep.dutyDate}</h3>
                        <p>
                            {!formData.dutyDate
                                ? '-'
                                : formatDate(formData.dutyDate, 'dd/MM/yyyy')}
                        </p>
                    </div>
                )}
            </section>

            <h2>{eventStep.title}</h2>
            <h3>{eventStep.eventName}</h3>
            <p>{formData.eventName === '' ? '-' : formData.eventName}</p>
            <h3>{eventStep.eventDescription}</h3>
            <p>
                {formData.eventDescription === ''
                    ? '-'
                    : formData.eventDescription}
            </p>
            <h3>{eventStep.eventDate}</h3>
            <p>
                {!formData.eventDate
                    ? '-'
                    : formatDate(formData.eventDate, 'dd/MM/yyyy')}
            </p>
            <h2>{fileStep.title}</h2>
            <FileItem
                file={{
                    status: 'done',
                    _id: '',
                    custom_identifier: '',
                    meta: {
                        name: formData.file?.name ?? '',
                        size: formData.file?.size ?? 0,
                    },
                }}
            />
            <ButtonPair
                onBack={goBack}
                onNext={goNext}
                loadingNext={disabled}
                disableBack={disabled}
                nextLabel={localization.components.common.button.finish}
            />
        </section>
    );
};
