import { ExportAuthorizationTargetParams } from 'services/ether/case-manager/targets/types';
import useBaseFileExport from '../useBaseFileExport';
import { exportAuthorizationTarget } from 'services/ether/case-manager/targets';

const useExportAuthorizationTarget = (
    params: ExportAuthorizationTargetParams
) => {
    const queryKey = params;

    const { exportFile, isExporting } = useBaseFileExport(
        'export-authorization-target',
        queryKey,
        ({ signal }) => exportAuthorizationTarget({ ...params, signal })
    );

    return { exportFile: () => exportFile({ params: null }), isExporting };
};

export default useExportAuthorizationTarget;
