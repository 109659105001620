import axios from 'axios';
import { EJSON, ObjectId } from 'bson';

const instance = axios.create({
    timeout: 5 * 60 * 1000,
    baseURL: window.API_URL,
});

instance.interceptors.request.use(
    (req) => {
        return req;
    },
    (err) => {
        console.error(err);
    }
);

export function handleOid(body: any) {
    if (body === null || body === undefined || typeof body !== 'object')
        return body;

    for (const key of Object.keys(body)) {
        const value = body[key];
        if (value instanceof ObjectId) {
            body[key] = value.toHexString();
        } else if (typeof value === 'object') handleOid(value);
    }
    return body;
}

instance.interceptors.response.use(
    async (res) => {
        const contentType = res.headers['content-type'];
        if (contentType === 'application/json') {
            if (res.data instanceof ArrayBuffer)
                res.data = JSON.parse(new TextDecoder().decode(res.data));
            else if (res.data instanceof Blob)
                res.data = JSON.parse(await res.data.text());
            res.data = EJSON.deserialize(res.data as any) as any;
            res.data = handleOid(res.data);
            if (res.data.success != null && !res.data.success)
                throw new Error('Server error - ' + res.data.error);
        }
        return res;
    },
    async (err) => {
        if (err.response) {
            let data = err.response.data;
            // if (data instanceof Blob) data = await data.text();
            // if (typeof data === 'string')
            //     throw new Error('Server error - ' + data);
            // if (typeof data?.error === 'string')
            //     throw new Error('Server error - ' + data.error);
            if (
                data &&
                typeof data === 'object' &&
                'error' in data &&
                typeof data.error === 'string'
            )
                throw new Error(data.error);
            console.error(data);
            throw new Error('Unknown server error');
        } else {
            if (err.message === 'Network Error')
                err.message = 'Failed to connect';
            throw err;
        }
    }
);

export default instance;
