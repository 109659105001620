import { useMutation } from '@tanstack/react-query';
import useQueryRefresh from 'hooks/queries/useQueryRefresh';
import { useLocalization } from 'hooks/context/useLocalization';
import { useToast } from 'hooks/context/useToast';
import { ToastMessage } from 'primereact/toast';
import {
    editAuthorization,
    insertAuthorization,
} from 'services/ether/case-manager/authorizations';
import {
    EditAuthorizationEP,
    InsertAuthorizationEP,
} from 'services/ether/case-manager/authorizations/types';
import { getErrorToast } from 'utils/errorHandler';
import { removeManyEvidences } from 'services/ether/case-manager/evidences';

const useAuthorizationMutation = ({
    onInsert,
    onUpdate,
}: {
    onInsert?: () => void;
    onUpdate?: () => void;
}) => {
    const [localization] = useLocalization();
    const toast = useToast();
    const { hardRefresh, softRefresh } = useQueryRefresh();

    const handleUpdate = ({
        data,
        params,
    }: {
        data: InsertAuthorizationEP.Result | EditAuthorizationEP.Result;
        params: InsertAuthorizationEP.Data | EditAuthorizationEP.Data;
    }) => {
        hardRefresh(['authorization']);
        softRefresh(['target', 'block-order', 'unblock-order', 'metrics']);

        const { targets, authorization } = data;

        const targetsFlat = Object.values(targets).flatMap(
            (targets) => targets
        );

        const stats = {
            success: 0,
            updated: 0,
            errors: 0,
        };
        const errors: [number | string, string][] = [];
        targetsFlat.forEach((item) => {
            if (item.error) {
                stats.errors += 1;
                errors.push([item.value, item.error]);
            } else if (item.status === 'created') stats.success += 1;
            else stats.updated += 1;
        });

        let targetsSummary: JSX.Element;

        const toastMessage: ToastMessage = {
            summary:
                authorization.status === 'created'
                    ? localization.endpoints.authorization.upsert_authorization
                          .insertSuccess
                    : localization.endpoints.authorization.upsert_authorization
                          .updateSuccess,
        };
        if (stats.errors && (stats.success || stats.updated)) {
            targetsSummary = (
                <>
                    <b>
                        {
                            localization.endpoints.target.upsert_target
                                .insertPartial
                        }
                    </b>
                    <span>
                        {
                            localization.endpoints.target.upsert_target
                                .insertFailedDetails
                        }
                    </span>
                    {errors.map(([index, error]) => {
                        const value = localization.validations.generic.lineError
                            .replace('{index}', index.toString())
                            .replace('{error}', error);
                        return <span key={value}>{value}</span>;
                    })}
                </>
            );
            toastMessage.severity = 'warn';
            toastMessage.life = 60000;
        } else if (stats.errors) {
            targetsSummary = (
                <>
                    <b>
                        {
                            localization.endpoints.target.upsert_target
                                .insertFailed
                        }
                    </b>
                    <span>
                        {
                            localization.endpoints.target.upsert_target
                                .insertFailedDetails
                        }
                    </span>
                    {errors.map(([index, error]) => {
                        const value = localization.validations.generic.lineError
                            .replace('{index}', index.toString())
                            .replace('{error}', error);
                        return <span key={value}>{value}</span>;
                    })}
                </>
            );
            toastMessage.severity = 'error';
            toastMessage.life = 60000;
        } else if (!stats.updated) {
            targetsSummary = (
                <b>
                    {localization.endpoints.target.upsert_target.insertSuccess}
                </b>
            );
            toastMessage.severity = 'success';
            toastMessage.life = 5000;
        } else {
            targetsSummary = (
                <b>
                    {localization.endpoints.target.upsert_target.updateSuccess}
                </b>
            );
            toastMessage.severity = 'success';
            toastMessage.life = 5000;
        }

        const detailsMessage = (
            <div className='flex flex-col text-xs gap-1'>
                <b>
                    {localization.endpoints.authorization.upsert_authorization.insertSuccessDetails
                        .replace('{name}', params.authorization.name)
                        .replace('{id}', authorization._id)}
                </b>
                {targetsSummary}
            </div>
        );

        toastMessage.detail = detailsMessage;

        toast.show(toastMessage);
    };

    const authorizationEditMutation = useMutation<
        EditAuthorizationEP.Result,
        Error,
        EditAuthorizationEP.Data & {
            evidenceIdsToRemove?: string[];
        }
    >({
        mutationFn: (data) => {
            return new Promise((resolve, reject) => {
                if (
                    data.evidenceIdsToRemove &&
                    data.evidenceIdsToRemove.length > 0
                ) {
                    removeManyEvidences(data.evidenceIdsToRemove)
                        .then(() => resolve(editAuthorization(data)))
                        .catch(reject);
                } else resolve(editAuthorization(data));
            });
        },
        onSuccess: (data, params) => {
            handleUpdate({ data, params });
            if (onUpdate) onUpdate();
        },
        onError: (err) => toast.show(getErrorToast(err.message, localization)),
    });

    const authorizationInsertMutation = useMutation<
        InsertAuthorizationEP.Result,
        Error,
        InsertAuthorizationEP.Data
    >({
        mutationFn: (data) => insertAuthorization(data),
        onSuccess: (data, params) => {
            handleUpdate({ data, params });
            if (onInsert) onInsert();
        },
        onError: (err) => toast.show(getErrorToast(err.message, localization)),
    });

    return { authorizationInsertMutation, authorizationEditMutation };
};

export default useAuthorizationMutation;
