import { Chips } from 'components/ethercity-primereact';
import { useLocalization } from 'hooks/context/useLocalization';
import { Button } from 'primereact/button';
import { useState } from 'react';
import { TargetsMultiInsertProps } from './types';
import useTargetParseList from 'hooks/mutations/target/useTargetParseList';
import { useProject } from 'hooks/context/project/useProject';
import SelectOnePirateBrand from 'components/models/Tag/SelectOnePirateBrand';
import { ParseTargetListEP } from 'services/ether/case-manager/targets/types';

const TargetsMultiInsert: React.FC<TargetsMultiInsertProps> = ({
    appendTargets,
    setTargets,
    authorizationConfigId,
}) => {
    const [localization] = useLocalization();
    const project = useProject();

    const [localTargets, setLocalTargets] = useState<string[]>([]);
    const [tag, setTag] = useState<Ether.CaseManager.Tag | null>(null);

    const [mode, setMode] = useState<'add' | 'override'>('add');

    const { isLoading, submitTargetsList } = useTargetParseList({
        onUpload: (data) => {
            const targets = data.formatted;
            if (mode === 'add') appendTargets(targets);
            else setTargets(targets);
        },
    });

    const handleTargets = () => {
        const prepparedTargets = localTargets.map((t) => {
            const baseTarget = {
                value: t,
            } as ParseTargetListEP.Data['targets'][0];
            if (tag) baseTarget.pirate_brand = tag.name;
            return baseTarget;
        });
        submitTargetsList({
            authorization_config_id: authorizationConfigId,
            project_id: project._id,
            targets: prepparedTargets,
        });
    };

    return (
        <div className='flex flex-col gap-2'>
            <SelectOnePirateBrand
                value={tag?._id}
                onChange={setTag}
                queryOptions={{
                    devFilters: {
                        authorization_config_id: authorizationConfigId,
                    },
                }}
                hideTitle
                disabled={isLoading}
            />
            <div className='w-full'>
                <Chips
                    required
                    placeholder={
                        localization.components.models.authorization.views
                            .insert.targetsPlaceholder
                    }
                    onKeyDown={(e) => {
                        if (
                            e.key === ' ' ||
                            e.key === ',' ||
                            e.key === 'Enter'
                        ) {
                            const value = e.currentTarget.value.trim();
                            if (value === '') return;
                            if (!localTargets.includes(value))
                                setLocalTargets((old) => [...old, value]);
                            e.currentTarget.value = '';
                            e.preventDefault();
                        }
                    }}
                    value={localTargets}
                    addOnBlur
                    allowDuplicate={false}
                    onChange={(e) => {
                        const uniqueArray = e.value?.filter(function (
                            value,
                            index,
                            array
                        ) {
                            return array.indexOf(value) === index;
                        });
                        setLocalTargets(uniqueArray ?? []);
                    }}
                    wrapperStyle={{
                        width: '100%',
                    }}
                    disabled={isLoading}
                />
                <p>
                    {
                        localization.components.models.authorization.views
                            .insert.helpManualTargets
                    }
                </p>
                {localTargets.length <= 0 && (
                    <span className='p-error text-xs'>
                        {localization.validations.target.oneTargetAtLeast}
                    </span>
                )}
            </div>
            <div className='flex flex-row gap-2'>
                <Button
                    label={
                        localization.components.models.authorization.views
                            .insert.targetsManualOverwrite
                    }
                    onClick={() => {
                        setMode('override');
                        handleTargets();
                    }}
                    loading={mode === 'override' && isLoading}
                    disabled={isLoading || localTargets.length <= 0}
                />
                <Button
                    label={
                        localization.components.models.authorization.views
                            .insert.targetsManualAdd
                    }
                    onClick={() => {
                        setMode('add');
                        handleTargets();
                    }}
                    loading={mode === 'add' && isLoading}
                    disabled={isLoading || localTargets.length <= 0}
                />
            </div>
        </div>
    );
};
export default TargetsMultiInsert;
