import { useLocalization } from 'hooks/context/useLocalization';
import { useProject } from 'hooks/context/project/useProject';
import useExportAuthorizationTarget from 'hooks/queries/target/useExportAuthorizationTarget';
import { Button } from 'primereact/button';

const ButtonExportAuthorizationTarget = () => {
    const [localization] = useLocalization();
    const project = useProject();

    const { exportFile, isExporting } = useExportAuthorizationTarget({
        project_id: project._id,
        status: ['approved', 'done'],
    });

    return (
        <Button
            icon='pi pi-download'
            loading={isExporting}
            label={localization.components.models.authorization.button.exportTargets}
            onClick={exportFile}
        />
    );
};

export default ButtonExportAuthorizationTarget;
