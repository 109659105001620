import { useLocalization } from 'hooks/context/useLocalization';
import { useProject } from 'hooks/context/project/useProject';
import useDetailManyOperation from 'hooks/queries/operation/useDetailManyOperation';
import { SelectOneItemProps } from 'components/form/SelectItems/types';
import { ListOperationEP } from 'services/ether/case-manager/operation/types';
import SelectOneItem from 'components/form/SelectItems/SelectOneItem';

const SelectOneOperation: React.FC<
    SelectOneItemProps<
        Ether.CaseManager.Operation,
        ListOperationEP.Filters.Options
    >
> = (props) => {
    const [localization] = useLocalization();
    const project = useProject();

    const query = useDetailManyOperation({
        project_id: project._id,
        options: { ...props.queryOptions, limit: 9999 },
    });

    return (
        <SelectOneItem
            title={localization.models.operation.singular}
            placeholder={
                localization.components.models.operation.form
                    .selectOnePlaceholder
            }
            label={localization.models.operation.singular}
            items={query.data}
            itemsLoading={query.isFetching}
            {...props}
        />
    );
};

export default SelectOneOperation;
