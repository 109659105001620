import CellDisplayDone from 'components/misc/CellDisplayDone';
import DisplayError from 'components/misc/CellDisplayError';
import DisplayPending from 'components/misc/CellDisplayPending';
import { getESTICurrentStatus } from 'utils/target';

const TargetEnrichmentField: React.FC<{
    target: Ether.CaseManager.Target.Detailed;
}> = ({ target }) => {
    let final = <CellDisplayDone />;
    const status = getESTICurrentStatus({
        target,
    });
    if (status === 'pending') final = <DisplayPending />;
    else if (status === 'error') final = <DisplayError />;
    return <span className='cm-target-enrichment-field'>{final}</span>;
};

export default TargetEnrichmentField;
