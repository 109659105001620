import { useAuth } from 'hooks/context/useAuth';
import { useProject } from 'hooks/context/project/useProject';
import { Navigate, useParams } from 'react-router-dom';
import CMBreadCrumb from 'components/page/CMBreadCrumb';
import useDetailOneAuthorization from 'hooks/queries/authorization/useDetailOneAuthorization';
import DetailedAuthorizationView from 'components/models/Authorization/DetailedAuthorizationView';

const DetailedAuthorization = () => {
    const { permissions } = useAuth();
    const params = useParams<{ authorizationId: string }>();
    const project = useProject();

    const authorizationId = params.authorizationId as string;

    const detailedAuthorizationQuery = useDetailOneAuthorization(
        {
            _id: authorizationId,
            project_id: project._id,
        },
        {
            enabled: permissions.detailAuthorizations,
        }
    );

    if (!permissions.detailAuthorizations) return <Navigate to='/' />;
    return (
        <>
            <CMBreadCrumb
                projectLabel={project.name}
                authorizationLabel={detailedAuthorizationQuery.data?.name}
            />
            <DetailedAuthorizationView
                authorization={detailedAuthorizationQuery.data}
                isLoading={detailedAuthorizationQuery.isLoading}
                error={detailedAuthorizationQuery.error}
            />
        </>
    );
};

export default DetailedAuthorization;
