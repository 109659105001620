import useQueryWithRefresh from 'hooks/helpers/useQueryWithRefresh/useQueryWithRefresh';
import { getBlockOrderStats } from 'services/ether/case-manager/block-orders';
import { GetBlockOrderStatsEP } from 'services/ether/case-manager/block-orders/types';

const useGetBlockOrderStats = (
    params: GetBlockOrderStatsEP.Params,
    queryOptions?: {
        enabled?: boolean;
    }
) => {
    const queryKey = ['authorization', 'stats', params];

    const query = useQueryWithRefresh<GetBlockOrderStatsEP.Result, Error>({
        queryKey,
        queryFn: ({ signal }) =>
            getBlockOrderStats({
                params,
                signal,
            }),
        ...queryOptions,
    });

    return query;
};

export default useGetBlockOrderStats;
