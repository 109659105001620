import { useMutation } from '@tanstack/react-query';
import useQueryRefresh from 'hooks/queries/useQueryRefresh';
import { useLocalization } from 'hooks/context/useLocalization';
import { useToast } from 'hooks/context/useToast';
import { changeAuthorizationStatus } from 'services/ether/case-manager/authorizations';
import { ChangeAuthorizationStatus } from 'services/ether/case-manager/authorizations/types';
import { getErrorToast } from 'utils/errorHandler';

const useChangeAuthorizationStatus = (options?: {
    onUpdate?: () => void;
}): [
    (data: ChangeAuthorizationStatus.Data) => void,
    {
        isLoading: boolean;
        error: Error | null;
    }
] => {
    const [localization] = useLocalization();
    const toast = useToast();

    const { hardRefresh, softRefresh } = useQueryRefresh();

    const handleUpdate = ({
        data,
        params,
    }: {
        data: ChangeAuthorizationStatus.Result;
        params: ChangeAuthorizationStatus.Data;
    }) => {
        hardRefresh(['authorization'])
        softRefresh(['metrics']);
        toast.show({
            summary:
                localization.endpoints.authorization.upsert_authorization
                    .updateSuccess,
            severity: 'success',
            life: 10000,
        });
        if (options?.onUpdate) options.onUpdate();
    };

    const changeMutation = useMutation<
        ChangeAuthorizationStatus.Result,
        Error,
        ChangeAuthorizationStatus.Data
    >({
        mutationFn: (data) => changeAuthorizationStatus(data),
        onSuccess: (data, params) => handleUpdate({ data, params }),
        onError: (err) => toast.show(getErrorToast(err.message, localization)),
    });

    const updateStatus = (data: ChangeAuthorizationStatus.Data) => {
        changeMutation.mutate(data);
    };

    return [
        updateStatus,
        {
            error: changeMutation.error,
            isLoading: changeMutation.isPending,
        },
    ];
};

export default useChangeAuthorizationStatus;
