import { useMutation } from '@tanstack/react-query';
import { useLocalization } from 'hooks/context/useLocalization';
import { useToast } from 'hooks/context/useToast';
import {
    checkActionTargetWarning,
    removeTargetValidation,
} from 'services/ether/case-manager/targets';
import {
    CheckActionTargetWarningEP,
    RemoveTargetValidationEP,
} from 'services/ether/case-manager/targets/types';
import { getErrorToast } from 'utils/errorHandler';
import useQueryRefresh from 'hooks/queries/useQueryRefresh';
import { useState } from 'react';
import useRemoveTargetValidationDialog from 'hooks/dialogs/target/useRemoveTargetValidationDialog';
import useShowTargetActionDisambiguation from 'hooks/dialogs/target/useShowTargetActionDisambiguation';
import useShowTargetCheckWarning from 'hooks/dialogs/target/useShowTargetCheckWarning';

export type RemoveValidationDialogData = {
    authorization_id: string;
    validation_type: 'rejected' | 'accepted';
    targets: {
        _id: string;
        value: string;
    }[];
    checkIfAll?: boolean;
};

const useRemoveTargetValidation = ({ onRevert }: { onRevert?: () => void }) => {
    const [localization] = useLocalization();
    const { hardRefresh, softRefresh } = useQueryRefresh();
    const toast = useToast();

    const [isLoading, setIsLoading] = useState(false);

    const epLocale = localization.endpoints.target.revert_target_validation;

    const { show: warnTargetsRevert, dialog: revertWarningDialog } =
        useShowTargetCheckWarning();
    const { ConfirmRevertDialog, confirmRevert: showRemoveTargetDialog } =
        useRemoveTargetValidationDialog();

    const { mutate, isPending, error } = useMutation<
        RemoveTargetValidationEP.Result,
        Error,
        RemoveTargetValidationEP.Data
    >({
        mutationFn: (data) => removeTargetValidation(data),
        onSuccess: (data) => {
            toast.show({
                summary: data[0]?.all_targets
                    ? epLocale.successAll
                    : epLocale.successCountable.replace(
                          '{count}',
                          data.length.toString()
                      ),
                severity: 'success',
                life: 5000,
            });
            hardRefresh(['target']);
            softRefresh(['metrics', 'authorization']);
            if (onRevert) onRevert();
        },
        onError: (err) => toast.show(getErrorToast(err.message, localization)),
    });

    const isReverting = isPending || isLoading;

    const showDisambiguation = useShowTargetActionDisambiguation();

    const revertTargets = (
        data: RemoveValidationDialogData & {
            hideConfirm?: true;
        }
    ) => {
        let postData: RemoveTargetValidationEP.Data = data.targets.map((t) => ({
            authorization_id: data.authorization_id,
            target_id: t._id,
            validation_type: data.validation_type,
        }));

        const validationTypeMap: Record<
            RemoveTargetValidationEP.SingleData['validation_type'],
            CheckActionTargetWarningEP.TargetsInAuthorization['action']
        > = {
            accepted: 'remove_target_validation',
            rejected: 'remove_target_from_rejected',
        };

        let checkPostData: CheckActionTargetWarningEP.Data = data.targets.map(
            (t) => ({
                authorization_id: data.authorization_id,
                target_id: t._id,
                action: validationTypeMap[data.validation_type],
            })
        );

        const revertAction = () => {
            setIsLoading(true);
            checkActionTargetWarning(checkPostData)
                .then((targetsInfo) => {
                    const targetsWithWarning = targetsInfo.filter(
                        (t) => t.warnings.length > 0
                    );
                    if (targetsWithWarning.length > 0) {
                        warnTargetsRevert({
                            targets: targetsWithWarning,
                            onContinue: () => mutate(postData),
                            action: 'revertTargets',
                        });
                    } else {
                        if (!data.hideConfirm)
                            showRemoveTargetDialog({
                                data: {
                                    targets: data.targets.map((t) => t.value),
                                },
                                onRevert: () => mutate(postData),
                            });
                        else mutate(postData);
                    }
                })
                .catch((err) => toast.show(getErrorToast(err, localization)))
                .finally(() => setIsLoading(false));
        };

        const revertAllAction = () => {
            setIsLoading(true);
            checkActionTargetWarning([
                {
                    all_targets: true,
                    authorization_id: data.authorization_id,
                    action: validationTypeMap[data.validation_type],
                },
            ])
                .then((targetsInfo) => {
                    const targetsWithWarning = targetsInfo.filter(
                        (t) => t.warnings.length > 0
                    );
                    if (targetsWithWarning.length > 0) {
                        warnTargetsRevert({
                            targets: targetsWithWarning,
                            onContinue: () => mutate(postData),
                            action: 'revertTargets',
                        });
                    } else {
                        if (!data.hideConfirm)
                            showRemoveTargetDialog({
                                data: {
                                    is_all: true,
                                },
                                onRevert: () => mutate(postData),
                            });
                        else mutate(postData);
                    }
                })
                .catch((err) => toast.show(getErrorToast(err, localization)))
                .finally(() => setIsLoading(false));
        };

        if (data.checkIfAll)
            showDisambiguation({
                answer: localization.components.models.target.views.targetValidation.revertTargets.toLocaleLowerCase(),
                isRevert: true,
                onApproveAll: revertAllAction,
                onApprovePage: revertAction,
            });
        else revertAction();
    };

    const dialogs = (
        <>
            <ConfirmRevertDialog />
            {revertWarningDialog}
        </>
    );

    return {
        dialogs: dialogs,
        revertTargets,
        error: error,
        isLoading: isReverting,
    };
};

export default useRemoveTargetValidation;
