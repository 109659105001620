import NameIdAggregator from 'components/datatable/NameIdAggregator';
import { Column } from 'primereact/column';
import { DataTableProps } from 'primereact/datatable';
import AuthorizationStatusBadge from '../AuthorizationStatusBadge';
import { DateBadge, ObjectDisplayModal } from 'components/ethercity-primereact';
import {
    isAuthorizationFilesExportable,
    isAuthorizationRespondable,
} from 'utils/authorization';
import { useAuth } from 'hooks/context/useAuth';
import { MenuItem } from 'primereact/menuitem';
import { useNavigate } from 'react-router-dom';
import { Menu } from 'primereact/menu';
import { useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { useLocalization } from 'hooks/context/useLocalization';
import { CMDataTableProps } from 'components/datatable/CMDataTable/types';
import CMDataTable from 'components/datatable/CMDataTable';
import useExportAuthorizationFiles from 'hooks/queries/authorization/useExportAuthorizationFiles';
import { useAuthorizationRespond } from 'hooks/models/authorization/useAuthorizationRespond';
import { getUserDisplayName } from 'utils/user';
import { getFilterData } from 'utils/datatable';
import useDownloadEvidencesFromAuthorization from 'hooks/queries/evidence/useDownloadEvidencesFromAuthorization';
import { FilterMatchMode } from 'primereact/api';
import useShowOperationStats from 'hooks/dialogs/operation/useShowOperationStats';

const AuthorizationMenu: React.FC<{
    authorization: Ether.CaseManager.Authorization.Detailed;
    onRespondAuthorization: (
        authorization: Ether.CaseManager.Authorization,
        answer: boolean
    ) => void;
    onShowDetail: (authorization: Ether.CaseManager.Authorization) => void;
    onStartEdit?: (authorization: Ether.CaseManager.Authorization) => void;
    onShowStats?: (item: { id: string; name: string }) => void;
}> = ({
    authorization,
    onRespondAuthorization,
    onStartEdit,
    onShowDetail,
    onShowStats,
}) => {
    const { user, permissions } = useAuth();
    const navigate = useNavigate();
    const [localization] = useLocalization();

    const menuRef = useRef<Menu>(null);

    const isFilesExportable = isAuthorizationFilesExportable({
        authorization: authorization,
        permissions: permissions,
        token: undefined,
    });

    const { exportFile, isExporting } = useExportAuthorizationFiles();
    const {
        downloadFile: downloadEvidences,
        isDownloading: isDownloadingEvidences,
    } = useDownloadEvidencesFromAuthorization({
        authorization_id: authorization._id,
    });

    const isRespondable = isAuthorizationRespondable({
        permissions: permissions,
        authorization,
        userId: user.data?._id,
        userRole: user.role,
    });
    const isEditable =
        authorization.status === 'draft' &&
        permissions.editAuthorizations &&
        !!onStartEdit &&
        authorization.target_group_ids &&
        authorization.target_group_ids.length > 0;
    const menuItems: MenuItem[] = [
        ...(permissions.getAuthorizationStats && onShowStats
            ? [
                  {
                      icon: 'pi pi-chart-bar',
                      label: localization.components.common.stats.header,
                      command: () =>
                          onShowStats({
                              id: authorization._id,
                              name: authorization.name,
                          }),
                  },
              ]
            : []),
        {
            id: 'targets',
            // icon: 'pi pi-sign-out',
            label: localization.models.target.plural,
            command: () =>
                navigate(
                    `/authorizations/${authorization._id}?start_view=targets`
                ),
        },
        {
            id: 'evidences',
            // icon: 'pi pi-sign-out',
            label: localization.models.evidence.plural,
            command: () =>
                navigate(
                    `/authorizations/${authorization._id}?start_view=evidences`
                ),
        },
        ...(isFilesExportable
            ? [
                  {
                      id: 'export-files',
                      icon: 'pi pi-download',
                      label: localization.components.common.button.exportFiles,
                      command: () =>
                          exportFile({
                              filename: null,
                              authorization_id: authorization._id,
                          }),
                      disabled: isExporting,
                  },
              ]
            : []),
        ...(permissions.downloadEvidencesAuthorization &&
        (authorization?.total_evidences ?? 0) > 0
            ? [
                  {
                      id: 'download-evidences',
                      icon: 'pi pi-download',
                      label: localization.components.models.evidence.button
                          .downloadMany,
                      command: downloadEvidences,
                      disabled: isDownloadingEvidences,
                  },
              ]
            : []),
        ...(isEditable
            ? [
                  {
                      id: 'edit',
                      label: localization.components.common.button.edit,
                      icon: 'pi pi-pencil',
                      command: () => onStartEdit(authorization),
                  },
              ]
            : []),
        ...(isRespondable
            ? [
                  {
                      id: 'accept',
                      label: !user.role?.startsWith('operator')
                          ? localization.components.common.button.approve
                          : localization.components.common.button.confirm,
                      command: () =>
                          onRespondAuthorization(authorization, true),
                      icon: 'pi pi-check',
                      style: {
                          backgroundColor: 'var(--semaphore-green)',
                      },
                  },
              ]
            : []),
        ...(permissions.debugAuthorizations
            ? [
                  {
                      id: 'debug',
                      label: 'Detail data',
                      command: () => onShowDetail(authorization),
                  },
              ]
            : []),
    ];

    if (menuItems.length <= 0) return;

    return (
        <>
            <Menu popup ref={menuRef} model={menuItems} />
            <Button
                icon='pi pi-ellipsis-h'
                onClick={(e) => menuRef?.current?.toggle(e)}
            />
        </>
    );
};

type KeyInColumn = keyof CaseManagerApp.ModelColumns['authorization'];

const columnFields: { [key in KeyInColumn]: key } = {
    approved_at: 'approved_at',
    approved_by: 'approved_by',
    created_at: 'created_at',
    created_by: 'created_by',
    evidences_count: 'evidences_count',
    status: 'status',
    targets_count: 'targets_count',
    registered_at: 'registered_at',
    authorization_config_name: 'authorization_config_name',
};

export const authorizationFilters = {
    _cm_name_id: getFilterData(),
    [columnFields.status]: getFilterData(FilterMatchMode.EQUALS),
    [columnFields.created_at]: getFilterData(FilterMatchMode.DATE_IS),
    [columnFields.approved_at]: getFilterData(FilterMatchMode.DATE_IS),
};

const AuthorizationDataTable: React.FC<
    DataTableProps<Ether.CaseManager.Authorization.Detailed[]> &
        Omit<CMDataTableProps, 'columnConfigName'> & {
            showApproved?: boolean;
            onRespond?: () => void;
            onStartEdit?: (
                authorization: Ether.CaseManager.Authorization
            ) => void;
        }
> = ({ showApproved, onRespond, onStartEdit, ...props }) => {
    const [localization] = useLocalization();
    const { user } = useAuth();

    const authorizationBadge =
        localization.components.models.authorization.badge.status;
    const defaultStatusOptions = [
        {
            label: authorizationBadge.pending.toLocaleUpperCase(),
            value: 'pending',
        },
        {
            label: authorizationBadge.approved.toLocaleUpperCase(),
            value: 'approved',
        },
        { label: authorizationBadge.done.toLocaleUpperCase(), value: 'done' },
        ...(user.role?.startsWith('authorizer')
            ? []
            : [
                  {
                      label: authorizationBadge.draft.toLocaleUpperCase(),
                      value: 'draft',
                  },
              ]),
    ];

    const { dialog: statsDialog, show: showStats } = useShowOperationStats();

    const [viewAuthorizationDialogOptions, setViewAuthorizationDialogOptions] =
        useState<{
            visible: boolean;
            data: Ether.CaseManager.Authorization | null;
        }>({
            visible: false,
            data: null,
        });

    const onAuthorizationViewModalShow = (
        data: Ether.CaseManager.Authorization
    ) =>
        setViewAuthorizationDialogOptions({
            visible: true,
            data: data,
        });

    const onAuthorizationViewModalHide = () =>
        setViewAuthorizationDialogOptions({
            data: null,
            visible: false,
        });

    const { RespondDialog, respondAuthorization } = useAuthorizationRespond({
        onRespond: onRespond,
    });

    const sortable = 'sortField' in props;
    const datatableLocale =
        localization.components.models.authorization.datatable;

    return (
        <>
            <RespondDialog />
            {statsDialog}
            <ObjectDisplayModal
                header={viewAuthorizationDialogOptions.data?.name}
                visible={viewAuthorizationDialogOptions.visible}
                displayData={viewAuthorizationDialogOptions.data}
                onHide={onAuthorizationViewModalHide}
                sortKeys={false}
            />
            <CMDataTable
                columnConfigName='authorization'
                lazy
                emptyMessage={
                    localization.components.models.authorization.datatable.empty
                }
                filterOptions={{
                    _cm_name_id: {
                        placeholder: datatableLocale.selectName,
                        type: 'string',
                    },
                    [columnFields.status]: {
                        label: localization.fields.authorization.status,
                        placeholder: datatableLocale.selectStatus,
                        type: 'dropdown',
                        dropdownOptions: defaultStatusOptions,
                    },
                    [columnFields.created_at]: {
                        label: localization.fields.authorization.created,
                        type: 'date',
                    },
                    [columnFields.approved_at]: {
                        label: localization.fields.authorization.approval,
                        type: 'date',
                    },
                }}
                {...props}
            >
                <Column
                    field='_cm_name_id'
                    body={(
                        rowData: Ether.CaseManager.Authorization.Detailed
                    ) => (
                        <NameIdAggregator
                            name={rowData.name}
                            id={rowData._id}
                            navigateTo={`/authorizations/${rowData._id}?start_view=targets`}
                        />
                    )}
                />
                <Column
                    field={columnFields['authorization_config_name']}
                    header={localization.models.authorizationConfig.singular}
                    body={(data: Ether.CaseManager.Authorization.Detailed) =>
                        data.authorization_configs_data?.[0]?.name ?? '-'
                    }
                />
                <Column
                    field={columnFields['status']}
                    header={localization.fields.authorization.status}
                    body={(rowData: Ether.CaseManager.Authorization) => (
                        <AuthorizationStatusBadge authorization={rowData} />
                    )}
                />
                <Column
                    field={columnFields['targets_count']}
                    header={localization.models.target.plural}
                    body={(rowData: Ether.CaseManager.Authorization.Detailed) =>
                        rowData.total_targets ?? '-'
                    }
                />
                <Column
                    field={columnFields['evidences_count']}
                    header={localization.models.evidence.plural}
                    body={(rowData: Ether.CaseManager.Authorization.Detailed) =>
                        rowData.total_evidences ?? '-'
                    }
                />
                <Column
                    field={columnFields['created_at']}
                    header={localization.fields.authorization.created}
                    body={(rowData: Ether.CaseManager.Authorization) => (
                        <DateBadge value={rowData.created_at} />
                    )}
                    sortable={sortable}
                    dataType='date'
                />
                <Column
                    field={columnFields['created_by']}
                    header={localization.fields.authorization.createdBy}
                    body={(
                        rowData: Ether.CaseManager.Authorization.Detailed
                    ) => {
                        const user = rowData.created_by_data?.[0];
                        if (!user) return '-';
                        return getUserDisplayName(user);
                    }}
                />
                <Column
                    field={columnFields['registered_at']}
                    header={localization.fields.authorization.registeredAt}
                    body={(rowData: Ether.CaseManager.Authorization) =>
                        rowData.registered_at ? (
                            <DateBadge value={rowData.registered_at} />
                        ) : (
                            '-'
                        )
                    }
                    sortable={sortable}
                />
                {showApproved && (
                    <Column
                        field={columnFields['approved_at']}
                        header={localization.fields.authorization.approval}
                        body={(rowData: Ether.CaseManager.Authorization) =>
                            rowData.approved_at ? (
                                <DateBadge value={rowData.approved_at} />
                            ) : (
                                '-'
                            )
                        }
                        sortable={sortable}
                        dataType='date'
                    />
                )}
                {showApproved && (
                    <Column
                        field={columnFields['approved_by']}
                        header={localization.fields.authorization.approvedBy}
                        body={(
                            rowData: Ether.CaseManager.Authorization.Detailed
                        ) => {
                            const approvedBy =
                                rowData.authorizer_associations_data?.find(
                                    (auth) => auth.response?.accepted
                                );
                            if (!approvedBy) return '-';
                            const approvedByUser = approvedBy.users_data?.find(
                                (u) => !!u._id
                            );
                            if (!approvedByUser) return '-';
                            return getUserDisplayName(approvedByUser);
                        }}
                    />
                )}
                <Column
                    field='actions'
                    body={(
                        rowData: Ether.CaseManager.Authorization.Detailed
                    ) => (
                        <AuthorizationMenu
                            authorization={rowData}
                            onRespondAuthorization={respondAuthorization}
                            onShowDetail={onAuthorizationViewModalShow}
                            onStartEdit={onStartEdit}
                            onShowStats={showStats}
                        />
                    )}
                />
            </CMDataTable>
        </>
    );
};

export default AuthorizationDataTable;
