import LoadingMessage from 'components/misc/LoadingMessage';
import UpsertDocumentDialog from 'components/models/Document/UpsertDocumentDialog';
import { UpsertDocumentDialogSelectConfigProps } from 'components/models/Document/UpsertDocumentDialog/types';
import { useProject } from 'hooks/context/project/useProject';
import { useLocalization } from 'hooks/context/useLocalization';
import { Dialog } from 'primereact/dialog';
import { useCallback, useMemo, useState } from 'react';
import { detailOneAuthorizationConfig } from 'services/ether/case-manager/authorization-config';
import { detailOneAuthorization } from 'services/ether/case-manager/authorizations';
import { detailOneDocument } from 'services/ether/case-manager/documents';

type DetailedDoc = Ether.CaseManager.Document.Detailed;

const useInsertDocumentDialog = ({
    type,
    props,
}: {
    type: 'document' | 'official_document';
    props?: UpsertDocumentDialogSelectConfigProps;
}) => {
    const [localization] = useLocalization();
    const project = useProject();

    const [visible, setVisible] = useState(false);
    const [startingData, setStartingData] = useState<{
        authorization?: Ether.CaseManager.Authorization;
        authorizationConfig?: Ether.CaseManager.AuthorizationConfig;
    } | null>(null);
    const [editData, setEditData] = useState<DetailedDoc | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    const showInsert = useCallback(
        (data?: {
            authorizationId?: string;
            authorizationConfigId?: string;
        }) => {
            setIsLoading(true);
            const { authorizationConfigId, authorizationId } = data ?? {};
            const authorizationPromise = authorizationId
                ? detailOneAuthorization({
                      _id: authorizationId,
                      project_id: project._id,
                  })
                : null;
            const authorizationConfigPromise = authorizationConfigId
                ? detailOneAuthorizationConfig({
                      _id: authorizationConfigId,
                      project_id: project._id,
                  })
                : null;
            Promise.all([
                authorizationPromise,
                authorizationConfigPromise,
            ]).then(([authorization, authorizationConfig]) => {
                setVisible(true);
                setStartingData({
                    authorization: authorization ?? undefined,
                    authorizationConfig: authorizationConfig ?? undefined,
                });
                setEditData(null);
                setIsLoading(false);
            });
        },
        [project._id]
    );
    const showEdit = useCallback(
        (docId: string) => {
            setIsLoading(true);
            detailOneDocument({
                _id: docId,
                project_id: project._id,
            })
                .then((doc) => {
                    if (!doc) {
                        return;
                    }
                    setVisible(true);
                    setEditData(doc);
                })
                .finally(() => setIsLoading(false));
        },
        [project._id]
    );
    const hide = useCallback(() => {
        setVisible(false);
        setEditData(null);
    }, []);

    const dialog = useMemo(
        () =>
            isLoading ? (
                <Dialog visible onHide={() => {}}>
                    <LoadingMessage>
                        {localization.common.loading}
                    </LoadingMessage>
                </Dialog>
            ) : (
                visible && (
                    <UpsertDocumentDialog
                        type={type}
                        visible
                        onHide={hide}
                        editDoc={editData}
                        startingAuthorization={startingData?.authorization}
                        startingAuthorizationConfig={
                            startingData?.authorizationConfig
                        }
                        {...props}
                    />
                )
            ),
        [
            isLoading,
            localization.common.loading,
            visible,
            type,
            hide,
            editData,
            startingData,
            props,
        ]
    );

    return {
        dialog,
        showInsert,
        showEdit,
        hide,
    };
};

export default useInsertDocumentDialog;
