import { InputMaskProps } from 'primereact/inputmask';
import CMInputMask from '../CMInputMask';

const InputCEP: React.FC<InputMaskProps & { label: string }> = ({
    label,
    ...rest
}) => {
    return (
        <CMInputMask
            label={label}
            placeholder='99999-999'
            mask='99999-999'
            {...rest}
        />
    );
};

export default InputCEP;
