type BaseAuthorizationCheck = {
    authorization: Ether.CaseManager.Authorization.Detailed | undefined;
    userId: string | undefined | null;
    userRole: Ether.CaseManager.Role | undefined | null;
};

const baseAuthorizationCheck = ({
    authorization,
    userId,
    userRole,
}: BaseAuthorizationCheck) => {
    if (!authorization || !userRole || !userId) return false;

    const { authorizer_associations_data, status, authorization_configs_data } =
        authorization;

    if (
        authorizer_associations_data?.find((i) =>
            i.users_data?.find((u) => u._id === userId && i.response !== null)
        )
    )
        return false;

    const isAnsweredByAuthorizer =
        status === 'approved' || status === 'rejected';

    if (!isAnsweredByAuthorizer) {
        const configData = authorization_configs_data?.find((a) => !!a);
        if (!configData?.authorizer_ids.find((u) => u === userId)) return false;
        if (userRole.startsWith('authorizer') && status === 'pending')
            return true;
    }

    // if (userRole === 'operator' && isAnsweredByAuthorizer) return true;

    return false;
};

export const isAuthorizationRespondable = ({
    permissions,
    authorization,
    userId,
    userRole,
}: {
    permissions: CaseManagerApp.Permissions | undefined;
} & BaseAuthorizationCheck) => {
    if (!permissions || !permissions.respondAuthorization) return false;
    return baseAuthorizationCheck({
        authorization,
        userId,
        userRole,
    });
};

export const isTokenAuthorizationRespondable = ({
    authorization,
    userId,
    userRole,
}: BaseAuthorizationCheck) => {
    return baseAuthorizationCheck({
        authorization,
        userId,
        userRole,
    });
};

export const isAuthorizationFilesExportable = ({
    authorization,
    permissions,
    token,
}: {
    authorization: Ether.CaseManager.Authorization.Detailed;
    permissions: CaseManagerApp.Permissions;
    token: string | undefined;
}) => {
    if (!permissions.exportAuthorizationFiles && !token) return false;
    if (authorization._user_fields?.can_send_document) return false;
    const config = authorization.authorization_configs_data?.[0];
    return config && (config?.templates?.length ?? 0) > 0;
};
