import { Dialog } from 'primereact/dialog';
import { DetailTargetModalProps } from './types';
import DisplayPending from 'components/misc/CellDisplayPending';
import DisplayError from 'components/misc/CellDisplayError';
import { MenuItem } from 'primereact/menuitem';
import { TabMenu } from 'primereact/tabmenu';
import { Fragment, useState } from 'react';
import { useLocalization } from 'hooks/context/useLocalization';
import CellGroup from 'components/display/CellGroup';
import { DateBadge, ObjectDisplayModal } from 'components/ethercity-primereact';

import './style.css';
import { Divider } from 'primereact/divider';
import { capitalize } from 'lodash';
import LoadingMessage from 'components/misc/LoadingMessage';
import { Button } from 'primereact/button';
import { getESTICurrentStatus } from 'utils/target';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { getDomainsFromTargetEnrich } from '../TargetsDataTable/utils';
import FilterBox from 'components/datatable/FilterBox';
import useInitDataTableState from 'hooks/helpers/useInitDataTableState';
import CMPaginator from 'components/datatable/CMPaginator';
import { getFilterData } from 'utils/datatable';
import { getMatchModeRegex } from 'services/ether/utils';
import { useQuery } from '@tanstack/react-query';
import { getOneESTI } from 'services/ether/case-manager/esti';

// const GroupDataHorizontal: React.FC<{
//     className?: string;
//     leftText: string;
//     rightText: string;
// }> = ({ leftText, rightText, className }) => {
//     return (
//         <div
//             className={
//                 'flex flex-row gap-1' + (className ? ` ${className}` : '')
//             }
//         >
//             <strong>{leftText}</strong>
//             <span>{rightText}</span>
//         </div>
//     );
// };

const GroupDataVertical: React.FC<{
    className?: string;
    aboveText: string;
    belowText: string;
}> = ({ className, aboveText, belowText }) => {
    return (
        <div
            className={
                'flex flex-col gap-0.5' + (className ? ` ${className}` : '')
            }
        >
            <strong>{aboveText}</strong>
            <pre className='text-sm'>{belowText}</pre>
        </div>
    );
};

const DetailTargetDomainHistory: React.FC<{
    esti: Ether.CaseManager.Esti;
}> = ({ esti }) => {
    const [localization] = useLocalization();

    const { hosting_history } = esti.parsed_data ?? {};
    if (hosting_history?.status !== 'done') return <DisplayError />;

    const hostingHistoryData: { [key: string]: [string, string][] } = {};

    hostingHistoryData['ipv4'] = hosting_history.data.ipv4.map((e) => [
        e.ip,
        e.updatedate,
    ]);
    hostingHistoryData['ipv6'] = hosting_history.data.ipv6.map((e) => [
        e.ip,
        e.updatedate,
    ]);
    hostingHistoryData['dns'] = hosting_history.data.dns.map((e) => [
        e.dns,
        e.updatedate,
    ]);

    const entries = Object.entries(hostingHistoryData);

    return (
        <section>
            <h2>
                {
                    localization.components.models.target.views
                        .enrichmentDataDisplay.titleHistory
                }
            </h2>
            <div className='flex flex-col gap-6'>
                {entries.map(([title, obj], index) => (
                    <Fragment key={title + index}>
                        <h3>{title.toLocaleUpperCase()}</h3>
                        {obj.map(([i, j]) => (
                            <GroupDataVertical
                                aboveText={i}
                                belowText={`Created: ${j}`}
                            />
                        ))}
                        {index + 1 < entries.length && <Divider />}
                    </Fragment>
                ))}
            </div>
        </section>
    );
};

const DetailTargetDomainWhois: React.FC<{
    esti: Ether.CaseManager.Esti;
}> = ({ esti }) => {
    const [localization] = useLocalization();

    const whois = esti.parsed_data?.whois;

    if (whois?.status !== 'done') return <DisplayError />;

    const whoIsData = Object.entries(whois.data)
        .map(([key, value]) => {
            const title = key.replaceAll('_', ' ');
            if (!value) return [title, null];
            let data: string;
            if (Array.isArray(value)) {
                data = value.join('\n');
            } else if (value instanceof Date) {
                data = value.toISOString();
            } else if (typeof value === 'object') {
                data = Object.entries(value)
                    .map(([k, v]) =>
                        v ? `${key.toLocaleUpperCase()}: ${v}` : null
                    )
                    .filter((v) => !!v)
                    .join('\n');
            } else {
                data = value;
            }
            return [title, data];
        })
        .filter(([_, data]) => !!data && data !== '') as [string, string][];

    if (Object.entries(whoIsData).every((a) => !a)) return <DisplayError />;

    return (
        <section>
            <h2>
                {
                    localization.components.models.target.views
                        .enrichmentDataDisplay.titleWhois
                }
            </h2>
            <div className='flex flex-col gap-4'>
                {whoIsData.map(([i, j], index) => (
                    <GroupDataVertical
                        key={index + i + j}
                        aboveText={capitalize(i)}
                        belowText={j}
                    />
                ))}
            </div>
        </section>
    );
};

const DetailTargetDomainDNS: React.FC<{
    esti: Ether.CaseManager.Esti;
}> = ({ esti }) => {
    const [localization] = useLocalization();

    const dig = esti.parsed_data?.dig;

    if (dig?.status !== 'done') return <DisplayError />;

    const digData = dig.data;

    const displayData = [
        ...[...digData.a.map((target) => ['A', target])],
        ...[...digData.aaaa.map((target) => ['AAAA', target])],
        ...[...digData.ns.map((target) => ['NS', target])],
        ...[...digData.soa.map((target) => ['SOA', target])],
        ...[...digData.txt.map((target) => ['TXT', target])],
    ];

    // const displayData = [
    //     ...[...digData.a.map((target) => ({ type: 'A', value: target }))],
    //     ...[...digData.aaaa.map((target) => ({ type: 'AAAA', value: target }))],
    //     ...[...digData.ns.map((target) => ({ type: 'NS', value: target }))],
    //     ...[...digData.soa.map((target) => ({ type: 'SOA', value: target }))],
    //     ...[...digData.txt.map((target) => ({ type: 'TXT', value: target }))],
    // ];

    if (displayData.length <= 0) return <DisplayError />;

    return (
        <section>
            <h2>
                {
                    localization.components.models.target.views
                        .enrichmentDataDisplay.titleDomains
                }
            </h2>
            {/* <DataTable>
                <Column
                    header={localization.components.models.target.badge.type.domain}
                    field='value'
                />
                <Column header='' field='type' />
            </DataTable> */}
            <div className='grid grid-cols-4 gap-x-0 gap-y-8'>
                {displayData.map(([i, j, k], index) => (
                    <Fragment key={(i ?? '') + (j ?? '') + k + index}>
                        <strong>{i}</strong>
                        <span className='col-span-3'>
                            {j}
                            {k ? <strong> {k}</strong> : null}
                        </span>
                    </Fragment>
                ))}
            </div>
        </section>
    );
};

const DetailTargetDomainGeneral: React.FC<{
    domain: string;
    esti: Ether.CaseManager.Esti;
}> = ({ domain, esti }) => {
    const [localization] = useLocalization();

    const data = [['Domain', domain]];

    if (esti.parsed_data?.whois?.status === 'done') {
        const whoisData = esti.parsed_data.whois.data;
        if (whoisData.domain_registrar?.registrar_name)
            data.push(['Registrar', whoisData.domain_registrar.registrar_name]);
        else if (whoisData.registrar)
            data.push(['Registrar', whoisData.registrar]);
    }

    if (esti.parsed_data?.liveness?.status === 'done') {
        const liveData = esti.parsed_data.liveness.data;
        if (liveData.resolved_url)
            data.push(['Resolved URL', liveData.resolved_url]);
        if (liveData.is_active != null)
            data.push(['Currently active?', liveData.is_active ? 'Yes' : 'No']);
        if (liveData.last_check_at)
            data.push([
                'Last active check',
                liveData.last_check_at.toISOString(),
            ]);
    }

    return (
        <section>
            <h2>
                {
                    localization.components.models.target.views
                        .enrichmentDataDisplay.titleGeneral
                }
            </h2>
            <div className='flex flex-col gap-4'>
                {data.map(([i, j], index) => (
                    <GroupDataVertical
                        key={(i ?? '' + j ?? '') + index}
                        aboveText={i as string}
                        belowText={j as string}
                    />
                ))}
            </div>
        </section>
    );
};

// const DetailTargetIPHistory = () => {
//     const [localization] = useLocalization();

//     const fakeData = [
//         ['bucanerozone.com', 'Início: 20/12/2023 - Término: 23/12/2023'],
//         ['roguestreamer.io', 'Início: 30/11/2023 - Término: 03/12/2023'],
//         ['plunderbay.org', 'Início: 05/09/2023 - Término: 08/09/2023'],
//         ['piratemingle.net', 'Início: 10/06/2023 - Término: 13/06/2023'],
//         ['bootlegflix.co', 'Início: 15/03/2023 - Término: 18/03/2023'],
//         ['counterfeitvault.com', 'Início: 10/11/2022 - Término: 13/11/2022'],
//         ['swashbucklehub.net', 'Início: 15/08/2022 - Término: 18/08/2022'],
//         ['privateerstreams.org', 'Início: 20/05/2022 - Término: 23/05/2022'],
//         ['freebooterflix.co', 'Início: 25/02/2022 - Término: 28/02/2022'],
//         ['piratetreasurebay.io', 'Início: 05/02/2022 - Término: 08/02/2022'],
//     ];

//     return (
//         <section>
//             <h2>{localization.components.models.target.views.enrichmentDataDisplay.titleHistory}</h2>
//             <div className='flex flex-col gap-4'>
//                 {fakeData.map(([i, j]) => (
//                     <GroupDataVertical
//                         aboveText={i as string}
//                         belowText={j as string}
//                     />
//                 ))}
//             </div>
//         </section>
//     );
// };

const DetailTargetIPDetails: React.FC<{
    esti: Ether.CaseManager.Esti;
}> = ({ esti }) => {
    const [localization] = useLocalization();

    const finalInfo: {
        asnupstreams:
            | null
            | {
                  code: number;
                  name: string;
              }[];
        subnethost:
            | null
            | {
                  ip: string;
                  count: number;
                  example: string;
              }[];
        blocking: null | {
            dnsbl: boolean | undefined;
            adulthosting: boolean | undefined;
            mthreats: boolean | undefined;
            openproxy: boolean | undefined;
        };
    } = {
        subnethost: null,
        asnupstreams: null,
        blocking: null,
    };

    if (esti.parsed_data?.upstreams?.status === 'done') {
        finalInfo.asnupstreams =
            esti.parsed_data.upstreams.data.map((a) => ({
                code: a.asn_code,
                name: a.asn_name,
            })) ?? null;
    }
    if (esti.parsed_data?.overview?.status === 'done') {
        finalInfo.subnethost =
            esti.parsed_data.overview.data.subnethosting?.ips?.map((a) => ({
                count: a.count,
                example: a.example,
                ip: a.ip,
            })) ?? null;
    }
    if (esti.parsed_data?.block?.status === 'done') {
        const data = esti.parsed_data.block.data;
        finalInfo.blocking = {
            adulthosting: data.adulthosting,
            dnsbl: data.dnsbl,
            mthreats: data.mthreats,
            openproxy: data.openproxy,
        };
        if (Object.entries(finalInfo.blocking).every((t) => t == null))
            finalInfo.blocking = null;
    }

    if (Object.values(finalInfo).every((a) => !a)) return <DisplayError />;

    return (
        <section>
            <h2>
                {
                    localization.components.models.target.views
                        .enrichmentDataDisplay.titleDetails
                }
            </h2>
            <div>
                {finalInfo.asnupstreams && (
                    <>
                        <h3>ASN Upstreams</h3>
                        <div className='flex flex-col gap-1'>
                            {finalInfo.asnupstreams.map((e) => (
                                <span>
                                    <strong>{e.name}</strong> - {e.code}
                                </span>
                            ))}
                        </div>
                    </>
                )}
                {finalInfo.subnethost && (
                    <>
                        <h3>Subnet hosts</h3>
                        <div className='flex flex-col gap-4'>
                            {finalInfo.subnethost.map((e) => (
                                <span>
                                    <strong>{e.ip}</strong> - Found on {e.count}{' '}
                                    domains (e.g. {e.example}){' '}
                                </span>
                            ))}
                        </div>
                    </>
                )}
                {finalInfo.blocking && (
                    <>
                        <h3>Blocking</h3>
                        <div className='flex flex-col'>
                            <span>
                                Is DNS blocked:{' '}
                                <strong>
                                    {finalInfo.blocking.dnsbl != null
                                        ? finalInfo.blocking.dnsbl
                                            ? 'Yes'
                                            : 'No'
                                        : 'Unknown'}
                                </strong>
                            </span>
                            <span>
                                Is adult host:{' '}
                                <strong>
                                    {finalInfo.blocking.adulthosting != null
                                        ? finalInfo.blocking.adulthosting
                                            ? 'Yes'
                                            : 'No'
                                        : 'Unknown'}
                                </strong>
                            </span>
                            <span>
                                Is malicious DNS:{' '}
                                <strong>
                                    {finalInfo.blocking.mthreats != null
                                        ? finalInfo.blocking.mthreats
                                            ? 'Yes'
                                            : 'No'
                                        : 'Unknown'}
                                </strong>
                            </span>
                            <span>
                                Is open proxy:{' '}
                                <strong>
                                    {finalInfo.blocking.openproxy != null
                                        ? finalInfo.blocking.openproxy
                                            ? 'Yes'
                                            : 'No'
                                        : 'Unknown'}
                                </strong>
                            </span>
                        </div>
                    </>
                )}
            </div>
        </section>
    );
};

const DetailTargetIPDomains: React.FC<{
    esti: Ether.CaseManager.Esti;
}> = ({ esti }) => {
    const [localization] = useLocalization();

    const domainsList = getDomainsFromTargetEnrich(esti);

    const { filters, setFilters, pageOptions, setPageOptions } =
        useInitDataTableState({
            filters: {
                _cm_name_id: getFilterData(),
            },
        });

    // const data = [
    //     ...[...domainsListData.map((target) => ['Domain', target])],
    //     ...[...(overviewData.mxrecords ?? []).map((target) => ['MX', target])],
    //     ...[...(overviewData.nsrecords ?? []).map((target) => ['NS', target])],
    // ];
    if (!domainsList || domainsList.length <= 0) return <DisplayError />;

    const data = domainsList.map((target) => ({
        type: 'Domain',
        value: target,
    }));

    const filteredData = data.filter(({ value: domain }) => {
        if (!filters['_cm_name_id']) return true;
        const filterData = filters['_cm_name_id'];
        const option =
            'value' in filterData ? filterData : filterData.constraints[0];
        if (!option) return true;
        const { value, matchMode } = option;
        if (!value) return true;
        switch (matchMode) {
            case 'startsWith':
                return !!domain.match(getMatchModeRegex(value, matchMode));
            case 'contains':
                return !!domain.match(getMatchModeRegex(value, matchMode));
            case 'equals':
                return value.toLocaleLowerCase() === domain.toLocaleLowerCase();
            case 'endsWith':
                return !!domain.match(getMatchModeRegex(value, matchMode));
            case 'notContains':
                return !!domain.match(getMatchModeRegex(value, matchMode));
            case 'notEquals':
                return value.toLocaleLowerCase() !== domain.toLocaleLowerCase();
            default:
                return true;
        }
    });

    const startIndex = (pageOptions.page - 1) * pageOptions.rows;
    const endIndex = startIndex + pageOptions.rows;
    const trimmedPageData = filteredData.slice(startIndex, endIndex);

    return (
        <section>
            <h2>
                {
                    localization.components.models.target.views
                        .enrichmentDataDisplay.titleDomains
                }
            </h2>
            <span>
                {localization.components.common.datatable.totalAmount.replace(
                    '{count}',
                    filteredData.length.toString()
                )}
            </span>
            <FilterBox
                filters={filters}
                setFilters={setFilters}
                filterOptions={{
                    _cm_name_id: {
                        type: 'string',
                    },
                }}
                hideBorder
            />
            <CMPaginator
                page={pageOptions.page}
                rows={pageOptions.rows}
                onPageChange={(e) => setPageOptions(e)}
                rowsPerPageOptions={[10, 20, 30, 40, 50]}
                maxPages={Math.ceil(filteredData.length / pageOptions.rows)}
            />
            {localization.components.common.datatable.showingAmount.replace(
                '{count}',
                trimmedPageData.length.toString()
            )}
            <DataTable value={trimmedPageData}>
                <Column field='value' />
            </DataTable>
        </section>
    );
};

const DetailTargetIPGeneral: React.FC<{
    esti: Ether.CaseManager.Esti;
}> = ({ esti }) => {
    const [localization] = useLocalization();

    const info = esti.parsed_data;

    const data: [string, string][] = [];

    if (info?.asn?.status === 'done') {
        const asnData = info.asn.data;
        if (asnData.code || asnData.name) {
            const { code, name } = asnData;
            let str =
                code && name
                    ? `${name} (${code})`
                    : name
                    ? name
                    : code?.toString();
            if (str) data.push(['ASN', str]);
        }
    }

    if (info?.geo?.status === 'done') {
        const geoData = info.geo.data;
        if (geoData.continent?.code || geoData.continent?.name) {
            const { code, name } = geoData.continent;
            let str = code && name ? `${name} (${code})` : name ? name : code;
            if (str) data.push(['Continent', str]);
        }
        if (geoData.country?.code || geoData.country?.name) {
            const { code, name } = geoData.country;
            let str = code && name ? `${name} (${code})` : name ? name : code;
            if (str) data.push(['Country', str]);
        }
        if (geoData.state?.code || geoData.state?.name) {
            const { code, name } = geoData.state;
            let str = code && name ? `${name} (${code})` : name ? name : code;
            if (str) data.push(['State', str]);
        }
        if (geoData.city?.code || geoData.city?.name) {
            const { code, name } = geoData.city;
            let str = code && name ? `${name} (${code})` : name ? name : code;
            if (str) data.push(['City', str]);
        }
        if (geoData.location?.latitude && geoData.location?.latitude)
            data.push([
                'Location',
                `Latitude: ${geoData.location.latitude}, Longitutde: ${geoData.location.longitude}`,
            ]);
    }

    if (info?.open_ports?.status === 'done') {
        const openPortsData = info.open_ports.data;
        if (openPortsData.ports)
            data.push(['Open ports', openPortsData.ports.join(', ')]);
    }

    if (data.length <= 0) return <DisplayError />;

    return (
        <section>
            <h2>
                {
                    localization.components.models.target.views
                        .enrichmentDataDisplay.titleGeneral
                }
            </h2>
            <div className='flex flex-col gap-4'>
                {data.map(([i, j]) => (
                    <GroupDataVertical aboveText={i} belowText={j} />
                ))}
            </div>
        </section>
    );
};

const DetailTargetIP: React.FC<{
    esti: Ether.CaseManager.Esti;
}> = ({ esti }) => {
    const [localization] = useLocalization();
    const [activeIndex, setActiveIndex] = useState(0);

    const thisLocalization =
        localization.components.models.target.views.enrichmentDataDisplay;

    const tabOptions: MenuItem[] = [
        {
            label: thisLocalization.tabGeneral,
        },
        {
            label: thisLocalization.tabDomains,
        },
        {
            label: thisLocalization.tabDetails,
        },
        // {
        //     label: thisLocalization.tabHistory,
        // },
    ];

    return (
        <div>
            <TabMenu
                model={tabOptions}
                activeIndex={activeIndex}
                onTabChange={(e) => setActiveIndex(e.index)}
            />
            {activeIndex === 0 && <DetailTargetIPGeneral esti={esti} />}
            {activeIndex === 1 && <DetailTargetIPDomains esti={esti} />}
            {activeIndex === 2 && <DetailTargetIPDetails esti={esti} />}
            {activeIndex === 3 && <DetailTargetDomainHistory esti={esti} />}
        </div>
    );
};

const DetailTargetDomain: React.FC<{
    domain: string;
    esti: Ether.CaseManager.Esti;
}> = ({ domain, esti }) => {
    const [localization] = useLocalization();
    const [activeIndex, setActiveIndex] = useState(0);

    const thisLocalization =
        localization.components.models.target.views.enrichmentDataDisplay;

    const tabOptions: MenuItem[] = [
        {
            label: thisLocalization.tabGeneral,
        },
        {
            label: thisLocalization.tabDNS,
        },
        {
            label: thisLocalization.tabWhois,
        },
        {
            label: thisLocalization.tabHistory,
        },
    ];

    return (
        <div>
            <TabMenu
                model={tabOptions}
                activeIndex={activeIndex}
                onTabChange={(e) => setActiveIndex(e.index)}
            />
            {activeIndex === 0 && (
                <DetailTargetDomainGeneral domain={domain} esti={esti} />
            )}
            {activeIndex === 1 && <DetailTargetDomainDNS esti={esti} />}
            {activeIndex === 2 && <DetailTargetDomainWhois esti={esti} />}
            {activeIndex === 3 && <DetailTargetDomainHistory esti={esti} />}
        </div>
    );
};

const DetailTargetModal: React.FC<DetailTargetModalProps> = ({
    target,
    ...props
}) => {
    const [localization] = useLocalization();
    const [viewAll, setViewAll] = useState<any>(null);

    const estiQuery = useQuery({
        queryKey: ['target', 'esti', target?.value],
        queryFn: () =>
            getOneESTI({
                target_value: target?.value ?? '',
            }),
        enabled: !!target,
    });

    let element = (
        <LoadingMessage>{localization.common.loading}</LoadingMessage>
    );

    if (target && estiQuery.data) {
        const status = getESTICurrentStatus({
            target,
        });
        element = (
            <>
                <div className='flex mb-4'>
                    <CellGroup
                        element1={
                            <span>{localization.fields.target.created}:</span>
                        }
                        element2={
                            target.created_at ? (
                                <DateBadge value={target.created_at} />
                            ) : (
                                <span>-</span>
                            )
                        }
                    />
                </div>
                <Button
                    icon='pi pi-search'
                    label={
                        localization.components.models.target.views
                            .enrichmentDataDisplay.viewEnrich
                    }
                    onClick={() => setViewAll(estiQuery.data)}
                    style={{
                        marginBottom: '8px',
                    }}
                />
                {status === 'error' && <DisplayError />}
                {status === 'pending' && <DisplayPending />}
                {status === 'done' &&
                    (target.type === 'ipv4' || target.type === 'ipv6' ? (
                        <DetailTargetIP esti={estiQuery.data} />
                    ) : (
                        <DetailTargetDomain
                            esti={estiQuery.data}
                            domain={target.value}
                        />
                    ))}
            </>
        );
    } else if (target && !estiQuery.isLoading) {
        element = <DisplayError />;
    }

    return (
        <Dialog header={target?.value} {...props}>
            <ObjectDisplayModal
                header={
                    localization.components.models.target.views
                        .enrichmentDataDisplay.enrichDetails
                }
                visible={viewAll}
                onHide={() => setViewAll(false)}
                displayData={estiQuery.data?.parsed_data}
            />
            {element}
        </Dialog>
    );
};

export default DetailTargetModal;
