import { useMutation } from '@tanstack/react-query';
import { useLocalization } from 'hooks/context/useLocalization';
import { useToast } from 'hooks/context/useToast';
import { updateTargetOrderStatus } from 'services/ether/case-manager/targets';
import { UpdateTargetOrderStatusEP } from 'services/ether/case-manager/targets/types';
import { getErrorToast } from 'utils/errorHandler';
import useQueryRefresh from 'hooks/queries/useQueryRefresh';

const useUpdateTargetOrderStatus = ({
    onUpdate,
}: {
    onUpdate?: (result: UpdateTargetOrderStatusEP.Result) => void;
}): {
    updateTarget: (options: UpdateTargetOrderStatusEP.Data) => void;
    isLoading: boolean;
    error: Error | null;
} => {
    const [localization] = useLocalization();
    const { hardRefresh, softRefresh } = useQueryRefresh();
    const toast = useToast();

    const epLocale = localization.endpoints.target.update_target_order_status;

    const { mutate, isPending, error } = useMutation<
        UpdateTargetOrderStatusEP.Result,
        Error,
        UpdateTargetOrderStatusEP.Data
    >({
        mutationFn: (data) => updateTargetOrderStatus(data),
        onSuccess: (data) => {
            toast.show({
                summary:
                    data.status === 'add'
                        ? epLocale.successAdd
                        : epLocale.successRemove,
                severity: 'success',
                life: 5000,
            });
            hardRefresh(['target']);
            softRefresh(['metrics', 'block-order', 'unblock-order']);
            if (onUpdate) onUpdate(data);
        },
        onError: (err) => toast.show(getErrorToast(err.message, localization)),
    });

    return {
        updateTarget: (data) => mutate(data),
        error: error,
        isLoading: isPending,
    };
};

export default useUpdateTargetOrderStatus;
