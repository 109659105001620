import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';
import LocalizedStringsFactory from 'localized-strings';
import { useSuspenseQuery } from '@tanstack/react-query';
import _ from 'lodash';
import {
    LocaleOptions,
    addLocale,
    locale,
    localeOptions,
} from 'primereact/api';
import CacheControl from 'controller/cache/cacheController';
import LocalizedStrings from 'localized-strings';
import { CMLocalization } from 'static/language';

type LocalizedStr = CMLocalization.Localization & {
    formatString: (str: string, ...values: string[]) => string;
};
type LocalizedStringWithFormat = typeof LocalizedStrings &
    CMLocalization.Localization & {
        formatString<T extends number | string>(
            str: string,
            ...values: Array<T | { [key: string]: T }>
        ): string;
    };

const usePseudo = process.env.REACT_APP_LOCALIZATION_PSEUDO === 'true';

const LocalizationContext = createContext<
    | [
          LocalizedStr,
          {
              currentLanguage: CMLocalization.ValidLanguages;
              getLocalizationFromProject: (
                  project: Ether.CaseManager.Project
              ) => CMLocalization.Localization;
              switchLanguage: (language: CMLocalization.ValidLanguages) => void;
              mergeLocale: (data: CMLocalization.LocalizationObject) => void;
              isMerged: boolean;
              getPrimereactLocale: () => LocaleOptions;
          }
      ]
    | null
>(null);

const overrideFlexion = (
    localized: LocalizedStringWithFormat,
    obj: any,
    level: string[]
) => {
    Object.keys(obj).forEach((key) => {
        if (key.startsWith('_')) return;
        if (!obj.hasOwnProperty(key)) return;
        let strValue = obj[key];
        if (typeof strValue === 'string') {
            if (strValue.includes('$flex')) {
                const stringToReplace = strValue
                    .split('$flex{')[1]
                    ?.split('}')[0];
                obj[key] =
                    localized.flexion[stringToReplace as 'female' | 'male'];
            }
        } else if (typeof obj[key] === 'object')
            overrideFlexion(localized, obj[key], [...level, key]);
    });
};

const overrideProperties = (
    localized: LocalizedStringWithFormat,
    obj: any,
    level: string[]
) => {
    Object.keys(obj).forEach((key) => {
        if (key === 'flexion') return;
        if (key.startsWith('_')) return;
        if (!obj.hasOwnProperty(key)) return;
        let strValue = obj[key];
        if (typeof strValue === 'string') {
            if (strValue.includes('$ref')) {
                // Might include other formats that might override
                const stringsToReplace: string[] = [];
                strValue.split('$ref{').forEach((str, index) => {
                    if (index === 0) return;
                    const subStr = str.split('}')[0] as string;
                    stringsToReplace.push(subStr);
                });
                stringsToReplace.forEach(
                    (str) =>
                        (strValue = strValue.replace(
                            `$ref{${str}}`,
                            localized.formatString(str)
                        ))
                );
                obj[key] = strValue;
            }
            if (typeof obj[key] === 'string') obj[key] = _.upperFirst(strValue);
        } else if (typeof obj[key] === 'object')
            overrideProperties(localized, obj[key], [...level, key]);
    });
};

const createLocalization = (
    language: string,
    data: any
): LocalizedStringWithFormat => {
    // This is needed because this lib doesn't protect the original data
    // We make a copy of it so it can be freely edited
    data = _.merge({}, data);

    const localizedStrings =
        new LocalizedStringsFactory<CMLocalization.Localization>(
            { [language]: data },
            {
                pseudo: usePseudo,
            }
        ) as any as LocalizedStringWithFormat;

    overrideFlexion(localizedStrings, localizedStrings, []);
    overrideProperties(localizedStrings, localizedStrings, []);

    return localizedStrings;
};

const browserLocale = !['en-US', 'pt-BR'].find((l) => l === navigator.language)
    ? 'en-US'
    : (navigator.language as CMLocalization.ValidLanguages);

const useLocaleQuery = ({
    language,
}: {
    language: CMLocalization.ValidLanguages;
}) => {
    const locale = language.split('-')[0] ?? '';
    const query = useSuspenseQuery<CMLocalization.Localization>({
        queryKey: ['locale', locale],
        queryFn: () =>
            new Promise((resolve) =>
                import(`static/locale/${language}`).then((mod) => {
                    const localeData = JSON.parse(JSON.stringify(mod))
                        .default as CMLocalization.Localization;
                    import(`primelocale/${locale}.json`).then(
                        (primeLocaleData) => {
                            const primeLocaleJson = JSON.parse(
                                JSON.stringify(primeLocaleData)
                            )[locale];
                            const mergedPrimeReact = _.merge(
                                primeLocaleJson,
                                localeData.primereact
                            );
                            addLocale(language, mergedPrimeReact);
                            resolve(localeData);
                        }
                    );
                })
            ),
        gcTime: Infinity,
    });

    return query.data;
};

export const localeMap: {
    [k in CMLocalization.ValidLanguages]: CMLocalization.ValidLocales;
} = {
    'pt-BR': 'pt',
    'en-US': 'en',
};

const LocalizationProvider: React.FC<React.PropsWithChildren> = ({
    children,
}) => {
    const [isMerged, setIsMerged] = useState(false);
    const [currentLocale, setCurrentLocale] =
        useState<CMLocalization.ValidLanguages>(
            CacheControl.SavedLanguage.get() ?? browserLocale
        );
    const language = localeMap[currentLocale];

    const activeLocale = useLocaleQuery({
        language: currentLocale,
    });

    const handleLocalizedStrings = useCallback(
        () => createLocalization(language, activeLocale),
        [language, activeLocale]
    );

    const [localizedStrings, setLocalizedStrings] = useState(
        handleLocalizedStrings
    );

    useEffect(() => {
        setLocalizedStrings(handleLocalizedStrings);
        setIsMerged(false);
    }, [currentLocale, handleLocalizedStrings, setLocalizedStrings]);

    const getLocalizationFromProject = (
        project: Ether.CaseManager.Project
    ): CMLocalization.Localization => {
        if (!activeLocale) throw new Error('activeLocale not loaded');
        if (!project?.config?.terminology) return localizedStrings;
        const projectConfig = project.config.terminology;
        let projectLocalization = projectConfig.localization;
        if (!projectLocalization) return activeLocale;
        const localization = createLocalization(
            language,
            _.merge({}, activeLocale ?? {}, projectLocalization[language])
        );
        return localization;
    };

    const switchLanguage = (language: CMLocalization.ValidLanguages) => {
        CacheControl.SavedLanguage.save(language);
        setCurrentLocale(language);
    };

    const getPrimereactLocale = () => {
        const locale = localeOptions(currentLocale);
        return locale as LocaleOptions;
    };

    useEffect(() => {
        locale(currentLocale);
    }, [currentLocale]);

    const mergeLocale = (data: CMLocalization.LocalizationObject) => {
        if (!activeLocale) return;
        const merged = _.merge({}, activeLocale, data[language]);
        setLocalizedStrings(createLocalization(language, merged));
        setIsMerged(true);
    };

    return (
        <LocalizationContext.Provider
            value={[
                localizedStrings,
                {
                    currentLanguage: currentLocale,
                    mergeLocale,
                    isMerged,
                    getLocalizationFromProject,
                    switchLanguage,
                    getPrimereactLocale,
                },
            ]}
        >
            {children}
        </LocalizationContext.Provider>
    );
};

const useLocalization = () => {
    const context = useContext(LocalizationContext);
    if (!context)
        throw new Error(
            'useLocalization must be used inside a LocalizationContext.Provider'
        );
    return context;
};

export { useLocalization, LocalizationProvider };
