import CMFileUpload from 'components/form/CMFileUpload';
import useUpsertDocument from 'hooks/mutations/document/useUpsertDocument';
import { useLocalization } from 'hooks/context/useLocalization';
import { useProjectMaybe } from 'hooks/context/project/useProject';
import { Button } from 'primereact/button';
import { Dialog, DialogProps } from 'primereact/dialog';
import { useState } from 'react';
import { UpsertDocumentEP } from 'services/ether/case-manager/documents/types';
import { Checkbox } from 'components/ethercity-primereact';

type FormData = {
    officialDocFile: File | null;
};

type ButtonGroupProps = {
    nextLabel?: string;
    backLabel?: string;
    onNext?: () => void;
    onBack?: () => void;
    disableNext?: boolean;
    disableBack?: boolean;
    loadingNext?: boolean;
};

const ButtonGroup: React.FC<ButtonGroupProps> = ({
    nextLabel,
    onNext,
    disableNext,
    loadingNext,
    backLabel,
    onBack,
    disableBack,
}) => {
    const [localization] = useLocalization();
    nextLabel = nextLabel ?? localization.components.common.button.next;
    backLabel = backLabel ?? localization.components.common.button.back;
    return (
        <div className='flex flex-row gap-2 justify-center items-center'>
            {onBack && (
                <Button
                    className='w-52'
                    label={backLabel}
                    onClick={onBack}
                    disabled={disableBack}
                />
            )}
            {onNext && (
                <Button
                    className='w-52'
                    outlined
                    label={nextLabel}
                    onClick={onNext}
                    disabled={disableNext}
                    loading={loadingNext}
                />
            )}
        </div>
    );
};

const InsertOfficialDocumentSimple: React.FC<
    {
        authorization: Ether.CaseManager.Authorization;
        tokenData?: CaseManagerApp.TokenInfo;
    } & DialogProps
> = ({ authorization, tokenData, ...props }) => {
    const projectContext = useProjectMaybe();
    const project = tokenData?.project ?? projectContext;
    if (!project) throw new Error('missing project');
    const [localization] = useLocalization();

    const signLocale = localization.components.models.oficio.views.sign;

    const [formData, setFormData] = useState<FormData>({
        officialDocFile: null,
    });
    const [checked, setChecked] = useState(false);

    const { insertDocuments, uploadStatus } = useUpsertDocument({
        showSuccess: true,
        onUpload: props.onHide,
    });

    const handleSubmit = () => {
        if (!formData.officialDocFile) return;
        const uploadData: UpsertDocumentEP.UpsertData = {
            project_id: project._id,
            authorization_id: authorization._id,
            authorization_config_id: authorization.authorization_config_id,
            category: 'official_document',
            status: 'using',
            files: [
                {
                    file: formData.officialDocFile,
                    custom_identifier: '',
                },
            ],
        };
        insertDocuments(uploadData);
    };

    const sendDisabled =
        uploadStatus === 'pending' || !formData.officialDocFile || !checked;

    return (
        <Dialog
            header={signLocale.title}
            style={{
                minWidth: '50vw',
            }}
            {...props}
        >
            <section className='flex flex-col gap-2 items-center'>
                <h3>{signLocale.warnCannotUndo}</h3>
                <CMFileUpload
                    customUpload
                    value={
                        formData.officialDocFile && {
                            _id: 'file',
                            status: 'done',
                            custom_identifier: 'file',
                            meta: {
                                name: formData.officialDocFile.name,
                                size: formData.officialDocFile.size,
                            },
                        }
                    }
                    removeFile={() =>
                        setFormData((old) => ({
                            ...old,
                            officialDocFile: null,
                        }))
                    }
                    hideUploadButton={!!formData.officialDocFile}
                    onSelect={(e) => {
                        const file = e.files[0];
                        if (!file) return;
                        setFormData((old) => ({
                            ...old,
                            officialDocFile: file,
                        }));
                    }}
                    multiple={false}
                />
                <div className='mt-4'>{signLocale.reminder}</div>
                <div className='flex flex-row gap-2 justify-start mt-4'>
                    <Checkbox
                        checked={checked}
                        onChange={() => setChecked((old) => !old)}
                    />
                    <span>{signLocale.confirmAction}</span>
                </div>
                <ButtonGroup
                    onBack={props.onHide}
                    backLabel={localization.components.common.button.cancel}
                    onNext={() => handleSubmit()}
                    nextLabel={localization.components.common.button.sign}
                    disableNext={sendDisabled}
                />
            </section>
        </Dialog>
    );
};

export default InsertOfficialDocumentSimple;
