import { useLocalization } from 'hooks/context/useLocalization';

const DisplayError = () => {
    const [localization] = useLocalization();
    return (
        <div className='flex flex-row gap-1 text-red-400'>
            <i className='pi pi-ban' />
            <span>
                {localization.components.models.target.badge.enrich.error}
            </span>
        </div>
    );
};

export default DisplayError;
