type BaseUnblockOrderCheck = {
    unblockOrder: Ether.CaseManager.UnblockOrder.Detailed | undefined;
    userId: string | undefined | null;
};

const baseUnblockOrderCheck = ({ unblockOrder, userId }: BaseUnblockOrderCheck) => {
    if (!unblockOrder || !userId) return false;

    const { operators_order_data } = unblockOrder;

    const canRespond = !!operators_order_data?.find(
        (oo) =>
            oo.response == null &&
            (oo.operator_id === userId ||
                !!oo.operators_data?.find((o) =>
                    o.users_data?.find((u) => u._id === userId)
                ))
    );

    return canRespond;
};

export const isUnblockOrderRespondable = ({
    permissions,
    unblockOrder,
    userId,
}: {
    permissions: CaseManagerApp.Permissions | undefined;
} & BaseUnblockOrderCheck) => {
    if (!permissions || !permissions.respondUnblockOrder) return false;
    return baseUnblockOrderCheck({
        unblockOrder,
        userId,
    });
};

export const isTokenUnblockOrderRespondable = ({
    unblockOrder,
    userId,
}: BaseUnblockOrderCheck) => {
    return baseUnblockOrderCheck({
        unblockOrder,
        userId,
    });
};