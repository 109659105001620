import { useLocalization } from 'hooks/context/useLocalization';
import { EvidenceMetadataFormProps } from './types';
import { Datepicker, InputText } from 'components/ethercity-primereact';

const EvidenceMetadataForm: React.FC<EvidenceMetadataFormProps> = ({
    authorizationConfig,
    formData,
    setFormData,
}) => {
    const [localization] = useLocalization();

    const form = authorizationConfig.evidences_metadata_form;

    if (!form) return null;

    return (
        <div className='flex flex-col gap-2'>
            {form.map((formItem) => {
                const currentLanguage = localization.getLanguage();
                const label = localization.getString(
                    formItem.localeKey,
                    currentLanguage
                );
                const defaultProps = {
                    key: formItem.key,
                    required: formItem.required,
                    label: label,
                };
                switch (formItem.type) {
                    case 'text':
                        return (
                            <InputText
                                {...defaultProps}
                                value={formData[formItem.key] as string}
                                onChange={(e) =>
                                    setFormData((old) => ({
                                        ...old,
                                        [formItem.key]: e.target.value,
                                    }))
                                }
                            />
                        );
                    case 'date':
                        return (
                            <Datepicker
                                {...defaultProps}
                                value={formData[formItem.key] as Date | null}
                                onChange={(e) =>
                                    setFormData((old) => ({
                                        ...old,
                                        [formItem.key]: e,
                                    }))
                                }
                            />
                        );
                    default:
                        return null;
                }
            })}
        </div>
    );
};

export default EvidenceMetadataForm;
