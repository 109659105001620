import { useCallback, useMemo, useState } from 'react';
import { useLocalization } from 'hooks/context/useLocalization';
import { AuthorizationStatsDialog } from '../authorization/useShowAuthorizationStats';
import useGetOperationStats from 'hooks/queries/operation/useGetOperationStats';

const OperationStatsDialog = AuthorizationStatsDialog;

const useShowOperationStats = () => {
    const [localization] = useLocalization();
    const [item, setItem] = useState<{
        id: string;
        name: string;
    } | null>(null);
    const { data: stats, isLoading } = useGetOperationStats(
        {
            operation_id: item?.id ?? '',
        },
        {
            enabled: !!item,
        }
    );

    const show = useCallback(
        (data: NonNullable<typeof item>) => setItem(data),
        []
    );
    const hide = () => setItem(null);

    const dialog = useMemo(
        () =>
            item && (
                <OperationStatsDialog
                    header={localization.components.models.operation.views.stats.header.replace(
                        '{name}',
                        item.name
                    )}
                    visible
                    isLoading={isLoading}
                    data={stats ?? null}
                    onHide={hide}
                />
            ),
        [stats, isLoading, item, localization]
    );

    return {
        dialog: dialog,
        show: show,
    };
};

export default useShowOperationStats;
