import { useLocalization } from 'hooks/context/useLocalization';
import { Badge, BadgeProps } from 'primereact/badge';

const AuthorizationStatusBadge: React.FC<{
    authorization: Ether.CaseManager.Authorization;
    badgeProps?: BadgeProps;
}> = ({ authorization, badgeProps }) => {
    const [localization] = useLocalization();
    const authorizationBadge = localization.components.models.authorization.badge.status;
    const status = authorization.status;
    badgeProps = {
        ...badgeProps,
        severity: 'info',
        value: status ? status.toUpperCase() : 'null',
    };
    switch (status) {
        case 'pending':
            badgeProps.severity = 'warning';
            badgeProps.value = authorizationBadge.pending.toLocaleUpperCase();
            break;
        case 'approved':
            badgeProps.severity = 'success';
            badgeProps.value = authorizationBadge.approved.toLocaleUpperCase();
            break;
        case 'error':
            badgeProps.severity = 'danger';
            badgeProps.value = authorizationBadge.error.toLocaleUpperCase();
            break;
        case 'rejected':
            badgeProps.severity = 'danger';
            badgeProps.value = authorizationBadge.rejected.toLocaleUpperCase();
            break;
        case 'draft':
            badgeProps.severity = undefined;
            badgeProps.value = authorizationBadge.draft.toLocaleUpperCase();
    }
    return (
        <Badge
            {...badgeProps}
            style={{
                ...badgeProps.style,
                whiteSpace: 'nowrap',
            }}
        />
    );
};

export default AuthorizationStatusBadge;
