import CMFileUpload from 'components/form/CMFileUpload';
import TargetGroup from 'components/models/Targets/TargetGroup';
import { useLocalization } from 'hooks/context/useLocalization';
import useTargetParseZip from 'hooks/mutations/target/useTargetParseZip';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { ProgressBar } from 'primereact/progressbar';
import { useState } from 'react';
import {
    ParseTargetFormatted,
    ParseZipFileEP,
} from 'services/ether/case-manager/targets/types';

type Result = ParseZipFileEP.Result;
type Params = Omit<ParseZipFileEP.Data, 'commit' | 'file'>;
type GroupedTargets = Record<
    string,
    {
        targets: ParseTargetFormatted[];
        evidences: CaseManagerApp.LocalFile.Uploaded[];
    }
>;

const useTargetParseZipDialog = ({
    params,
    onCommit,
}: {
    params: Params;
    onCommit?: (result: Result) => void;
}): {
    openUploadDialog: () => void;
    uploadDialog: JSX.Element;
    reviewDialog: JSX.Element;
    isLoading: boolean;
    error: Error | null;
} => {
    const [localization] = useLocalization();
    const thisLocale = localization.components.models.target.views.targetParseZip;

    const [file, setFile] = useState<File | null>(null);
    const [uploadFileVisible, setUploadFileVisible] = useState(false);
    const [groupedData, setGroupedData] = useState<null | GroupedTargets>(null);
    const [progress, setProgress] = useState(0);

    const hideFileDialog = () => {
        setUploadFileVisible(false);
    };

    const hideConfirmDialog = () => {
        setGroupedData(null);
    };

    const { uploadZipFile, error, isLoading } = useTargetParseZip({
        onProgressUpdate: (v) => setProgress(v),
        onUpload: (data, params) => {
            if (params.commit) {
                if (onCommit) onCommit(data);
                setGroupedData(null);
                hideConfirmDialog();
            } else {
                const group = {} as GroupedTargets;

                data.formatted.forEach((t) => {
                    if (!t.group_index) return;
                    if (!(t.group_index in group))
                        group[t.group_index] = {
                            evidences:
                                t.evidence_files?.map((e) => ({
                                    _id: '',
                                    custom_identifier: '',
                                    meta: {
                                        name: e,
                                        size: 0,
                                    },
                                    status: 'done',
                                })) ?? [],
                            targets: [],
                        };
                    const items = group[t.group_index];
                    if (!items) return;
                    items.targets.push(t);
                });
                setGroupedData(group);
                hideFileDialog();
            }
            setProgress(0);
        },
    });

    const progressBar = isLoading && (
        <>
            <ProgressBar value={progress} />
            <br />
        </>
    );

    const uploadDialog = (
        <Dialog
            header={thisLocale.uploadDialog.title}
            visible={uploadFileVisible}
            onHide={() => setUploadFileVisible(false)}
            footer={
                <div>
                    {progressBar}
                    <div className='flex flex-row gap-2'>
                        <Button
                            label={localization.components.common.button.send}
                            onClick={() => {
                                if (!file) return;
                                uploadZipFile({
                                    ...params,
                                    commit: false,
                                    file: file,
                                    showToast: false,
                                });
                            }}
                            severity='success'
                            loading={isLoading}
                            disabled={!file || isLoading}
                        />
                        <Button
                            label={localization.components.common.button.cancel}
                            onClick={hideFileDialog}
                            disabled={isLoading}
                        />
                    </div>
                </div>
            }
        >
            <p>{thisLocale.uploadDialog.description}</p>
            <CMFileUpload
                value={file}
                removeFile={() => setFile(null)}
                customUpload
                onFileSelection={(e) => {
                    const file = e.files[0];
                    setFile(!file ? null : file);
                }}
            />
        </Dialog>
    );

    const generateTargetGroupsTab = () => {
        if (!groupedData) return;
        const targetGroups = Object.values(groupedData);
        return targetGroups.map((targetGroup, groupIndex) => {
            const header = `${localization.models.targetsGroup.singular} ${
                groupIndex + 1
            }`;
            return (
                <AccordionTab
                    key={groupIndex}
                    header={
                        <div className='flex flex-row items-center w-full'>
                            <span>{header}</span>
                        </div>
                    }
                >
                    <TargetGroup
                        evidences={targetGroup.evidences}
                        targets={targetGroup.targets}
                        authorizationConfigId={params.authorization_config_id}
                        hideUpload
                    />
                </AccordionTab>
            );
        });
    };

    const reviewDialog = (
        <Dialog
            header={thisLocale.reviewDialog.title}
            visible={!!groupedData}
            onHide={hideConfirmDialog}
            className='w-8/12'
            footer={
                <div>
                    {progressBar}
                    <div className='flex flex-row gap-2'>
                        <Button
                            label={localization.components.common.button.confirm}
                            onClick={() => {
                                if (!file) return;
                                uploadZipFile({
                                    ...params,
                                    commit: true,
                                    file: file,
                                    showToast: true,
                                });
                            }}
                            severity='success'
                            loading={isLoading}
                            disabled={isLoading}
                        />
                        <Button
                            label={localization.components.common.button.cancel}
                            onClick={hideConfirmDialog}
                            disabled={isLoading}
                        />
                    </div>
                </div>
            }
        >
            <p>{thisLocale.reviewDialog.description}</p>
            <Accordion
                multiple
                activeIndex={Object.keys(groupedData ?? {}).map((_, i) => i)}
            >
                {generateTargetGroupsTab()}
            </Accordion>
        </Dialog>
    );

    const openUploadDialog = () => {
        setUploadFileVisible(true);
        setFile(null);
    };

    return {
        openUploadDialog,
        reviewDialog,
        uploadDialog,
        error: error,
        isLoading: isLoading,
    };
};

export default useTargetParseZipDialog;
