import GoBackButton from 'components/misc/GoBackButton';
import InsertMonitorDialog from 'components/models/Monitor/InsertMonitorDialog';
import MonitoringDataTable from 'components/models/Monitor/MonitoringDataTable';
import CMBreadCrumb from 'components/page/CMBreadCrumb';
import useListMonitoringQuery from 'hooks/queries/monitoring/useListMonitoringQuery';
import { useAuth } from 'hooks/context/useAuth';
import useInitDataTableState from 'hooks/helpers/useInitDataTableState';
import { useLocalization } from 'hooks/context/useLocalization';
import { useProject } from 'hooks/context/project/useProject';
import { Button } from 'primereact/button';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFilterData } from 'utils/datatable';

const ListRealTimeMonitors = () => {
    const [localization] = useLocalization();
    const { permissions } = useAuth();
    const project = useProject();
    const navigate = useNavigate();

    const [isInsertModalVisible, setIsInsertModalVisible] = useState(false);

    const showInsertMonitorDialog = () => setIsInsertModalVisible(true);
    const hideInsertMonitorDialog = () => setIsInsertModalVisible(false);

    const afterInsert = (monitorId: string) => {
        navigate(monitorId);
    };

    const monitoringQuery = useListMonitoringQuery(project._id);

    const { filters, setFilters, pageOptions, setPageOptions } =
        useInitDataTableState({
            filters: {
                _cm_name_id: getFilterData(),
                network_url: getFilterData(),
                video_url: getFilterData(),
            },
        });

    return (
        <section>
            <InsertMonitorDialog
                visible={isInsertModalVisible}
                onHide={hideInsertMonitorDialog}
                afterInsert={afterInsert}
            />
            <CMBreadCrumb projectLabel={project.name} />
            <GoBackButton />
            <h2>
                {project.name} - {localization.models.monitoring.plural}
            </h2>
            <div className='flex flex-col justify-end'>
                {permissions.insertMonitors && (
                    <Button
                        className='ml-auto'
                        label={localization.components.models.monitoring.button.new}
                        icon='pi pi-plus'
                        onClick={showInsertMonitorDialog}
                    />
                )}
            </div>
            <MonitoringDataTable
                value={monitoringQuery.data}
                onFilter={(e) => setFilters(e.filters)}
                filters={filters}
                paginatorProps={{
                    page: pageOptions.page,
                    rows: pageOptions.rows,
                    onPageChange: (options) => setPageOptions(options),
                    disableNext:
                        (monitoringQuery.data?.length ?? 9999) <
                        pageOptions.rows,
                    onRefresh: monitoringQuery.refresh,
                }}
            />
        </section>
    );
};

export default ListRealTimeMonitors;
