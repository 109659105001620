import { useLocalization } from 'hooks/context/useLocalization';
import { useProject } from 'hooks/context/project/useProject';
import useDetailTag from 'hooks/queries/tag/useDetailTag';
import {  SelectOneItemProps } from 'components/form/SelectItems/types';
import { DetailTagEP } from 'services/ether/case-manager/tags/types';
import SelectOneItem from 'components/form/SelectItems/SelectOneItem';

const SelectOneTag: React.FC<
    SelectOneItemProps<Ether.CaseManager.Tag, DetailTagEP.Filters.Options>
> = (props) => {
    const [localization] = useLocalization();
    const project = useProject();

    const query = useDetailTag({
        project_id: project._id,
        options: { ...props.queryOptions, limit: 9999 },
    });

    return (
        <SelectOneItem
            title={localization.models.tag.plural}
            placeholder={
                localization.components.models.tag.form.selectManyPlaceholder
            }
            label={localization.models.tag.plural}
            items={query.data}
            itemsLoading={query.isFetching}
            {...props}
        />
    );
};

export default SelectOneTag;
