import {
    mapDevFilters,
    parseDataTableFilterMetaForAPI,
} from 'services/ether/utils';
import {
    UpsertOperationEP,
    DetailOperationEP,
    GetOperationStatsEP,
    UpdateOperationOrderEP,
    UpdateUserDefaultOperationEP,
} from './types';
import { getApiBase, listBase, postApiBase } from 'services/ether/base';
import _ from 'lodash';

export const _detailOperations = ({
    project_id,
    options,
    signal,
}: DetailOperationEP.Params.Restricted) => {
    const devFilters = options?.devFilters ?? {};
    const devKeys: DetailOperationEP.Filters.Map = {
        _id: ['_id', devFilters._id],
    };
    const mappedFilters = mapDevFilters(devKeys);
    const filters = _.merge(
        {
            project_id,
        },
        options?.rawFilters,
        mappedFilters
    );
    return listBase<Ether.CaseManager.Operation.Detailed[]>({
        endpoint: '/detail-operation',
        handleParams: parseDataTableFilterMetaForAPI,
        options: {
            ...options,
            filters,
        },
        signal,
    });
};

export const detailManyOperations = async ({
    project_id,
    options,
    signal,
}: DetailOperationEP.Params.Many) => {
    return _detailOperations({
        project_id,
        options,
        signal,
    });
};

export const upsertOperation = (data: UpsertOperationEP.Data) => {
    return postApiBase<UpsertOperationEP.Result>({
        endpoint: '/upsert-operation',
        data: data,
    });
};

export const updateUserDefaultOperation = (
    data: UpdateUserDefaultOperationEP.Data
) => {
    return postApiBase<UpdateUserDefaultOperationEP.Result>({
        endpoint: '/update-user-default-operation',
        data: data,
    });
};

export const updateOperationOrder = (data: UpdateOperationOrderEP.Data) => {
    return postApiBase<UpdateOperationOrderEP.Result>({
        endpoint: '/update-operation-order',
        data: data,
    });
};

export const getOperationStats = (options: {
    params: GetOperationStatsEP.Params;
    signal?: AbortSignal;
}) => {
    return getApiBase<GetOperationStatsEP.Result>({
        endpoint: '/get-operation-stats/' + options.params.operation_id,
        signal: options.signal,
    });
};
