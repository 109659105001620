import { useLocalization } from 'hooks/context/useLocalization';
import { BreadCrumb } from 'primereact/breadcrumb';
import { MenuItem } from 'primereact/menuitem';
import { Link, useLocation, useParams } from 'react-router-dom';
import isValidOid from 'utils/isValidOid';

const getTemplate = (item: MenuItem) => {
    const MenuText = <span className='p-menuitem-text'>{item.label}</span>;
    if (item.url) {
        return (
            <Link to={item.url} className='p-menuitem-link'>
                {MenuText}
            </Link>
        );
    }
    return MenuText;
};

const CMBreadCrumb: React.FC<{
    projectLabel?: string;
    authorizationLabel?: string;
    authorizationConfigLabel?: string;
    blockOrderLabel?: string;
    unblockOrderLabel?: string;
    monitoringLabel?: string;
    operatorLabel?: string;
}> = ({
    projectLabel,
    authorizationLabel,
    authorizationConfigLabel,
    blockOrderLabel,
    unblockOrderLabel,
    monitoringLabel,
    operatorLabel,
}) => {
    const location = useLocation();
    const [localization] = useLocalization();

    const params = useParams<{
        projectId: string;
    }>();

    const projectUrl = params.projectId ? `/projects/${params.projectId}` : '';

    const paths = location.pathname.split('/').filter((i) => i !== '');

    const menuItems: MenuItem[] = paths
        .map((path, index) => {
            const validUrl = index + 1 < paths.length;
            const previousPath = index === 0 ? undefined : paths[index - 1];

            if (isValidOid(path)) {
                switch (previousPath) {
                    case 'projects':
                        path = ':projectId';
                        break;
                    case 'authorizations':
                        path = ':authorizationId';
                        break;
                    case 'block-orders':
                        path = ':blockOrderId';
                        break;
                    case 'unblock-orders':
                        path = ':unblockOrderId';
                        break;
                    case 'monitoring':
                        path = ':monitorId';
                        break;
                    case 'operators':
                        path = ':operatorId';
                        break;
                    case 'authorization-config':
                        path = ':authConfigId';
                        break;
                }
            }

            switch (path) {
                case 'authorizations':
                    return {
                        label: localization.models.authorization.plural,
                        url: validUrl
                            ? projectUrl + '/authorizations'
                            : undefined,
                    };
                case 'targets':
                    return {
                        label: localization.models.target.plural,
                        url: validUrl ? projectUrl + '/targets' : undefined,
                    };
                case 'evidences':
                    return {
                        label: localization.models.evidence.plural,
                        url: validUrl ? projectUrl + '/evidences' : undefined,
                    };
                case 'block-orders':
                    return {
                        label: localization.models.blockOrder.plural,
                        url: validUrl
                            ? projectUrl + '/block-orders'
                            : undefined,
                    };
                case 'unblock-orders':
                    return {
                        label: localization.models.unblockOrder.plural,
                        url: validUrl
                            ? projectUrl + '/unblock-orders'
                            : undefined,
                    };
                case 'users':
                    return {
                        label: localization.models.user.plural,
                        url: validUrl ? '/users' : undefined,
                    };
                case 'projects':
                    return {
                        label: localization.models.project.plural,
                        url: validUrl ? '/projects' : undefined,
                    };
                case 'monitoring':
                    return {
                        label: localization.models.monitoring.plural,
                        url: validUrl ? '/monitoring' : undefined,
                    };
                case 'documents':
                    return {
                        label: localization.models.document.plural,
                        url: validUrl ? '/documents' : undefined,
                    };
                case 'operators':
                    return {
                        label: localization.models.operator.plural,
                        url: validUrl ? '/operators' : undefined,
                    };
                case 'authorization-config':
                    return {
                        label: localization.models.authorizationConfig.plural,
                        url: undefined,
                    };
                case 'operations':
                    return {
                        label: localization.models.operation.plural,
                        url: validUrl ? projectUrl + '/targets' : undefined,
                    };

                case ':projectId':
                    return {
                        label: projectLabel,
                    };
                case ':authorizationId':
                    return {
                        label: authorizationLabel,
                    };
                case ':blockOrderId':
                    return {
                        label: blockOrderLabel,
                    };
                case ':unblockOrderId':
                    return {
                        label: unblockOrderLabel,
                    };
                case ':monitorId':
                    return {
                        label: monitoringLabel,
                    };
                case ':authConfigId':
                    return {
                        label: authorizationConfigLabel,
                    };

                default:
                    return {
                        label: 'UNHANDLED',
                    };
            }
        })
        .map((i) => ({ ...i, template: getTemplate }));

    return <BreadCrumb model={menuItems} />;
};

export default CMBreadCrumb;
