import { useMutation } from '@tanstack/react-query';
import { InputText } from 'components/ethercity-primereact';
import { useProject } from 'hooks/context/project/useProject';
import { useAuth } from 'hooks/context/useAuth';
import { useLocalization } from 'hooks/context/useLocalization';
import { useToast } from 'hooks/context/useToast';
import useQueryRefresh from 'hooks/queries/useQueryRefresh';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useCallback, useState } from 'react';
import { upsertOperation } from 'services/ether/case-manager/operation';
import { getErrorToast } from 'utils/errorHandler';

const CreateOperationDialog: React.FC<{
    onCreate?: (id: string) => void;
    onHide: () => void;
}> = ({ onCreate, onHide }) => {
    const [name, setName] = useState('');
    const project = useProject();
    const { user } = useAuth();

    const [localization] = useLocalization();
    const { hardRefresh } = useQueryRefresh();
    const toast = useToast();

    const mutation = useMutation({
        mutationFn: () => {
            if (!user.data) throw new Error('expected user');
            return upsertOperation({
                name: name,
                is_active: true,
                project_id: project._id,
            });
        },
        onSuccess: (operation) => {
            hardRefresh(['operation']);
            toast.show({
                severity: 'success',
                life: 5000,
                summary:
                    localization.endpoints.operation.create_operation
                        .createSuccess,
            });
            if (onCreate) onCreate(operation._id);
            onHide();
        },
        onError: (e: Error) => {
            toast.show(getErrorToast(e.message, localization));
        },
    });

    return (
        <Dialog
            visible={true}
            onHide={onHide}
            header={localization.components.models.operation.button.new}
            footer={
                <>
                    <Button
                        label={localization.components.common.button.save}
                        onClick={() => mutation.mutate()}
                        disabled={name === '' || mutation.isPending}
                    />
                    <Button
                        label={localization.components.common.button.cancel}
                        severity='danger'
                        onClick={onHide}
                    />
                </>
            }
        >
            <InputText
                label={localization.fields.operation.name}
                value={name}
                onChange={(e) => setName(e.target.value)}
            />
        </Dialog>
    );
};

const useCreateOperationDialog = (options?: {
    onCreate?: (id: string) => void;
}) => {
    const [visible, setVisible] = useState(false);

    const hideDialog = () => setVisible(false);
    const showDialog = () => setVisible(true);

    const element = useCallback(() => {
        return (
            visible && (
                <CreateOperationDialog
                    onHide={hideDialog}
                    onCreate={options?.onCreate}
                />
            )
        );
    }, [visible, options?.onCreate]);

    return {
        element,
        show: showDialog,
        hide: hideDialog,
    };
};

export default useCreateOperationDialog;
