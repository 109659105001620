import { useLocalization } from 'hooks/context/useLocalization';

const DisplayPending = () => {
    const [localization] = useLocalization();
    return (
        <div className='flex flex-row gap-1 text-yellow-500'>
            <i className='pi pi-exclamation-triangle' />
            <span>
                {localization.components.models.target.badge.enrich.pending}
            </span>
        </div>
    );
};

export default DisplayPending;
