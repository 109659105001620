import { Tooltip, TooltipProps } from 'primereact/tooltip';
import { useEffect, useRef, useState } from 'react';

const getToastPosition = (selector?: string) => {
    if (!selector) return 'left';
    const elem = window.document.querySelector(selector);
    if (!elem) return 'left';
    const boundingBox = elem.getBoundingClientRect();
    const windowHalf = window.innerWidth / 2;
    return boundingBox.x > windowHalf ? 'left' : 'right';
};

const AdaptableTooltip: React.FC<
    Omit<TooltipProps, 'position'> & {
        target: string;
    }
> = (props) => {
    const element = useRef<Element | null>();

    const [position, setPosition] = useState<'left' | 'right'>(
        getToastPosition(props.target)
    );

    useEffect(() => {
        element.current = window.document.querySelector(props.target);
        if (!element.current) return;
        const updatePositionEvent = () => {
            const pos = getToastPosition(props.target);
            setPosition(pos);
        };
        element.current.addEventListener('scroll', updatePositionEvent);
        return () =>
            element.current?.removeEventListener('scroll', updatePositionEvent);
    }, [props.target]);

    return <Tooltip {...props} position={position} />;
};

export default AdaptableTooltip;
