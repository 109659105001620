const storageJSONSafeParse = <T>(
    key: string,
    reviver?: (key: string, value: any) => any,
    useSession?: boolean
): T | null => {
    const baseStorage = useSession ? sessionStorage : localStorage;
    const value = baseStorage.getItem(key);
    if (!value) return null;

    try {
        const parsedValue = JSON.parse(value, reviver);
        return parsedValue;
    } catch (err) {
        if (err instanceof SyntaxError) {
            baseStorage.removeItem(key);
            console.error(err);
            return null;
        }
        throw err;
    }
};

var reDateDetect = /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})/;
export function dateReviver(key: any, value: any) {
    if (typeof value == 'string' && reDateDetect.exec(value)) {
        return new Date(value);
    }
    return value;
}

export default storageJSONSafeParse;
