export const getUserDisplayName = (user?: Ether.UserInfo | null) => {
    if (!user) return '-';
    let name = '';
    if (user?.infos) {
        if (user.infos.first_name && user.infos.first_name !== '')
            name += user.infos.first_name;
        if (user.infos.last_name && user.infos.last_name !== '')
            name += ' ' + user.infos.last_name;
    }

    if (name === '') name += user.data?.contact?.name ?? user.username;
    return name.trim();
};
