import { Checkbox } from 'components/ethercity-primereact';
import { useLocalization } from 'hooks/context/useLocalization';
import { CheckboxProps } from 'primereact/checkbox';

const SetAsDefaultCheckbox: React.FC<CheckboxProps> = (props) => {
    const [localization] = useLocalization();
    return (
        <div className='flex flex-row gap-2'>
            <Checkbox {...props} />
            <span>{localization.common.setAsDefault}</span>
        </div>
    );
};

export default SetAsDefaultCheckbox;
