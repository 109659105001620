import InsertAuthorizationDialog from 'components/models/Authorization/InsertAuthorizationDialog';
import { useLocalization } from 'hooks/context/useLocalization';
import { useCallback, useState } from 'react';

const useUpsertAuthorizationDialog = (options?: { onUpsert?: () => void }) => {
    const [visible, setVisible] = useState(false);
    const [editItem, setEditItem] =
        useState<Ether.CaseManager.Authorization | null>(null);
    const [localization] = useLocalization();

    const hideDialog = () => {
        setEditItem(null);
        setVisible(false);
    };
    const showDialog = (item?: Ether.CaseManager.Authorization | null) => {
        setEditItem(item ?? null);
        setVisible(true);
    };

    const element = useCallback(() => {
        return (
            visible && (
                <InsertAuthorizationDialog
                    visible={true}
                    onHide={hideDialog}
                    header={localization.components.models.operation.button.new}
                    onUpsert={options?.onUpsert}
                    authorizationToEdit={editItem}
                />
            )
        );
    }, [visible, localization, editItem, options?.onUpsert]);

    return {
        element,
        showCreate: () => showDialog(),
        showEdit: (item: Ether.CaseManager.Authorization) => showDialog(item),
        hide: hideDialog,
    };
};

export default useUpsertAuthorizationDialog;
