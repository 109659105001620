import { Logo } from 'components/ethercity-primereact';
import { Link, Navigate, useSearchParams } from 'react-router-dom';
import { Button } from 'primereact/button';
import { useLocalization } from 'hooks/context/useLocalization';
import LocalizationSync from 'static/LocalizationSync';
import DetailedAuthorizationView from 'components/models/Authorization/DetailedAuthorizationView';
import useDetailOneAuthorization from 'hooks/queries/authorization/useDetailOneAuthorization';
import { AuthProvider, useAuth } from 'hooks/context/useAuth';
import { ProjectProvider, useProject } from 'hooks/context/project/useProject';

const AcceptAuthorizationWrapper = () => {
    const [params] = useSearchParams();
    const token = params.get('token');

    if (!token) return <Navigate to='/' />;

    return (
        <AuthProvider temporaryToken={token}>
            <ProjectProvider>
                <AcceptAuthorization />
            </ProjectProvider>
        </AuthProvider>
    );
};

const AcceptAuthorization: React.FC<{}> = () => {
    const { user, temporaryToken } = useAuth();
    const project = useProject();
    const [localization] = useLocalization();
    const thisPage =
        localization.components.models.authorization.views.accept;

    const authorizationQuery = useDetailOneAuthorization(
        {
            _id: '',
            project_id: project?._id ?? '',
        },
        {
            enabled: !!temporaryToken && !!project?._id,
        }
    );

    const answeredByUser = false;

    return (
        <>
            <LocalizationSync user={user.data} project={project}>
                <section className='p-4'>
                    <div className='flex flex-row gap-4'>
                        <Logo label='CaseManager' labelRatio={0.9} />
                        <h2>{thisPage.title}</h2>
                    </div>
                    {answeredByUser && (
                        <>
                            <h4>{thisPage.alreadyAnswered}</h4>
                            <Link to='/'>
                                <Button
                                    label={
                                        localization.components.common.button
                                            .leave
                                    }
                                />
                            </Link>
                        </>
                    )}
                    <DetailedAuthorizationView
                        authorization={authorizationQuery.data}
                        isLoading={authorizationQuery.isFetching}
                        error={authorizationQuery.error}
                        hideBackButton
                        hideViewAllAuthorizationButton
                    />
                </section>
            </LocalizationSync>
        </>
    );
};

export default AcceptAuthorizationWrapper;
