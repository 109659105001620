import clipboard from 'clipboardy';
import { useLocalization } from 'hooks/context/useLocalization';
import { useToast } from 'hooks/context/useToast';

const useCopyToClipboard = () => {
    const toast = useToast();
    const [localization] = useLocalization();

    const copy = (value: string | null | undefined) => {
        if (!value) return;
        clipboard.write(value).then(() =>
            toast.show({
                summary: localization.common.copiedToClipboard,
                severity: 'success',
                life: 3000,
            })
        );
    };

    return copy;
};

export default useCopyToClipboard;
