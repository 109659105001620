import api from 'services/ether/api';

export const handleUserDirectoryCallback = (
    hash: string,
    options?: {
        signal?: AbortSignal;
    }
) => {
    return new Promise<Ether.UDCallback>((resolve, reject) => {
        api.post<Ether.UDCallback>(
            '/auth/ud-callback',
            {
                hash,
            },
            {
                signal: options?.signal,
            }
        )
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const isTokenValid = (
    token: string,
    options?: {
        signal?: AbortSignal;
    }
): Promise<{ valid: boolean; data: Ether.MeInfo | null }> => {
    return new Promise<{ valid: boolean; data: Ether.MeInfo | null }>(
        (resolve) => {
            api.get<Ether.ApiResponse<Ether.MeInfo>>('/me', {
                signal: options?.signal,
                headers: {
                    'access-token': token,
                },
            })
                .then((res) => {
                    resolve({
                        valid: true,
                        data: res.data.payload,
                    });
                })
                .catch(() =>
                    resolve({
                        valid: false,
                        data: null,
                    })
                );
        }
    );
};

export const isTemporaryTokenValid = (
    temporaryToken: string,
    options?: {
        signal?: AbortSignal;
    }
): Promise<{ valid: boolean; data: Ether.MeInfo | null }> => {
    return new Promise<{ valid: boolean; data: Ether.MeInfo | null }>(
        (resolve) => {
            api.get<Ether.ApiResponse<Ether.MeInfo>>('/me', {
                signal: options?.signal,
                headers: {
                    'temporary-token': temporaryToken,
                },
            })
                .then((res) => {
                    resolve({
                        valid: true,
                        data: res.data.payload,
                    });
                })
                .catch(() =>
                    resolve({
                        valid: false,
                        data: null,
                    })
                );
        }
    );
};

export const listGroups = (options?: {
    limit?: number;
    offset?: number;
    signal?: AbortSignal;
}) => {
    return new Promise<Ether.Group[]>((resolve, reject) => {
        api.get<Ether.ApiResponse<Ether.Group[]>>('/list-groups', {
            signal: options?.signal,
            params: {
                limit: options?.limit ?? 10,
                offset: options?.offset ?? 0,
            },
        })
            .then((res) => {
                resolve(res.data.payload);
            })
            .catch((err) => reject(err));
    });
};

export const getGroup = (
    _id: string,
    options?: {
        signal?: AbortSignal;
    }
) => {
    return new Promise<Ether.Group | null>((resolve, reject) => {
        api.get<Ether.ApiResponse<Ether.Group[]>>('/list-groups', {
            signal: options?.signal,
            params: {
                _id,
            },
        })
            .then((res) => {
                resolve(res.data.payload[0] ?? null);
            })
            .catch((err) => reject(err));
    });
};
