import { ProjectProvider } from 'hooks/context/project/useProject';
import { Outlet } from 'react-router-dom';

const ProjectSubpages = () => {
    return (
        <ProjectProvider>
            <Outlet />
        </ProjectProvider>
    );
};

export default ProjectSubpages;
