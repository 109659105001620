import api from 'services/ether/api';
import {
    DataTableFilterMeta,
    DataTableOperatorFilterMetaData,
    DataTableSortMeta,
} from 'primereact/datatable';
import isValidOid from 'utils/isValidOid';
import { handleFilterMatchMode } from 'services/ether/utils';
import { InsertMonitoring } from './types';
import { handleSort } from 'utils/datatable';

const handleDataTableFilterMeta = (filters?: DataTableFilterMeta) => {
    if (!filters) return {};
    let paramFilters: { [key: string]: string | number } = {};

    ['name', 'data.network_url', 'data.video_url'].forEach((key) => {
        if (!(key in filters)) return;
        const filterMeta = filters[key] as DataTableOperatorFilterMetaData;
        const { value, matchMode } = filterMeta.constraints[0] ?? {};
        paramFilters = {
            ...paramFilters,
            ...handleFilterMatchMode(key, value, matchMode),
        };
    });

    if (filters['_id']) {
        const filterMeta = filters['_id'] as DataTableOperatorFilterMetaData;
        const oid = filterMeta.constraints[0]?.value;
        if (isValidOid(oid)) paramFilters['_id'] = oid;
    }
    if (filters['_cm_name_id']) {
        const filterMeta = filters[
            '_cm_name_id'
        ] as DataTableOperatorFilterMetaData;
        const value = filterMeta.constraints[0]?.value;
        if (isValidOid(value)) paramFilters['_id'] = value;
        else {
            paramFilters = {
                ...paramFilters,
                ...handleFilterMatchMode(
                    'name',
                    filterMeta.constraints[0]?.value,
                    filterMeta.constraints[0]?.matchMode
                ),
            };
        }
    }

    return paramFilters;
};

export const listMonitoringFromProject = (
    project_id: string,
    options?: {
        limit?: number;
        offset?: number;
        filters?: DataTableFilterMeta;
        signal?: AbortSignal;
        sort?: DataTableSortMeta;
    }
) => {
    const filters = handleDataTableFilterMeta(options?.filters);
    const order = handleSort(options?.sort);

    return new Promise<Ether.CaseManager.Monitoring[]>((resolve, reject) => {
        api.get<Ether.ApiResponse<Ether.CaseManager.Monitoring[]>>(
            '/list-monitoring',
            {
                signal: options?.signal,
                params: {
                    project_id,
                    ...filters,
                    limit: options?.limit ?? 10,
                    offset: options?.offset ?? 0,
                    order: order,
                },
            }
        )
            .then((res) => {
                resolve(res.data.payload);
            })
            .catch((err) => reject(err));
    });
};

export const insertMonitoring = async (data: InsertMonitoring.Data) => {
    return new Promise<string>((resolve, reject) => {
        api.post<Ether.ApiResponse<InsertMonitoring.Response>>(
            '/insert-monitoring',
            data
        )
            .then((res) => {
                const response = res.data.payload[0];
                if (!response)
                    throw new Error('insert-monitoring failed to return data');
                if (typeof response === 'object' && 'error' in response)
                    throw new Error(response.error);
                resolve(response);
            })
            .catch((err) => reject(err));
    });
};
