import DetailedBlockOrderView from 'components/models/BlockOrder/DetailedBlockOrderView';
import CMBreadCrumb from 'components/page/CMBreadCrumb';
import useDetailOneBlockOrder from 'hooks/queries/block-order/useDetailOneBlockOrder';
import { useAuth } from 'hooks/context/useAuth';
import { useProject } from 'hooks/context/project/useProject';
import { Navigate, useParams } from 'react-router-dom';

const DetailedBlockOrder = () => {
    const { permissions, user } = useAuth();
    const params = useParams<{ blockOrderId: string }>();
    const project = useProject();

    const blockOrderId = params.blockOrderId as string;
    // const role = user.role;
    // const showComplete =
    //     role?.startsWith('authorizer') || role === 'analyst' || user.isAdmin;

    const blockOrderQuery = useDetailOneBlockOrder({
        _id: blockOrderId,
        project_id: project._id,
    });

    if (!permissions.viewBlockOrders) return <Navigate to='/' />;
    return (
        <>
            <CMBreadCrumb
                projectLabel={project.name}
                blockOrderLabel={blockOrderQuery.data?.name}
            />

            <DetailedBlockOrderView
                blockOrder={blockOrderQuery.data}
                isLoading={blockOrderQuery.isLoading}
                error={blockOrderQuery.error}
                userRole={user.role ?? 'operator'}
                userId={user.data?._id ?? null}
                operatorId={user.data?.operator_data?._id ?? null}
            />
        </>
    );
};

export default DetailedBlockOrder;
