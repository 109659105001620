import CMFileUpload from 'components/form/CMFileUpload';
import SelectOneAuthorization from 'components/models/Authorization/SelectOneAuthorization';
import SelectOneAuthorizationConfig from 'components/models/AuthorizationConfig/SelectOneAuthorizationConfig';
import SelectOneBlockOrder from 'components/models/BlockOrder/SelectOneBlockOrder';
import useUpsertDocument from 'hooks/mutations/document/useUpsertDocument';
import { useLocalization } from 'hooks/context/useLocalization';
import { useProject } from 'hooks/context/project/useProject';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { Steps } from 'primereact/steps';
import { Dispatch, SetStateAction, useState } from 'react';
import {
    BaseStep,
    UpsertDocumentDialogProps,
    UpsertDocumentDialogSelectConfigProps,
} from './types';
import { useAuth } from 'hooks/context/useAuth';
import {
    ReviewStep as DocumentReviewStep,
    MetadataStep as DocumentMetadataStep,
    UpsertDocumentFormData,
    validateDocumentForm,
} from './document';
import {
    ReviewStep as OfficialDocumentReviewStep,
    MetadataStep as OfficialDocumentMetadataStep,
    UpsertOfficialDocumentFormData,
    validateOfficialDocumentForm,
} from './officialDocument';

type Step = BaseStep<UpsertDocumentFormData | UpsertOfficialDocumentFormData>;

type ButtonPairProps = {
    nextLabel?: string;
    backLabel?: string;
    onNext?: () => void;
    onBack?: () => void;
    disableNext?: boolean;
    disableBack?: boolean;
    loadingNext?: boolean;
};

export const ButtonPair: React.FC<ButtonPairProps> = ({
    nextLabel,
    onNext,
    disableNext,
    loadingNext,
    backLabel,
    onBack,
    disableBack,
}) => {
    const [localization] = useLocalization();
    nextLabel = nextLabel ?? localization.components.common.button.next;
    backLabel = backLabel ?? localization.components.common.button.back;
    return (
        <div className='flex flex-row gap-2 mt-8 justify-center items-center'>
            {onBack && (
                <Button
                    className='w-52'
                    label={backLabel}
                    onClick={onBack}
                    disabled={disableBack}
                />
            )}
            {onNext && (
                <Button
                    className='w-52'
                    outlined
                    label={nextLabel}
                    onClick={onNext}
                    disabled={disableNext}
                    loading={loadingNext}
                />
            )}
        </div>
    );
};

const SelectConfigStep: React.FC<
    UpsertDocumentDialogSelectConfigProps &
        Step & {
            editDoc?: Ether.CaseManager.Document.Detailed | null;
        }
> = ({
    formData,
    setFormData,
    goNext,
    requiredAuthorization,
    requiredBlockOrder,
    hideAuthorization,
    hideBlockOrder,
    editDoc,
}) => {
    const { permissions } = useAuth();

    const disableSelection = !!editDoc;

    const disableNext =
        !formData.authorizationConfig ||
        (requiredAuthorization && !formData.authorization) ||
        (requiredBlockOrder && !formData.blockOrder);

    const viewAuthorization =
        permissions.viewAuthorizations && !hideAuthorization;
    const viewBlockOrder = permissions.viewBlockOrders && !hideBlockOrder;

    const authConfig = editDoc?.authorization_configs_data?.[0];
    const authorization = editDoc?.authorizations_data?.[0];
    const blockOrder = editDoc?.block_orders_data?.[0];

    return (
        <section>
            <SelectOneAuthorizationConfig
                value={formData.authorizationConfig?._id}
                onChange={(config) =>
                    setFormData((old) => ({
                        ...old,
                        authorizationConfig: config,
                        authorization: null,
                        blockOrder: null,
                    }))
                }
                required
                overrideItems={
                    editDoc ? (authConfig ? [authConfig] : []) : undefined
                }
                disabled={disableSelection}
            />
            {formData.authorizationConfig && (
                <>
                    {viewAuthorization && (
                        <>
                            <Divider />
                            <SelectOneAuthorization
                                value={formData.authorization?._id}
                                onChange={(auth) =>
                                    setFormData((old) => ({
                                        ...old,
                                        authorization: auth,
                                    }))
                                }
                                queryOptions={{
                                    devFilters: {
                                        authorization_config_id:
                                            formData.authorizationConfig._id,
                                    },
                                }}
                                showClear
                                required={requiredAuthorization}
                                overrideItems={
                                    editDoc
                                        ? authorization
                                            ? [authorization]
                                            : []
                                        : undefined
                                }
                                disabled={disableSelection}
                            />
                        </>
                    )}
                    {viewBlockOrder && (
                        <>
                            <SelectOneBlockOrder
                                value={formData.blockOrder?._id}
                                onChange={(order) =>
                                    setFormData((old) => ({
                                        ...old,
                                        blockOrder: order,
                                    }))
                                }
                                queryOptions={{
                                    devFilters: {
                                        authorization_config_ids:
                                            formData.authorizationConfig._id,
                                    },
                                }}
                                showClear
                                required={requiredBlockOrder}
                                overrideItems={
                                    editDoc
                                        ? blockOrder
                                            ? [blockOrder]
                                            : []
                                        : undefined
                                }
                                disabled={disableSelection}
                            />
                            <Divider />
                        </>
                    )}
                </>
            )}
            <ButtonPair onNext={goNext} disableNext={disableNext} />
        </section>
    );
};

const FileStep: React.FC<Step> = ({
    formData,
    setFormData,
    goNext,
    goBack,
}) => {
    const disableNext = formData.file == null;

    return (
        <section className='pt-8'>
            <CMFileUpload
                customUpload
                value={
                    formData.file && {
                        _id: 'file',
                        status: 'done',
                        custom_identifier: 'file',
                        meta: {
                            name: formData.file.name,
                            size: formData.file.size,
                        },
                    }
                }
                removeFile={() =>
                    setFormData((old) => ({ ...old, file: null }))
                }
                hideUploadButton={!!formData.file}
                onSelect={(e) => {
                    const file = e.files[0];
                    if (!file) return;
                    setFormData((old) => ({ ...old, file: file }));
                }}
                multiple={false}
            />
            <ButtonPair
                onNext={goNext}
                onBack={goBack}
                disableNext={disableNext}
            />
        </section>
    );
};

const UpsertDocumentDialog: React.FC<UpsertDocumentDialogProps> = ({
    type,
    editDoc,
    requiredAuthorization,
    requiredBlockOrder,
    hideAuthorization,
    hideBlockOrder,
    startingAuthorizationConfig,
    startingAuthorization,
    ...props
}) => {
    const project = useProject();
    const [localization] = useLocalization();
    const [currentStep, setCurrentStep] = useState(0);

    const isEdit = !!editDoc;

    const [formData, setFormData] = useState<
        UpsertDocumentFormData | UpsertOfficialDocumentFormData
    >(() => {
        if (type === 'document')
            return {
                file: editDoc?.file ?? null,
                authorization:
                    startingAuthorization ??
                    editDoc?.authorizations_data?.[0] ??
                    null,
                authorizationConfig:
                    startingAuthorizationConfig ??
                    editDoc?.authorization_configs_data?.[0] ??
                    null,
                blockOrder: editDoc?.block_orders_data?.[0] ?? null,
                documentName: editDoc?.meta?.['document_name'] ?? '',
                documentCode: editDoc?.meta?.['document_code'] ?? '',
                documentDescription:
                    editDoc?.meta?.['document_description'] ?? '',
                eventName: editDoc?.meta?.['event_name'] ?? '',
                eventDescription: editDoc?.meta?.['event_description'] ?? '',
                eventDate: editDoc?.meta?.['event_date']
                    ? new Date(editDoc?.meta?.['event_date'])
                    : null,
            } as UpsertDocumentFormData;
        return {
            file: editDoc?.file ?? null,
            authorization: editDoc?.authorizations_data?.[0] ?? null,
            authorizationConfig:
                editDoc?.authorization_configs_data?.[0] ?? null,
            blockOrder: editDoc?.block_orders_data?.[0] ?? null,
            officialDocName: editDoc?.meta?.['document_name'] ?? '',
            officialDocCode: editDoc?.meta?.['document_code'] ?? '',
            officialDocDescription:
                editDoc?.meta?.['document_description'] ?? '',
            officialDocProtocolDate: editDoc?.meta?.['document_protocol_date']
                ? new Date(editDoc.meta['document_protocol_date'])
                : null,
            officialDocValidityStart: editDoc?.meta?.['document_validity_start']
                ? new Date(editDoc.meta['document_validity_start'])
                : null,
            officialDocValidityEnd: editDoc?.meta?.['document_validity_end']
                ? new Date(editDoc.meta['document_validity_end'])
                : null,
            isDuty: Boolean(editDoc?.meta?.['document_is_duty']),
            dutyDate: editDoc?.meta?.['document_duty_date']
                ? new Date(editDoc.meta['document_duty_date'])
                : null,
            eventName: editDoc?.meta?.['event_name'] ?? '',
            eventDescription: editDoc?.meta?.['event_description'] ?? '',
            eventDate: editDoc?.meta?.['event_date']
                ? new Date(editDoc?.meta?.['event_date'])
                : null,
        } as UpsertOfficialDocumentFormData;
    });
    const { insertDocuments, updateDocuments, uploadStatus } =
        useUpsertDocument({
            showSuccess: true,
            onUpload: props.onHide,
        });

    const handleSubmit = () => {
        const uploadData =
            type === 'document'
                ? validateDocumentForm({
                      form: formData as UpsertDocumentFormData,
                      project_id: project._id,
                  })
                : validateOfficialDocumentForm({
                      form: formData as UpsertOfficialDocumentFormData,
                      project_id: project._id,
                  });
        if (!uploadData) return;
        isEdit
            ? updateDocuments({
                  _id: editDoc._id,
                  ...uploadData,
              })
            : insertDocuments(uploadData);
    };

    const advance = () =>
        setCurrentStep((old) => {
            const res = old + 1;
            return res >= steps.length ? steps.length - 1 : res;
        });
    const retrograde = () =>
        setCurrentStep((old) => {
            const res = old - 1;
            return res < 0 ? 0 : res;
        });

    const steps = [{}, {}, {}, {}];

    return (
        <Dialog
            header={
                type === 'document'
                    ? localization.components.models.document.views.insert.title
                    : localization.components.models.oficio.views.insert.title
            }
            style={{
                minWidth: '50vw',
            }}
            {...props}
        >
            <Steps activeIndex={currentStep} model={steps} />
            {currentStep === 0 && (
                <SelectConfigStep
                    formData={formData}
                    setFormData={setFormData}
                    goNext={advance}
                    requiredAuthorization={requiredAuthorization}
                    requiredBlockOrder={requiredBlockOrder}
                    hideAuthorization={hideAuthorization}
                    hideBlockOrder={hideBlockOrder}
                    editDoc={editDoc}
                />
            )}
            {currentStep === 1 &&
                (type === 'document' ? (
                    <DocumentMetadataStep
                        formData={formData as UpsertDocumentFormData}
                        setFormData={
                            setFormData as Dispatch<
                                SetStateAction<UpsertDocumentFormData>
                            >
                        }
                        goNext={advance}
                        goBack={retrograde}
                    />
                ) : (
                    <OfficialDocumentMetadataStep
                        formData={formData as UpsertOfficialDocumentFormData}
                        setFormData={
                            setFormData as Dispatch<
                                SetStateAction<UpsertOfficialDocumentFormData>
                            >
                        }
                        goNext={advance}
                        goBack={retrograde}
                    />
                ))}
            {currentStep === 2 && (
                <FileStep
                    formData={formData}
                    setFormData={setFormData}
                    goNext={advance}
                    goBack={retrograde}
                />
            )}
            {currentStep === 3 &&
                (type === 'document' ? (
                    <DocumentReviewStep
                        formData={formData as UpsertDocumentFormData}
                        setFormData={() => {}}
                        goNext={handleSubmit}
                        goBack={retrograde}
                        disabled={uploadStatus === 'pending'}
                    />
                ) : (
                    <OfficialDocumentReviewStep
                        formData={formData as UpsertOfficialDocumentFormData}
                        setFormData={() => {}}
                        goNext={handleSubmit}
                        goBack={retrograde}
                        disabled={uploadStatus === 'pending'}
                    />
                ))}
        </Dialog>
    );
};

export default UpsertDocumentDialog;
