import {
    DefaultError,
    DefinedInitialDataOptions,
    DefinedUseQueryResult,
    QueryClient,
    QueryKey,
    UndefinedInitialDataOptions,
    useQuery,
    useQueryClient,
    UseQueryOptions,
    UseQueryResult,
} from '@tanstack/react-query';

function useQueryWithRefresh<
    TQueryFnData = unknown,
    TError = DefaultError,
    TData = TQueryFnData,
    TQueryKey extends QueryKey = QueryKey
>(
    options: DefinedInitialDataOptions<TQueryFnData, TError, TData, TQueryKey>,
    queryClient?: QueryClient
): DefinedUseQueryResult<TData, TError> & {
    refresh: () => void;
};
function useQueryWithRefresh<
    TQueryFnData = unknown,
    TError = DefaultError,
    TData = TQueryFnData,
    TQueryKey extends QueryKey = QueryKey
>(
    options: UndefinedInitialDataOptions<
        TQueryFnData,
        TError,
        TData,
        TQueryKey
    >,
    queryClient?: QueryClient
): UseQueryResult<TData, TError> & {
    refresh: () => void;
};
function useQueryWithRefresh<
    TQueryFnData = unknown,
    TError = DefaultError,
    TData = TQueryFnData,
    TQueryKey extends QueryKey = QueryKey
>(
    options: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    queryClient?: QueryClient
): UseQueryResult<TData, TError> & {
    refresh: () => void;
} {
    const altQueryClient = useQueryClient();

    const query = useQuery<TQueryFnData, TError, TData, TQueryKey>(
        options,
        queryClient
    );

    return {
        ...query,
        refresh: () =>
            (queryClient ?? altQueryClient).resetQueries({
                queryKey: options.queryKey,
            }),
    };
}

export default useQueryWithRefresh;
