import { useLocalization } from 'hooks/context/useLocalization';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { CheckActionTargetWarningEP } from 'services/ether/case-manager/targets/types';

const TargetCheckWarningDialog: React.FC<{
    targets: CheckActionTargetWarningEP.Result;
    action: 'revertTargets' | 'finishAuthorization' | 'finishBlockOrder';
    onHide: () => void;
    onContinue?: () => void;
}> = ({ targets, onHide, onContinue, action }) => {
    const [localization] = useLocalization();

    if (!targets) return null;

    const hideAndContinue = () => {
        onHide();
        if (onContinue) onContinue();
    };

    const sortedTargets = targets.sort((t1, t2) =>
        t1.value > t2.value ? 1 : t1.value < t2.value ? -1 : 0
    );

    const thisLocale =
        localization.components.models.target.views.targetCheckWarning;
    const modelLocale = thisLocale[action];
    const isPlural = sortedTargets.length > 1;
    const count = sortedTargets.length.toString();

    return (
        <Dialog
            visible
            onHide={onHide}
            className='min-w-[40vw]'
            header={modelLocale.title}
            footer={
                <div className='flex flex-row justify-end w-full'>
                    <Button
                        label={modelLocale.buttonConfirm}
                        icon='pi pi-exclamation-triangle'
                        severity='warning'
                        onClick={hideAndContinue}
                        tooltip={
                            isPlural
                                ? modelLocale.buttonConfirmTooltip.plural.replace(
                                      '{count}',
                                      count
                                  )
                                : modelLocale.buttonConfirmTooltip.singular
                        }
                        tooltipOptions={{
                            position: 'left',
                        }}
                    />
                    <Button
                        label={localization.components.common.button.cancel}
                        icon='pi pi-times'
                        severity='danger'
                        onClick={onHide}
                    />
                </div>
            }
        >
            <div className='text-xl font-bold flex flex-row gap-2 justify-center items-center'>
                <i className='pi pi-exclamation-triangle' />
                <span>{localization.common.warning}</span>
                <i className='pi pi-exclamation-triangle' />
            </div>
            <h3>
                {isPlural
                    ? thisLocale.amount.plural.replace('{count}', count)
                    : thisLocale.amount.singular}
            </h3>
            <p>{modelLocale.description}</p>
            <DataTable
                value={sortedTargets}
                showHeaders={false}
                scrollable
                scrollHeight='30vh'
            >
                <Column field='value' />
                <Column
                    field='warnings'
                    body={(data: CheckActionTargetWarningEP.Result[0]) => {
                        return data.warnings
                            .map((w) => {
                                switch (w) {
                                    case 'review':
                                        return thisLocale.targetReasons[
                                            w
                                        ].replace(
                                            '{value}',
                                            data.list_data?.highest_grading?.toString() ??
                                                '-'
                                        );
                                    default:
                                        return thisLocale.targetReasons[w];
                                }
                            })
                            .join(', ');
                    }}
                />
            </DataTable>
            <p>{modelLocale.confirmMessage}</p>
        </Dialog>
    );
};

export default TargetCheckWarningDialog;
