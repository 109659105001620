import {
    DataTableFilterMeta,
    DataTableOperatorFilterMetaData,
    SortOrder,
} from 'primereact/datatable';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';

type Options = {
    pageOptions?: {
        startingPage?: number;
        startingRow?: number;
    };
    sort?: {
        field: string;
        order: NonNullable<SortOrder>;
    };
    filters?: {
        [field: string]: DataTableOperatorFilterMetaData;
    };
};

const useInitDataTableState = (options?: Options) => {
    const [pageOptions, setPageOptions] = useState<{
        page: number;
        rows: number;
    }>({
        page: options?.pageOptions?.startingPage ?? 1,
        rows: options?.pageOptions?.startingRow ?? 50,
    });

    const [sort, setSort] = useState<{
        field: string;
        order: SortOrder | undefined;
    } | null>(options?.sort ?? null);

    const [stateFilters, setStateFilters] = useState<DataTableFilterMeta>(
        options?.filters ?? {}
    );

    const onFiltersApply: Dispatch<SetStateAction<DataTableFilterMeta>> =
        useCallback((e) => {
            setPageOptions((old) => ({
                ...old,
                page: 1,
            }));
            setStateFilters(e);
        }, []);

    return {
        pageOptions,
        setPageOptions,
        sort,
        setSort,
        filters: stateFilters,
        setFilters: onFiltersApply,
    };
};

export default useInitDataTableState;
