import useQueryWithRefresh from 'hooks/helpers/useQueryWithRefresh/useQueryWithRefresh';
import { getAuthorizationStats } from 'services/ether/case-manager/authorizations';
import { GetAuthorizationStatsEP } from 'services/ether/case-manager/authorizations/types';

const useGetAuthorizationStats = (
    params: GetAuthorizationStatsEP.Params,
    queryOptions?: {
        enabled?: boolean;
    }
) => {
    const queryKey = ['authorization', 'stats', params];

    const query = useQueryWithRefresh<GetAuthorizationStatsEP.Result, Error>({
        queryKey,
        queryFn: ({ signal }) =>
            getAuthorizationStats({
                params,
                signal,
            }),
        ...queryOptions,
    });

    return query;
};

export default useGetAuthorizationStats;
