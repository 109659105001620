import UpsertCompanyForm from 'components/models/Company/UpsertCompanyForm';
import { useAuth } from 'hooks/context/useAuth';
import { Navigate } from 'react-router-dom';

const InsertCompanyPage = () => {
    const { permissions } = useAuth();

    if (!permissions.upsertCompanies) return <Navigate to='/admin' />;

    return <UpsertCompanyForm companyToEdit={null} />;
};

export default InsertCompanyPage;
