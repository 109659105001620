import { useProject } from 'hooks/context/project/useProject';
import { detailManyEvidences } from 'services/ether/case-manager/evidences';
import EvidencesDataTable, {
    evidenceFilters,
} from 'components/models/Evidences/EvidencesDataTable';
import CMBreadCrumb from 'components/page/CMBreadCrumb';
import { useLocalization } from 'hooks/context/useLocalization';
import GoBackButton from 'components/misc/GoBackButton';
import useInitDataTableState from 'hooks/helpers/useInitDataTableState';
import CacheControl from 'controller/cache/cacheController';
import useQueryWithRefresh from 'hooks/helpers/useQueryWithRefresh/useQueryWithRefresh';

const ListEvidences = () => {
    const project = useProject();
    const [localization] = useLocalization();

    const { filters, pageOptions, setFilters, setPageOptions } =
        useInitDataTableState({
            filters: evidenceFilters,
            pageOptions: {
                startingRow:
                    CacheControl.UserConfig.get()['paginatorRows']?.evidences,
            },
        });

    const evidencesQuery = useQueryWithRefresh<
        Ether.CaseManager.Evidence.Detailed[]
    >({
        queryKey: ['detail-evidence', project._id, pageOptions, filters],
        queryFn: async ({ signal }) => {
            return await detailManyEvidences({
                project_id: project._id,
                options: {
                    rawFilters : filters,
                    limit: pageOptions.rows,
                    offset: (pageOptions.page - 1) * pageOptions.rows,
                },
                signal,
            });
        },
    });

    return (
        <section>
            <CMBreadCrumb projectLabel={project.name} />
            <GoBackButton />
            <h2>
                {project.name} - {localization.models.evidence.plural}
            </h2>
            <EvidencesDataTable
                loading={evidencesQuery.isFetching}
                value={evidencesQuery.data}
                filters={filters}
                setFilters={(e) => setFilters(e)}
                paginatorProps={{
                    page: pageOptions.page,
                    rows: pageOptions.rows,
                    onPageChange: (options) => setPageOptions(options),
                    disableNext:
                        (evidencesQuery.data?.length ?? 9999) <
                        pageOptions.rows,
                    onRefresh: evidencesQuery.refresh,
                }}
            />
        </section>
    );
};

export default ListEvidences;
