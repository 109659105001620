import { useMutation } from '@tanstack/react-query';
import useQueryRefresh from 'hooks/queries/useQueryRefresh';
import { useLocalization } from 'hooks/context/useLocalization';
import { useToast } from 'hooks/context/useToast';
import { upsertCompany } from 'services/ether/case-manager/company';
import { UpsertCompanyEP } from 'services/ether/case-manager/company/types';
import { getErrorToast } from 'utils/errorHandler';

const useUpsertCompany = ({
    onInsert,
}: {
    onInsert?: () => void;
}): {
    insertCompany: (data: UpsertCompanyEP.InsertData) => void;
    updateCompany: (data: UpsertCompanyEP.UpdateData) => void;
    isLoading: boolean;
    error: Error | null;
} => {
    const [localization] = useLocalization();
    const toast = useToast();

    const { hardRefresh, softRefresh } = useQueryRefresh();

    const handleUpdate = ({
        data,
    }: {
        data: UpsertCompanyEP.Result;
        params: UpsertCompanyEP.UpsertData;
    }) => {
        hardRefresh(['company']);
        softRefresh(['user']);
        toast.show({
            summary:
                data.status === 'created'
                    ? localization.endpoints.company.upsert_company
                          .createSuccess
                    : localization.endpoints.company.upsert_company
                          .updateSuccess,
            severity: 'success',
            life: 10000,
        });
        if (onInsert) onInsert();
    };

    const upsertCompanyMutation = useMutation<
        UpsertCompanyEP.Result,
        Error,
        UpsertCompanyEP.UpsertData
    >({
        mutationFn: (data) => upsertCompany(data),
        onSuccess: (data, params) => handleUpdate({ data, params }),
        onError: (err) => toast.show(getErrorToast(err.message, localization)),
    });

    return {
        insertCompany: (data) => upsertCompanyMutation.mutate(data),
        updateCompany: (data) => upsertCompanyMutation.mutate(data),
        error: upsertCompanyMutation.error,
        isLoading: upsertCompanyMutation.isPending,
    };
};

export default useUpsertCompany;
