import { Logo } from 'components/ethercity-primereact';
import { Button } from 'primereact/button';
import { useAuth } from 'hooks/context/useAuth';
import { Navigate } from 'react-router-dom';
import { useLocalization } from 'hooks/context/useLocalization';

const Login = () => {
    const { status, error, getUDLoginHref } = useAuth();
    const [localization] = useLocalization();

    if (status === 'loading') return <></>;
    if (status === 'success') return <Navigate to='/' />;

    const loginHref = getUDLoginHref();

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
            }}
        >
            <section
                style={{
                    border: '1px solid var(--alternative-grey-blacked-dark)',
                    borderRadius: '12px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '320px',
                    padding: '12px',
                }}
            >
                <Logo label='CaseManager' labelRatio={0.9} />
                {status === 'error' && (
                    <p
                        style={{
                            color: 'var(--darkish-red)',
                            fontSize: '12px',
                        }}
                    >
                        {localization.common.error}: {error}
                    </p>
                )}
                <p>{localization.components.views.login.proceedMessage}</p>
                <a href={loginHref}>
                    <Button
                        icon='pi pi-user'
                        label={
                            localization.components.views.login.signInUDButton
                        }
                    />
                </a>
            </section>
        </div>
    );
};

export default Login;
