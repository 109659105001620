import _ from 'lodash';
import { ListAuthorizationFlowEP } from './types';
import { listBase } from 'services/ether/base';
import {
    mapDevFilters,
    parseDataTableFilterMetaForAPI,
} from 'services/ether/utils';

export const listAuthorizationFlow = ({
    project_id,
    options,
    signal,
}: ListAuthorizationFlowEP.Params.Many) => {
    const devFilters = options?.devFilters ?? {};
    const devKeys: ListAuthorizationFlowEP.Filters.Map = {
        _id: ['_id', devFilters._id],
        can_join_authorization: [
            'can_join_authorization',
            devFilters.can_join_authorization,
        ],
    };
    const mappedFilters = mapDevFilters(devKeys);
    const filters = _.merge({}, options?.rawFilters, mappedFilters, {
        project_id,
    });
    return listBase<Ether.CaseManager.AuthorizationFlow[]>({
        endpoint: '/list-authorization-flow',
        handleParams: parseDataTableFilterMetaForAPI,
        options: {
            ...options,
            filters,
        },
        signal,
    });
};
