const CellGroup: React.FC<{
    element1: JSX.Element | string;
    element2: JSX.Element | string;
    span?: number;
}> = ({ span, element1, element2 }) => {
    return (
        <div
            className='flex items-center gap-2'
            style={{
                gridColumn: `span ${span ?? 1}`,
            }}
        >
            {element1}
            {element2}
        </div>
    );
};

export default CellGroup;
