import NameIdAggregator from 'components/datatable/NameIdAggregator';
import { Column } from 'primereact/column';
import { DataTableProps } from 'primereact/datatable';
import { useAuth } from 'hooks/context/useAuth';
import { MenuItem } from 'primereact/menuitem';
import { Menu } from 'primereact/menu';
import { useRef } from 'react';
import { Button } from 'primereact/button';
import { useLocalization } from 'hooks/context/useLocalization';
import { CMDataTableProps } from 'components/datatable/CMDataTable/types';
import CMDataTable from 'components/datatable/CMDataTable';
import { useNavigate } from 'react-router-dom';

type DetailedModel = Ether.CaseManager.User.GroupedProfile;

const RowMenu: React.FC<{
    item: DetailedModel;
}> = ({ item }) => {
    const { permissions } = useAuth();
    const [localization] = useLocalization();
    const navigate = useNavigate();

    const menuRef = useRef<Menu>(null);

    const menuItems: MenuItem[] = [
        ...(permissions.upsertUsers
            ? [
                  {
                      id: 'edit',
                      label: localization.components.common.button.edit,
                      command: () =>
                          navigate('edit-user/' + item.username_base),
                  },
              ]
            : []),
    ];

    if (menuItems.length <= 0) return null;
    return (
        <>
            <Menu popup ref={menuRef} model={menuItems} />
            <Button
                icon='pi pi-ellipsis-h'
                onClick={(e) => menuRef?.current?.toggle(e)}
            />
        </>
    );
};

type KeyInColumn = keyof CaseManagerApp.ModelColumns['groupedUserProfile'];

const columnFields: { [key in KeyInColumn]: key } = {
    permissions_count: 'permissions_count',
};

export const userGroupFilters = {};

const GroupedUserProfileDataTable: React.FC<
    DataTableProps<DetailedModel[]> & Omit<CMDataTableProps, 'columnConfigName'>
> = ({ ...props }) => {
    const [localization] = useLocalization();

    const datatableLocale =
        localization.components.models.groupedUserProfile.datatable;

    return (
        <>
            <CMDataTable
                dataKey='username_base'
                columnConfigName='groupedUserProfile'
                lazy
                emptyMessage={datatableLocale.empty}
                // filterOptions={{}}
                {...props}
            >
                <Column
                    field='_cm_name_id'
                    body={(data: Ether.CaseManager.User.GroupedProfile) => (
                        <NameIdAggregator
                            id={data.username_base ?? ''}
                            name={''}
                        />
                    )}
                />
                <Column
                    field={columnFields['permissions_count']}
                    header={localization.fields.groupedUserProfile.profileCount}
                />
                <Column
                    field='actions'
                    body={(data: DetailedModel) => <RowMenu item={data} />}
                />
            </CMDataTable>
        </>
    );
};

export default GroupedUserProfileDataTable;
