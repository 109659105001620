import useQueryWithRefresh from 'hooks/helpers/useQueryWithRefresh/useQueryWithRefresh';
import { detailOneBlockOrder } from 'services/ether/case-manager/block-orders';
import { DetailBlockOrderEP } from 'services/ether/case-manager/block-orders/types';

const useDetailOneBlockOrder = (
    params: DetailBlockOrderEP.Params.One,
    queryOptions?: {
        enabled?: boolean;
    }
) => {
    const queryKey = ['block-order', 'detail', params];

    const query = useQueryWithRefresh<
        Ether.CaseManager.BlockOrder.Detailed | null,
        Error
    >({
        queryKey,
        queryFn: ({ signal }) =>
            detailOneBlockOrder({
                ...params,
                signal,
            }),
        ...queryOptions,
    });

    return query;
};

export default useDetailOneBlockOrder;
