import useQueryWithRefresh from 'hooks/helpers/useQueryWithRefresh/useQueryWithRefresh';
import { detailManyTargets } from 'services/ether/case-manager/targets';
import { DetailTargetEP } from 'services/ether/case-manager/targets/types';

const useDetailTarget = (
    params: DetailTargetEP.Params.Many,
    queryOptions?: {
        enabled?: boolean;
    }
) => {
    const queryKey = ['target', 'detail', params];

    const query = useQueryWithRefresh<Ether.CaseManager.Target.Detailed[], Error>({
        queryKey,
        queryFn: ({ signal }) =>
            detailManyTargets({
                ...params,
                signal,
            }),
        ...queryOptions,
    });

    return query;
};

export default useDetailTarget;
