import { createContext, useContext } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { useQuery } from '@tanstack/react-query';
import { getOneProject } from 'services/ether/case-manager/projects';
import { useLocalization } from '../useLocalization';
import LoadingMessage from 'components/misc/LoadingMessage';
import { useProjectSelect } from 'hooks/context/project/useProjectSelect';
import { getTemporaryTokenProject } from 'services/ether/case-manager/accept-token';
import { useAuth } from '../useAuth';

const ProjectContext = createContext<Ether.CaseManager.Project | null>(null);

const useProject = () => {
    const context = useContext(ProjectContext);
    if (!context)
        throw new Error(
            'useProject must be used within ProjectContext.Provider'
        );
    return context;
};

const useProjectMaybe = () => {
    const context = useContext(ProjectContext);
    return context;
};

const ProjectProvider: React.FC<{
    children?: React.ReactNode;
}> = ({ children }) => {
    const { temporaryToken } = useAuth();
    const errorBoundary = useErrorBoundary();
    const [localization] = useLocalization();

    const projectSelect = useProjectSelect();

    const projectQuery = useQuery<Ether.CaseManager.Project | null, Error>({
        queryKey: [
            'project',
            'list',
            temporaryToken ?? projectSelect?.selectedProjectId,
        ],
        queryFn: async ({
            signal,
        }): Promise<Ether.CaseManager.Project | null> => {
            const project = temporaryToken
                ? await getTemporaryTokenProject(temporaryToken)
                : await getOneProject(projectSelect?.selectedProjectId ?? '', {
                      signal,
                  });
            if (!project && projectSelect?.updateProject)
                projectSelect.updateProject(null);
            return project;
        },
        enabled: !!temporaryToken || !!projectSelect?.selectedProjectId,
    });

    if (projectQuery.error) {
        errorBoundary.showBoundary(projectQuery.error);
        return <h2>Project not found</h2>;
    }

    if (projectQuery.isFetching || !projectQuery.data)
        return (
            <LoadingMessage>
                {localization.common.loading}
            </LoadingMessage>
        );

    if (!projectQuery.data) return null;

    return (
        <ProjectContext.Provider value={projectQuery.data}>
            {children}
        </ProjectContext.Provider>
    );
};

export { ProjectProvider, useProject, useProjectMaybe };
