import { useLocalization } from 'hooks/context/useLocalization';
import { Badge, BadgeProps } from 'primereact/badge';

const UnblockOrderStatusBadge: React.FC<{
    unblockOrder: Ether.CaseManager.UnblockOrder;
    badgeProps?: BadgeProps;
}> = ({ unblockOrder, badgeProps }) => {
    const status = unblockOrder.status;
    const [localization] = useLocalization();
    if (!status) return '-';
    badgeProps = {
        ...badgeProps,
        severity: 'info',
        value: status.toUpperCase(),
    };
    switch (status) {
        case 'draft':
            badgeProps.severity = undefined;
            badgeProps.value =
                localization.components.models.unblockOrder.badge.status.draft.toLocaleUpperCase();
            break;
        case 'pending':
            badgeProps.severity = 'warning';
            badgeProps.value =
                localization.components.models.unblockOrder.badge.status.pending.toLocaleUpperCase();
            break;
        case 'done':
            badgeProps.severity = 'success';
            badgeProps.value =
                localization.components.models.unblockOrder.badge.status.done.toLocaleUpperCase();
            break;
    }
    return <Badge {...badgeProps} />;
};

export default UnblockOrderStatusBadge;
