import useQueryWithRefresh from 'hooks/helpers/useQueryWithRefresh/useQueryWithRefresh';
import {
    detailManyBlockOrder,
    detailManyBlockOrderForSelect,
} from 'services/ether/case-manager/block-orders';
import { DetailBlockOrderEP } from 'services/ether/case-manager/block-orders/types';

const useDetailBlockOrder = (
    params: DetailBlockOrderEP.Params.Many,
    mode: 'select' | 'list',
    queryOptions?: {
        enabled?: boolean;
    }
) => {
    const queryKey = ['block-order', 'detail', params];

    const query = useQueryWithRefresh<
        Ether.CaseManager.BlockOrder.Detailed[],
        Error
    >({
        queryKey,
        queryFn: ({ signal }) =>
            mode === 'select'
                ? detailManyBlockOrderForSelect({
                      ...params,
                      signal,
                  })
                : detailManyBlockOrder({
                      ...params,
                      signal,
                  }),
        ...queryOptions,
    });

    return query;
};

export default useDetailBlockOrder;
