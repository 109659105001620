import useQueryWithRefresh from 'hooks/helpers/useQueryWithRefresh/useQueryWithRefresh';
import { getBlockOrderHistory } from 'services/ether/case-manager/block-orders';

const useGetBlockOrderHistory = (
    blockOrderId: string,
    queryOptions?: {
        enabled?: boolean;
    }
) => {
    const queryKey = ['block-order', 'history', blockOrderId];

    const query = useQueryWithRefresh<
        Ether.CaseManager.BlockOrderHistory | null,
        Error
    >({
        queryKey,
        queryFn: ({ signal }) =>
            getBlockOrderHistory(blockOrderId, {
                signal,
            }),
        ...queryOptions,
    });

    return query;
};

export default useGetBlockOrderHistory;
