import GoBackButton from 'components/misc/GoBackButton';
import LoadingMessage from 'components/misc/LoadingMessage';
import InsertUserForm from 'components/models/User/InsertUserForm';
import { useAuth } from 'hooks/context/useAuth';
import { useLocalization } from 'hooks/context/useLocalization';
import useListOneUser from 'hooks/queries/user/useListOneUser';
import { Navigate, useParams } from 'react-router-dom';

const EditUserPage = () => {
    const { permissions } = useAuth();
    const [localization] = useLocalization();

    const { userId } = useParams<{ userId: string }>();

    const userQuery = useListOneUser(
        {
            _id: userId ?? '',
        },
        {
            enabled: !!userId,
        }
    );

    if (userQuery.isFetching)
        return (
            <LoadingMessage>
                {localization.common.loading}
            </LoadingMessage>
        );

    if (!permissions.upsertUsers || !userId) return <Navigate to='/admin' />;

    return (
        <>
            <GoBackButton />
            <InsertUserForm userToEdit={userQuery.data} />
        </>
    );
};

export default EditUserPage;
