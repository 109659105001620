import useQueryWithRefresh from 'hooks/helpers/useQueryWithRefresh/useQueryWithRefresh';
import { detailOneUnblockOrder } from 'services/ether/case-manager/unblock-orders';
import { DetailUnblockOrderEP } from 'services/ether/case-manager/unblock-orders/types';

const useDetailOneUnblockOrder = (
    params: DetailUnblockOrderEP.Params.One,
    queryOptions?: {
        enabled?: boolean;
    }
) => {
    const queryKey = ['unblock-order', 'detail', params];

    const query = useQueryWithRefresh<
        Ether.CaseManager.UnblockOrder.Detailed | null,
        Error
    >({
        queryKey,
        queryFn: ({ signal }) =>
            detailOneUnblockOrder({
                ...params,
                signal,
            }),
        ...queryOptions,
    });

    return query;
};

export default useDetailOneUnblockOrder;
