import { useMutation } from '@tanstack/react-query';
import useQueryRefresh from 'hooks/queries/useQueryRefresh';
import { useLocalization } from 'hooks/context/useLocalization';
import { useToast } from 'hooks/context/useToast';
import { sendAuthorizationToAuthority } from 'services/ether/case-manager/authorizations';
import { SendToAuthoritySignEP } from 'services/ether/case-manager/authorizations/types';
import { getErrorToast } from 'utils/errorHandler';

const useSendAuthorizationToAuthority = (options?: {
    onSuccess?: () => void;
}) => {
    const [localization] = useLocalization();
    const toast = useToast();

    const { hardRefresh, softRefresh } = useQueryRefresh();

    const handleUpdate = ({
        data,
        params,
    }: {
        data: SendToAuthoritySignEP.Result;
        params: SendToAuthoritySignEP.Data;
    }) => {
        hardRefresh(['authorization'])
        softRefresh(['metrics']);
        toast.show({
            summary:
                localization.endpoints.authorization.send_to_authority_sign
                    .success,
            severity: 'success',
            life: 10000,
        });
        if (options?.onSuccess) options.onSuccess();
    };

    const changeMutation = useMutation<
        SendToAuthoritySignEP.Result,
        Error,
        SendToAuthoritySignEP.Data
    >({
        mutationFn: (data) => sendAuthorizationToAuthority(data),
        onSuccess: (data, params) => handleUpdate({ data, params }),
        onError: (err) => toast.show(getErrorToast(err.message, localization)),
    });

    const runMutation = (data: SendToAuthoritySignEP.Data) =>
        changeMutation.mutate(data);

    return {
        sendToAuthority: runMutation,
        isLoading: changeMutation.isPending,
        error: changeMutation.error,
    };
};

export default useSendAuthorizationToAuthority;
