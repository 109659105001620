import { useRef } from 'react';

import { FileUpload } from 'primereact/fileupload';

import { useLocalization } from 'hooks/context/useLocalization';
import { useMutation } from '@tanstack/react-query';
import { useToast } from 'hooks/context/useToast';
import { parseTargetFile } from 'services/ether/case-manager/targets';
import { ParseTargetResponse } from 'services/ether/case-manager/targets/types';
import { useProject } from 'hooks/context/project/useProject';
import { TargetsUploadProps } from './types';
import { Button } from 'primereact/button';
import useDownloadTemplateTargets from 'hooks/queries/project/useDownloadTemplateTargets';
import { ToastMessage } from 'primereact/toast';
import { getCsvLineErrors, localizeError } from 'utils/errorHandler';

const TargetsUpload: React.FC<TargetsUploadProps> = ({
    setTargets,
    authorizationConfigId,
    disabled,
}) => {
    const [localization] = useLocalization();
    const project = useProject();
    const toast = useToast();
    const targetsFileUploadRef = useRef<FileUpload>(null);

    const { downloadTemplate, isDownloadingTemplate } =
        useDownloadTemplateTargets({
            authorizationConfigId,
        });

    const targetsFileMutation = useMutation<
        ParseTargetResponse,
        Error,
        { file: File }
    >({
        mutationFn: ({ file }) =>
            parseTargetFile({
                projectId: project._id,
                targetsFile: file,
                authorizationConfigId,
            }),
        onSuccess: (res) => {
            const defaultToastMessage: ToastMessage = {
                life: 10000,
                summary:
                    localization.endpoints.target.parse_target_file.success
                        .brief,
                severity: 'success',
            };

            const messages: JSX.Element[] = [];

            const newCountMessage =
                localization.endpoints.target.parse_target_file.success
                    .newCount;
            const duplicatedCountMessage =
                localization.endpoints.target.parse_target_file.success
                    .duplicatedCount;
            const errorCountMessage =
                localization.endpoints.target.parse_target_file.success
                    .errorCount;
            messages.push(
                <span className='text-s'>
                    {(res.new_count === 1
                        ? newCountMessage.singular
                        : newCountMessage.plural
                    ).replace('{count}', res.new_count.toString())}
                </span>
            );
            messages.push(
                <span className='text-s'>
                    {(res.duplicated_count === 1
                        ? duplicatedCountMessage.singular
                        : duplicatedCountMessage.plural
                    ).replace('{count}', res.duplicated_count.toString())}
                </span>
            );
            messages.push(
                <span className='text-s'>
                    {(res.error_count === 1
                        ? errorCountMessage.singular
                        : errorCountMessage.plural
                    ).replace('{count}', res.error_count.toString())}
                </span>
            );
            if (res.errors.length > 0) {
                defaultToastMessage.severity =
                    res.formatted.length === 0 ? 'error' : 'warn';
                defaultToastMessage.summary =
                    res.formatted.length === 0
                        ? localization.endpoints.target.parse_target_file.error
                              .failed
                        : localization.endpoints.target.parse_target_file
                              .warning.partial;
                messages.push(
                    <b className='my-1'>
                        {
                            localization.endpoints.target.parse_target_file
                                .error.failedDetails
                        }
                    </b>
                );
                messages.push(getCsvLineErrors(res.errors, localization));
            }
            defaultToastMessage.detail = (
                <div className='flex flex-col'>
                    {messages.map((m, i) => (
                        <span key={i}>{m}</span>
                    ))}
                </div>
            );
            toast.show(defaultToastMessage);
            if (res.formatted.length <= 0) return;
            setTargets(
                res.formatted.map((t) => ({
                    ...t,
                    target_file_id: res.target_file_id,
                }))
            );
        },
        onError: (e) => {
            const localizedError = localizeError(e.message, localization);
            toast.show({
                severity: 'error',
                summary: localization.validations.generic.fileUpload,
                detail: localizedError.summary,
            });
        },
        onSettled: () => targetsFileUploadRef.current?.clear(),
    });

    return (
        <div>
            <div className='flex flex-col gap-2'>
                <div className='min-w-[160px]'>
                    <div className='flex flex-row gap-2'>
                        <Button
                            icon='pi pi-cloud-download'
                            label={
                                localization.components.common.button
                                    .downloadTemplate
                            }
                            onClick={() => downloadTemplate()}
                            loading={isDownloadingTemplate}
                        />
                        <FileUpload
                            ref={targetsFileUploadRef}
                            chooseOptions={{
                                icon: 'pi pi-upload',
                            }}
                            auto
                            customUpload
                            accept='text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                            mode='basic'
                            uploadHandler={({ files }) => {
                                const file = files[0];
                                if (!file) return;
                                targetsFileMutation.mutate({ file });
                            }}
                            disabled={targetsFileMutation.isPending || disabled}
                        />
                    </div>
                    <div className='flex flex-col'>
                        <span className='text-xs'>
                            {localization.validations.generic.requiredFields}:
                            value {localization.common.or.toLocaleUpperCase()}{' '}
                            alvo {localization.common.or.toLocaleUpperCase()}{' '}
                            target
                        </span>
                        <span className='text-xs'>
                            {
                                localization.components.models.authorization
                                    .views.insert
                                    .helpInsertTargetsAdditionalColumns
                            }
                        </span>
                    </div>
                </div>
            </div>
            <p>
                {
                    localization.components.models.authorization.views.insert
                        .helpInsertTargets
                }
            </p>
        </div>
    );
};
export default TargetsUpload;
