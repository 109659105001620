import useQueryWithRefresh from 'hooks/helpers/useQueryWithRefresh/useQueryWithRefresh';
import { detailOneDocument } from 'services/ether/case-manager/documents';

const useOneDetailDocument = (
    documentId: string,
    projectId: string,
    queryOptions?: {
        enabled?: boolean;
    }
) => {
    const queryKey = ['document', 'detail', projectId, documentId];

    const query = useQueryWithRefresh<
        Ether.CaseManager.Document.Detailed | null,
        Error
    >({
        queryKey,
        queryFn: async ({ signal }) =>
            detailOneDocument({
                project_id: projectId,
                _id: documentId,
                signal,
            }),
        ...queryOptions,
    });

    return query;
};

export default useOneDetailDocument;
