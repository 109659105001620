import api from 'services/ether/api';

export const getModelViaToken = (
    token: string,
    options?: {
        signal?: AbortSignal;
    }
) => {
    return new Promise<Ether.CaseManager.AcceptToken.Detailed | null>(
        (resolve, reject) => {
            api.get<Ether.ApiResponse<Ether.CaseManager.AcceptToken.Detailed[]>>(
                '/get-model-token',
                {
                    signal: options?.signal,
                    params: {
                        token,
                    },
                }
            )
                .then((res) => resolve(res.data.payload[0] ?? null))
                .catch((err) => reject(err));
        }
    );
};

export const getTemporaryTokenProject = (
    token: string,
    options?: {
        signal?: AbortSignal;
    }
) => {
    return new Promise<Ether.CaseManager.Project | null>(
        (resolve, reject) => {
            api.get<Ether.ApiResponse<Ether.CaseManager.Project>>(
                '/get-project-token',
                {
                    signal: options?.signal,
                    params: {
                        token,
                    },
                }
            )
                .then((res) => resolve(res.data.payload ?? null))
                .catch((err) => reject(err));
        }
    );
};