import { useAuth } from 'hooks/context/useAuth';
import { Navigate, Outlet, useParams } from 'react-router-dom';

const AuthorizationConfigs = () => {
    const { permissions } = useAuth();

    const params = useParams<{ authConfigId: string }>();

    if (!params.authConfigId) return <Navigate to='/' />;
    if (!permissions.viewAuthorizationConfigs) return <Navigate to='/' />;

    return <Outlet />;
};

export default AuthorizationConfigs;
