import { BaseAbstractedApiParams } from 'services/ether/types';
import { SelectInternalProps, SelectManyItemsProps } from '../types';
import BaseSelectItems from '../BaseSelectItems';

function SelectManyItems<
    T extends Record<string, any>,
    V extends BaseAbstractedApiParams
>(
    props: React.PropsWithChildren<
        SelectInternalProps<T, V> & SelectManyItemsProps<T, V>
    >
) {
    return <BaseSelectItems {...props} type='many' />;
}

export default SelectManyItems;