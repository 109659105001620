import CMFileUpload from 'components/form/CMFileUpload';
import useImportOperator from 'hooks/mutations/useImportOperator';
import { useLocalization } from 'hooks/context/useLocalization';
import { useProject } from 'hooks/context/project/useProject';
import { Button } from 'primereact/button';
import { Dialog, DialogProps } from 'primereact/dialog';
import { useState } from 'react';

const UploadOperatorsCsvModal: React.FC<
    DialogProps & { onInsert?: () => void }
> = (props) => {
    const [localization] = useLocalization();
    const project = useProject();
    const [fileToUpload, setFileToUpload] = useState<File | null>(null);

    const hide = () => {
        props.onHide();
        setFileToUpload(null);
    };

    const importOperatorMutation = useImportOperator({
        onInsert: hide,
    });

    return (
        <Dialog
            {...props}
            onHide={hide}
            header={
                localization.components.models.operator.views.insertCsv.title
            }
            footer={
                <>
                    <Button
                        label={localization.components.common.button.cancel}
                        severity='danger'
                        onClick={hide}
                    />
                    <Button
                        label={localization.components.common.button.insert}
                        severity='success'
                        disabled={!fileToUpload}
                        onClick={() => {
                            if (!fileToUpload) return;
                            importOperatorMutation.mutate({
                                project_id: project._id,
                                file: fileToUpload,
                            });
                        }}
                        loading={importOperatorMutation.isPending}
                    />
                </>
            }
        >
            <span>{localization.validations.generic.requiredFields}: </span>
            <span>username, tags, email</span>
            <CMFileUpload
                multiple={false}
                customUpload
                hideUploadButton={!!fileToUpload}
                onSelect={(e) => {
                    const file = e.files[0];
                    if (!file) return;
                    setFileToUpload(file);
                }}
                onRemove={() => setFileToUpload(null)}
                removeFile={() => setFileToUpload(null)}
                value={
                    fileToUpload && {
                        _id: 'file',
                        status: 'done',
                        custom_identifier: 'file',
                        meta: {
                            name: fileToUpload.name,
                            size: fileToUpload.size,
                        },
                    }
                }
            />
        </Dialog>
    );
};

export default UploadOperatorsCsvModal;
