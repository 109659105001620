import useQueryWithRefresh from 'hooks/helpers/useQueryWithRefresh/useQueryWithRefresh';
import { detailManyOperations } from 'services/ether/case-manager/operation';
import { DetailOperationEP } from 'services/ether/case-manager/operation/types';

const useDetailManyOperation = (
    params: DetailOperationEP.Params.Many,
    queryOptions?: {
        enabled?: boolean;
    }
) => {
    const queryKey = ['operation', 'detail', params];

    const query = useQueryWithRefresh<Ether.CaseManager.Operation.Detailed[], Error>({
        queryKey,
        queryFn: ({ signal }) => detailManyOperations({ ...params, signal }),
        ...queryOptions,
    });

    return query;
};

export default useDetailManyOperation;
