import { useLocalization } from '../../context/useLocalization';
import { useMemo, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { UpdateTargetOrderStatusEP } from 'services/ether/case-manager/targets/types';
import useUpdateTargetOrderStatus from 'hooks/mutations/target/useUpdateTargetOrderStatus';

type DialogData = UpdateTargetOrderStatusEP.Data & {
    target_value: string;
};

const UpdateTargetOrderStatusDialog: React.FC<{
    data: DialogData | null;
    onHide: () => void;
    onUpdate?: () => void;
}> = ({ data, onHide, onUpdate }) => {
    const [localization] = useLocalization();

    const hide = () => onHide();

    const { updateTarget, isLoading } = useUpdateTargetOrderStatus({
        onUpdate: () => {
            if (onUpdate) onUpdate();
            onHide();
        },
    });

    if (!data) return null;

    const localeBranch =
        localization.components.models.target.views
            .updateTargetOrderConfirmation;
    const localeMode =
        data.status === 'add' ? localeBranch.add : localeBranch.remove;

    return (
        <Dialog
            footer={
                <>
                    <Button
                        label={localization.components.common.button.cancel}
                        severity='danger'
                        onClick={onHide}
                        disabled={isLoading}
                    />
                    <Button
                        label={localization.components.common.button.confirm}
                        severity='success'
                        onClick={() => updateTarget(data)}
                        disabled={isLoading}
                        loading={isLoading}
                    />
                </>
            }
            visible={true}
            header={localeMode.title}
            onHide={hide}
        >
            <div className='flex flex-col items-center'>
                <span>
                    {localeMode.description.replace(
                        '{value}',
                        data.target_value
                    )}
                </span>
            </div>
        </Dialog>
    );
};

const useUpdateTargetOrderStatusDialog = (options?: {
    onUpdate?: () => void;
}) => {
    const { onUpdate } = options ?? {};

    const [visible, setVisible] = useState<null | DialogData>(null);

    const show = (data: DialogData) => setVisible(data);
    const hide = () => setVisible(null);

    const dialog = useMemo(
        () => (
            <UpdateTargetOrderStatusDialog
                data={visible}
                onUpdate={onUpdate}
                onHide={hide}
            />
        ),
        [visible, onUpdate]
    );

    const updateTargetOrderStatus = (data: DialogData) => show(data);

    return {
        dialog,
        updateTargetOrderStatus,
    };
};

export default useUpdateTargetOrderStatusDialog;
