import useQueryWithRefresh from 'hooks/helpers/useQueryWithRefresh/useQueryWithRefresh';
import { DataTableFilterMeta, DataTableSortMeta } from 'primereact/datatable';
import { listMonitoringFromProject } from 'services/ether/case-manager/monitoring';

const useListMonitoringQuery = (
    projectId: string,
    options?: {
        filters?: DataTableFilterMeta;
        limit?: number;
        offset?: number;
        sort?: DataTableSortMeta;
    },
    queryOptions?: {
        enabled?: boolean;
    }
) => {
    const queryKey = {
        projectId,
        ...options,
    };

    const query = useQueryWithRefresh<Ether.CaseManager.Monitoring[], Error>({
        queryKey: ['monitor', 'list', queryKey],
        queryFn: ({ signal }) =>
            listMonitoringFromProject(projectId, {
                ...options,
                signal,
            }),
        ...queryOptions,
    });

    return query;
};

export default useListMonitoringQuery;
