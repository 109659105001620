import { useLocalization } from 'hooks/context/useLocalization';
import { Button } from 'primereact/button';
import { useState } from 'react';
import { useProject } from 'hooks/context/project/useProject';
import { InputText } from 'components/ethercity-primereact';
import { InsertUserFormProps } from './types';
import useInsertUser from 'hooks/mutations/user/useInsertUser';
import SelectOneCompany from 'components/models/Company/SelectOneCompany';
import ActiveStatusDropdown from 'components/form/ActiveStatusDropdown';
import UserRoleDropdown from '../UserRoleDropdown';
import useGoBack from 'hooks/helpers/useGoBack';
import isValidEmail from 'utils/isValidEmail';
import InputEmail from 'components/form/InputEmail';
import InputPhone from 'components/form/InputPhone';
import useEditUser from 'hooks/mutations/user/useEditUser';
import { EditUserEP } from 'services/ether/case-manager/users/types';

type FormItems = {
    username_base: string;
    name: string;
    email: string;
    phone: string | null;
    company_id: string | null;
    status: 'active' | 'inactive' | null;
    password: string;
    auth_level: Ether.CaseManager.Role | null;
};

type ValidFormItems = FormItems & {
    company_id: string;
    phone: string;
    auth_level: Ether.CaseManager.Role;
    status: 'active' | 'inactive';
};

const InsertUserForm: React.FC<InsertUserFormProps> = ({ userToEdit }) => {
    const [localization] = useLocalization();
    const project = useProject();
    const goBack = useGoBack();

    const isEdit = !!userToEdit;

    const insertUserOptions = useInsertUser({
        onInsert: goBack,
    });
    const editUserOptions = useEditUser({
        onEdit: goBack,
    });

    const [formData, setFormData] = useState<FormItems>({
        auth_level: userToEdit?.data?.auth?.level ?? null,
        company_id: userToEdit?.data?.company_id ?? null,
        email: userToEdit?.infos?.email ?? '',
        name: userToEdit?.data?.contact?.name ?? '',
        password: '',
        phone: userToEdit?.data?.contact?.phone ?? '',
        status: isEdit ? userToEdit?.data?.status ?? 'active' : null,
        username_base: userToEdit?.username ?? '',
    });

    const validateFormData = () => {
        if (!formData.auth_level || !formData.status || !formData.company_id)
            return;
        if (formData.email === '' || !isValidEmail(formData.email)) return;
        if (formData.name === '') return;
        if (formData.password === '' && !isEdit) return;
        if (!formData.phone || formData.phone.includes('_')) return;
        if (formData.username_base === '') return;
        return formData as ValidFormItems;
    };

    const handleInsert = () => {
        const validatedForm = validateFormData();
        if (!validatedForm) return;

        if (isEdit && !!userToEdit) {
            const editData = {
                _id: userToEdit._id,
                project_id: project._id,
                ...validatedForm,
                password: validatedForm.password,
            } as EditUserEP.Data;
            if (editData.password === '') delete editData.password;
            editUserOptions.editUser(editData);
        } else
            insertUserOptions.insertUser({
                project_id: project._id,
                ...validatedForm,
            });
    };

    const locale = localization.components.models.user.views.insert;
    const fieldsNames = {
        ...localization.fields.user,
        ...locale.fields,
    };

    const isFormDataOk = !!validateFormData();
    const isFormDisabled =
        insertUserOptions.isLoading || editUserOptions.isLoading;

    return (
        <section>
            <h1>{isEdit ? locale.titleEdit : locale.titleCreate}</h1>
            {!isEdit && <p>{locale.description}</p>}
            <div className='grid grid-cols-4 gap-4'>
                <InputText
                    label={fieldsNames.username}
                    value={formData.username_base}
                    onChange={(e) =>
                        setFormData((old) => ({
                            ...old,
                            username_base: e.target.value,
                        }))
                    }
                    required
                    disabled={isFormDisabled || isEdit}
                />
                <InputText
                    label={fieldsNames.name}
                    value={formData.name}
                    onChange={(e) =>
                        setFormData((old) => ({
                            ...old,
                            name: e.target.value,
                        }))
                    }
                    required
                    disabled={isFormDisabled}
                />
                <InputEmail
                    label={fieldsNames.email}
                    value={formData.email}
                    onChange={(e) =>
                        setFormData((old) => ({
                            ...old,
                            email: e.target.value,
                        }))
                    }
                    required
                    disabled={isFormDisabled}
                />
                <InputPhone
                    label={fieldsNames.phone}
                    value={formData.phone ?? undefined}
                    onChange={(e) =>
                        setFormData((old) => ({
                            ...old,
                            phone: e.target.value ?? '',
                        }))
                    }
                    required
                    disabled={isFormDisabled}
                />
                <InputText
                    label={fieldsNames.password}
                    value={formData.password}
                    type='password'
                    onChange={(e) =>
                        setFormData((old) => ({
                            ...old,
                            password: e.target.value,
                        }))
                    }
                    required={!isEdit}
                    disabled={isFormDisabled}
                />
                <SelectOneCompany
                    hideTitle
                    value={formData.company_id}
                    onChange={(c) =>
                        setFormData((old) => ({
                            ...old,
                            company_id: c?._id ?? null,
                        }))
                    }
                    required
                    disabled={isFormDisabled}
                />
                <UserRoleDropdown
                    label={fieldsNames.role}
                    value={formData.auth_level}
                    onChange={(e) =>
                        setFormData((old) => ({
                            ...old,
                            auth_level: e.target.value,
                        }))
                    }
                    className='w-full'
                    required
                    disabled={isFormDisabled}
                />
                <ActiveStatusDropdown
                    label={fieldsNames.status}
                    value={formData.status}
                    onChange={(e) =>
                        setFormData((old) => ({
                            ...old,
                            status: e.target.value,
                        }))
                    }
                    className='w-full'
                    required
                    disabled={isFormDisabled}
                />
            </div>
            <div className='flex flex-row align-center justify-center gap-4 mt-8'>
                <Button
                    size='large'
                    onClick={goBack}
                    label={localization.components.common.button.cancel}
                    disabled={isFormDisabled}
                />
                <Button
                    size='large'
                    outlined
                    label={localization.components.common.button.save}
                    loading={isFormDisabled}
                    disabled={!isFormDataOk}
                    onClick={handleInsert}
                />
            </div>
        </section>
    );
};

export default InsertUserForm;
