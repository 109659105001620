import { MenuItem, MenuItemOptions } from 'primereact/menuitem';
import { Link } from 'react-router-dom';

const MenuTemplate: React.FC<{
    item: MenuItem;
    options: MenuItemOptions;
}> = ({ item, options }) => {
    const baseItem = (
        <>
            {item.icon && <span className={options.iconClassName} />}
            <span className={options.labelClassName}>{item.label}</span>
        </>
    );

    return (
        <Link to={item.url ?? '#'} className={options.className}>
            {baseItem}
        </Link>
    );
};
export default MenuTemplate;
