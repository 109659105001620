import { BaseAbstractedApiParams } from 'services/ether/types';
import { SelectInternalProps, SelectOneItemProps } from '../types';
import BaseSelectItems from '../BaseSelectItems';

function SelectOneItem<
    T extends Record<string, any>,
    V extends BaseAbstractedApiParams
>(
    props: React.PropsWithChildren<
        SelectInternalProps<T, V> & SelectOneItemProps<T, V>
    >
) {
    return <BaseSelectItems {...props} type='one' />;
}

export default SelectOneItem;
