import { createContext, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import CacheControl from 'controller/cache/cacheController';
import SelectProject from 'pages/SelectProject';

const ProjectSelectContext = createContext<{
    selectedProjectId: string | null;
    updateProject: (id: string | null) => void;
    enableChangeProject: () => void;
} | null>(null);

const useProjectSelect = () => {
    const context = useContext(ProjectSelectContext);
    return context;
};

const ProjectSelectProvider: React.FC<{
    children?: React.ReactNode;
}> = ({ children }) => {
    const params = useParams<{
        projectId: string;
    }>();

    const code = params.projectId
        ? params.projectId
        : CacheControl.SelectedProject.get();

    const [selectedProject, setSelectedProject] = useState(code ?? null);

    const selectProject = (id: string | null) => {
        if (id) CacheControl.SelectedProject.save(id);
        else CacheControl.SelectedProject.delete();
        setSelectedProject(id);
    };

    if (!selectedProject)
        return (
            <SelectProject
                onProjectSelection={selectProject}
                startingProject={code}
            />
        );

    return (
        <ProjectSelectContext.Provider
            value={{
                selectedProjectId: selectedProject,
                updateProject: selectProject,
                enableChangeProject: () => {
                    setSelectedProject(null);
                },
            }}
        >
            {children}
        </ProjectSelectContext.Provider>
    );
};

export { ProjectSelectProvider, useProjectSelect };
