import useQueryWithRefresh from 'hooks/helpers/useQueryWithRefresh/useQueryWithRefresh';
import { listOneCompany } from 'services/ether/case-manager/company';
import { ListCompanyEP } from 'services/ether/case-manager/company/types';

const useListOneCompany = (
    params: ListCompanyEP.Params.One,
    queryOptions?: {
        enabled?: boolean;
    }
) => {
    const queryKey = ['company', 'list-one', params];

    const query = useQueryWithRefresh<Ether.CaseManager.Company | null, Error>({
        queryKey,
        queryFn: ({ signal }) =>
            listOneCompany({
                ...params,
                signal,
            }),
        ...queryOptions,
    });

    return query;
};

export default useListOneCompany;
