import { Navigate } from 'react-router-dom';

const RealTimeMonitoring = () => {
    // const { permissions } = useAuth();

    // if (!permissions.viewMonitors) return <Navigate to='/' />;

    // return <Outlet />;

    return <Navigate to='/' />;
};

export default RealTimeMonitoring;
