import { useMutation } from '@tanstack/react-query';
import useQueryRefresh from 'hooks/queries/useQueryRefresh';
import { useLocalization } from 'hooks/context/useLocalization';
import { useToast } from 'hooks/context/useToast';
import { editUser } from 'services/ether/case-manager/users';
import { EditUserEP } from 'services/ether/case-manager/users/types';
import { getErrorToast } from 'utils/errorHandler';

const useEditUser = ({
    onEdit,
}: {
    onEdit?: () => void;
}): {
    editUser: (data: EditUserEP.Data) => void;
    isLoading: boolean;
    error: Error | null;
} => {
    const [localization] = useLocalization();
    const toast = useToast();

    const { hardRefresh, softRefresh } = useQueryRefresh();

    const handleUpdate = ({
        data,
    }: {
        data: EditUserEP.Result;
        params: EditUserEP.Data;
    }) => {
        hardRefresh(['user']);
        softRefresh(['company']);
        toast.show({
            summary: localization.endpoints.user.upsert_user.updateSuccess,
            severity: 'success',
            life: 10000,
        });
        if (onEdit) onEdit();
    };

    const editUserMutation = useMutation<
        EditUserEP.Result,
        Error,
        EditUserEP.Data
    >({
        mutationFn: (data) => editUser(data),
        onSuccess: (data, params) => handleUpdate({ data, params }),
        onError: (err) => toast.show(getErrorToast(err.message, localization)),
    });

    const sendUser = (data: EditUserEP.Data) => {
        editUserMutation.mutate(data);
    };

    return {
        editUser: sendUser,
        error: editUserMutation.error,
        isLoading: editUserMutation.isPending,
    };
};

export default useEditUser;
