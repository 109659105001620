import useGoBack from 'hooks/helpers/useGoBack';
import { useLocalization } from 'hooks/context/useLocalization';
import { Button } from 'primereact/button';

const GoBackButton = () => {
    const [localization] = useLocalization();
    const goBack = useGoBack();

    return (
        <Button
            label={localization.components.common.button.back}
            icon='pi pi-arrow-left'
            onClick={goBack}
        />
    );
};

export default GoBackButton;
