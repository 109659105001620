import DetailedUnblockOrderView from 'components/models/UnblockOrder/DetailedUnblockOrderView';
import CMBreadCrumb from 'components/page/CMBreadCrumb';
import { useAuth } from 'hooks/context/useAuth';
import { useProject } from 'hooks/context/project/useProject';
import { Navigate, useParams } from 'react-router-dom';
import useDetailOneUnblockOrder from 'hooks/queries/unblock-order/useDetailOneUnblockOrder';

const DetailedUnblockOrder = () => {
    const { permissions } = useAuth();
    const params = useParams<{ unblockOrderId: string }>();
    const project = useProject();

    const unblockOrderId = params.unblockOrderId as string;

    const unblockOrderQuery = useDetailOneUnblockOrder(
        {
            _id: unblockOrderId,
            project_id: project._id,
        },
        {
            enabled: !!unblockOrderId && permissions.viewUnblockOrders,
        }
    );

    if (!permissions.viewUnblockOrders) return <Navigate to='/' />;
    return (
        <>
            <CMBreadCrumb
                projectLabel={project.name}
                unblockOrderLabel={unblockOrderQuery.data?.name}
            />
            <DetailedUnblockOrderView
                unblockOrder={unblockOrderQuery.data}
                isLoading={unblockOrderQuery.isLoading}
                error={unblockOrderQuery.error}
                onRefresh={() => unblockOrderQuery.refetch()}
            />
        </>
    );
};

export default DetailedUnblockOrder;
