import LoadingMessage from 'components/misc/LoadingMessage';
import { useLocalization } from '../hooks/context/useLocalization';
import { PropsWithChildren, useEffect, useRef } from 'react';
import { CMLocalization } from 'static/language';
import _ from 'lodash';

const usePseudo = process.env.REACT_APP_LOCALIZATION_PSEUDO === 'true';

const LocalizationSync: React.FC<
    PropsWithChildren & {
        project: Ether.CaseManager.Project | null | undefined;
        user: Ether.UserInfo | null | undefined;
    }
> = ({ project, user, children }) => {
    const [localization, { currentLanguage: language, mergeLocale, isMerged }] =
        useLocalization();
    const loadedLanguages = useRef({ [language] : true });

    const projectLocalization = project?.config?.terminology?.localization;
    const roleLocalization = project?.config?.terminology?.per_role_localization;
    const userLocalization = user?.data?.terminology?.localization;

    const isAllowed = isMerged || usePseudo;
    const syncOk = isAllowed && loadedLanguages.current[language];

    useEffect(() => {
        if (isAllowed) return;
        loadedLanguages.current[language] = true;
        let localeData: CMLocalization.LocalizationObject = {};
        if (projectLocalization) _.merge(localeData, projectLocalization);
        if (roleLocalization && user?.data?.auth?.level) {
            const roleLocaleData = roleLocalization[user.data.auth.level];
            if (roleLocaleData) _.merge(localeData, roleLocaleData);
        }
        if (userLocalization) _.merge(localeData, userLocalization);
        mergeLocale(localeData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [project?._id, user?._id, language, isMerged]);

    if (!syncOk)
        return (
            <LoadingMessage>
                {localization.common.loading}
            </LoadingMessage>
        );
    return children;
};

export default LocalizationSync;
