import { useLocalization } from 'hooks/context/useLocalization';
import { BaseStep, SharedDocumentFormData } from '../types';
import {
    Datepicker,
    InputText,
    InputTextArea,
} from 'components/ethercity-primereact';
import { format as formatDate } from 'date-fns';
import { FileItem } from 'components/form/CMFileUpload';
import { UpsertDocumentEP } from 'services/ether/case-manager/documents/types';
import { ButtonPair } from '..';

export type UpsertDocumentFormData = SharedDocumentFormData & {
    documentName: string;
    documentCode: string;
    documentDescription: string;
    eventName: string;
    eventDescription: string;
    eventDate: Date | null;
};

type DocumentBaseStep = BaseStep<UpsertDocumentFormData>;

export const validateDocumentForm = ({
    form,
    project_id,
}: {
    project_id: string;
    form: UpsertDocumentFormData;
}) => {
    if (
        !form.authorizationConfig ||
        !form.file ||
        form.documentName === '' ||
        form.documentCode === '' ||
        form.documentDescription === ''
    )
        return null;
    return {
        project_id: project_id,
        authorization_config_id: form.authorizationConfig._id,
        category: 'extra',
        status: 'using',
        meta: {
            document_name: form.documentName,
            document_code: form.documentCode,
            document_description: form.documentDescription,
            event_name: form.eventName === '' ? null : form.eventName,
            event_description:
                form.eventDescription === '' ? null : form.eventDescription,
            event_date: form.eventDate
                ? form.eventDate.toISOString()
                : undefined,
        },
        files:
            form.file instanceof File
                ? [
                      {
                          file: form.file,
                          custom_identifier: '',
                      },
                  ]
                : [form.file],
        authorization_id: form.authorization?._id,
        block_order_id: form.blockOrder?._id,
    } as UpsertDocumentEP.UpsertData;
};

export const MetadataStep: React.FC<DocumentBaseStep> = ({
    formData,
    setFormData,
    goNext,
    goBack,
}) => {
    const [localization] = useLocalization();

    const documentLocale =
        localization.components.models.document.views.insert.metadataStep;
    const eventLocale =
        localization.components.models.document.views.insert.eventStep;

    const disableNext =
        formData.documentName === '' ||
        formData.documentCode === '' ||
        formData.documentDescription === '';

    return (
        <section>
            <h2>{documentLocale.title}</h2>
            <section className='flex flex-col gap-4'>
                <InputText
                    required
                    label={documentLocale.documentName}
                    placeholder={documentLocale.documentNamePlaceholder}
                    value={formData.documentName}
                    onChange={(e) =>
                        setFormData((old) => ({
                            ...old,
                            documentName: e.target.value,
                        }))
                    }
                />
                <InputText
                    required
                    label={documentLocale.documentCode}
                    placeholder={documentLocale.documentCodePlaceholder}
                    value={formData.documentCode}
                    onChange={(e) =>
                        setFormData((old) => ({
                            ...old,
                            documentCode: e.target.value,
                        }))
                    }
                />
                <InputTextArea
                    required
                    label={documentLocale.documentDescription}
                    placeholder={documentLocale.documentDescriptionPlaceholder}
                    value={formData.documentDescription}
                    onChange={(e) =>
                        setFormData((old) => ({
                            ...old,
                            documentDescription: e.target.value,
                        }))
                    }
                />
            </section>
            <h2>{eventLocale.title}</h2>
            <section className='flex flex-col gap-4'>
                <InputText
                    label={eventLocale.eventName}
                    placeholder={eventLocale.eventNamePlaceholder}
                    value={formData.eventName}
                    onChange={(e) =>
                        setFormData((old) => ({
                            ...old,
                            eventName: e.target.value,
                        }))
                    }
                />
                <InputTextArea
                    label={eventLocale.eventDescription}
                    placeholder={eventLocale.eventDescriptionPlaceholder}
                    value={formData.eventDescription}
                    onChange={(e) =>
                        setFormData((old) => ({
                            ...old,
                            eventDescription: e.target.value,
                        }))
                    }
                />
                <Datepicker
                    label={eventLocale.eventDate}
                    value={formData.eventDate}
                    onChange={(date) =>
                        setFormData((old) => ({
                            ...old,
                            eventDate: date,
                        }))
                    }
                />
            </section>
            <ButtonPair
                disableNext={disableNext}
                onNext={goNext}
                onBack={goBack}
            />
        </section>
    );
};

export const ReviewStep: React.FC<DocumentBaseStep> = ({
    formData,
    goNext,
    goBack,
    disabled,
}) => {
    const [localization] = useLocalization();
    const documentView = localization.components.models.document.views.insert;
    const { reviewStep, configStep, eventStep, fileStep, metadataStep } =
        documentView;
    return (
        <section>
            <div className='flex flex-col w-full justify-center items-center'>
                <h1>{reviewStep.title}</h1>
                <p>{reviewStep.description}</p>
            </div>
            <h2>{configStep.title}</h2>
            <p>{formData.authorizationConfig?.name}</p>
            {formData.authorization && (
                <>
                    <h3>{localization.models.authorization.singular}</h3>
                    <p>{formData.authorization.name}</p>
                </>
            )}
            {formData.blockOrder && (
                <>
                    <h3>{localization.models.blockOrder.singular}</h3>
                    <p>{formData.blockOrder.name}</p>
                </>
            )}
            <h2>{metadataStep.title}</h2>
            <h3>{metadataStep.documentName}</h3>
            <p>{formData.documentName}</p>
            <h3>{metadataStep.documentCode}</h3>
            <p>{formData.documentCode}</p>
            <h3>{metadataStep.documentDescription}</h3>
            <p>{formData.documentDescription}</p>
            <h2>{eventStep.title}</h2>
            <h3>{eventStep.eventName}</h3>
            <p>{formData.eventName === '' ? '-' : formData.eventName}</p>
            <h3>{eventStep.eventDescription}</h3>
            <p>
                {formData.eventDescription === ''
                    ? '-'
                    : formData.eventDescription}
            </p>
            <h3>{eventStep.eventDate}</h3>
            <p>
                {!formData.eventDate
                    ? '-'
                    : formatDate(formData.eventDate, 'dd/MM/yyyy')}
            </p>
            <h2>{fileStep.title}</h2>
            <FileItem
                file={{
                    status: 'done',
                    _id: '',
                    custom_identifier: '',
                    meta: {
                        name: formData.file?.name ?? '',
                        size: formData.file?.size ?? 0,
                    },
                }}
            />
            <ButtonPair
                disableNext={disabled}
                disableBack={disabled}
                onNext={goNext}
                onBack={goBack}
                nextLabel={localization.components.common.button.finish}
            />
        </section>
    );
};
