import { InputMaskProps } from 'primereact/inputmask';
import CMInputMask from '../CMInputMask';

const InputPhone: React.FC<InputMaskProps & { label: string }> = ({
    label,
    ...rest
}) => {
    return (
        <CMInputMask
            label={label}
            placeholder='(99) 9999-99999'
            mask='(99) 9999-99999'
            {...rest}
        />
    );
};

export default InputPhone;
