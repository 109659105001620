import { useLocalization } from 'hooks/context/useLocalization';
import { SelectOneItemProps } from 'components/form/SelectItems/types';
import { DetailTagEP } from 'services/ether/case-manager/tags/types';
import SelectOneTag from '../SelectOneTag';

const SelectOnePirateBrand: React.FC<
    SelectOneItemProps<Ether.CaseManager.Tag, DetailTagEP.Filters.Options>
> = (props) => {
    const [localization] = useLocalization();

    return (
        <SelectOneTag
            label={localization.models.pirateBrands.singular}
            title={localization.models.pirateBrands.singular}
            placeholder={
                localization.components.models.pirateBrand.form
                    .selectOnePlaceholder
            }
            {...props}
            queryOptions={{
                ...props.queryOptions,
                devFilters: {
                    ...props.queryOptions?.devFilters,
                    category: 'pirate-brand',
                },
            }}
        />
    );
};

export default SelectOnePirateBrand;
