import { useLocalization } from 'hooks/context/useLocalization';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';

const useShowTargetActionDisambiguation = () => {
    const [localization] = useLocalization();
    const targetConfirmLocale =
        localization.components.models.target.views.allTargetDialogConfirmation
            .toSend;

    const show = ({
        isRevert,
        answer,
        onApprovePage,
        onApproveAll,
    }: {
        isRevert: boolean;
        answer: string;
        onApproveAll: () => void;
        onApprovePage: () => void;
    }) =>
        confirmDialog({
            header: isRevert
                ? targetConfirmLocale.titleRevert
                : targetConfirmLocale.titleValidate,
            message: () => {
                return (
                    <div>
                        <p>
                            {targetConfirmLocale.description.replace(
                                '{answer}',
                                answer
                            )}
                        </p>
                        <ol>
                            <li>
                                {targetConfirmLocale.optionAll.toLocaleUpperCase()}
                            </li>
                            <li>
                                {targetConfirmLocale.optionPageOnly.toLocaleUpperCase()}
                            </li>
                        </ol>
                        <p>{targetConfirmLocale.selectBelow}</p>
                    </div>
                );
            },
            footer: (props) => {
                return (
                    <div className='flex flex-col gap-2'>
                        <Button
                            label={
                                '1. ' +
                                targetConfirmLocale.optionAll.toLocaleUpperCase()
                            }
                            onClick={() => {
                                onApproveAll();
                                props.reject();
                            }}
                        />
                        <Button
                            label={
                                '2. ' +
                                targetConfirmLocale.optionPageOnly.toLocaleUpperCase()
                            }
                            onClick={() => {
                                onApprovePage();
                                props.reject();
                            }}
                        />
                    </div>
                );
            },
        });

    return show;
};

export default useShowTargetActionDisambiguation;
