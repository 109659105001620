import { useLocalization } from 'hooks/context/useLocalization';
import { format as formatDate } from 'date-fns';

const ShowOperation: React.FC<{
    operation?: Ether.CaseManager.Operation | null | undefined;
}> = ({ operation }) => {
    const [localization] = useLocalization();
    if (!operation) return null;

    return (
        <h4>
            {localization.models.operation.singular}: {operation.name} -{' '}
            {localization.fields.operation.created}{' '}
            {formatDate(operation.created_at, 'dd/MM/yyyy')}
        </h4>
    );
};

export default ShowOperation;
