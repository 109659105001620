import { useLocalization } from 'hooks/context/useLocalization';

const CellDisplayDone = () => {
    const [localization] = useLocalization();
    return (
        <div className='flex flex-row gap-1 text-green-500'>
            <i className='pi pi-check' />
            <span>
                {localization.components.models.target.badge.enrich.done}
            </span>
        </div>
    );
};

export default CellDisplayDone;
