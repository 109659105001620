import { IPaginator, Paginator } from 'components/ethercity-primereact';
import { useLocalization } from 'hooks/context/useLocalization';

const CMPaginator: React.FC<IPaginator> = (props) => {
    const [localization] = useLocalization();

    return (
        <Paginator
            style={{ marginTop: '8px' }}
            showRefresh
            rowsPerPageOptions={[20, 50, 100]}
            labelOptions={{
                refresh: localization.components.common.datatable.refresh,
                next: localization.components.common.datatable.next,
                previous: localization.components.common.datatable.previous,
                maxPageCount: localization.components.common.datatable.maxPages,
            }}
            {...props}
        />
    );
};

export default CMPaginator;
