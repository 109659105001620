import { confirmDialog } from 'primereact/confirmdialog';
import { useMutation } from '@tanstack/react-query';
import { useToast } from '../../context/useToast';
import { useLocalization } from '../../context/useLocalization';
import { respondBlockOrder } from 'services/ether/case-manager/block-orders';
import { getErrorToast } from 'utils/errorHandler';
import useQueryRefresh from 'hooks/queries/useQueryRefresh';

const useBlockOrderRespond = (options?: {
    token?: string;
    onRespond?: () => void;
}): [
    (blockOrder: Ether.CaseManager.BlockOrder, accept: boolean) => void,
    {
        isLoading: boolean;
        error: Error | null;
    }
] => {
    const { token, onRespond } = options ?? {};

    const [localization] = useLocalization();
    const { hardRefresh, softRefresh } = useQueryRefresh();
    const toast = useToast();
    const viewLocale = localization.components.models.blockOrder.views.respond;
    const endpointLocale = localization.endpoints.order.accept_order;

    const blockOrderRespondMutation = useMutation<
        Ether.CaseManager.BlockOrder,
        Error,
        { blockOrderId: string; accepted: boolean }
    >({
        mutationFn: (data) => {
            if (token)
                return respondBlockOrder({
                    accepted: data.accepted,
                    token: token,
                });
            if (data.blockOrderId)
                return respondBlockOrder({
                    accepted: data.accepted,
                    _id: data.blockOrderId,
                });
            throw new Error('Must supply token or blockOrderId');
        },
        onSuccess: (_, params) => {
            toast.show({
                summary: params.accepted
                    ? endpointLocale.approvedBlockOrder
                    : endpointLocale.rejectedBlockOrder,
                severity: 'success',
                life: 5000,
            });
            softRefresh(['metrics']);
            hardRefresh(['block-order']);
            if (onRespond) onRespond();
        },
        onError: (err) => toast.show(getErrorToast(err.message, localization)),
    });

    const onRespondBlockOrder = (
        blockOrder: Ether.CaseManager.BlockOrder,
        accept: boolean
    ) => {
        const message = (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <span>
                    {viewLocale.summary.replace(
                        '{blockOrderName}',
                        blockOrder.name
                    )}
                </span>
                <span>{viewLocale.detail}</span>
            </div>
        );

        const answer = accept
            ? localization.components.common.button.confirm
            : localization.components.common.button.reject;

        confirmDialog({
            header: viewLocale.header.replace('{answer}', answer),
            message: message,
            accept: () =>
                blockOrderRespondMutation.mutate({
                    blockOrderId: blockOrder._id,
                    accepted: accept,
                }),
            acceptLabel: answer,
            rejectLabel: localization.components.common.button.cancel,
        });
    };
    return [
        onRespondBlockOrder,
        {
            isLoading: blockOrderRespondMutation.isPending,
            error: blockOrderRespondMutation.error,
        },
    ];
};

export { useBlockOrderRespond };
