import { filesize } from 'filesize';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useState } from 'react';
import { downloadEvidenceFile } from 'services/ether/case-manager/evidences';
import { useQuery } from '@tanstack/react-query';
import { useLocalization } from 'hooks/context/useLocalization';
import { downloadDocumentFile } from 'services/ether/case-manager/documents';
import { useToast } from 'hooks/context/useToast';
import { getErrorToast } from 'utils/errorHandler';
import { handleFileModelDownload } from 'utils/file';
import { FileDownload } from 'services/ether/base';

const PcapDetail: React.FC<{
    data: {
        summary: string;
        type: string;
        source: string;
        destination: string;
        source_ip: string;
        destination_ip: string;
        source_port: number;
        destination_port: number;
        connections_count: number;
        bytes_total: number;
    };
}> = ({ data }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <div className='flex flex-row gap-2'>
            <Button
                icon={isExpanded ? 'pi pi-minus' : 'pi pi-plus'}
                onClick={() => setIsExpanded((old) => !old)}
            />
            <div className='flex flex-col'>
                <span>{data.summary}</span>
                <span>
                    Connections count: <strong>{data.connections_count}</strong>
                </span>
                <span>
                    Total bytes: <strong>{data.bytes_total}</strong>
                </span>
                {isExpanded && (
                    <>
                        <span>Type: {data.type}</span>
                        <span>
                            Source {data.source} {'->'} Destination:{' '}
                            {data.destination}
                        </span>
                        <span>
                            Source IP {data.source_ip} {'->'} Destination IP:{' '}
                            {data.destination_ip}
                        </span>
                        <span>
                            Source Port {data.source_port} {'->'} Destination
                            Port: {data.destination_port}
                        </span>
                    </>
                )}
            </div>
        </div>
    );
};

const PcapViewer: React.FC<{
    parsedData: {
        len_total: number;
        len_unique: number;
        result: {
            summary: string;
            type: string;
            source: string;
            destination: string;
            source_ip: string;
            destination_ip: string;
            source_port: number;
            destination_port: number;
            connections_count: number;
            bytes_total: number;
        }[];
    };
}> = ({ parsedData }) => {
    const { result } = parsedData;
    return (
        <section className='max-h-[58vh] overflow-scroll w-full text-sm flex flex-col gap-4'>
            {result.map((r) => (
                <PcapDetail data={r} />
            ))}
        </section>
    );
};

const FileViewer: React.FC<{
    item:
        | Ether.CaseManager.Document.Detailed
        | Ether.CaseManager.Evidence.Detailed;
    mode: 'document' | 'evidence';
    token?: string;
    showCloseButton?: boolean;
    onClose?: () => void;
}> = ({ item, mode, token, showCloseButton, onClose }) => {
    const [downloading, setDownloading] = useState(false);
    const [localization] = useLocalization();
    const toast = useToast();

    const isImage = item?.type?.startsWith('image/') ?? false;
    const isPcap =
        item &&
        'type' in item &&
        (item.extension?.endsWith('pcap') ||
            item.extension?.endsWith('pcapng'));

    const isPreviewable = isImage;
    const isParsePreviable = isPcap;

    const downloadMethod = () => {
        if (!item) return new Promise<null>((resolve) => resolve(null));
        if (mode === 'document')
            return downloadDocumentFile(item._id, { token });
        else
            return downloadEvidenceFile(item._id, item.file.id, {
                token,
            });
    };

    const fileDataQuery = useQuery<FileDownload.Blob.Item | null, Error>({
        queryKey: ['model-file', mode, item._id],
        queryFn: () => {
            return downloadMethod();
        },
        enabled: isImage,
        retry: 1,
    });

    let thumbnailUrl: string | null = null;

    if (fileDataQuery.data) {
        const url = URL.createObjectURL(fileDataQuery.data.file);
        thumbnailUrl = url;
    }

    const onDownloadClick = () => {
        setDownloading(true);
        downloadMethod()
            .then((data) => {
                if (!data) return;
                handleFileModelDownload({
                    file: data.file,
                    item: item,
                    extension: data.extension,
                });
            })
            .catch((err) =>
                toast.show(getErrorToast(err.message, localization))
            )
            .finally(() => setDownloading(false));
    };

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}
        >
            {isImage && (
                <span
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    className={thumbnailUrl ? 'expandable-image' : undefined}
                >
                    {thumbnailUrl ? (
                        <img
                            src={thumbnailUrl}
                            aria-label={`File ${item?.file.name}`}
                            style={{ maxWidth: '80vw' }}
                        />
                    ) : (
                        <ProgressSpinner
                            style={{ width: '100%', height: '100%' }}
                        />
                    )}
                </span>
            )}
            {!isPreviewable && !isParsePreviable && (
                <>
                    <span>
                        {
                            localization.components.views.file.preview
                                .noPreviewIntro
                        }
                    </span>
                    <span>
                        {
                            localization.components.views.file.preview
                                .noPreviewDescription
                        }
                    </span>
                </>
            )}
            {fileDataQuery.error && (
                <>
                    <span>
                        {
                            localization.components.views.file.preview
                                .filePreviewFailed
                        }
                    </span>
                    {/* <span>{fileDataQuery.error.message}</span> */}
                </>
            )}
            {isPreviewable && fileDataQuery.isLoading && (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '8px',
                    }}
                >
                    <ProgressSpinner
                        style={{ width: '30px', height: '30px' }}
                        strokeWidth='4'
                    />
                    <span>{localization.common.loading}...</span>
                </div>
            )}

            {isParsePreviable && item.parsed_data == null && (
                <>
                    <span>
                        {
                            localization.components.views.file.preview
                                .fileBeingParsed
                        }
                    </span>
                    <span>
                        {
                            localization.components.views.file.preview
                                .noPreviewDescription
                        }
                    </span>
                </>
            )}
            {isParsePreviable &&
                item.parsed_data &&
                'error' in item.parsed_data && (
                    <>
                        <span>
                            {
                                localization.components.views.file.preview
                                    .fileParseError
                            }
                        </span>
                        <span>
                            {
                                localization.components.views.file.preview
                                    .noPreviewDescription
                            }
                        </span>
                    </>
                )}
            {isParsePreviable &&
            item.parsed_data &&
            !('error' in item.parsed_data) &&
            isPcap ? (
                <PcapViewer parsedData={item.parsed_data} />
            ) : null}
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    flexGrow: 1,
                    alignSelf: 'flex-start',
                }}
            >
                <strong>{item?.file.name}</strong>
                <span>{filesize(item?.file.size ?? 0)}</span>
            </div>
            <div
                style={{
                    display: 'flex',
                    gap: '8px',
                    width: '100%',
                }}
            >
                <Button
                    icon='pi pi-download'
                    loading={downloading}
                    onClick={onDownloadClick}
                    label={localization.components.common.button.download}
                    style={{ width: '100%' }}
                />
                {showCloseButton && (
                    <Button
                        icon='pi pi-times'
                        onClick={onClose}
                        label={localization.components.common.button.close}
                        style={{ width: '100%' }}
                    />
                )}
            </div>
        </div>
    );
};

export default FileViewer;
