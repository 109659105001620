import { useMutation } from '@tanstack/react-query';
import useQueryRefresh from 'hooks/queries/useQueryRefresh';
import { useLocalization } from 'hooks/context/useLocalization';
import { useToast } from 'hooks/context/useToast';
import { joinAuthorization } from 'services/ether/case-manager/authorizations';
import { JoinAuthorization } from 'services/ether/case-manager/authorizations/types';
import { getErrorToast } from 'utils/errorHandler';

const useJoinAuthorization = (options?: {
    onJoin?: () => void;
}): [
    (data: JoinAuthorization.Data) => void,
    {
        isLoading: boolean;
        error: Error | null;
    }
] => {
    const [localization] = useLocalization();
    const toast = useToast();

    const { hardRefresh, softRefresh } = useQueryRefresh();

    const handleUpdate = ({
        data,
        params,
    }: {
        data: JoinAuthorization.Result;
        params: JoinAuthorization.Data;
    }) => {
        hardRefresh(['authorization'])
        softRefresh(['metrics']);
        toast.show({
            summary:
                localization.endpoints.authorization.join_authorization.success,
            detail: localization.endpoints.authorization.join_authorization.successDetails
                .replace('{count}', data.targets_count.toString())
                .replace('{name}', data.new_authorization.name),
            severity: 'success',
            life: 10000,
        });
        if (options?.onJoin) options.onJoin();
    };

    const joinAuthorizationMutation = useMutation<
        JoinAuthorization.Result,
        Error,
        JoinAuthorization.Data
    >({
        mutationFn: (data) => joinAuthorization(data),
        onSuccess: (data, params) => handleUpdate({ data, params }),
        onError: (err) => toast.show(getErrorToast(err.message, localization)),
    });

    const joinAuthorizations = (data: JoinAuthorization.Data) => {
        joinAuthorizationMutation.mutate(data);
    };

    return [
        joinAuthorizations,
        {
            error: joinAuthorizationMutation.error,
            isLoading: joinAuthorizationMutation.isPending,
        },
    ];
};

export default useJoinAuthorization;
