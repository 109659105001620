import { useMutation } from '@tanstack/react-query';
import useQueryRefresh from 'hooks/queries/useQueryRefresh';
import { useLocalization } from 'hooks/context/useLocalization';
import { useToast } from 'hooks/context/useToast';
import { insertUser } from 'services/ether/case-manager/users';
import { InsertUserEP } from 'services/ether/case-manager/users/types';
import { getErrorToast } from 'utils/errorHandler';

const useInsertUser = ({
    onInsert,
}: {
    onInsert?: () => void;
}): {
    insertUser: (data: InsertUserEP.Data) => void;
    isLoading: boolean;
    error: Error | null;
} => {
    const [localization] = useLocalization();
    const toast = useToast();

    const { hardRefresh, softRefresh } = useQueryRefresh();

    const handleUpdate = ({
        data,
    }: {
        data: InsertUserEP.Result;
        params: InsertUserEP.Data;
    }) => {
        hardRefresh(['user'])
        softRefresh(['company']);
        toast.show({
            summary: localization.endpoints.user.upsert_user.createSuccess,
            severity: 'success',
            life: 10000,
        });
        if (onInsert) onInsert();
    };

    const insertUserMutation = useMutation<
        InsertUserEP.Result,
        Error,
        InsertUserEP.Data
    >({
        mutationFn: (data) => insertUser(data),
        onSuccess: (data, params) => handleUpdate({ data, params }),
        onError: (err) => toast.show(getErrorToast(err.message, localization)),
    });

    const sendUser = (data: InsertUserEP.Data) => {
        insertUserMutation.mutate(data);
    };

    return {
        insertUser: sendUser,
        error: insertUserMutation.error,
        isLoading: insertUserMutation.isPending,
    };
};

export default useInsertUser;
