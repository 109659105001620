import { useLocalization } from 'hooks/context/useLocalization';
import { ListAuthorizationEP } from 'services/ether/case-manager/authorizations/types';
import { useProject } from 'hooks/context/project/useProject';
import useListAuthorization from 'hooks/queries/authorization/useListAuthorization';
import { SelectManyItemsProps } from 'components/form/SelectItems/types';
import SelectManyItems from 'components/form/SelectItems/SelectManyItems';

const SelectManyAuthorizations: React.FC<
    SelectManyItemsProps<
        Ether.CaseManager.Authorization,
        ListAuthorizationEP.Filters.Options
    >
> = ({ ...props }) => {
    const [localization] = useLocalization();
    const project = useProject();
    const query = useListAuthorization({
        project_id: project._id,
        options: {
            ...props.queryOptions,
            limit: 9999,
        },
    });

    return (
        <SelectManyItems
            title={localization.models.authorization.plural}
            placeholder={
                localization.components.models.authorization.form.selectManyPlaceholder
            }
            label={localization.models.authorization.plural}
            items={query.data}
            itemsLoading={query.isFetching}
            {...props}
        />
    );
};

export default SelectManyAuthorizations;
