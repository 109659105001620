import {
    exportTargetFromAuthorization,
    exportTargetFromBlockOrder,
} from 'services/ether/case-manager/targets';
import useBaseFileExport from '../useBaseFileExport';
import { DetailTargetEP } from 'services/ether/case-manager/targets/types';

const useExportTarget = (
    options: DetailTargetEP.Filters.Options,
    fields:
        | {
              authorizationId: string;
          }
        | {
              blockOrderId: string;
          }
) => {
    const queryKey = {
        ...options,
        ...fields,
    };

    const { exportFile, isExporting } = useBaseFileExport(
        'export-targets-file',
        queryKey,
        ({ signal }) => {
            if ('authorizationId' in fields) {
                return exportTargetFromAuthorization(
                    fields.authorizationId,
                    options,
                    signal
                );
            }
            return exportTargetFromBlockOrder(
                fields.blockOrderId,
                options,
                signal
            );
        }
    );

    return { exportFile: () => exportFile({ params: null }), isExporting };
};

export default useExportTarget;
