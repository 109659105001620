import { useMutation } from '@tanstack/react-query';
import { useLocalization } from 'hooks/context/useLocalization';
import { useToast } from 'hooks/context/useToast';
import { parseTargetList } from 'services/ether/case-manager/targets';
import { ParseTargetListEP } from 'services/ether/case-manager/targets/types';
import { getErrorToast } from 'utils/errorHandler';

type Result = ParseTargetListEP.Result;
type Params = ParseTargetListEP.Data;

const useTargetParseList = ({
    onUpload,
}: {
    onUpload?: (result: Result, params: Params) => void;
}): {
    submitTargetsList: (params: Params) => void;
    isLoading: boolean;
    error: Error | null;
} => {
    const [localization] = useLocalization();
    const toast = useToast();

    // const handleDataForToast = ({
    //     data,
    //     params,
    // }: {
    //     data: Result;
    //     params: Params;
    // }) => {
    //     const defaultToastMessage: ToastMessage = {
    //         life: 10000,
    //         severity: 'error',
    //     };

    //     const messages: JSX.Element[] = [];

    //     const okCount = data.filter((t) => !t.errors?.length).length;

    //     if (!errorsCount) return;

    //     if (okCount) {
    //         defaultToastMessage.severity = 'warn';
    //         defaultToastMessage.summary =
    //             localization.endpoints.target.parse_target_file.warning.partial;
    //     } else {
    //         defaultToastMessage.severity = 'error';
    //         defaultToastMessage.summary =
    //             localization.endpoints.target.parse_target_file.error.failed;
    //     }

    //     const newCountMessage =
    //         localization.endpoints.target.parse_target_file.success.newCount;
    //     const duplicatedCountMessage =
    //         localization.endpoints.target.parse_target_file.success
    //             .duplicatedCount;
    //     const errorCountMessage =
    //         localization.endpoints.target.parse_target_file.success.errorCount;
    //     messages.push(
    //         <span className='text-s'>
    //             {(data.new_count === 1
    //                 ? newCountMessage.singular
    //                 : newCountMessage.plural
    //             ).replace('{count}', data.new_count.toString())}
    //         </span>
    //     );
    //     messages.push(
    //         <span className='text-s'>
    //             {(data.duplicated_count === 1
    //                 ? duplicatedCountMessage.singular
    //                 : duplicatedCountMessage.plural
    //             ).replace('{count}', data.duplicated_count.toString())}
    //         </span>
    //     );
    //     messages.push(
    //         <span className='text-s'>
    //             {(data.error_count === 1
    //                 ? errorCountMessage.singular
    //                 : errorCountMessage.plural
    //             ).replace('{count}', data.error_count.toString())}
    //         </span>
    //     );
    //     if (data.errors.length > 0) {
    //         defaultToastMessage.severity =
    //             data.formatted.length === 0 ? 'error' : 'warn';
    //         defaultToastMessage.summary =
    //             data.formatted.length === 0
    //                 ? localization.endpoints.target.parse_target_file.error
    //                       .failed
    //                 : localization.endpoints.target.parse_target_file.warning
    //                       .partial;
    //         messages.push(
    //             <b className='my-1'>
    //                 {
    //                     localization.endpoints.target.parse_target_file.error
    //                         .failedDetails
    //                 }
    //             </b>
    //         );
    //         messages.push(getCsvLineErrors(data.errors, localization));
    //     }
    //     defaultToastMessage.detail = (
    //         <div className='flex flex-col'>
    //             {messages.map((m, i) => (
    //                 <span key={i}>{m}</span>
    //             ))}
    //         </div>
    //     );
    //     toast.show(defaultToastMessage);
    // };

    const parseTargetListMutation = useMutation<Result, Error, Params>({
        mutationFn: (data) => parseTargetList(data),
        onSuccess: (data, params) => {
            if (onUpload) onUpload(data, params);
        },
        onError: (err) => toast.show(getErrorToast(err.message, localization)),
    });

    const submitTargetsList = (params: Params) =>
        parseTargetListMutation.mutate(params);

    return {
        submitTargetsList,
        error: parseTargetListMutation.error,
        isLoading: parseTargetListMutation.isPending,
    };
};

export default useTargetParseList;
